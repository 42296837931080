import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import styled from "@emotion/styled";
import { Button, CircularProgress } from "@material-ui/core";
import { Update, Restore, RotateLeft, RotateRight } from "@material-ui/icons";
import moment, { now } from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useRecoilValue } from "recoil";
import FormDateTimePicker from "../../../../../../../../components/Form/FormDateTimePicker/FormDateTimePicker";
import FormInput from "../../../../../../../../components/Form/FormInput/FormInput";
import FormSelect from "../../../../../../../../components/Form/FormSelect/FormSelect";
import { useCountdown } from "../../../../../../../../hooks/models/useCountdown";
import useNotify from "../../../../../../../../hooks/tools/useNotify";
import { i18n } from "../../../../../../../../i18n/i18nText";
import { isMobile } from "../../../../../../../../mobileConfig";
import {
  PivotConfigFamily,
  PivotGPSStreamFamily,
} from "../../../../../../../../recoils/PivotRecoil";
import { ApplicationState } from "../../../../../../../../redux";
import {
  hideLoading,
  showLoading,
} from "../../../../../../../../redux/loading/actions";
import { hideModal } from "../../../../../../../../redux/modal/actions";
import {
  ControllerConfig,
  ControllerStream,
  Pivot,
} from "../../../../../../../../redux/pivots/types";
import { coreHTTPClient } from "../../../../../../../../services/webclient";
import { PTPToMillimeter } from "../../../../../../../../utils/models/pivots";
import ConfirmationModal from "../../../../../../../Auth/SignUp/ConfirmationModal/ConfirmationModal";
import { ZOOM_TABLE } from "../../../../../utils/utils";
import {
  handleNumberMask,
  hasValidationErrors,
  validadeFloatInput,
} from "../../../HistoricBox/utils/utils";
import SegmentModeSelect from "./components/SegmentModeSelect/SegmentModeSelect";
import SegmentSpeedInputs from "./components/SegmentSpeedInputs/SegmentSpeedInputs";
import "./SegmentIrrigationV5.scss";
import RedoIcon from "@material-ui/icons/Redo";
import LinearProgress from "@material-ui/core/LinearProgress";
import { ResponsivePie } from "@nivo/pie";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { formatFloatValue } from "../../../../../../../../utils/models/format";
// import FormCheckBox from "../../../../../../../../components/Form/FormCheckBox/FormCheckBox";
// import FormOutlinedInput from "../../../../../../../../components/Form/FormOutlinedInput/FormOutlinedInput";

const StyledToggleButtonGroup = styled(ToggleButtonGroup)`
  margin: 15px 0 !important;
  /* margin-left: -25px !important; */
`;

interface Props {
  pivot: Pivot;
  farmID: number;
  sectorEndAngle?: string;
}

type Data = {
  id: string;
  label: string;
  value: number;
  color?: string;
  mode_forward: string;
  mode_reverse: string;
  index: number;
};

const wetColors = [
  "#002780DD",
  "#3356a5DD",
  "#6688d8DD",
  "#99bdffDD",
  "#b8cdffDD",
];
const dryColors = [
  "#59370fDD",
  "#75481bDD",
  "#694725DD",
  "#614b22DD",
  "#805300DD",
];

/**
 * V5_SEGMENTS_START_MODE = ((0, "Now"), (1, "On a date"))
 * V5_SEGMENTS_END_MODE = ((0, "Never ends"), (2, "On a date"), (3, "End segments"), (4, "End rounds"))
 */

// ANO 2000 é inicia agora, forward seta a frente, reverso ao contrario, linha divisória cinza

function SegmentIrrigationV5(props: Props) {
  const { farmID, pivot, sectorEndAngle } = props;

  const notify = useNotify();

  const dispatch = useDispatch();

  const currentGPSStream = useRecoilValue(PivotGPSStreamFamily(pivot.id));

  const [isLoadingLastAction, setIsLoadingLastAction] = useState(false);

  const [counter, setCounter] = useCountdown("Pivot", String(pivot.id));

  const [startMode, setStartMode] = useState("now");

  const [endMode, setEndMode] = useState("0");

  const [startTime, setStartTime] = useState(moment());

  const [endTime, setEndTime] = useState(moment());

  const [endRounds, setEndRounds] = useState("0");

  const [percentageForwardArray, setPercentageForwardArray] = useState<any[]>(
    new Array(pivot.controllerconfig.content?.segments.length).fill("100")
  );

  const [percentageReverseArray, setPercentageReverseArray] = useState<any[]>(
    new Array(pivot.controllerconfig.content?.segments.length).fill("100")
  );

  const [irrigationModeForwardArray, setIrrigationModeForwardArray] = useState<
    any[]
  >(new Array(pivot.controllerconfig.content?.segments.length).fill("2"));

  const [irrigationModeReverseArray, setIrrigationModeReverseArray] = useState<
    any[]
  >(new Array(pivot.controllerconfig.content?.segments.length).fill("2"));

  const [segmentModes, setSegmentModes] = useState<any>(undefined);
  const [injectionPumpStatus, setInjectionPumpStatus] = useState(false);
  const [injectionPumpNote, setInjectionPumpNote] = useState("");

  const setValidIrrigationModeForwardArray = useCallback(
    (value: any[]) => {
      setIrrigationModeForwardArray(value);
      if (irrigationModeForwardArray[selectedSegment.index] === "2") {
        setInjectionPumpStatus(false);
        setInjectionPumpNote("");
      }
    },
    [irrigationModeForwardArray]
  );

  const setValidIrrigationModeReverseArray = useCallback(
    (value: any[]) => {
      setIrrigationModeReverseArray(value);
      if (irrigationModeReverseArray[selectedSegment.index] === "2") {
        setInjectionPumpStatus(false);
        setInjectionPumpNote("");
      }
    },
    [irrigationModeReverseArray]
  );

  const [stopAngle, setStopAngle] = useState(sectorEndAngle);

  const [segmentsHasNaNValues, setSegmentsHasNaNValues] = useState(false);

  const [confirmationForwardModal, setConfirmationForwardModal] = useState(
    false
  );
  const [confirmationReverseModal, setConfirmationReverseModal] = useState(
    false
  );

  const [direction, setDirection] = useState(true); // true = avanço | false = reverso

  const chartRef = useRef<HTMLDivElement>(null);

  const [selectedSegment, setSelectedSegment] = useState<any>({
    index: 0,
    color: wetColors[0],
  });
  // Center In String
  let centerInString =
    parseFloat(
      pivot.controllerconfig.content.pivot_positions.latitude_center
    ).toString() +
    "," +
    parseFloat(
      pivot.controllerconfig.content.pivot_positions.longitude_center
    ).toString();

  //Zoom Level Reference
  let center = new google.maps.LatLng(
    parseFloat(pivot.controllerconfig.content.pivot_positions.latitude_center),
    parseFloat(pivot.controllerconfig.content.pivot_positions.longitude_center)
  );

  let reference = new google.maps.LatLng(
    parseFloat(
      pivot.controllerconfig.content.pivot_positions.latitude_reference
    ),
    parseFloat(
      pivot.controllerconfig.content.pivot_positions.longitude_reference
    )
  );

  // Nos testes feitos como não era um caso de um pivo real as lat e lng estavam erradas então fiz um tratamento de erros
  // nos pivos oficiais não será necessário porem não atrapalha em nada
  let pivotLine = new google.maps.LatLng(
    parseFloat(
      pivot.controllerstream_gps.content.latitude_longitude_gps.latitude_gps
    ),
    parseFloat(
      pivot.controllerstream_gps.content.latitude_longitude_gps.longitude_gps
    )
  );

  let pivotAngle = google.maps.geometry.spherical.computeHeading(
    center,
    pivotLine
  );

  let referenceRadius = google.maps.geometry.spherical.computeDistanceBetween(
    center,
    reference
  );

  let pivotLineWithRadius = google.maps.geometry.spherical.computeOffset(
    center,
    referenceRadius,
    pivotAngle
  );

  let triangleReferenceRadius = google.maps.geometry.spherical.computeDistanceBetween(
    center,
    pivotLineWithRadius
  );

  // Triangle
  const referenceTriangleBottom = google.maps.geometry.spherical.computeOffset(
    center,
    triangleReferenceRadius + 30,
    pivot.reference_angle - 7
  );

  const referenceTriangleTop = google.maps.geometry.spherical.computeOffset(
    center,
    referenceRadius + 30,
    pivot.reference_angle + 7
  );

  const referenceTriangleCenter = google.maps.geometry.spherical.computeOffset(
    center,
    (referenceRadius + 30) / 1.3,
    pivot.reference_angle
  );

  const triangleCoords: string[] = [
    referenceTriangleBottom.lat().toString() +
      "," +
      referenceTriangleBottom.lng().toString(),
    referenceTriangleTop.lat().toString() +
      "," +
      referenceTriangleTop.lng().toString(),
    referenceTriangleCenter.lat().toString() +
      "," +
      referenceTriangleCenter.lng().toString(),
  ];

  const referenceZoom = referenceRadius < 12 ? 12 : referenceRadius;

  let zoomLevel = 0;
  for (let i = ZOOM_TABLE.length - 1; i >= 0; i--) {
    if (ZOOM_TABLE[i].distance <= referenceZoom) {
      zoomLevel = ZOOM_TABLE[i].zoomLevel;
    }
  }

  let currentPivotPosition =
    pivotLineWithRadius.lat().toString() +
    "," +
    pivotLineWithRadius.lng().toString();

  useEffect(() => {
    if (segmentModes) {
      var jsonString = JSON.stringify(segmentModes);
      var hasNaN = jsonString.includes("null");

      setSegmentsHasNaNValues(hasNaN);
    }
  }, [segmentModes]);

  const ResponsiveChart = () => {
    const startAngleChart = () => {
      if (pivot.controllerconfig.content.segments[0].angle_start == 0)
        return pivot.reference_angle - 360;
      else {
        let StartSegment =
          360 - pivot.controllerconfig.content.segments[0].angle_start;
        return pivot.reference_angle - 360 - StartSegment;
      }
    };

    const data = useMemo((): Data[] => {
      let responseData: Data[] = [];
      pivot.controllerconfig.content.segments.forEach((element, index) => {
        responseData.push({
          id: `${i18n("PIVOT_CONFIG_V5_FIELD_SEGMENT")}${index + 1}`,
          label: pivot.controllerconfig?.segments_crop?.[index]?.name
            ? pivot.controllerconfig?.segments_crop?.[index]?.name
            : `${i18n("PIVOT_CONFIG_V5_FIELD_SEGMENT")} ${index + 1}`,
          value:
            parseInt(element.angle_end) <= parseInt(element.angle_start)
              ? parseInt(element.angle_end) -
                (parseInt(element.angle_start) - 360)
              : parseInt(element.angle_end) - parseInt(element.angle_start),
          mode_forward: irrigationModeForwardArray[index],
          mode_reverse: irrigationModeReverseArray[index],
          index: index,
        });
      });

      if (pivot.controllerconfig?.content?.sector?.end_angle !== 360) {
        responseData.push({
          id: `${i18n("PIVOT_CONFIG_V5_FIELD_SEGMENT")}${0}`,
          label: pivot.controllerconfig?.segments_crop?.[0]?.name
            ? pivot.controllerconfig?.segments_crop?.[0]?.name
            : `${i18n("PIVOT_CONFIG_V5_FIELD_SEGMENT")} ${1}`,
          value: 360 - pivot.controllerconfig?.content?.sector?.end_angle,
          mode_forward: "-1",
          mode_reverse: "-1",
          index: -1,
        });
      }
      return responseData;
    }, [
      irrigationModeForwardArray,
      irrigationModeReverseArray,
      pivot.controllerconfig,
    ]);

    const getColor = useCallback(
      (sector) => {
        if (sector.data.index == -1) return "transparent";
        const mode = direction
          ? sector.data.mode_forward
          : sector.data.mode_reverse;
        if (mode == "1") {
          return wetColors[sector.data.index];
        } else {
          return dryColors[sector.data.index];
        }
      },
      [direction]
    );

    return (
      <div
        style={{ height: 270, marginTop: -290, opacity: 1, marginBottom: 20 }}
        ref={chartRef}
      >
        <ResponsivePie
          data={data}
          startAngle={startAngleChart()}
          innerRadius={0.75}
          padAngle={0.5}
          cornerRadius={3}
          enableSliceLabels={false}
          enableRadialLabels={false}
          colors={getColor}
          tooltip={(value) => {
            if (value.datum.data.index == -1) return <div></div>;
            return (
              <div
                style={{
                  backgroundColor: "white",
                  display: "flex",
                  flexDirection: "column",
                  padding: "10px",
                  borderRadius: "10px",
                }}
              >
                <div>
                  <span style={{ fontWeight: "bold" }}>
                    {pivot.controllerconfig?.segments_crop?.[
                      value.datum.arc.index
                    ]?.name
                      ? pivot.controllerconfig?.segments_crop?.[
                          value.datum.arc.index
                        ]?.name
                      : `${i18n("PIVOT_CONFIG_V5_FIELD_SEGMENT")}${
                          value.datum.arc.index + 1
                        }`}
                  </span>
                </div>
                <div style={{ color: "#808080" }}>
                  <span>{i18n("SIMPLE_IRRIGATION_FIELD_MODE")}: </span>
                  <span>
                    {(direction
                      ? irrigationModeForwardArray[value.datum.arc.index]
                      : irrigationModeReverseArray[value.datum.arc.index]) ==
                    "1"
                      ? i18n("SIMPLE_IRRIGATION_VALUE_WET")
                      : i18n("SIMPLE_IRRIGATION_VALUE_DRY")}
                  </span>
                </div>
                <div style={{ color: "#808080" }}>
                  <span>{i18n("PIVOT_ACTION_FIELD_PERCENT")}: </span>
                  <span>
                    {direction
                      ? percentageForwardArray[value.datum.arc.index]
                      : percentageReverseArray[value.datum.arc.index]}
                    %
                  </span>
                </div>
                <div style={{ color: "#808080" }}>
                  <span>{i18n("PIVOT_ACTION_FIELD_PRECIPITATION")}: </span>
                  <span>
                    {formatFloatValue(
                      PTPToMillimeter(
                        pivot,
                        direction
                          ? percentageForwardArray[value.datum.arc.index]
                          : percentageReverseArray[value.datum.arc.index]
                      )
                    )}
                    mm
                  </span>
                </div>
              </div>
            );
          }}
          onClick={(value) => {
            if (value.data.index === -1) return;
            setSelectedSegment({
              index: value.arc.index,
              color: getColor(value),
            });
          }}
          onMouseEnter={(value) => {
            if (value.data.index === -1) return;
            chartRef.current.style.cursor = "pointer";
          }}
          onMouseLeave={() => {
            chartRef.current.style.cursor = "default";
          }}
          defs={[
            {
              id: "lines",
              type: "patternLines",
              background: "green",
              color: "rgba(255, 255, 255, 0.3)",
              rotation: -45,
              lineWidth: 6,
              spacing: 10,
            },
          ]}
          fill={[
            {
              match: {
                id: `${i18n("PIVOT_CONFIG_V5_FIELD_SEGMENT")}${
                  selectedSegment.index + 1
                }`,
              },
              id: "lines",
            },
          ]}
        />
      </div>
    );
  };

  const setValidStopAngle = useCallback((value: string) => {
    if (value === "") {
      setStopAngle(value);
    } else if (value === "0") {
      setStopAngle(value);
    } else if (
      validadeFloatInput(value) &&
      !hasValidationErrors(
        value,
        0,
        props.pivot.controllerconfig.content?.sector?.end_angle
      )
    ) {
      setStopAngle(handleNumberMask(value));
    }
    // eslint-disable-next-line
  }, []);

  const pivotConfig = useRecoilValue(PivotConfigFamily(props.pivot.id));

  useEffect(() => {
    if (
      pivotConfig !== undefined &&
      (startMode === "after_pause_time_1" || startMode === "after_pause_time_2")
    ) {
      var config = pivotConfig as ControllerConfig;
      var hour;
      var minute;
      var second = 59;
      if (startMode === "after_pause_time_1") {
        hour = config.content?.pause_time.end_pause_time_hour_1;
        minute = config.content?.pause_time.end_pause_time_minute_1;
      } else if (startMode === "after_pause_time_2") {
        hour = config.content?.pause_time.end_pause_time_hour_2;
        minute = config.content?.pause_time.end_pause_time_minute_2;
      }
      setStartTime(
        moment()
          .hour(hour)
          .minute(minute + 5)
          .second(second)
      );
    }
    if (startMode === "now") {
      setStartTime(moment());
    }
  }, [pivotConfig, startMode]);

  const handleStartModeValues = useCallback((startMode) => {
    if (startMode === "now") {
      return 0;
    } else {
      return 1;
    }
  }, []);

  const lastIrrigationUsed = async () => {
    setIsLoadingLastAction(true);
    const response = await coreHTTPClient.get(
      `v3/farms/${farmID}/pivots/${pivot.id}/controlleraction/segment/latest/`
    );
    if (response.data?.content) {
      if (
        pivot.controllerconfig.content.segments.length ==
        response.data.content.segment_modes.length
      ) {
        let lastIrrigationObj = response.data.content.segment_modes;
        let lastIrrigationParam =
          response.data.content.segment_irrigation_parameters;
        let arrayModeForward = lastIrrigationObj.map(
          (segment) => segment.mode_forward
        );
        let arrayPercentForward = lastIrrigationObj.map(
          (segment) => segment.percent_forward
        );
        setIrrigationModeForwardArray(arrayModeForward);
        let arrayModeReverse = lastIrrigationObj.map(
          (segment) => segment.mode_reverse
        );
        let arrayPercentReverse = lastIrrigationObj.map(
          (segment) => segment.percent_reverse
        );
        setIrrigationModeReverseArray(arrayModeReverse);
        setPercentageForwardArray(arrayPercentForward);
        setPercentageReverseArray(arrayPercentReverse);
        // Parametros Gerais
        if (lastIrrigationParam.start_mode == 1) {
          setStartMode("on_a_date");
          let startLastHour = moment(
            `20${lastIrrigationParam.start_time_year}-${lastIrrigationParam.start_time_month}-${lastIrrigationParam.start_time_day} ${lastIrrigationParam.start_time_hour}:${lastIrrigationParam.start_time_minute}`
          );
          if (startLastHour.isAfter(now())) setStartTime(startLastHour);
        } else setStartMode("now");
        setEndMode(lastIrrigationParam.end_mode.toString());
        if (lastIrrigationParam.end_mode == 1)
          setValidStopAngle(lastIrrigationParam.stop_angle.toString());
        if (lastIrrigationParam.end_mode == 2) {
          let endLastHour = moment(
            `20${lastIrrigationParam.end_time_year}-${lastIrrigationParam.end_time_month}-${lastIrrigationParam.end_time_day} ${lastIrrigationParam.end_time_hour}:${lastIrrigationParam.end_time_minute}`
          );
          if (endLastHour.isAfter(now())) setEndTime(endLastHour);
        }
        if (lastIrrigationParam.end_mode == 4)
          setValidEndRounds(lastIrrigationParam.rounds.toString());
      } else
        notify("SEGMENT_IRRIGATION_INCOMPATIBLE_IRRIGATIONS", "warning", 2000);
    } else notify("IRRIGATION_BOX_CONVENTIONAL_REDO_ERROR_MSG", "error");
    setIsLoadingLastAction(false);
  };

  const handleStartModeOptions = (): [string, string][] => {
    var config = pivotConfig as ControllerConfig;
    if (
      config?.content?.pause_time_command.pause_time_command === 1 &&
      config?.content?.pause_time.start_pause_time_hour_2 ===
        config?.content?.pause_time.end_pause_time_hour_2
    ) {
      return [
        ["now", "SEGMENT_IRRIGATION_MODE_NOW"], //now
        ["after_pause_time_1", "PIVOT_ACTION_FIELD_START_AFTER_PAUSE_TIME_1"], //after pause time hour 1
        ["on_a_date", "SEGMENT_IRRIGATION_ON_DATE"], //on a date
      ];
    } else if (
      config?.content?.pause_time_command.pause_time_command === 1 &&
      config?.content?.pause_time.start_pause_time_hour_2 !==
        config?.content?.pause_time.end_pause_time_hour_2
    ) {
      return [
        ["now", "SEGMENT_IRRIGATION_MODE_NOW"], //now
        ["after_pause_time_1", "PIVOT_ACTION_FIELD_START_AFTER_PAUSE_TIME_1"], //after pause time hour 1
        ["after_pause_time_2", "PIVOT_ACTION_FIELD_START_AFTER_PAUSE_TIME_2"], //after pause time hour 3
        ["on_a_date", "SEGMENT_IRRIGATION_ON_DATE"], //on a date
      ];
    }
    return [
      ["now", "SEGMENT_IRRIGATION_MODE_NOW"], //now
      ["on_a_date", "SEGMENT_IRRIGATION_ON_DATE"], //on a date
    ];
  };

  const setValidEndRounds = useCallback(
    (value) => {
      if (value === "") {
        setEndRounds(value);
      } else if (
        validadeFloatInput(value) &&
        !hasValidationErrors(value, 1, 99)
      ) {
        setEndRounds(handleNumberMask(value));
      }
      // eslint-disable-next-line
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    if (endMode === "1") {
      setEndRounds("1");
    } else if (endMode === "4") {
      setStopAngle("0");
    } else {
      setEndRounds("1");
      setStopAngle("0");
    }
  }, [endMode]);

  async function postSegmentIrrigation(turnDirection: "forward" | "reverse") {
    // Validations
    if (parseInt(stopAngle) > parseInt(sectorEndAngle)) {
      notify("SEGMENT_IRRIGATION_ERROR_END_ANGLE_OUT_OF_RANGE", "error");
      return;
    }

    try {
      var config = pivotConfig as ControllerConfig;
      const autoReversionCommand =
        config.content.autoreversion_command?.command || 0;

      let body = {
        message_subtype: "segment",
        equipment: pivot.control,
        //on year we subtract 2000 because the hardware can get only 1byte size var
        content: {
          injection_pump_command: {
            command: +injectionPumpStatus,
            note: injectionPumpNote,
          },
          autoreversion_command: {
            command: autoReversionCommand,
          },
          segment_irrigation_parameters: {
            start_mode: handleStartModeValues(startMode),
            end_mode: parseInt(endMode),
            rounds: parseInt(endRounds),

            start_time_day:
              startMode === "now" ? moment().date() : startTime.date(),
            start_time_month:
              startMode === "now"
                ? moment().month() + 1
                : startTime.month() + 1,
            start_time_year:
              startMode === "now"
                ? moment().year() - 2000
                : startTime.year() - 2000,
            start_time_hour:
              startMode === "now" ? moment().hour() : startTime.hour(),
            start_time_minute:
              startMode === "now" ? moment().minute() : startTime.minute(),
            end_time_day: endMode === "2" ? endTime.date() : 0,
            end_time_month: endMode === "2" ? endTime.month() + 1 : 0,
            end_time_year: endMode === "2" ? endTime.year() - 2000 : 0,
            end_time_hour: endMode === "2" ? endTime.hour() : 0,
            end_time_minute: endMode === "2" ? endTime.minute() : 0,
            stop_angle: parseInt(stopAngle),
          },
          segment_modes: segmentModes,
          irrigation_status: {
            irrigation_type: 3,
            irrigation_status: turnDirection === "forward" ? 1 : 2,
          },
        },
      };

      let bodyWithoutNullFields = {};
      Object.keys(body).forEach((field) => {
        if ((body as any)[field] !== null)
          (bodyWithoutNullFields as any)[field] = (body as any)[field];
      });
      dispatch(showLoading());
      const response = await coreHTTPClient
        .post(
          `v3/farms/${farmID}/pivots/${pivot.id}/device/${pivot.control}/action/`,
          bodyWithoutNullFields
        )
        .then(() => {
          dispatch(hideLoading());
          notify("SELECTED_PIVOT_SEGMENT_MODAL_SUCCESS", "success");
          setCounter(10);
          dispatch(hideModal());
        });
    } catch (err) {
      dispatch(hideLoading());
      notify("SELECTED_PIVOT_SEGMENT_MODAL_FAILURE", "error");
    }
  }

  let isLoading: boolean = useSelector(
    (state: ApplicationState) => state.loading.data?.isLoading
  );

  useEffect(() => {
    var segmentModeArray = [];
    pivot.controllerconfig.content?.segments.map((segment, index) => {
      var segmentMode = {
        number_editing: index,
        percent_forward: parseInt(percentageForwardArray[index]),
        percent_reverse: parseInt(percentageReverseArray[index]),
        mode_forward: parseInt(irrigationModeForwardArray[index]),
        mode_reverse: parseInt(irrigationModeReverseArray[index]),
      };
      segmentModeArray.push(segmentMode);
    });
    setSegmentModes(segmentModeArray);
  }, [
    percentageForwardArray,
    percentageReverseArray,
    irrigationModeForwardArray,
    irrigationModeReverseArray,
  ]);

  return (
    <div className="segment-modal">
      {isLoading && <LinearProgress />}
      <div
        style={{
          width: "100%",
          padding: "10px",
          borderRadius: "5px",
          textAlign: "center",
        }}
      >
        <Button
          variant="contained"
          color="primary"
          disabled={isLoadingLastAction}
          endIcon={
            isLoading ? (
              <CircularProgress size={20} style={{ marginLeft: "8px" }} />
            ) : (
              <RedoIcon />
            )
          }
          onClick={lastIrrigationUsed}
        >
          <>{i18n("REDO_CONVENTIONAL_ACTION")}</>
        </Button>
      </div>
      <div
        style={{
          textAlign: "center",
          width: "100%",
          marginBottom: "10px",
          marginTop: "10px",
          fontSize: "14px",
        }}
      >
        {i18n("CURRENT_ANGLE")}{" "}
        {
          (currentGPSStream as ControllerStream)?.content?.current_angle
            ?.current_angle
        }
        º
      </div>
      <div className="segment-modal__form-grid">
        <FormSelect
          id="segment-start-mode"
          label={"SEGMENT_IRRIGATION_FIELD_START_MODE"}
          state={[startMode, setStartMode]}
          values={handleStartModeOptions()}
        />

        <FormDateTimePicker
          id="segment-start-date"
          minDate={moment()}
          label={"SEGMENT_IRRIGATION_FIELD_START_DATE"}
          state={[startTime, setStartTime]}
          disabled={startMode === "now"}
        />

        <FormSelect
          id="segment-end-mode"
          label={"SEGMENT_IRRIGATION_FIELD_END_MODE"}
          state={[endMode, setEndMode]}
          values={[
            ["0", "SEGMENT_IRRIGATION_NEVER_ENDS"], //never ends
            ["1", "SEGMENT_IRRIGATION_BY_ANGLE"], //stop by angle
            ["2", "SEGMENT_IRRIGATION_BY_DATE"], //stop by date
            ["3", "SEGMENT_IRRIGATION_END_SECTOR"], //stop end sector
            ["4", "SEGMENT_IRRIGATION_BY_ROUNDS"], //stop by rounds
          ]}
        />
        {endMode === "2" ? (
          <FormDateTimePicker
            id="segment-end-time"
            minDate={startTime}
            label={"SEGMENT_IRRIGATION_FIELD_END_BY_TIME"}
            state={[endTime, setEndTime]}
          />
        ) : null}
        {endMode === "1" ? (
          <FormInput
            id="segment-stop-angle"
            label={"SEGMENT_IRRIGATION_FIELD_END_ANGLE"}
            state={[stopAngle, setValidStopAngle]}
            inputMode="numeric"
          />
        ) : null}
        {endMode === "4" ? (
          <FormInput
            state={[endRounds, setValidEndRounds]}
            id="segment-end-rounds"
            label={"SEGMENT_IRRIGATION_FIELD_END_ROUNDS"}
            inputMode="numeric"
          />
        ) : null}
      </div>
      <div>
        <StyledToggleButtonGroup
          value={direction}
          exclusive
          onChange={() => {
            setDirection((state) => !state);
          }}
          size="medium"
          aria-label="direction"
          style={{ width: isMobile() ? 280 : 500 }}
        >
          <ToggleButton
            value={false}
            aria-label="Reverse"
            style={{ width: isMobile() ? 140 : 250 }}
          >
            <RotateLeft style={{ marginRight: "5px" }} />
            {i18n("PIVOT_SEGMENT_CONFIG_REVERSE")}
          </ToggleButton>
          <ToggleButton
            value={true}
            aria-label="Progress"
            style={{ width: isMobile() ? 140 : 250 }}
          >
            <RotateRight style={{ marginRight: "5px" }} />
            {i18n("PIVOT_SEGMENT_CONFIG_FORWARD")}
          </ToggleButton>
        </StyledToggleButtonGroup>
      </div>
      <div className={"segment-modal__row-6-6"}>
        <div>
          <div>
            <span style={{ color: "darkgrey", fontSize: 14 }}>
              {i18n("CLICK_ON_SEGMENT")}
            </span>
          </div>
          <img
            src={`https://maps.googleapis.com/maps/api/staticmap?center=${centerInString}&zoom=${zoomLevel}&size=${
              isMobile() ? "300" : "335"
            }x300&maptype=satellite&path=color:0xffffffff|weight:4|${
              triangleCoords[0]
            }|${triangleCoords[1]}|${triangleCoords[2]}|${
              triangleCoords[0]
            }&path=color:0x000000ff|weight:4|${centerInString}|${currentPivotPosition}${
              process.env.MAPS_KEY
            }`}
            style={{
              width: isMobile() ? "300px" : "335px",
              height: "300px",
              marginTop: 10,
            }}
          />
          <ResponsiveChart />
        </div>
        <div className="segment-modal__grid-selected-seg">
          <div
            style={{
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <span style={{ color: "darkgrey", marginBottom: 30 }}>
              {i18n("SELECTED_PIVOT_SEGMENT_TITLE")}:
            </span>
            <span style={{ fontWeight: "bold" }}>
              {pivot.controllerconfig?.segments_crop?.[selectedSegment?.index]
                ?.name
                ? pivot.controllerconfig?.segments_crop?.[
                    selectedSegment?.index
                  ]?.name
                : `${i18n("PIVOT_CONFIG_V5_FIELD_SEGMENT")}${
                    selectedSegment?.index + 1
                  }`}
            </span>
          </div>
          <div>
            <SegmentModeSelect
              mode={
                direction
                  ? irrigationModeForwardArray[selectedSegment.index]
                  : irrigationModeReverseArray[selectedSegment.index]
              }
              setModeArray={
                direction
                  ? setValidIrrigationModeForwardArray
                  : setValidIrrigationModeReverseArray
              }
              currentIndex={selectedSegment.index}
            />
            {/* <div style={{ display: "flex", alignItems: "flex-start" }}>
              {(direction
                ? irrigationModeForwardArray[selectedSegment.index]
                : irrigationModeReverseArray[selectedSegment.index]) === "1" &&
                pivot?.controllerconfig?.injection_pump && (
                  <FormCheckBox
                    label={"SIMPLE_IRRIGATION_FIELD_INJECTION_PUMP"}
                    state={[injectionPumpStatus, setInjectionPumpStatus]}
                  />
                )}
            </div>
            {injectionPumpStatus && (
              <FormOutlinedInput
                id="input"
                type="text"
                label={"IRRIGATION_FIELD_INJECTION_PUMP_NOTE"}
                max="200"
                helperText={
                  injectionPumpNote.length > 200
                    ? "ERROR_INVALID_AMOUNT_MAX"
                    : ""
                }
                state={[injectionPumpNote, setInjectionPumpNote]}
                multiline={true}
              />
            )} */}
            {/* TODO: dividir percent forward e reverse */}
            <SegmentSpeedInputs
              pivot={pivot}
              percentage={
                direction
                  ? percentageForwardArray[selectedSegment.index]
                  : percentageReverseArray[selectedSegment.index]
              }
              setPercentageArray={
                direction
                  ? setPercentageForwardArray
                  : setPercentageReverseArray
              }
              currentIndex={selectedSegment.index}
            />
          </div>
        </div>
      </div>
      <div className="segment-modal__grid-buttons">
        <Button
          disabled={
            (endMode === "2" && startMode !== "now" && startTime >= endTime) ||
            isLoading ||
            stopAngle === "" ||
            endRounds === "" ||
            segmentsHasNaNValues ||
            injectionPumpNote.length > 200
          }
          className="margin-left"
          variant="contained"
          color="primary"
          startIcon={<Restore />}
          onClick={() => {
            // REVERSE VALUE 2 IRRIGATION_DIRECTION
            setConfirmationReverseModal(true);
          }}
        >
          {i18n("PIVOT_SEGMENT_BEGIN_REVERSE")}
        </Button>
        <Button
          disabled={
            (endMode === "2" && startMode !== "now" && startTime >= endTime) ||
            isLoading ||
            stopAngle === "" ||
            endRounds === "" ||
            segmentsHasNaNValues ||
            injectionPumpNote.length > 200
          }
          className="margin-left"
          variant="contained"
          color="primary"
          endIcon={<Update />}
          onClick={() => {
            // FORWARD VALUE 1 IRRIGATION_DIRECTION
            setConfirmationForwardModal(true);
          }}
        >
          {i18n("PIVOT_SEGMENT_BEGIN_FORWARD")}
        </Button>
      </div>
      <ConfirmationModal
        openModal={confirmationForwardModal}
        setModal={setConfirmationForwardModal}
        text={"SEGMENT_IRRIGATION_CONFIRMATION_BUTTON_FORWARD"}
        handleConfirmation={() => postSegmentIrrigation("forward")}
      />
      <ConfirmationModal
        openModal={confirmationReverseModal}
        setModal={setConfirmationReverseModal}
        text={"SEGMENT_IRRIGATION_CONFIRMATION_BUTTON_REVERSE"}
        handleConfirmation={() => postSegmentIrrigation("reverse")}
      />
    </div>
  );
}

export default SegmentIrrigationV5;

import { i18n } from "../../i18n/i18nText";

export enum IMeterEventStreamStatusColor {
  YELLOW = "#ffd700",
  BLUE = "#4679ed", // BLUE
  RED = "#e92400", // RED
  GREEN = "#33c152", // GREEN
  ORANGE = "#ff8c00", // ORANGE
  BLACK = "#000000", // BLACK
}

export const getIMeterEventStreamStatusColor = (status: number) => {
  switch (status) {
    case 1:
      return IMeterEventStreamStatusColor.YELLOW;
    case 2:
      return IMeterEventStreamStatusColor.YELLOW;
    case 3:
      return IMeterEventStreamStatusColor.BLUE;
    case 176:
      return IMeterEventStreamStatusColor.ORANGE;
    case 177:
      return IMeterEventStreamStatusColor.ORANGE;
    case 208:
      return IMeterEventStreamStatusColor.RED;
    case 209:
      return IMeterEventStreamStatusColor.BLUE;
    case 210:
      return IMeterEventStreamStatusColor.ORANGE;
    case 211:
      return IMeterEventStreamStatusColor.ORANGE;
    case 212:
      return IMeterEventStreamStatusColor.RED;
    default:
      return IMeterEventStreamStatusColor.BLACK;
  }
};

export type IMeterStreamStatus = {
  number: number;
  text: string;
  color: IMeterEventStreamStatusColor;
};

export const getIMeterEventStreamStatus = (
  status: number
): IMeterStreamStatus => {
  switch (status) {
    case 1:
      return {
        number: status,
        text: i18n("HISTORIC_SIMPLE_IRRIGATION_INJECTION_PUMP_ON"),
        color: getIMeterEventStreamStatusColor(status),
      };
    case 2:
      return {
        number: status,
        text: i18n("METERSYSTEM_STATUS_2"),
        color: getIMeterEventStreamStatusColor(status),
      };
    case 3:
      return {
        number: status,
        text: i18n("METERSYSTEM_STATUS_3"),
        color: getIMeterEventStreamStatusColor(status),
      };
    case 176:
      return {
        number: status,
        text: i18n("METERSYSTEM_STATUS_176"),
        color: getIMeterEventStreamStatusColor(status),
      };
    case 177:
      return {
        number: status,
        text: i18n("METERSYSTEM_STATUS_177"),
        color: getIMeterEventStreamStatusColor(status),
      };
    case 208:
      return {
        number: status,
        text: i18n("METERSYSTEM_STATUS_208"),
        color: getIMeterEventStreamStatusColor(status),
      };
    case 209:
      return {
        number: status,
        text: i18n("METERSYSTEM_STATUS_209"),
        color: getIMeterEventStreamStatusColor(status),
      };
    case 210:
      return {
        number: status,
        text: i18n("HISTORIC_SIMPLE_IRRIGATION_INJECTION_PUMP_OFF"),
        color: getIMeterEventStreamStatusColor(status),
      };
    case 211:
      return {
        number: status,
        text: i18n("METERSYSTEM_STATUS_211"),
        color: getIMeterEventStreamStatusColor(status),
      };
    case 212:
      return {
        number: status,
        text: i18n("METERSYSTEM_STATUS_212"),
        color: getIMeterEventStreamStatusColor(status),
      };
    default:
      return {
        number: status,
        text: i18n("PANEL_STREAM_STATUS_NONE"),
        color: getIMeterEventStreamStatusColor(status),
      };
  }
};

import React, {
  useState,
  useCallback,
  useEffect,
  useContext,
  useMemo,
} from "react";
import PropTypes from "prop-types";
import DashboardBox from "../../../../components/DashboardBox/DashboardBox";
import FormInput from "../../../../../../components/Form/FormInput/FormInput";
import FormInputPosition from "../EditPivotForm/components/FormInputPosition/FormInputPosition";
import DraggableMap from "../EditPivotForm/components/DraggableMap/DraggableMap";
import FormCheckBox from "../../../../../../components/Form/FormCheckBox/FormCheckBox";
import FormTimePicker from "../../../../../../components/Form/FormTimePicker/FormTimePicker";
import moment from "moment";
import PluviometerIcon from "./components/PluviometerIcon";
import FormSelect from "../../../../../../components/Form/FormSelect/FormSelect";
import { ValidationErrorMessage } from "../../../../../../components/Form/types";
import {
  checkLatCoordinates,
  checkUInt,
  checkSegmentComplete,
  checkLngCoordinates,
  checkIsOnIntervalString,
  checkIsOnIntervalNumeric,
  formatPositiveFloat,
} from "../../../../../../utils/checks";

import {
  Button,
  Box,
  CircularProgress,
  Checkbox,
  Divider,
} from "@material-ui/core";
import {
  ArrowBack,
  Settings,
  Map,
  AccessTime,
  Speed,
  Power,
  PieChart,
  Loop,
  PanoramaFishEye,
  EditLocationOutlined,
} from "@material-ui/icons";

import Typography from "@material-ui/core/Typography";
import "./TablePivotSegments.scss";
import "./EditPivotFormV5.scss";
import { useStyles } from "./EditPivotFormV5.style";
import { numPad, objPatch } from "../../../../../../utils/types";
import {
  PIVOT_COMMUNICATION_TYPE,
  Pivot,
} from "../../../../../../redux/pivots/types";
import useNotify from "../../../../../../hooks/tools/useNotify";
import Routes from "../../../../../../routes/routes";
import { useParams, useNavigate } from "react-router";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { green, red } from "@material-ui/core/colors";
import { isMobile } from "../../../../../../mobileConfig";
import FormDateTimePicker from "../../../../../../components/Form/FormDateTimePicker/FormDateTimePicker";
import {
  handleNumberMask,
  countDecimals,
  validadeFloatInput,
  handleFloatOneDecimalMask,
} from "../../../SelectedPivot/components/HistoricBox/utils/utils";

import MobileZone from "../../../../../../components/Zones/MobileZone";
import { EditPivotV5Context } from "./EditPivotV5Provider/EditPivotV5Provider";
import { hideModal, showModal } from "../../../../../../redux/modal/actions";
import GeolocationGetter from "../../../../../../components/GeolocationGetter/GeolocationGetter";
import { useDispatch, useSelector } from "react-redux";
import useSocketIO from "../../../../../../hooks/tools/useSocketIO";
import { coreHTTPClient } from "../../../../../../services/webclient";
import { updateOrInsertPivot } from "../../../../../../redux/pivots/actions";
import {
  centerMarkerSelector,
  initialMarkerSelector,
  isNorthReferenceState,
} from "../../../../../../recoils/DraggableMapRecoil";

import styled from "@emotion/styled";
import ShowHistoricFloatingLabel from "../EditPivotForm/ShowHistoricFloatingLabel";
import { useRecoilState, useSetRecoilState } from "recoil";
import { PivotConfigFamily } from "../../../../../../recoils/PivotRecoil";
import ChangeRadioField from "../ChangeRadioField/ChangeRadioField";
import HistoricConfigMobileV5 from "../EditPivotForm/components/HistoricConfigMobile/HistoricConfigMobileV5";
import { MessageStatus, RadioStatuses } from "../../EditPivot";
import { i18n, i18nTextId } from "../../../../../../i18n/i18nText";
import ProtocolFooter from "../../../../../../components/ProtocolFooter/ProtocolFooter";
import { useCountdown } from "../../../../../../hooks/models/useCountdown";
import { Alert } from "@material-ui/lab";
import GeneralAngleSelector from "../../../../../../components/GeneralAngleSelector/GeneralAngleSelector";
import { BLUE_COLORS_TABLE } from "../../../utils/utils";
import EndgunAngleSelector from "../../../../../../components/EndgunAngleSelector/EndgunAngleSelector";
import { ModalState } from "../../../../../../redux/modal/types";
import { ApplicationState } from "../../../../../../redux";
import { superUserStatus } from "../../../../../../recoils/SuperUserStatusRecoil";
import DialogModal from "../../../../../../components/DialogModal/DialogModal";
import { showLocationModal } from "../../../../../../redux/locationModal/actions";
import PermissionsInfo from "../../../../Farm/EditFarm/components/EditFarmForm/DrawerFarm/components/Users/CreateUser/PermisionsInfo";
import HelpOutlineOutlined from "@material-ui/icons/HelpOutlineOutlined";

const redDot = require("../../../../../../assets/images/markers/light-red-dot.svg");
const greenDot = require("../../../../../../assets/images/markers/light-green-dot.svg");

const { SOCKET_SUFFIX } = process.env;

const StyledH4 = styled.h4`
  margin: 10px 0px 0px 0px;
`;
interface Props {
  farmID: number;
  pivot: Pivot;
  patchPivot: (
    pivotConfig: any,
    patchObject: any,
    isV5?: boolean,
    deviceID?: number
  ) => void;
}

export interface Segments {
  number_editing: number;
  angle_start: number;
  angle_end: number;
}

export interface Endgun {
  number_editing: number;
  start_angle: number;
  end_angle: number;
}

export enum TYPE {
  CENTRAL_PIVOT_AUTOMATION = 0,
  CENTRAL_PIVOT_MONITOR,
  LINEAR_PIVOT_MONITOR,
}
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
      style={{ height: "100%" }}
    >
      {value === index && (
        <Box style={{ height: "100%" }} p={3}>
          {children}
        </Box>
      )}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function selectedTab(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

var onMount = true;

const CheckBoxItem = styled.div`
  font-size: 0.8em;
  opacity: 0.8;
  margin-left: -12px;
`;

function NewEditPivotV5(props: Props) {
  const [validationErrors, setValidationErrors] = useState<
    ValidationErrorMessage
  >({
    name: undefined,
    radius: undefined,
    flow: undefined,
    speed: undefined,
    brandModel: undefined,
    setBrandModel: undefined,
    otherBrand: undefined,
    setOtherBrand: undefined,
    panelType: undefined,
    setPanelType: undefined,
    voltageMin: undefined,
    voltageMax: undefined,
    voltageStableTime: undefined,
    voltageRef: undefined,
    centerLat: undefined,
    centerLng: undefined,
    startRefLat: undefined,
    startRefLng: undefined,
    rtcDate: undefined,
    rtcTime: undefined,
    potency: undefined,
    powerDelay: undefined,
    pumpPressDelay: undefined,
    pumpTimeout: undefined,
    pumpPressSwitch: undefined,
    sensorScaleEnd: undefined,
    pumpSoftStartTime: undefined,
    pilotAveragePower: undefined,
    canalAveragePower: undefined,
    mmToStop: undefined,
    pluviometerScale: undefined,
    valueKwhPeak: undefined,
    valueKwhOutOfPeak: undefined,
    setValidValueKwhReduced: undefined,
    pauseTimeStart1: undefined,
    pauseTimeEnd1: undefined,
    pauseTimeStart2: undefined,
    pauseTimeEnd2: undefined,
    pauseTimeOverlap: undefined,
    pivotSegments: undefined,
    angleStart: undefined,
    angleEnd: undefined,
    angleEndGun: undefined,
    autoreversionTime: undefined,
  });

  const { pivot } = props;
  const config = pivot.controllerconfig;
  const [value, setValue] = useState(0);
  const classes = useStyles(props);
  const notify = useNotify();
  const pivotID: number = parseInt(useParams().pivot, 10);
  const farmID: number = parseInt(useParams().farm, 10);
  const navigate = useNavigate();
  const ctx = useContext(EditPivotV5Context);
  const dispatch = useDispatch();

  var modal: ModalState = useSelector((state: ApplicationState) => state.modal);

  const [counter, setCounter] = useCountdown("Pivot", String(pivotID));

  // check super user status
  const [superUser, setSuperUser] = useRecoilState(superUserStatus);
  // end check super user status

  useEffect(() => {
    if (pivot?.permission_level < 3) {
      navigate(
        Routes.DASHBOARD.SELECTED_PIVOT.replace(
          ":farm",
          String(farmID)
        ).replace(":pivot", String(pivot.id))
      );
    }
  }, [pivot]);

  const [event, setEvent] = useState(false);
  const [device, setDevice] = useState(pivot?.base_radio_id);
  const [gps, setGps] = useState(pivot?.monitor_radio_id);
  const [pump, setPump] = useState(pivot?.pump_radio_id);
  const [controller, setController] = useState(pivot?.control_radio_id);

  /////////////////////////////////////////////////////////
  // Código do SocketIO para confirmação de configuração //
  /////////////////////////////////////////////////////////

  const [radioStatus, setRadioStatuses] = useState<RadioStatuses>({
    monitor: pivot.controllerconfig.gps_config,
    pump:
      pivot.controllerconfig.pump_config !== undefined
        ? pivot.controllerconfig.pump_config
        : 0,
    control: pivot.controllerconfig.message_status,
  });

  const [lastUpdate, setLastUpdate] = useState(
    moment(pivot.controllerconfig?.created).format("DD MMM YYYY HH:mm")
  );

  const [isConnected, socket] = useSocketIO();

  const [loading, setLoading] = useState(false);

  const [error, setError] = useState<any>(undefined);

  const setPivotConfig = useSetRecoilState(PivotConfigFamily(props.pivot.id));

  useEffect(() => {
    if (isConnected) {
      socket.subscribe(`${SOCKET_SUFFIX}pivot@${pivot.id}`);

      socket.bind("ControllerConfig_standard", (data) => {
        if (data?.message_errors) {
          setError(data.message_errors);
        }
        setLastUpdate(moment(data.created).format("DD MMM YYYY HH:mm"));
        if (radioStatus.control < data.message_status) {
          setRadioStatuses({ ...radioStatus, control: data.message_status });
        }
      });

      socket.bind("ControllerConfig_gps", (data) => {
        setLastUpdate(moment(data.created).format("DD MMM YYYY HH:mm"));
        if (radioStatus.monitor < data.message_status) {
          setRadioStatuses({ ...radioStatus, monitor: data.message_status });
        }
      });

      socket.bind("pivot_config", (data) => {
        setLastUpdate(moment(data.created).format("DD MMM YYYY HH:mm"));
        if (data.gps_delivered)
          setRadioStatuses({ ...radioStatus, monitor: 2 });
      });

      socket.bind("ControllerConfig_pump", (data) => {
        setLastUpdate(moment(data.created).format("DD MMM YYYY HH:mm"));
        if (radioStatus.pump < data.message_status) {
          setRadioStatuses({ ...radioStatus, pump: data.message_status });
        }
      });
    }

    return () => {
      socket.unbind("ControllerConfig_standard");
      socket.unbind("ControllerConfig_gps");
      socket.unbind("ControllerConfig_pump");
      socket.unbind("pivot_config");
      socket.unsubscribe(`d@pivot@${pivot.id}`);
    };
  }, [isConnected, radioStatus]);

  /////////////////////
  // Fim do SocketIO //
  /////////////////////

  // ------------------------------------------------------------------ COMEÇO DAS ABAS

  // GERAL -------------------------------------------------------------------

  const { name, setName } = ctx;

  const setValidName = useCallback(
    (value) => {
      setName(value);
      setValidationErrors({
        ...validationErrors,
        name: checkIsOnIntervalString(value, 1, 16),
      });
    },
    [validationErrors]
  );

  const { language, setLanguage } = ctx;

  const { radius, setRadius } = ctx;

  const setValidRadius = useCallback(
    (value) => {
      value = formatPositiveFloat(value);
      if (validadeFloatInput(value)) {
        setRadius(value);
        setValidationErrors({
          ...validationErrors,
          radius: checkIsOnIntervalNumeric(value, 0, 1500, true),
        });
      }
    },
    [validationErrors]
  );

  const { flow, setFlow } = ctx;

  const setValidFlow = useCallback(
    (value) => {
      value = formatPositiveFloat(value);
      if (validadeFloatInput(value)) {
        setFlow(value);
        setValidationErrors({
          ...validationErrors,
          flow: checkIsOnIntervalNumeric(value, 0, 1000, true),
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [validationErrors]
  );

  const { speed, setSpeed } = ctx;

  const setValidSpeed = useCallback(
    (value) => {
      value = formatPositiveFloat(value);
      if (validadeFloatInput(value)) {
        setSpeed(value);
        setValidationErrors({
          ...validationErrors,
          speed: checkIsOnIntervalNumeric(value, 0, 900, true),
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [validationErrors]
  );

  const { area, setArea } = ctx;

  const setValidArea = useCallback(
    (value) => {
      value = formatPositiveFloat(value);
      if (validadeFloatInput(value)) {
        setArea(value);
        setValidationErrors({
          ...validationErrors,
          area: checkIsOnIntervalNumeric(value, 0, 500, true),
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [validationErrors]
  );

  const { brandModel, setBrandModel } = ctx;

  const { otherBrand, setOtherBrand } = ctx;
  const setValidOtherBrand = useCallback(
    (value) => {
      setOtherBrand(value);
      setValidationErrors({
        ...validationErrors,
        otherBrand: checkIsOnIntervalString(value, 1, 20),
      });
    },
    [validationErrors]
  );

  const { panelType, setPanelType } = ctx;

  useEffect(() => {
    if (brandModel !== "Bauer") setPanelType("1");
  }, [brandModel]);

  const { checkVoltage, setCheckVoltage } = ctx;

  useEffect(() => {
    if (checkVoltage === false && onMount === false) {
      setVoltageMin("0");
      setVoltageMax("0");
      setVoltageStableTime("0");
    }
  }, [checkVoltage]);

  const { voltageMin, setVoltageMin } = ctx;

  const { voltageMax, setVoltageMax } = ctx;

  const setValidVoltageMin = useCallback(
    (value) => {
      if (checkUInt(value)) {
        setVoltageMin(value);
        setValidationErrors({
          ...validationErrors,
          voltageMin: checkIsOnIntervalNumeric(value, 0, 999, true),
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [validationErrors, voltageMax]
  );

  const setValidVoltageMax = useCallback(
    (value) => {
      //
      if (checkUInt(value)) {
        setVoltageMax(value);
        setValidationErrors({
          ...validationErrors,
          voltageMax: checkIsOnIntervalNumeric(value, 0, 999, true),
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [validationErrors, voltageMin]
  );

  const { voltageStableTime, setVoltageStableTime } = ctx;

  const setValidVoltageStableTime = useCallback(
    (value) => {
      if (checkUInt(value)) {
        setVoltageStableTime(value);
        setValidationErrors({
          ...validationErrors,
          voltageStableTime: checkIsOnIntervalNumeric(value, 0, 999, true),
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [validationErrors, voltageStableTime]
  );

  useEffect(() => {
    if (parseInt(voltageMin) > parseInt(voltageMax)) {
      setValidationErrors({
        ...validationErrors,
        ["voltageMin"]: "EDIT_PIVOT_V5_ERROR_VOLTAGE_MIN",
      });
    } else {
      setValidationErrors({
        ...validationErrors,
        ["voltageMin"]: checkIsOnIntervalNumeric(voltageMin, 0, 999, true),
      });
    }

    if (parseInt(voltageMax) < parseInt(voltageMin)) {
      setValidationErrors({
        ...validationErrors,
        ["voltageMax"]: "EDIT_PIVOT_V5_ERROR_VOLTAGE_MAX",
      });
    } else {
      setValidationErrors({
        ...validationErrors,
        ["voltageMax"]: checkIsOnIntervalNumeric(voltageMax, 0, 999, true),
      });
    }

    if (!checkVoltage) {
      setValidationErrors({
        ...validationErrors,
        ["voltageMin"]: checkIsOnIntervalNumeric(voltageMin, 0, 999, true),
        ["voltageMax"]: checkIsOnIntervalNumeric(voltageMax, 0, 999, true),
        ["voltageStableTime"]: checkIsOnIntervalNumeric(
          voltageStableTime,
          0,
          999,
          true
        ),
      });
    }
  }, [voltageMin, voltageMax, voltageStableTime, checkVoltage]);

  useEffect(() => {
    if (
      (voltageMin != 0 && voltageMin !== undefined) ||
      (voltageMax != 0 && voltageMax !== undefined) ||
      (voltageStableTime != 0 && voltageStableTime !== undefined)
    ) {
      setCheckVoltage(true);
    }
  }, [voltageMin, voltageMax, voltageStableTime]);

  // LOCALIZAÇÃO -------------------------------------------------------------------

  //Labels para validar os lat long sem comprometer o layout
  const { centerLatLabel, setCenterLatLabel } = ctx;

  const { centerLngLabel, setCenterLngLabel } = ctx;

  const { startLatLabel, setStartLatLabel } = ctx;

  const { startLngLabel, setStartLngLabel } = ctx;

  // CENTRO LATITUDE
  const { centerLat, setCenterLat } = ctx;

  const setValidCenterLat = useCallback(
    (value) => {
      if (countDecimals(value) > 7) {
        return;
      }
      if (checkLatCoordinates(value)) {
        setCenterLat(value);
        setCenterLatLabel(value);
      }
      if (value.length === 0) {
        setCenterLat(config.content.pivot_positions.latitude_center);
        setValidationErrors({
          ...validationErrors,
          centerLat: "ERROR_EMPTY_FIELD",
        });
      } else
        setValidationErrors({
          ...validationErrors,
          centerLat: undefined,
        });
    },
    [validationErrors]
  );

  // CENTRO LONGITUDE
  const { centerLng, setCenterLng } = ctx;

  const setValidCenterLng = useCallback(
    (value) => {
      if (countDecimals(value) > 7) {
        return;
      }
      if (checkLngCoordinates(value)) {
        setCenterLng(value);
        setCenterLngLabel(value);
      }
      if (value.length === 0) {
        setCenterLng(config.content.pivot_positions.longitude_center);
        setValidationErrors({
          ...validationErrors,
          centerLng: "ERROR_EMPTY_FIELD",
        });
      } else
        setValidationErrors({
          ...validationErrors,
          centerLng: undefined,
        });
    },
    [validationErrors]
  );

  // REFERENCIA INICIAL LATITUDE
  const { startRefLat, setStartRefLat } = ctx;

  const setValidStartRefLat = useCallback(
    (value) => {
      if (countDecimals(value) > 7) {
        return;
      }
      if (checkLatCoordinates(value)) {
        setStartRefLat(value);
        setStartLatLabel(value);
      }
      if (value.length === 0) {
        setStartRefLat(config.content.pivot_positions.latitude_reference);
        setValidationErrors({
          ...validationErrors,
          startRefLat: "ERROR_EMPTY_FIELD",
        });
      } else
        setValidationErrors({
          ...validationErrors,
          startRefLat: undefined,
        });
    },
    [validationErrors]
  );

  // REFERENCIA INICIAL LONGITUDE
  const { startRefLng, setStartRefLng } = ctx;

  const setValidStartRefLng = useCallback(
    (value) => {
      if (countDecimals(value) > 7) {
        return;
      }
      if (checkLngCoordinates(value)) {
        setStartRefLng(value);
        setStartLngLabel(value);
      }
      if (value.length === 0) {
        setStartRefLng(config.content.pivot_positions.longitude_reference);
        setValidationErrors({
          ...validationErrors,
          startRefLng: "ERROR_EMPTY_FIELD",
        });
      } else
        setValidationErrors({
          ...validationErrors,
          startRefLng: undefined,
        });
    },
    [validationErrors]
  );

  // NORTE COMO REFERÊNCIA
  const { referenceNorth, setReferenceNorth } = ctx;

  const [pivotCenterCoordsError, setPivotCenterCoordsError] = useState(false);

  const [pivotStartCoordsError, setPivotStartCoordsError] = useState(false);

  // HORÁRIO -------------------------------------------------------------------

  const [rtc, setRtc] = useState("auto");

  const [rtcDate, setRtcDate] = useState(moment());

  const setValidRtcDate = useCallback(
    (value) => {
      let errorID = "rtcDate";
      setRtcDate(value);
      if (value.lenght === 0 || !value.isValid()) {
        setValidationErrors({
          ...validationErrors,
          [errorID]: "PIVOT_CONFIG_V5_FIELD_CLOCK",
        });
      } else {
        setValidationErrors({
          ...validationErrors,
          [errorID]: undefined,
        });
      }
    },
    [validationErrors]
  );

  const handleSetRtc = useCallback((value) => {
    if (value == "auto") {
      setRtc(value);
      setRtcDate(moment());
    } else {
      setRtc(value);
    }
  }, []);

  // BOMBA -------------------------------------------------------------------

  const { potency, setPotency } = ctx;

  const setValidPotency = useCallback(
    (value) => {
      value = formatPositiveFloat(value);
      if (validadeFloatInput(String(value))) setPotency(value);
      setValidationErrors({
        ...validationErrors,
        potency: checkIsOnIntervalNumeric(value, 1, 22000),
      });
    },
    [validationErrors]
  );

  const { powerDelay, setPowerDelay } = ctx;

  const setValidPowerDelay = useCallback(
    (value) => {
      value = formatPositiveFloat(value);
      setPowerDelay(handleNumberMask(value));
      setValidationErrors({
        ...validationErrors,
        switchFactor: checkIsOnIntervalNumeric(value, 0, 30, true),
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [validationErrors]
  );

  const { readPressureBy, setReadPressureBy } = ctx;

  const { pumpTimeout, setPumpTimeout } = ctx;

  const setValidPumpTimeout = useCallback(
    (value) => {
      value = formatPositiveFloat(value);
      if (validadeFloatInput(value)) setPumpTimeout(handleNumberMask(value));
      setValidationErrors({
        ...validationErrors,
        pumpTimeout: checkIsOnIntervalNumeric(value, 0, 59),
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [validationErrors]
  );

  const { pumpPressDelay, setPumpPressDelay } = ctx;

  const setValidPumpPressDelay = useCallback(
    (value) => {
      value = formatPositiveFloat(value);
      if (validadeFloatInput(value)) setPumpPressDelay(handleNumberMask(value));
      setValidationErrors({
        ...validationErrors,
        pumpPressDelay: checkIsOnIntervalNumeric(value, 0, 59),
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [validationErrors]
  );

  const { pumpPressSwitch, setPumpPressSwitch } = ctx;

  const setValidPumpPressSwitch = useCallback(
    (value) => {
      value = formatPositiveFloat(value);
      if (validadeFloatInput(value))
        setPumpPressSwitch(handleNumberMask(value));
      setValidationErrors({
        ...validationErrors,
        pumpPressSwitch: checkIsOnIntervalNumeric(value, 0, 59),
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [validationErrors]
  );

  const { sensorMinRange, setSensorMinRange } = ctx;

  const setValidSwitchPumpMinRange = useCallback(
    (value) => {
      value = formatPositiveFloat(value);
      if (validadeFloatInput(value))
        setSensorMinRange(handleFloatOneDecimalMask(value));
      setValidationErrors({
        ...validationErrors,
        switchMinRange: checkIsOnIntervalNumeric(value, 0, 10, true),
      });
    },
    [validationErrors]
  );

  const { sensorMaxRange, setSensorMaxRange } = ctx;

  const setValidSwitchPumpMaxRange = useCallback(
    (value) => {
      value = formatPositiveFloat(value);
      if (validadeFloatInput(value))
        setSensorMaxRange(handleFloatOneDecimalMask(value));
      setValidationErrors({
        ...validationErrors,
        switchMaxRange: checkIsOnIntervalNumeric(value, 0, 10, true),
      });
    },
    [validationErrors]
  );

  const { sensorScaleEnd, setSensorScaleEnd } = ctx;

  const setValidSensorScaleEnd = useCallback(
    (value) => {
      value = formatPositiveFloat(handleFloatOneDecimalMask(value));
      setSensorScaleEnd(value);
      setValidationErrors({
        ...validationErrors,
        sensorScaleEnd: checkIsOnIntervalNumeric(value, 0, 20, true),
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [validationErrors]
  );

  const { injectionPump, setInjectionPump } = ctx;

  const [disabledPumpTimeout, setDisabledPumpTimeout] = useState(false);

  const [disabledPumpPressDelay, setDisabledPumpPressDelay] = useState(false);

  const [disabledPumpPressSwitch, setDisabledPumpPressSwitch] = useState(false);

  const [disabledSensorMinRange, setDisabledSensorMinRange] = useState(false);

  const [disabledSensorMaxRange, setDisabledSensorMaxRange] = useState(false);

  const [disabledSensorScaleEnd, setDisabledSensorScaleEnd] = useState(false);

  useEffect(() => {
    if (readPressureBy == 0) {
      setDisabledPumpTimeout(true);
      setDisabledPumpPressDelay(true);
      setDisabledPumpPressSwitch(true);
      setDisabledSensorMinRange(true);
      setDisabledSensorMaxRange(true);
      setDisabledSensorScaleEnd(true);
    } else if (readPressureBy == 1) {
      setDisabledPumpTimeout(false);
      setDisabledPumpPressDelay(false);
      setDisabledPumpPressSwitch(false);
      setDisabledSensorMinRange(true);
      setDisabledSensorMaxRange(true);
      setDisabledSensorScaleEnd(true);
    } else if (readPressureBy == 2) {
      setDisabledPumpTimeout(false);
      setDisabledPumpPressDelay(false);
      setDisabledPumpPressSwitch(false);
      setDisabledSensorMinRange(false);
      setDisabledSensorMaxRange(false);
      setDisabledSensorScaleEnd(false);
    }
  }, [readPressureBy]);

  // PLUVIÔMETRO -------------------------------------------------------------------

  const { pluviometerStopMode, setPluviometerStopMode } = ctx;

  useEffect(() => {
    if (pluviometerStopMode == 0 || pluviometerStopMode == 2) {
      setValidMMToStop("1");
    }
  }, [pluviometerStopMode]);

  const { mmToStop, setMMToStop } = ctx;

  const setValidMMToStop = useCallback(
    (value) => {
      value = formatPositiveFloat(value);
      if (validadeFloatInput(value)) {
        setMMToStop(value);
        setValidationErrors({
          ...validationErrors,
          mmToStop: checkIsOnIntervalNumeric(value, 0, 10000),
        });
      }
    },
    [validationErrors]
  );

  const { pluviometerScale, setPluviometerScale } = ctx;

  const setValidPluviometerScale = useCallback(
    (value) => {
      value = formatPositiveFloat(value);
      if (validadeFloatInput(value)) {
        setPluviometerScale(value);
        setValidationErrors({
          ...validationErrors,
          pluviometerScale: checkIsOnIntervalNumeric(value, 0, 10000),
        });
      }
    },
    [validationErrors]
  );

  const pluviometerUpdate = { pluviometer: !pivot.pluviometer };

  async function _patchPivot(pivotObject) {
    await coreHTTPClient
      .patch(`v3/farms/${farmID}/pivots/${pivotID}/`, pivotObject)
      .then((res) => {
        dispatch(updateOrInsertPivot(res.data));
        setLoading(false);
      })
      .catch((err) => {
        notify("EDIT_PIVOT_SAVE_AWAIT_ERROR", "error");
        setCounter(0);
        setLoading(false);
      });
  }

  const enablePluviometerHandler = () => {
    dispatch(
      showModal({
        content: (
          <DialogModal
            title={"IRRIGATION_BOX_STOP_PIVOT_DIALOG_TITLE"}
            description={"PLUVIOMETER_BOX_ENABLE_DIALOG"}
            descriptionArgs={
              pivot.pluviometer
                ? [i18n("DISABLE_PLUVIOMETER")]
                : [i18n("ENABLE_PLUVIOMETER")]
            }
            yes={"IRRIGATION_BOX_STOP_PIVOT_DIALOG_YES"}
            yesAction={() => {
              _patchPivot(pluviometerUpdate);
              setPluviometerStopMode(1);
              // setPluviometerStopMode(0);
            }}
            no={"IRRIGATION_BOX_STOP_PIVOT_DIALOG_NO"}
          />
        ),
      })
    );
  };

  useEffect(() => {
    if (pivot.pluviometer && !mmToStop) {
      setMMToStop(100);
    }
  }, [pivot.pluviometer]);

  // HORÁRIO DE PICO -------------------------------------------------------------------

  const { valueKwhPeak, setValueKwhPeak } = ctx;

  const setValidValueKwhPeak = useCallback(
    (value) => {
      value = formatPositiveFloat(value);
      if (validadeFloatInput(value) && countDecimals(value) < 3) {
        setValueKwhPeak(value);
        setValidationErrors({
          ...validationErrors,
          valueKwhPeak: checkIsOnIntervalNumeric(value, 0, 99.99, true),
        });
      }
    },
    [validationErrors]
  );

  const { valueKwhOutOfPeak, setValueKwhOutOfPeak } = ctx;

  const setValidValueKwhOutOfPeak = useCallback(
    (value) => {
      value = formatPositiveFloat(value);
      if (validadeFloatInput(value) && countDecimals(value) < 3) {
        setValueKwhOutOfPeak(value);
        setValidationErrors({
          ...validationErrors,
          valueKwhOutOfPeak: checkIsOnIntervalNumeric(value, 0, 99.99, true),
        });
      }
    },
    [validationErrors]
  );

  const { valueKwhReduced, setValueKwhReduced } = ctx;

  const setValidValueKwhReduced = useCallback(
    (value) => {
      value = formatPositiveFloat(value);
      if (validadeFloatInput(value) && countDecimals(value) < 3) {
        setValueKwhReduced(value);
        setValidationErrors({
          ...validationErrors,
          valueKwhReduced: checkIsOnIntervalNumeric(value, 0, 99.99, true),
        });
      }
    },
    [validationErrors]
  );

  const { weekday, setWeekday } = ctx;

  const setValidWeekday = useCallback(
    (weekdayAbbreviantion) => {
      switch (weekdayAbbreviantion) {
        case "mon":
          setWeekday({ ...weekday, mon: weekday.mon ? 0 : 1 });
          break;
        case "tue":
          setWeekday({ ...weekday, tue: weekday.tue ? 0 : 1 });
          break;
        case "wed":
          setWeekday({ ...weekday, wed: weekday.wed ? 0 : 1 });
          break;
        case "thu":
          setWeekday({ ...weekday, thu: weekday.thu ? 0 : 1 });
          break;
        case "fri":
          setWeekday({ ...weekday, fri: weekday.fri ? 0 : 1 });
          break;
        case "sat":
          setWeekday({ ...weekday, sat: weekday.sat ? 0 : 1 });
          break;
        case "sun":
          setWeekday({ ...weekday, sun: weekday.sun ? 0 : 1 });
          break;
      }
    },
    [weekday]
  );

  const { pauseTimeStatus1, setPauseTimeStatus1 } = ctx;

  useEffect(() => {
    if (!pauseTimeStatus1) {
      setPauseTimeStatus2(false);
    }
  }, [pauseTimeStatus1]);

  const { pauseTimeStart1, setPauseTimeStart1 } = ctx;

  const setValidPauseTimeStart1 = (value) => {
    const errorID = "pauseTimeStart1";

    if (value.length === 0 || !value.isValid()) {
      setValidationErrors({
        ...validationErrors,
        [errorID]: "EDIT_PIVOT_V5_ERROR_PAUSE_TIME_START",
      });
    } else {
      setPauseTimeStart1(value);
    }
  };

  const { pauseTimeEnd1, setPauseTimeEnd1 } = ctx;

  const setValidPauseTimeEnd1 = (value) => {
    const errorID = "pauseTimeEnd1";

    if (!value.isValid()) {
      setValidationErrors({
        ...validationErrors,
        [errorID]: "EDIT_PIVOT_V5_ERROR_PAUSE_TIME_END",
      });
    } else {
      setPauseTimeEnd1(value);
    }
  };

  useEffect(() => {
    if (pauseTimeStart1.isSameOrAfter(pauseTimeEnd1)) {
      setValidationErrors({
        ...validationErrors,
        ["pauseTimeStart1"]: "EDIT_PIVOT_V5_ERROR_PAUSE_TIME_END",
      });
    } else {
      setValidationErrors({
        ...validationErrors,
        ["pauseTimeStart1"]: undefined,
      });
    }
  }, [pauseTimeStart1, pauseTimeEnd1]);

  const { pauseTimeStatus2, setPauseTimeStatus2 } = ctx;

  useEffect(() => {
    if (!pauseTimeStatus2) {
      setValidationErrors({
        ...validationErrors,
        ["pauseTimeOverlap"]: undefined,
      });
    }
  }, [pauseTimeStatus2]);

  const { pauseTimeStart2, setPauseTimeStart2 } = ctx;

  const setValidPauseTimeStart2 = (value) => {
    const errorID = "pauseTimeStart2";

    if (value.length === 0 || !value.isValid()) {
      setValidationErrors({
        ...validationErrors,
        [errorID]: "EDIT_PIVOT_V5_ERROR_PAUSE_TIME_START",
      });
    } else {
      var strTime2 = [value.format("HH:mm"), pauseTimeEnd2.format("HH:mm")];
      var strTime1 = [
        pauseTimeStart1.format("HH:mm"),
        pauseTimeEnd1.format("HH:mm"),
      ];

      var timeSegments = [strTime2, strTime1];

      if (checkOverlap(timeSegments)) {
        setValidationErrors({
          ...validationErrors,
          ["pauseTimeOverlap"]: "EDIT_PIVOT_V5_ERROR_PAUSE_TIME_START",
        });
      } else {
        setValidationErrors({
          ...validationErrors,
          ["pauseTimeOverlap"]: undefined,
        });
      }
      setPauseTimeStart2(value);
    }
  };

  const { pauseTimeEnd2, setPauseTimeEnd2 } = ctx;

  const setValidPauseTimeEnd2 = (value: moment.Moment) => {
    const errorID = "pauseTimeEnd2";

    if (!value.isValid()) {
      setValidationErrors({
        ...validationErrors,
        [errorID]: "EDIT_PIVOT_V5_ERROR_PAUSE_TIME_END",
      });
    } else {
      var strTime2 = [pauseTimeStart2.format("HH:mm"), value.format("HH:mm")];
      var strTime1 = [
        pauseTimeStart1.format("HH:mm"),
        pauseTimeEnd1.format("HH:mm"),
      ];

      var timeSegments = [strTime2, strTime1];

      if (checkOverlap(timeSegments)) {
        setValidationErrors({
          ...validationErrors,
          ["pauseTimeOverlap"]: "EDIT_PIVOT_V5_ERROR_PAUSE_TIME_START",
        });
      } else {
        setValidationErrors({
          ...validationErrors,
          ["pauseTimeOverlap"]: undefined,
        });
      }
      setPauseTimeEnd2(value);
    }
  };

  useEffect(() => {
    if (pauseTimeStart2.isSameOrAfter(pauseTimeEnd2)) {
      setValidationErrors({
        ...validationErrors,
        ["pauseTimeStart2"]: "EDIT_PIVOT_V5_ERROR_PAUSE_TIME_END",
      });
    } else {
      setValidationErrors({
        ...validationErrors,
        ["pauseTimeStart2"]: undefined,
      });
    }
  }, [pauseTimeStart2, pauseTimeEnd2]);

  const checkOverlap = (timeSegments) => {
    if (timeSegments.length === 1) return false;

    timeSegments.sort((timeSegment1, timeSegment2) =>
      timeSegment1[0].localeCompare(timeSegment2[0])
    );

    for (let i = 0; i < timeSegments.length - 1; i++) {
      const currentEndTime = timeSegments[i][1];
      const nextStartTime = timeSegments[i + 1][0];

      if (currentEndTime > nextStartTime) {
        return true;
      }
    }

    return false;
  };

  // SEGMENTOS E PLANTIO -------------------------------------------------------------------

  const { reload, setReload } = ctx;

  // ANGLE START
  const [angleStart, setAngleStart] = useState("0");

  const setValidAngleStart = useCallback(
    (value) => {
      const errorID = "angleStart";

      if (checkUInt(value)) setAngleStart(value);
      if (value.length === 0 || parseInt(value) < 0 || parseInt(value) > 360)
        setValidationErrors({
          ...validationErrors,
          [errorID]: "EDIT_PIVOT_V5_ERROR_ANGLE_START",
        });
      else
        setValidationErrors({
          ...validationErrors,
          [errorID]: undefined,
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [validationErrors]
  );

  // ANGLE END
  const { angleEnd, setAngleEnd } = ctx;

  const setValidAngleEnd = useCallback(
    (value) => {
      value = formatPositiveFloat(value);
      if (value <= 360) {
        setArrayAngles([]);
        setAngleEnd(handleNumberMask(value));
        setValidationErrors({
          ...validationErrors,
          angleEnd: checkIsOnIntervalNumeric(value, 0, 360, true),
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [validationErrors]
  );

  // SEGMENT ARRAYS
  const { arrayAngleEnd: arrayAngles, setArrayAngleEnd: setArrayAngles } = ctx;

  const arrayAnglesHasErrors = useMemo(() => {
    return (
      arrayAngles?.[arrayAngles?.length - 1]?.name == "" ||
      arrayAngles?.[arrayAngles?.length - 1]?.name?.length > 16
    );
  }, [arrayAngles]);

  const [showLastArrayAngle, setShowLastArrayAngle] = useState<boolean>(true);

  const arrayAngleStart = useMemo(() => {
    return arrayAngles.map((angle, index) => {
      return index === 0 ? "0" : arrayAngles[index - 1]?.angle;
    });
  }, [arrayAngles]);

  const { segmentsArray, setSegmentsArray } = ctx;

  const [segmentsNameArray, setSegmentsNameArray] = useState([]);

  const appendArraySegments = () => {
    setSegmentsArray(
      arrayAngles.map((item, index) => {
        return {
          number_editing: index,
          angle_start:
            index === 0 ? 0 : parseInt(arrayAngles[index - 1]?.angle),
          angle_end: parseInt(item?.angle),
        };
      })
    );

    setSegmentsNameArray(
      arrayAngles.map((item, index) => {
        return {
          number_editing: index,
          name: item?.name,
          segment_type: item?.segment_type,
          crop_plant_date: item?.crop_plant_date,
          crop_harvest_date: item?.crop_harvest_date,
        };
      })
    );
  };

  useEffect(() => {
    appendArraySegments();
  }, [arrayAngles]);

  //VALIDATION PIVOT SEGMENTS
  const [disablePivotSegments, setDisablePivotSegments] = useState(false);

  const [disableStartAngle, setDisableStartAngle] = useState(false);

  useEffect(() => {
    if (
      !checkSegmentComplete(
        parseInt(angleEnd, 10),
        arrayAngleStart,
        arrayAngles
      )
    ) {
      setValidationErrors({
        ...validationErrors,
        ["pivotSegments"]: "EDIT_PIVOT_V5_ERROR_CANAL_AVERAGE_POWER",
      });
      setDisablePivotSegments(false);
    } else {
      setValidationErrors({
        ...validationErrors,
        ["pivotSegments"]: undefined,
      });
      setDisablePivotSegments(true);
    }

    if (arrayAngleStart.length > 0) {
      setDisableStartAngle(true);
    } else {
      setDisableStartAngle(false);
    }
  }, [arrayAngleStart, arrayAngles]);

  // CANHÃO FINAL -------------------------------------------------------------------

  const { endgunMode, setEndgunMode } = ctx;

  const { endgunArray, setEndgunArray } = ctx;

  const [showEndgunDrawing, setShowEndgunDrawing] = useState(false);

  useEffect(() => {
    if (endgunMode == 2) {
      setShowEndgunDrawing(true);
    } else {
      setShowEndgunDrawing(false);
    }
  }, [endgunMode]);

  // AUTOREVERSÃO -------------------------------------------------------------------

  const { autoreversionMode, setAutoreversionMode } = ctx;

  const { autoreversionTime, setAutoreversionTime } = ctx;

  const setValidAutoreversionTime = useCallback(
    (value) => {
      value = formatPositiveFloat(value);
      if (validadeFloatInput(value)) setAutoreversionTime(parseInt(value));
      setValidationErrors({
        ...validationErrors,
        autoreversionTime: checkIsOnIntervalNumeric(value, 0, 1000),
      });
    },
    [validationErrors]
  );

  const [disabledAutoreversionTime, setDisabledAutoreversionTime] = useState(
    false
  );

  useEffect(() => {
    if (autoreversionMode == -1) {
      setDisabledAutoreversionTime(true);
    } else {
      setDisabledAutoreversionTime(false);
    }
  }, [autoreversionMode]);

  // ENCODER -------------------------------------------------------------------

  const ResetManualContent = () => {
    return (
      <Box style={isMobile() ? {} : { borderLeft: "1px solid #808080" }}>
        <Box
          style={{
            lineHeight: "21px",
            margin: isMobile() ? "" : "10px 15px 30px 35px",
          }}
        >
          <h3 style={{ marginTop: "10px", marginBottom: "10px" }}>
            {i18n("ENCODER_RESET_INSTRUCTION_TITLE")}
          </h3>
          <b>{i18n("ENCODER_RESET_INSTRUCTION_STEP1")}</b>{" "}
          <p>{i18n("ENCODER_RESET_INSTRUCTION_STEP1_TEXT")}</p>
          <b>{i18n("ENCODER_RESET_INSTRUCTION_STEP2")}</b>{" "}
          <p>{i18n("ENCODER_RESET_INSTRUCTION_STEP2_TEXT")}</p>
          <b>{i18n("ENCODER_RESET_INSTRUCTION_STEP3")}</b>{" "}
          <p>{i18n("ENCODER_RESET_INSTRUCTION_STEP3_TEXT")}</p>
          <p>{i18n("ENCODER_RESET_INSTRUCTION_DESCRIPTION")}</p>
        </Box>
      </Box>
    );
  };

  async function postEncoderUpdate() {
    await coreHTTPClient
      .post(`v4/farms/${farmID}/pivots/${pivotID}/encoder/reset/`)
      .then((res) => {
        if (res?.data) notify("EDIT_ENCODER_SAVE_AWAIT_SUCCESS", "success");
      })
      .catch((err) => {
        notify("EDIT_ENCODER_SAVE_AWAIT_ERROR", "error");
      });
  }

  const ResetConfirmationContent = () => {
    return (
      <div>
        <div>
          <Typography
            style={{
              display: "flex",
              alignItems: " center",
              justifyContent: "center",
              height: "150px",
              margin: "0px 12px",
            }}
          >
            {i18n("ENCODER_CONFIRMATION")}
          </Typography>
        </div>

        <div
          style={{
            display: "flex",
            alignItems: " center",
            justifyContent: "space-evenly",
            margin: "15px",
            color: "white",
          }}
        >
          <Button
            variant="contained"
            onClick={() => {
              dispatch(hideModal());
            }}
          >
            {i18n("CANCEL")}
          </Button>

          <Button
            style={{ width: "100px" }}
            variant="contained"
            color="primary"
            onClick={resetEncoderHandler}
          >
            {i18n("REDEFINE")}
          </Button>
        </div>
      </div>
    );
  };

  const resetEncoderHandler = () => {
    if (pivot.communication_type === PIVOT_COMMUNICATION_TYPE.TYPE_4G)
      postEncoderUpdate();
    dispatch(hideModal());
  };

  // ------------------------------------------------------------------ FIM DAS ABAS

  // Temos um Alert  que pode mostrar mensagens diferentes de acordo com  a situação
  // Caso não definirmos uma altura para sua div pai de forma a ter espaço em altura
  // tanto para o  texto do alert com menos caracteres quanto para o texto  com mais
  // caracteres, quando o usuário está mexendo o componente de seleção  de segmentos
  // a tela ira pular e atrapalhar a usabilidade (basta recomer esse height e testar
  // e será possível verificar o problema.
  function heightToPreventFlicks() {
    if (window.innerWidth >= 625) {
      return "auto";
    } else if (window.innerWidth < 625 && window.innerWidth >= 393) {
      return "70px";
    } else if (window.innerWidth < 393 && window.innerWidth >= 337) {
      return "90px";
    } else if (window.innerWidth < 337 && window.innerWidth >= 273) {
      return "120px";
    }
  }

  const showHistoricConfigModal = (pivotID, farmID, ctx, pivot) => {
    dispatch(
      showModal({
        content: (
          <HistoricConfigMobileV5
            farmID={farmID}
            pivotID={pivotID}
            pivot={pivot}
            ctx={ctx}
          />
        ),
        title: "EDIT_PIVOT_LABEL_SETTING_HISTORIC",
      })
    );
  };

  const showErrorMessages = (): boolean => {
    let hasError = false;
    Object.keys(validationErrors).forEach((key) => {
      if (validationErrors[key] !== undefined) {
        notify(validationErrors[key] as i18nTextId, "error");
        hasError = true;
      }
    });
    return hasError;
  };

  const checkCanSave = (): boolean => {
    if (name === "") {
      return false;
    }
    if (brandModel === "Other" && otherBrand === "") {
      return false;
    }
    return true;
  };

  async function patchPivotName(newPivotName) {
    await coreHTTPClient
      .patch(`v3/farms/${farmID}/pivots/${pivotID}/`, newPivotName)
      .then((res) => {
        dispatch(updateOrInsertPivot(res.data));
        setLoading(false);
      })
      .catch((err) => {
        notify("EDIT_PIVOT_SAVE_AWAIT_ERROR", "error");
        setCounter(0);
        setLoading(false);
      });
  }

  async function patchPivot(pivotConfig, deviceID, newPivotName) {
    notify("EDIT_PIVOT_SAVE_AWAIT", "info");

    if (!superUser) {
      setCounter(30);
      setLoading(true);
    }
    setRadioStatuses({
      monitor: -1,
      pump: -1,
      control: -1,
    });

    await coreHTTPClient
      .post(`v3/farms/${farmID}/pivots/${pivotID}/device/${deviceID}/config/`, {
        message_subtype: "standard",
        equipment: pivotID,
        segments_crop: segmentsNameArray,
        kwh_peak: pivotConfig.kwh_peak,
        kwh_out_of_peak: pivotConfig.kwh_out_of_peak,
        kwh_reduced: pivotConfig.kwh_reduced,
        injection_pump: pivotConfig.injection_pump,
        name_pivot_on_config: name,
        brand_model: brandModel === "Other" ? otherBrand : brandModel,
        panel_type: panelType,
        potency: potency,
        content: {
          ...pivotConfig.content,
          // TODO: Integrar com o front, por enquanto tá hardcodado
          pause_time: {
            ...pivotConfig.content.pause_time,
            start_pause_time_hour_1: pauseTimeStatus1
              ? pauseTimeStart1.hour()
              : 0,
            start_pause_time_minute_1: pauseTimeStatus1
              ? pauseTimeStart1.minute()
              : 0,
            end_pause_time_hour_1: pauseTimeStatus1 ? pauseTimeEnd1.hour() : 0,
            end_pause_time_minute_1: pauseTimeStatus1
              ? pauseTimeEnd1.minute()
              : 0,
            start_pause_time_hour_2: pauseTimeStatus2
              ? pauseTimeStart2.hour()
              : 0,
            start_pause_time_minute_2: pauseTimeStatus2
              ? pauseTimeStart2.minute()
              : 0,
            end_pause_time_hour_2: pauseTimeStatus2 ? pauseTimeEnd2.hour() : 0,
            end_pause_time_minute_2: pauseTimeStatus2
              ? pauseTimeEnd2.minute()
              : 0,
          },
        },
      })
      .then((res) => {
        dispatch(updateOrInsertPivot(res.data));
        patchPivotName(newPivotName);
        setPivotConfig(res.data);
      })
      .catch((err) => {
        notify("EDIT_PIVOT_SAVE_AWAIT_ERROR", "error");
        setCounter(0);
        setLoading(false);
      });
  }

  const saveButtonHandler = () => {
    if (!showErrorMessages()) {
      const patchObject = objPatch(props.pivot, {
        name: name,
        potency: potency,
        brand_model: brandModel === "Other" ? otherBrand : brandModel,
        panel_type: panelType,
      });

      let kmInLatAngle = 0.0089928;

      const pivotConfigV5 = {
        content: {
          pivot_parameters: {
            radius_last: Math.round(parseFloat(radius) * 1e2) / 1e2,
            flow_rate: Math.round(parseFloat(flow) * 1e2) / 1e2,
            speed: Math.round(parseFloat(speed) * 1e2) / 1e2,
            irrigated_area: Math.round(parseFloat(area) * 1e2) / 1e2,
          },
          clock: {
            day: rtc == "manual" ? rtcDate.date() : moment().date(),
            month: rtc == "manual" ? rtcDate.month() + 1 : moment().month() + 1, // estranhamente, o mês começa em zero no Moment
            year:
              rtc == "manual" ? rtcDate.year() - 2000 : moment().year() - 2000,
            hour: rtc == "manual" ? rtcDate.hour() : moment().hour(),
            minute: rtc == "manual" ? rtcDate.minute() : moment().minute(),
          },
          voltage_limit_enable: {
            voltage_limit_enable: checkVoltage ? 1 : 0,
          },
          voltage_configurations: {
            minimum_voltage: checkVoltage ? parseInt(voltageMin) : 0,
            maximum_voltage: checkVoltage ? parseInt(voltageMax) : 0,
            stable_time: checkVoltage ? parseInt(voltageStableTime) : 0,
            voltage_reference: 480, // valor padrão sugerido pelo Marcelo
          },
          language: {
            language: parseInt(language),
          },
          pressure_config: {
            read_pressure_by: parseInt(readPressureBy),
            pump_time_out: parseInt(pumpTimeout),
            pump_press_switch: parseInt(pumpPressSwitch),
            pump_press_delay: parseInt(pumpPressDelay),
            pump_soft_start_time: 0, // parseInt(pumpSoftStartTime),
            press_sensor_max_range: parseFloat(sensorMaxRange),
            press_sensor_min_range: parseFloat(sensorMinRange),
            sensor_scale_end: parseFloat(sensorScaleEnd),
          },
          autoreversion_configurations: {
            mode: parseInt(autoreversionMode != -1 ? autoreversionMode : 0),
            time: parseInt(autoreversionTime),
          },
          autoreversion_command: {
            command: autoreversionMode == -1 ? 0 : 1,
          },
          pause_time: {
            enable_friday: pauseTimeStatus1 ? weekday.fri : 0,
            enable_monday: pauseTimeStatus1 ? weekday.mon : 0,
            enable_sunday: pauseTimeStatus1 ? weekday.sun : 0,
            enable_tuesday: pauseTimeStatus1 ? weekday.tue : 0,
            enable_saturday: pauseTimeStatus1 ? weekday.sat : 0,
            enable_thursday: pauseTimeStatus1 ? weekday.thu : 0,
            enable_wednesday: pauseTimeStatus1 ? weekday.wed : 0,

            start_pause_time_hour_1: pauseTimeStatus1
              ? parseInt(`${numPad(pauseTimeStart1.hour(), 2)}`)
              : 0,
            start_pause_time_minute_1: pauseTimeStatus1
              ? parseInt(`${numPad(pauseTimeStart1.minutes(), 2)}`)
              : 0,
            end_pause_time_hour_1: pauseTimeStatus1
              ? parseInt(`${numPad(pauseTimeEnd1.hour(), 2)}`)
              : 0,
            end_pause_time_minute_1: pauseTimeStatus1
              ? parseInt(`${numPad(pauseTimeEnd1.minutes(), 2)}`)
              : 0,
            start_pause_time_hour_2: pauseTimeStatus2
              ? parseInt(`${numPad(pauseTimeStart2.hour(), 2)}`)
              : 0,
            start_pause_time_minute_2: pauseTimeStatus2
              ? parseInt(`${numPad(pauseTimeStart2.minutes(), 2)}`)
              : 0,
            end_pause_time_hour_2: pauseTimeStatus2
              ? parseInt(`${numPad(pauseTimeEnd2.hour(), 2)}`)
              : 0,
            end_pause_time_minute_2: pauseTimeStatus2
              ? parseInt(`${numPad(pauseTimeEnd2.minutes(), 2)}`)
              : 0,
          },
          pause_time_command: {
            pause_time_command: pauseTimeStatus1 | 0,
          },
          power_delay: {
            power_delay: parseInt(powerDelay),
          },
          sector: {
            start_angle: parseInt(angleStart),
            end_angle: parseInt(angleEnd),
          },
          segments: segmentsArray,
          pivot_positions: {
            latitude_center: Math.round(parseFloat(centerLat) * 1e7) / 1e7,
            longitude_center: Math.round(parseFloat(centerLng) * 1e7) / 1e7,
            north_reference: referenceNorth,
            latitude_reference: !referenceNorth
              ? Math.round(parseFloat(startRefLat) * 1e7) / 1e7
              : Math.round(
                  (parseFloat(centerLat) + (kmInLatAngle * radius) / 1000) * 1e7
                ) / 1e7,
            longitude_reference: !referenceNorth
              ? Math.round(parseFloat(startRefLng) * 1e7) / 1e7
              : Math.round(parseFloat(centerLng) * 1e7) / 1e7,
          },
          endgun_mode: {
            endgun_mode: parseInt(endgunMode),
          },
          endgun_angles: endgunArray,
        },
        kwh_peak: parseFloat(valueKwhPeak),
        kwh_out_of_peak: parseFloat(valueKwhOutOfPeak),
        kwh_reduced: parseFloat(valueKwhReduced),
        injection_pump: injectionPump,
      };

      if (
        parseInt(voltageMin) !== undefined ||
        parseInt(voltageMax) !== undefined ||
        parseInt(voltageStableTime) !== 0
      ) {
        // Adicionando atributos de configuração de pluviômetro caso o pivô tenha pluviômetro
        if (pivot.pluviometer) {
          pivotConfigV5.content["pluviometer_enable"] = {
            enable: pluviometerStopMode === "0" ? 0 : 1,
          };
          pivotConfigV5.content["pluviometer_stop_mode"] = {
            stop_mode: parseInt(pluviometerStopMode),
          };
          pivotConfigV5.content["mm_to_stop"] = {
            value: parseFloat(mmToStop),
          };
          pivotConfigV5.content["pluviometer_scale"] = {
            mm: parseFloat(pluviometerScale),
          };
        }

        const linearPivotConfigV5 = {
          content: {
            pivot_parameters: {
              radius_last: Math.round(parseFloat(radius) * 1e2) / 1e2,
              flow_rate: Math.round(parseFloat(flow) * 1e2) / 1e2,
              speed: Math.round(parseFloat(speed) * 1e2) / 1e2,
              irrigated_area: Math.round(parseFloat(area) * 1e2) / 1e2,
            },
            clock: {
              day: moment().date(),
              month: moment().month() + 1, // estranhamente, o mês começa em zero no Moment
              year: moment().year() - 2000,
              hour: moment().hour(),
              minute: moment().minute(),
            },
            voltage_limit_enable: {
              voltage_limit_enable: 0,
            },
            voltage_configurations: {
              minimum_voltage: 0,
              maximum_voltage: 0,
              stable_time: 0,
              voltage_reference: 480, // valor padrão sugerido pelo Marcelo
            },
            language: {
              language: 2,
            },
            pressure_config: {
              read_pressure_by: 0,
              pump_time_out: 0,
              pump_press_switch: 0,
              pump_press_delay: 0,
              pump_soft_start_time: 0, // 0,e),
              press_sensor_max_range: 0,
              press_sensor_min_range: 0,
              sensor_scale_end: 0,
            },
            autoreversion_configurations: {
              mode: 0,
              time: parseInt(autoreversionTime),
            },
            autoreversion_command: {
              command: 0,
            },
            pause_time: {
              enable_friday: 0,
              enable_monday: 0,
              enable_sunday: 0,
              enable_tuesday: 0,
              enable_saturday: 0,
              enable_thursday: 0,
              enable_wednesday: 0,
              start_pause_time_hour_1: 0,
              start_pause_time_minute_1: 0,
              end_pause_time_hour_1: 0,
              end_pause_time_minute_1: 0,
              start_pause_time_hour_2: 0,
              start_pause_time_minute_2: 0,
              end_pause_time_hour_2: 0,
              end_pause_time_minute_2: 0,
            },
            pause_time_command: {
              pause_time_command: 0,
            },
            power_delay: {
              power_delay: 0,
            },
            sector: {
              start_angle: 0,
              end_angle: 0,
            },
            segments: [],
            pivot_positions: {
              latitude_center: Math.round(parseFloat(centerLat) * 1e7) / 1e7,
              longitude_center: Math.round(parseFloat(centerLng) * 1e7) / 1e7,
              north_reference: referenceNorth,
              latitude_reference: !referenceNorth
                ? Math.round(parseFloat(startRefLat) * 1e7) / 1e7
                : Math.round(
                    (parseFloat(centerLat) + (kmInLatAngle * radius) / 1000) *
                      1e7
                  ) / 1e7,
              longitude_reference: !referenceNorth
                ? Math.round(parseFloat(startRefLng) * 1e7) / 1e7
                : Math.round(parseFloat(centerLng) * 1e7) / 1e7,
            },
            endgun_mode: {
              endgun_mode: 0,
            },
            endgun_angles: [],
          },
          kwh_peak: 0,
          kwh_out_of_peak: 0,
          kwh_reduced: 0,
        };

        if (
          parseInt(voltageMin) !== undefined ||
          parseInt(voltageMax) !== undefined ||
          parseInt(voltageStableTime) !== 0
        ) {
          // Adicionando atributos de configuração de pluviômetro caso o pivô tenha pluviômetro
          if (pivot.pluviometer) {
            pivotConfigV5.content["pluviometer_enable"] = {
              enable: pluviometerStopMode === "0" ? 0 : 1,
            };
            pivotConfigV5.content["pluviometer_stop_mode"] = {
              stop_mode: parseInt(pluviometerStopMode),
            };
            pivotConfigV5.content["mm_to_stop"] = {
              value: parseFloat(mmToStop),
            };
            pivotConfigV5.content["pluviometer_scale"] = {
              mm: parseFloat(pluviometerScale),
            };
          }
        }

        if (props.pivot.automation_type == 0) {
          patchPivot(pivotConfigV5, props.pivot.control, patchObject);
        } else if (props.pivot.automation_type == 1) {
          patchPivot(pivotConfigV5, props.pivot.monitor, patchObject);
        } else {
          patchPivot(linearPivotConfigV5, props.pivot.monitor, patchObject);
        }

        onMount = true;
      } else {
        notify("EDIT_PIVOT_V5_SAVE_ERROR", "error");
        setCounter(0);
      }
    }

    setValue(0);
  };

  const buttonBack = (
    <Button
      color="primary"
      size="small"
      onClick={() => {
        navigate(
          Routes.DASHBOARD.SELECTED_PIVOT.replace(
            ":farm",
            String(farmID)
          ).replace(":pivot", String(pivot.id))
        );
      }}
      startIcon={<ArrowBack />}
    >
      <>{i18n("SELECTED_PIVOT_BACK_BUTTON_TEXT")}</>
    </Button>
  );

  const [disableSave, setDisableSave] = useState(false);

  useEffect(() => {
    for (const key in validationErrors) {
      if (validationErrors.hasOwnProperty(key)) {
        const element = validationErrors[key];
        if (element !== undefined) {
          setDisableSave(true);
          return;
        } else {
          setDisableSave(false);
        }
      }
    }
  }, [validationErrors]);

  const saveButton = (
    <Button
      color="primary"
      endIcon={
        loading || counter !== 0 ? (
          <CircularProgress size={20} style={{ marginLeft: "8px" }} />
        ) : (
          <CheckCircleIcon />
        )
      }
      onClick={saveButtonHandler}
      disabled={
        arrayAnglesHasErrors ||
        counter !== 0 ||
        disableSave ||
        loading ||
        pivotCenterCoordsError ||
        pivotStartCoordsError ||
        !checkCanSave()
      }
    >
      {counter !== 0 ? `${counter}` : <>{i18n("EDIT_PIVOT_SAVE_BUTTON")}</>}
    </Button>
  );

  const markerButton = (
    imageUrl: string,
    setStartRefLat,
    setStartLatLabel,
    setStartRefLng,
    setStartLngLabel,
    recoilSelector = null
  ) => (
    <button
      className="edit-pivot-form__3d-button"
      onClick={() => {
        dispatch(
          showLocationModal({
            content: (
              <GeolocationGetter
                setLatitude={setStartRefLat}
                setLongitude={setStartRefLng}
                recoilSelector={recoilSelector}
              />
            ),
          })
        );
      }}
    >
      <img src={imageUrl} />
    </button>
  );

  return (
    <div className="general-form__container">
      <DashboardBox
        disableFade={true}
        leftElement={buttonBack}
        centerElement={
          <h2>
            <>
              {pivot.automation_type === 0
                ? i18n("EDIT_PIVOT_LABEL")
                : pivot.automation_type === 1
                ? i18n("EDIT_MONITOR_LABEL")
                : i18n("EDIT_LINEAR_PIVOT_MONITOR_LABEL")}
            </>
          </h2>
        }
        // title={"EDIT_PIVOT_LABEL"}
        rightElement={saveButton}
      >
        <div
          style={{
            backgroundColor: "white",
            display: "grid",
            gridTemplateColumns: "12fr",
            height: "100%",
          }}
        >
          <Tabs
            orientation={"horizontal"}
            value={value}
            onChange={(event, newValue) => setValue(newValue)}
            variant={"scrollable"}
            scrollButtons="auto"
            indicatorColor="primary"
            aria-label="Edit Farm"
          >
            <Tab
              className={classes.tab}
              label={<>{i18n("EDIT_PIVOT_LABEL_GENERAL")}</>}
              icon={
                validationErrors["voltageMin"] ||
                validationErrors["voltageMax"] ||
                validationErrors["voltageStableTime"] ||
                validationErrors["voltageRef"] ||
                validationErrors["speed"] ||
                validationErrors["name"] ||
                validationErrors["flow"] ||
                validationErrors["radius"] ? (
                  <Settings
                    style={{
                      color: red[500],
                    }}
                  />
                ) : (
                  <Settings
                    style={{
                      color: green[500],
                    }}
                  />
                )
              }
              {...selectedTab(0)}
            />

            {pivot.automation_type === 0 || pivot.automation_type === 1 ? (
              <Tab
                className={classes.tab}
                label={<>{i18n("EDIT_PIVOT_LABEL_LOCATION")}</>}
                icon={
                  pivotStartCoordsError || pivotCenterCoordsError ? (
                    <Map
                      style={{
                        color: red[500],
                      }}
                    />
                  ) : (
                    <Map
                      style={{
                        color: green[500],
                      }}
                    />
                  )
                }
                {...selectedTab(1)}
              />
            ) : null}

            {pivot.automation_type === 0 || pivot.automation_type === 1 ? (
              <Tab
                className={classes.tab}
                label={<>{i18n("EDIT_PIVOT_LABEL_CLOCK")}</>}
                icon={
                  validationErrors["rtcDate"] || validationErrors["rtcTime"] ? (
                    <AccessTime
                      style={{
                        color: red[500],
                      }}
                    />
                  ) : (
                    <AccessTime
                      style={{
                        color: green[500],
                      }}
                    />
                  )
                }
                {...selectedTab(2)}
              />
            ) : null}

            {pivot.automation_type === 0 || pivot.automation_type === 1 ? (
              <Tab
                className={classes.tab}
                label={<>{i18n("EDIT_PIVOT_LABEL_PUMP")}</>}
                icon={
                  validationErrors["pumpTimeout"] ||
                  validationErrors["pumpPressDelay"] ||
                  validationErrors["pumpPressSwitch"] ||
                  validationErrors["pumpSoftStartTime"] ||
                  validationErrors["potency"] ||
                  validationErrors["powerDelay"] ||
                  validationErrors["pilotAveragePower"] ||
                  validationErrors["canalAveragePower"] ? (
                    <Speed
                      style={{
                        color: red[500],
                      }}
                    />
                  ) : (
                    <Speed
                      style={{
                        color: green[500],
                      }}
                    />
                  )
                }
                {...selectedTab(3)}
              />
            ) : null}

            {pivot.automation_type === 0 || pivot.automation_type === 1 ? (
              <Tab
                style={!pivot.control_radio_id ? { display: "none" } : {}}
                className={classes.tab}
                label={<>{i18n("EDIT_PIVOT_LABEL_PLUVIOMETER")}</>}
                icon={
                  validationErrors["mmToStop"] ||
                  validationErrors["pluviometerScale"] ? (
                    <PluviometerIcon color={red[500]} size={27} />
                  ) : (
                    <PluviometerIcon color={green[500]} size={27} />
                  )
                }
                {...selectedTab(4)}
              />
            ) : null}

            {pivot.automation_type === 0 || pivot.automation_type === 1 ? (
              <Tab
                className={classes.tab}
                label={<>{i18n("EDIT_PIVOT_LABEL_PAUSE_TIME")}</>}
                icon={
                  validationErrors["valueKwhPeak"] ||
                  validationErrors["valueKwhOutOfPeak"] ||
                  validationErrors["setValidValueKwhReduced"] ||
                  validationErrors["pauseTimeStart1"] ||
                  validationErrors["pauseTimeEnd1"] ||
                  validationErrors["pauseTimeStart2"] ||
                  validationErrors["pauseTimeEnd2"] ||
                  validationErrors["pauseTimeOverlap"] ? (
                    <Power
                      style={{
                        color: red[500],
                      }}
                    />
                  ) : (
                    <Power
                      style={{
                        color: green[500],
                      }}
                    />
                  )
                }
                {...selectedTab(5)}
              />
            ) : null}

            {pivot.automation_type === 0 || pivot.automation_type === 1 ? (
              <Tab
                className={classes.tab}
                label={<>{i18n("EDIT_PIVOT_LABEL_PIVOT_SEGMENTS")}</>}
                icon={
                  <>
                    {validationErrors["pivotSegments"] ||
                    arrayAnglesHasErrors ? (
                      <PieChart
                        style={{
                          color: red[500],
                        }}
                      />
                    ) : (
                      <PieChart
                        style={{
                          color: green[500],
                        }}
                      />
                    )}
                  </>
                }
                {...selectedTab(6)}
              />
            ) : null}

            {pivot.automation_type === 0 || pivot.automation_type === 1 ? (
              <Tab
                style={!pivot.control_radio_id ? { display: "none" } : {}}
                className={classes.tab}
                label={<>{i18n("EDIT_PIVOT_LABEL_END_GUN")}</>}
                icon={
                  endgunArray?.length > 10 ? (
                    <PanoramaFishEye
                      style={{
                        color: red[500],
                      }}
                    />
                  ) : (
                    <PanoramaFishEye
                      style={{
                        color: green[500],
                      }}
                    />
                  )
                }
                {...selectedTab(7)}
              />
            ) : null}

            {pivot.automation_type === 0 || pivot.automation_type === 1 ? (
              <Tab
                style={!pivot.control_radio_id ? { display: "none" } : {}}
                className={classes.tab}
                label={<>{i18n("EDIT_PIVOT_V5_AUTOREVERSION")}</>}
                icon={
                  validationErrors["valueKwhPeak"] ||
                  validationErrors["valueKwhOutOfPeak"] ||
                  validationErrors["setValidValueKwhReduced"] ||
                  validationErrors["pauseTimeStart1"] ||
                  validationErrors["pauseTimeEnd1"] ||
                  validationErrors["pauseTimeStart2"] ||
                  validationErrors["pauseTimeEnd2"] ||
                  validationErrors["pauseTimeOverlap"] ? (
                    <Loop
                      style={{
                        color: red[500],
                      }}
                    />
                  ) : (
                    <Loop
                      style={{
                        color: green[500],
                      }}
                    />
                  )
                }
                {...selectedTab(8)}
              />
            ) : null}
            {pivot.communication_type === PIVOT_COMMUNICATION_TYPE.TYPE_4G ? (
              <Tab
                style={!pivot.control_radio_id ? { display: "none" } : {}}
                className={classes.tab}
                label={<>{"Encoder"}</>}
                icon={
                  <EditLocationOutlined
                    style={{
                      color: green[500],
                    }}
                  />
                }
                {...selectedTab(4)}
              />
            ) : null}
          </Tabs>

          {/* GERAL ------------------------------------------------------------------- */}

          <div
            style={{
              backgroundColor: "#fff",
              display: "grid",
              gridTemplateColumns: "1fr",
              height: "100%",
            }}
          >
            <div
              style={{
                overflowY: "hidden",
                overflowX: "hidden",
              }}
            >
              <TabPanel value={value} index={0}>
                <div className="edit-pivot-form__last-settings">
                  <span>{i18n("EDIT_PIVOT_LAST_SETTINGS", [lastUpdate])}</span>
                  {pivot.controllerconfig?.created_on_hardware
                    ? i18n("EDIT_PIVOT_MANUAL")
                    : i18n("EDIT_PIVOT_BY_WEB")}
                </div>

                <div className="general-form__row-3-3-3-3">
                  {pivot?.communication_type == 0 ? (
                    <ChangeRadioField
                      radioID={device}
                      farmID={farmID}
                      deviceID={pivotID}
                      label={i18n("EDIT_PIVOT_CENTRAL_LABEL")}
                      status={MessageStatus.NOT_SENT}
                      deviceType="PIVOT"
                      locked
                    />
                  ) : null}

                  {pivot?.automation_type == 0 ? (
                    <ChangeRadioField
                      radioID={controller}
                      error={error}
                      farmID={farmID}
                      deviceID={pivotID}
                      label={
                        pivot.communication_type !== 1
                          ? i18n("EDIT_PIVOT_DELIVERED_LABEL")
                          : i18n("EDIT_PIVOT_GATEWAY_NUMBER_LABEL")
                      }
                      status={radioStatus.control}
                      deviceType="PIVOT"
                      deviceSubType="control"
                      communicationType={pivot.communication_type}
                    />
                  ) : null}

                  {pivot?.communication_type == 0 ? (
                    <ChangeRadioField
                      radioID={gps}
                      farmID={farmID}
                      deviceID={pivotID}
                      label={
                        pivot?.automation_type == TYPE.CENTRAL_PIVOT_AUTOMATION
                          ? i18n("EDIT_PIVOT_GPS_LABEL")
                          : i18n("EDIT_PIVOT_MONITOR_LABEL")
                      }
                      status={radioStatus.monitor}
                      deviceType="PIVOT"
                      deviceSubType="monitor"
                    />
                  ) : null}

                  {(pivot.automation_type === 0 ||
                    pivot.automation_type === 1) &&
                  pivot.communication_type == 0 ? (
                    <ChangeRadioField
                      radioID={pump}
                      farmID={farmID}
                      deviceID={pivotID}
                      label={i18n("EDIT_PIVOT_PUMP_LABEL")}
                      status={radioStatus.pump}
                      deviceType="PIVOT"
                      deviceSubType="pump"
                    />
                  ) : null}
                </div>

                <div className="general-form__row-6-6">
                  <FormInput
                    id="pivot-name"
                    helperText={validationErrors["name"]}
                    label={"PIVOT_CONFIG_V5_FIELD_NAME"}
                    state={[name, setValidName]}
                  />

                  {pivot.automation_type == 0 ? (
                    <FormSelect
                      id="pivot-config-language"
                      label={"PIVOT_CONFIG_V5_FIELD_LANGUAGE"}
                      labelTooltip={"PIVOT_CONFIG_V5_LANGUAGE_TOOLTIP"}
                      state={[language, setLanguage]}
                      values={[
                        ["1", "PIVOT_CONFIG_V5_VALUE_LANGUAGE_ENGLISH"],
                        ["2", "PIVOT_CONFIG_V5_VALUE_LANGUAGE_PORTUGUESE"],
                        ["3", "PIVOT_CONFIG_V5_VALUE_LANGUAGE_GERMAN"],
                        ["4", "PIVOT_CONFIG_V5_VALUE_LANGUAGE_SPANISH"],
                        ["5", "PIVOT_CONFIG_V5_VALUE_LANGUAGE_RUSSIAN"],
                      ]}
                    />
                  ) : null}
                </div>

                <div className="general-form__row-6-6">
                  <FormInput
                    id="pivot-config-radius"
                    helperText={validationErrors["radius"]}
                    label={
                      pivot.automation_type === 0 || pivot.automation_type === 1
                        ? "PIVOT_CONFIG_FIELD_RADIUS"
                        : "LPM_CONFIG_FIELD_LENGTH"
                    }
                    state={[radius, setValidRadius]}
                    tooltip={
                      pivot.automation_type === 0 || pivot.automation_type === 1
                        ? "PIVOT_CONFIG_FIELD_RADIUS_TOOLTIP"
                        : "LPM_CONFIG_FIELD_LENGTH_TOOLTIP"
                    }
                    endAdornment="m"
                    inputMode="numeric"
                  />

                  <FormInput
                    id="pivot-config-flow"
                    helperText={validationErrors["flow"]}
                    label={"PIVOT_CONFIG_V5_FIELD_FLOW"}
                    state={[flow, setValidFlow]}
                    endAdornment="m³/h"
                    inputMode="numeric"
                  />

                  <FormInput
                    id="pivot-config-speed"
                    helperText={validationErrors["speed"]}
                    label={
                      pivot.automation_type === 0 || pivot.automation_type === 1
                        ? "PIVOT_CONFIG_V5_FIELD_SPEED"
                        : "LPM_CONFIG_V5_FIELD_SPEED"
                    }
                    state={[speed, setValidSpeed]}
                    tooltip={
                      pivot.automation_type === 0 || pivot.automation_type === 1
                        ? "PIVOT_CONFIG_V5_FIELD_SPEED_TOOLTIP"
                        : "LPM_CONFIG_V5_FIELD_SPEED_TOOLTIP"
                    }
                    endAdornment="m/h"
                    inputMode="numeric"
                  />

                  <FormInput
                    id="pivot-config-area"
                    helperText={validationErrors["area"]}
                    label={"PIVOT_CONFIG_V5_FIELD_AREA"}
                    state={[area, setValidArea]}
                    endAdornment="ha"
                    inputMode="numeric"
                  />

                  <FormSelect
                    id="pivot-brand-model"
                    label={"PIVOT_CONFIG_V5_BRAND_MODEL"}
                    state={[brandModel, setBrandModel]}
                    values={[
                      ["Bauer", "CREATE_BRAND_MODEL_BAUER"],
                      ["Carborundum", "CREATE_BRAND_MODEL_CARBORUNDUM"],
                      ["Fockink", "CREATE_BRAND_MODEL_FOCKINK"],
                      ["Irrigabras", "CREATE_BRAND_MODEL_IRRIGABRAS"],
                      ["Krebs", "CREATE_BRAND_MODEL_KREBS"],
                      ["Lindsay", "CREATE_BRAND_MODEL_LINDSAY"],
                      ["Reinke", "CREATE_BRAND_MODEL_REINKE"],
                      ["Valley", "CREATE_BRAND_MODEL_VALLEY"],
                      ["Other", "CREATE_BRAND_MODEL_OTHER"],
                    ]}
                  />

                  {brandModel !== "Bauer" &&
                  brandModel !== "Carborundum" &&
                  brandModel !== "Fockink" &&
                  brandModel !== "Irrigabras" &&
                  brandModel !== "Krebs" &&
                  brandModel !== "Lindsay" &&
                  brandModel !== "Reinke" &&
                  brandModel !== "Valley" &&
                  brandModel !== "Other"
                    ? setBrandModel("Other")
                    : null}

                  <FormSelect
                    id="panelTypeSelect"
                    label="CREATE_DEVICE_MODAL_PANEL_TYPE"
                    newLabel="CREATE_DEVICE_MODAL_PANEL_TYPE"
                    state={[panelType, setPanelType]}
                    values={
                      panelType === null
                        ? [
                            [null, "CREATE_DEVICE_MODAL_OTHER"],
                            ["1", "CREATE_DEVICE_MODAL_NEXUS"],
                            ["2", "CREATE_DEVICE_MODAL_SMARTCONNECT"],
                          ]
                        : brandModel !== "Bauer"
                        ? [["1", "CREATE_DEVICE_MODAL_NEXUS"]]
                        : [
                            ["1", "CREATE_DEVICE_MODAL_NEXUS"],
                            ["2", "CREATE_DEVICE_MODAL_SMARTCONNECT"],
                          ]
                    }
                  />

                  {brandModel === "Other" ? (
                    <FormInput
                      id="otherBrand"
                      label="CREATE_BRAND_MODEL_INSERT"
                      state={[otherBrand, setValidOtherBrand]}
                      helperText={validationErrors["otherBrand"]}
                    />
                  ) : null}
                </div>

                {pivot.automation_type == 0 ? (
                  <div className="general-form__row-3-3-3-3">
                    <FormCheckBox
                      state={[checkVoltage, setCheckVoltage]}
                      label={"PIVOT_CONFIG_V5_FIELD_VOLTAGE_CHECK"}
                      labelTooltip={"PIVOT_CONFIG_V5_TENSION_LIMIT_TOOLTIP"}
                    />

                    <FormInput
                      id="pivot-config-voltage-min"
                      helperText={validationErrors["voltageMin"]}
                      label={"PIVOT_CONFIG_V5_FIELD_VOLTAGE_MIN"}
                      state={[voltageMin, setValidVoltageMin]}
                      endAdornment="V"
                      disabled={!checkVoltage}
                      inputMode="numeric"
                    />

                    <FormInput
                      id="pivot-config-voltage-max"
                      helperText={validationErrors["voltageMax"]}
                      label={"PIVOT_CONFIG_V5_FIELD_VOLTAGE_MAX"}
                      state={[voltageMax, setValidVoltageMax]}
                      endAdornment="V"
                      disabled={!checkVoltage}
                      inputMode="numeric"
                    />

                    <FormInput
                      id="pivot-config-voltage-stable-time"
                      helperText={validationErrors["voltageStableTime"]}
                      label={"PIVOT_CONFIG_V5_FIELD_LIMIT_TIME"}
                      state={[voltageStableTime, setValidVoltageStableTime]}
                      tooltip={"PIVOT_CONFIG_V5_FIELD_LIMIT_TIME_TOOLTIP"}
                      endAdornment="min"
                      disabled={!checkVoltage}
                      inputMode="numeric"
                    />
                  </div>
                ) : null}
              </TabPanel>

              {/* LOCALIZAÇÃO ------------------------------------------------------------------- */}

              <TabPanel value={value} index={1}>
                <div className="general-form__row-4-4-4">
                  <FormInputPosition
                    setError={setPivotCenterCoordsError}
                    id="pivot-config-center"
                    markerUrl={markerButton(
                      greenDot,
                      setCenterLat,
                      setCenterLatLabel,
                      setCenterLng,
                      setCenterLngLabel,
                      centerMarkerSelector
                    )}
                    label={"PIVOT_CONFIG_V5_FIELD_GP_CENTER"}
                    labelTooltip={"PIVOT_CONFIG_V5_FIELD_GP_CENTER_TOOLTIP"}
                    lat={[centerLatLabel, setValidCenterLat]}
                    lng={[centerLngLabel, setValidCenterLng]}
                    recoilSelector={centerMarkerSelector}
                  />

                  <FormInputPosition
                    setError={setPivotStartCoordsError}
                    id="pivot-config-start-ref"
                    markerUrl={markerButton(
                      redDot,
                      setStartRefLat,
                      setStartLatLabel,
                      setStartRefLng,
                      setStartLngLabel,
                      initialMarkerSelector
                    )}
                    label={"PIVOT_CONFIG_V5_FIELD_GP_REFERENCE"}
                    labelTooltip={"PIVOT_CONFIG_V5_FIELD_GP_REFERENCE_TOOLTIP"}
                    lat={[startLatLabel, setValidStartRefLat]}
                    lng={[startLngLabel, setValidStartRefLng]}
                    recoilSelector={initialMarkerSelector}
                    disabled={referenceNorth}
                  />
                </div>

                <div className="general-form__row">
                  <FormCheckBox
                    label={"EDIT_PIVOT_V5_REFERENCE_NORTH"}
                    state={[referenceNorth, setReferenceNorth]}
                    recoilState={isNorthReferenceState}
                  />
                </div>

                <div className="edit-pivot-form__map">
                  <DraggableMap
                    zoomScroll={false}
                    _setExperimentalRecoil
                    markers={[
                      {
                        lat: {
                          state: centerLat,
                          setFunction: setValidCenterLat,
                        },
                        lng: {
                          state: centerLng,
                          setFunction: setValidCenterLng,
                        },
                        markerUrl: greenDot,
                        key: "center",
                        event: {
                          state: event,
                          setFunction: setEvent,
                        },
                      },
                      {
                        lat: {
                          state: startRefLat,
                          setFunction: setValidStartRefLat,
                        },
                        lng: {
                          state: startRefLng,
                          setFunction: setValidStartRefLng,
                        },
                        markerUrl: redDot,
                        key: "start-ref",
                        event: {
                          state: event,
                          setFunction: setEvent,
                        },
                      },
                    ]}
                  />
                </div>
              </TabPanel>

              {/* HORÁRIO ------------------------------------------------------------------- */}

              <TabPanel value={value} index={2}>
                <div className="general-form__row-12">
                  <FormSelect
                    id="pivot-config-segment-mode-forward"
                    label={"PIVOT_CONFIG_V5_FIELD_CLOCK_LABEL"}
                    state={[rtc, handleSetRtc]}
                    values={[
                      ["auto", "PIVOT_CONFIG_V5_FIELD_AUTO_SET_CLOCK"],
                      ["manual", "PIVOT_CONFIG_V5_FIELD_CLOCK"],
                    ]}
                  />
                </div>

                <div className="general-form__row-6-6">
                  <FormDateTimePicker
                    id="pivot-config-rtc-date-time"
                    disabled={rtc == "auto"}
                    label={"PIVOT_CONFIG_V5_FIELD_CLOCK_DATE"}
                    state={[rtcDate, setValidRtcDate]}
                  />
                </div>
              </TabPanel>

              {/* BOMBA ------------------------------------------------------------------- */}

              <TabPanel value={value} index={3}>
                <h4 style={{ margin: 0 }}>{i18n("EDIT_PIVOT_LABEL_POWER")}</h4>

                <div className="general-form__row-6-6">
                  <FormInput
                    id="pivot-config-potency"
                    helperText={validationErrors["potency"]}
                    inputMode="numeric"
                    label={"PIVOT_CONFIG_V5_FIELD_POTENCY"}
                    state={[String(potency), setValidPotency]}
                    endAdornment="kW"
                  />

                  {pivot.automation_type == 0 ? (
                    <FormInput
                      id="pivot-config-power-delay"
                      inputMode="numeric"
                      helperText={validationErrors["switchFactor"]}
                      label={"PIVOT_CONFIG_V5_FIELD_POWER_DELAY"}
                      tooltip="PIVOT_CONFIG_V5_FIELD_POWER_DELAY_TOOLTIP"
                      state={[powerDelay, setValidPowerDelay]}
                      endAdornment="min"
                    />
                  ) : null}
                </div>

                <Divider style={{ marginTop: "20px", marginBottom: "20px" }} />

                <h4 style={{ margin: 0 }}>
                  {i18n("EDIT_PIVOT_V5_PUMP_PRESSURE_CONFIG")}
                </h4>

                {pivot.automation_type == 0 ? (
                  <div className="general-form__row-12">
                    <FormSelect
                      id="pivot-config-segment-mode-forward"
                      label={"PIVOT_CONFIG_V5_FIELD_PUMP_READ_PRESSURE"}
                      state={[readPressureBy, setReadPressureBy]}
                      labelTooltip={
                        "PIVOT_CONFIG_V5_FIELD_PUMP_READ_PRESSURE_TOOLTIP"
                      }
                      values={[
                        ["0", "PIVOT_CONFIG_V5_FIELD_PUMP_READ_PRESSURE_OFF"],
                        [
                          "1",
                          "PIVOT_CONFIG_V5_FIELD_PUMP_READ_PRESSURE_SWITCH",
                        ],
                        [
                          "2",
                          "PIVOT_CONFIG_V5_FIELD_PUMP_READ_PRESSURE_SENSOR",
                        ],
                      ]}
                    />
                  </div>
                ) : (
                  setReadPressureBy(2)
                )}

                <div className="general-form__row-4-4-4">
                  {pivot.automation_type == TYPE.CENTRAL_PIVOT_AUTOMATION ? (
                    <FormInput
                      id="pivot-config-pump-timeout"
                      helperText={validationErrors["pumpTimeout"]}
                      label={"PIVOT_CONFIG_V5_FIELD_PUMP_TIMEOUT"}
                      inputMode="numeric"
                      state={[pumpTimeout, setValidPumpTimeout]}
                      tooltip={"PRESSURE_TIMEOUT_INPUT"}
                      endAdornment={"min"}
                      disabled={disabledPumpTimeout}
                    />
                  ) : null}

                  <>
                    {pivot.automation_type == TYPE.CENTRAL_PIVOT_AUTOMATION ? (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <FormInput
                          id="pivot-config-pump-press-delay"
                          helperText={validationErrors["pumpPressDelay"]}
                          label={"PIVOT_CONFIG_V5_FIELD_PUMP_PRESS_DELAY"}
                          state={[pumpPressDelay, setValidPumpPressDelay]}
                          inputMode="numeric"
                          tooltip="PRESSURE_DELAY_INPUT"
                          endAdornment={"s"}
                          disabled={disabledPumpPressDelay}
                        />
                      </div>
                    ) : null}
                  </>

                  {pivot.automation_type == 0 ? (
                    <FormInput
                      id="pivot-config-pump-press-switch"
                      helperText={validationErrors["pumpPressSwitch"]}
                      inputMode="numeric"
                      label={"PIVOT_CONFIG_V5_FIELD_PUMP_PRESS_SWITCH"}
                      state={[pumpPressSwitch, setValidPumpPressSwitch]}
                      tooltip="PIVOT_CONFIG_V5_FIELD_PUMP_PRESS_SWITCH_TOOLTIP"
                      endAdornment="min"
                      disabled={disabledPumpPressSwitch}
                    />
                  ) : null}

                  {pivot.automation_type == 0 ? (
                    <FormInput
                      id="pivot-config-pump-press-switch-min-range"
                      inputMode="numeric"
                      helperText={validationErrors["switchMinRange"]}
                      label={
                        "PIVOT_CONFIG_V5_FIELD_PUMP_PRESS_SWITCH_MIN_RANGE"
                      }
                      state={[sensorMinRange, setValidSwitchPumpMinRange]}
                      tooltip="PRESSURE_SENSOR_RANGE_MIN"
                      endAdornment={"bar"}
                      disabled={disabledSensorMinRange}
                    />
                  ) : null}

                  {pivot.automation_type == 0 ? (
                    <FormInput
                      id="pivot-config-pump-press-switch-max-range"
                      helperText={validationErrors["switchMaxRange"]}
                      inputMode="numeric"
                      label={
                        "PIVOT_CONFIG_V5_FIELD_PUMP_PRESS_SWITCH_MAX_RANGE"
                      }
                      state={[sensorMaxRange, setValidSwitchPumpMaxRange]}
                      tooltip="PRESSURE_SENSOR_RANGE_MAX"
                      endAdornment={"bar"}
                      disabled={disabledSensorMaxRange}
                    />
                  ) : null}

                  <FormInput
                    id="pivot-config-pump-press-switch-factor"
                    helperText={validationErrors["sensorScaleEnd"]}
                    inputMode="numeric"
                    label={"PIVOT_CONFIG_V5_FIELD_SENSOR_SCALE_END"}
                    state={[sensorScaleEnd, setValidSensorScaleEnd]}
                    tooltip="SENSOR_SCALE_END"
                    endAdornment={"bar"}
                    disabled={disabledSensorScaleEnd}
                  />
                </div>

                <Divider style={{ marginTop: "20px", marginBottom: "20px" }} />

                <h4 style={{ margin: 0 }}>
                  {i18n("EDIT_PIVOT_V5_INJETCTION_PUMP_CONFIG")}
                </h4>

                <div className="general-form__row-6-6">
                  <FormCheckBox
                    state={[injectionPump, setInjectionPump]}
                    label={"PIVOT_CONFIG_V5_FIELD_INJECTION_PUMP_CHECK"}
                    labelTooltip="PIVOT_CONFIG_V5_FIELD_INJECTION_PUMP_TOOL_TIP"
                  />
                </div>
              </TabPanel>

              {/* PLUVIÔMETRO ------------------------------------------------------------------- */}

              <TabPanel value={value} index={4}>
                <div>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      enablePluviometerHandler();
                    }}
                  >
                    {!pivot.pluviometer ? (
                      <>{i18n("ENABLE_PLUVIOMETER")}</>
                    ) : (
                      <>{i18n("DISABLE_PLUVIOMETER")}</>
                    )}
                  </Button>
                </div>

                {pivot.pluviometer ? (
                  <div className="general-form__row-4-4-4">
                    {/* FormSelect tanto para desabilitado quanto para os modos */}
                    <FormSelect
                      id="pivot-config-language"
                      label={"PIVOT_CONFIG_V5_FIELD_ENDGUN_ANGLE_MODE"}
                      state={[pluviometerStopMode, setPluviometerStopMode]}
                      labelTooltip={
                        "PIVOT_CONFIG_V5_FIELD_ENDGUN_ANGLE_MODE_TOOLTIP"
                      }
                      values={[
                        ["0", "EDIT_PIVOT_V5_AUTOREVERSION_DISABLED"],
                        ["1", "EDIT_PLUVIOMETER_MODE_BY_VALUE"],
                        ["2", "EDIT_PLUVIOMETER_MODE_DECREMENT"],
                      ]}
                    />

                    <FormInput
                      disabled={
                        pluviometerStopMode == "0" || pluviometerStopMode == "2"
                      }
                      id="pivot-config-pump-press-switch-factor"
                      helperText={validationErrors["mmToStop"]}
                      inputMode="numeric"
                      label={"EDIT_PLUVIOMETER_MM_TO_STOP"}
                      tooltip={"EDIT_PLUVIOMETER_MM_TO_STOP_TOOLTIP"}
                      state={[mmToStop, setValidMMToStop]}
                      endAdornment={"mm"}
                    />

                    <FormInput
                      id="pivot-config-pump-press-switch-factor"
                      // disabled={pluviometerStopMode !== "1"}
                      helperText={validationErrors["pluviometerScale"]}
                      inputMode="numeric"
                      label={"PIVOT_CONFIG_V5_FIELD_SENSOR_SCALE_END"}
                      state={[pluviometerScale, setValidPluviometerScale]}
                      tooltip={"PIVOT_CONFIG_V5_FIELD_SENSOR_SCALE_END_TOOLTIP"}
                      endAdornment={"mm"}
                    />
                  </div>
                ) : null}
              </TabPanel>

              {/* HORÁRIO DE PICO ------------------------------------------------------------------- */}

              <TabPanel value={value} index={5}>
                <h4>{i18n("EDIT_PIVOT_V5_DAYS_OF_THE_WEEK_DESCRIPTION")}</h4>

                <p style={{ marginTop: "-19px" }}>
                  {i18n("EDIT_PIVOT_V5_DAYS_OF_THE_WEEK_DESCRIPTION_TEXT")}
                </p>

                <div>
                  <h4>{i18n("EDIT_PIVOT_PAUSE_TIME_PRICES_TITLE")}</h4>

                  <div className="general-form__row-4-4-4">
                    <FormInput
                      id="standard-basic-hp"
                      helperText={validationErrors["valueKwhPeak"]}
                      label="BASIC_INFO_BOX_PONTA"
                      state={[valueKwhPeak, setValidValueKwhPeak]}
                      inputMode="numeric"
                      startAdornment={i18n("CURRENCY")}
                    />

                    <FormInput
                      id="standard-basic-hfp"
                      helperText={validationErrors["valueKwhOutOfPeak"]}
                      label="BASIC_INFO_BOX_FORA_DE_PONTA"
                      state={[valueKwhOutOfPeak, setValidValueKwhOutOfPeak]}
                      inputMode="numeric"
                      startAdornment={i18n("CURRENCY")}
                    />

                    <FormInput
                      id="standard-basic-hr"
                      helperText={validationErrors["valueKwhReduced"]}
                      label="BASIC_INFO_BOX_REDUZIDO"
                      state={[valueKwhReduced, setValidValueKwhReduced]}
                      inputMode="numeric"
                      startAdornment={i18n("CURRENCY")}
                    />
                  </div>
                </div>

                <h4 style={{ margin: 0, marginBottom: "5px" }}>
                  {i18n("EDIT_PIVOT_V5_DAYS_OF_THE_WEEK")}
                </h4>

                <div
                  className="general-form__row-x-1-1-1-1-1-1-1"
                  style={!isMobile() ? { width: "60%" } : null}
                >
                  <CheckBoxItem>
                    <Checkbox
                      color="primary"
                      disabled={!pauseTimeStatus1}
                      checked={weekday.sun}
                      onChange={(event) => {
                        setValidWeekday("sun");
                      }}
                    />
                    {i18n("EDIT_PIVOT_V5_WEEKDAY_SUNDAY")}
                  </CheckBoxItem>

                  <CheckBoxItem>
                    <Checkbox
                      color="primary"
                      disabled={!pauseTimeStatus1}
                      checked={weekday.mon}
                      onChange={(event) => {
                        setValidWeekday("mon");
                      }}
                    />
                    {i18n("EDIT_PIVOT_V5_WEEKDAY_MONDAY")}
                  </CheckBoxItem>

                  <CheckBoxItem>
                    <Checkbox
                      color="primary"
                      disabled={!pauseTimeStatus1}
                      checked={weekday.tue}
                      onChange={(event) => {
                        setValidWeekday("tue");
                      }}
                    />
                    {i18n("EDIT_PIVOT_V5_WEEKDAY_TUESDAY")}
                  </CheckBoxItem>

                  <CheckBoxItem>
                    <Checkbox
                      color="primary"
                      disabled={!pauseTimeStatus1}
                      checked={weekday.wed}
                      onChange={(event) => {
                        setValidWeekday("wed");
                      }}
                    />
                    {i18n("EDIT_PIVOT_V5_WEEKDAY_WEDNESDAY")}
                  </CheckBoxItem>

                  <CheckBoxItem>
                    <Checkbox
                      color="primary"
                      disabled={!pauseTimeStatus1}
                      checked={weekday.thu}
                      onChange={(event) => {
                        setValidWeekday("thu");
                      }}
                    />
                    {i18n("EDIT_PIVOT_V5_WEEKDAY_THURSDAY")}
                  </CheckBoxItem>

                  <CheckBoxItem>
                    <Checkbox
                      color="primary"
                      disabled={!pauseTimeStatus1}
                      checked={weekday.fri}
                      onChange={(event) => {
                        setValidWeekday("fri");
                      }}
                    />
                    {i18n("EDIT_PIVOT_V5_WEEKDAY_FRIDAY")}
                  </CheckBoxItem>

                  <CheckBoxItem>
                    <Checkbox
                      color="primary"
                      disabled={!pauseTimeStatus1}
                      checked={weekday.sat}
                      onChange={(event) => {
                        setValidWeekday("sat");
                      }}
                    />
                    {i18n("EDIT_PIVOT_V5_WEEKDAY_SATURDAY")}
                  </CheckBoxItem>
                </div>

                <h4
                  style={{ margin: 0, marginBottom: "5px", marginTop: "10px" }}
                >
                  {i18n("EDIT_PIVOT_V5_CONFIGURATIONS")}
                </h4>

                <div className="general-form__row-2-5-5">
                  <FormCheckBox
                    state={[pauseTimeStatus1, setPauseTimeStatus1]}
                    label={"PIVOT_CONFIG_V5_FIELD_PAUSE_TIME_STATUS_1"}
                  />

                  <FormTimePicker
                    id="pivot-config-pause-time-start-1"
                    disabled={!pauseTimeStatus1}
                    error={validationErrors["pauseTimeStart1"]}
                    label={"PIVOT_CONFIG_V5_FIELD_PAUSE_TIME_START"}
                    state={[pauseTimeStart1, setValidPauseTimeStart1]}
                  />

                  <FormTimePicker
                    id="pivot-config-pause-time-end-1"
                    disabled={!pauseTimeStatus1}
                    error={validationErrors["pauseTimeEnd1"]}
                    label={"PIVOT_CONFIG_V5_FIELD_PAUSE_TIME_END"}
                    state={[pauseTimeEnd1, setValidPauseTimeEnd1]}
                  />
                </div>

                <div className="general-form__row-2-5-5">
                  <FormCheckBox
                    state={[pauseTimeStatus2, setPauseTimeStatus2]}
                    disabled={!pauseTimeStatus1}
                    label={"PIVOT_CONFIG_V5_FIELD_PAUSE_TIME_STATUS_2"}
                  />

                  <FormTimePicker
                    id="pivot-config-pause-time-start-2"
                    disabled={!pauseTimeStatus2}
                    error={
                      validationErrors["pauseTimeStart2"] ||
                      validationErrors["pauseTimeOverlap"]
                    }
                    label={"PIVOT_CONFIG_V5_FIELD_PAUSE_TIME_START"}
                    state={[pauseTimeStart2, setValidPauseTimeStart2]}
                  />

                  <FormTimePicker
                    id="pivot-config-pause-time-end-2"
                    disabled={!pauseTimeStatus2}
                    error={
                      validationErrors["pauseTimeEnd2"] ||
                      validationErrors["pauseTimeOverlap"]
                    }
                    label={"PIVOT_CONFIG_V5_FIELD_PAUSE_TIME_END"}
                    state={[pauseTimeEnd2, setValidPauseTimeEnd2]}
                  />
                </div>
              </TabPanel>

              {/* SEGMENTOS E PLANTIO ------------------------------------------------------------------- */}

              <TabPanel value={value} index={6}>
                <div>
                  {pivot.automation_type == TYPE.CENTRAL_PIVOT_AUTOMATION ? (
                    <h4>
                      {i18n("EDIT_PIVOT_V5_DAYS_OF_THE_WEEK_DESCRIPTION")}
                    </h4>
                  ) : null}

                  {pivot.automation_type == 0 ? (
                    <p style={{ marginTop: "-19px" }}>
                      {i18n("EDIT_PIVOT_V5_PIVOT_SEGMENTS_TEXT")}
                    </p>
                  ) : null}

                  <div
                    key={`segments-alert-${arrayAngles.length}`}
                    style={{
                      height: heightToPreventFlicks(),
                      marginBottom: isMobile()
                        ? `calc(${heightToPreventFlicks()} / 2)`
                        : 0,
                    }}
                  >
                    <Alert
                      severity={
                        validationErrors["pivotSegments"] ? "error" : "info"
                      }
                      style={{ marginBottom: "10px" }}
                    >
                      {validationErrors["pivotSegments"]
                        ? i18n("EDIT_PIVOT_V5_SEGMENT_WARNING")
                        : i18n("PIVOT_CONFIG_V5_SEGMENT_ERROR", [5])}
                      <div
                        style={{
                          marginBottom:
                            isMobile() && !validationErrors["pivotSegments"]
                              ? "44px"
                              : 0,
                        }}
                      ></div>
                    </Alert>
                  </div>

                  {angleEnd != 360 && (
                    <Alert severity={"error"} style={{ marginBottom: "10px" }}>
                      {i18n("SETORIAL_ENDGUN_ERROR")}
                    </Alert>
                  )}

                  <div
                    className={
                      isMobile() ? null : "edit-pivot-form-v5__container_max"
                    }
                  >
                    <div style={{ marginBottom: "10px" }}>
                      <StyledH4>{i18n("WORKING_ANGLE")}</StyledH4>

                      <div
                        className="general-form__row"
                        style={{ marginBottom: "25px" }}
                      >
                        <FormInput
                          id="pivot-config-angle-end"
                          helperText={validationErrors["angleEnd"]}
                          label={"PIVOT_CONFIG_V5_FIELD_ANGLE_END"}
                          stringLabel={i18n("WORKING_ANGLE_LABEL")}
                          state={[angleEnd, setValidAngleEnd]}
                          endAdornment="º"
                          inputMode="numeric"
                        />
                      </div>

                      {pivot.automation_type == 0 ? (
                        <div>
                          <GeneralAngleSelector
                            key={reload}
                            pivot={pivot}
                            anglesState={[arrayAngles, setArrayAngles]}
                            maxLength={5}
                            setShowLastAngle={setShowLastArrayAngle}
                            startOnReference={true}
                            angleDirection={true}
                            hasNames={true}
                            sectorLength={angleEnd}
                            label={"PIVOT_CONFIG_V5_FIELD_ENDGUN_ANGLE_END"}
                          />
                        </div>
                      ) : null}
                    </div>

                    <MobileZone>
                      <div style={{ marginBottom: "200px" }}></div>
                    </MobileZone>
                  </div>
                </div>
              </TabPanel>

              {/* CANHÃO FINAL ------------------------------------------------------------------- */}

              <TabPanel value={value} index={7}>
                <div>
                  <h4>{i18n("EDIT_PIVOT_V5_DAYS_OF_THE_WEEK_DESCRIPTION")}</h4>

                  <p style={{ marginTop: "-19px" }}>
                    {i18n("PIVOT_CONFIG_V5_FIELD_ENDGUN_TEXT")}
                  </p>

                  {endgunMode === "2" ? (
                    <Alert severity={"info"} style={{ marginBottom: "10px" }}>
                      {i18n("PIVOT_CONFIG_V5_SEGMENT_ERROR", [6])}
                    </Alert>
                  ) : null}

                  {angleEnd != 360 && (
                    <Alert severity={"error"} style={{ marginBottom: "10px" }}>
                      {i18n("SETORIAL_ENDGUN_ERROR")}
                    </Alert>
                  )}

                  <div
                    className="general-form__row-12"
                    style={{ marginBottom: "10px" }}
                  >
                    <FormSelect
                      id="pivot-config-language"
                      label={"PIVOT_CONFIG_V5_FIELD_ENDGUN_ANGLE_MODE"}
                      state={[endgunMode, setEndgunMode]}
                      values={[
                        ["0", "PIVOT_CONFIG_V5_VALUE_ENDGUN_MODE_ALWAYS_OFF"],
                        ["1", "PIVOT_CONFIG_V5_VALUE_ENDGUN_MODE_ALWAYS_ON"],
                        ["2", "PIVOT_CONFIG_V5_VALUE_ENDGUN_MODE_BY_SEGMENTS"],
                      ]}
                    />
                  </div>

                  {showEndgunDrawing && (
                    <div className="edit-pivot-form-v5__container">
                      <EndgunAngleSelector
                        key={`EndgunAngleComponent_${isMobile()}-${reload}`}
                        pivot={pivot}
                        maxLength={6}
                        strokeSize={0.9}
                        startOnReference={true}
                        angleDirection={true}
                        anglesState={[endgunArray, setEndgunArray]}
                        setShowLastAngle={setShowLastArrayAngle}
                        sectorLength={angleEnd}
                        label={"PIVOT_CONFIG_V5_FIELD_ENDGUN_ANGLE_END"}
                      />

                      <div>
                        {endgunArray.length ? (
                          <div className="edit-pivot-form-v5__table">
                            <div
                              style={
                                isMobile()
                                  ? {}
                                  : {
                                      marginTop: "-10px",
                                      maxHeight: "500px",
                                      overflowY: "auto",
                                    }
                              }
                            >
                              {endgunArray.map((elem, index) => (
                                <>
                                  {index === 0 && (
                                    <StyledH4 style={{ marginBottom: 10 }}>
                                      {i18n("SELECTED_PIVOT_VRI_IRRIGATION")}
                                    </StyledH4>
                                  )}

                                  <div
                                    className={
                                      index === endgunArray.length - 1
                                        ? "table-pivot-segmentsLast"
                                        : "table-pivot-segments"
                                    }
                                    style={
                                      !showLastArrayAngle &&
                                      index === endgunArray.length - 1
                                        ? { opacity: 0 }
                                        : {}
                                    }
                                  >
                                    <div
                                      className="table-pivot-segments__index"
                                      style={{
                                        backgroundColor: BLUE_COLORS_TABLE[
                                          index
                                        ].replace("99", ""),
                                        color: "white",
                                      }}
                                    >
                                      {index + 1}
                                    </div>

                                    <div className="table-pivot-segments__info">
                                      <div className="table-pivot-segments__info__box">
                                        <div>
                                          {i18n(
                                            "PIVOT_CONFIG_V5_FIELD_SEGMENT_ANGLE_START",
                                            [endgunArray[index].start_angle]
                                          )}
                                        </div>
                                      </div>

                                      <div className="table-pivot-segments__info__box">
                                        <div>
                                          {i18n(
                                            "PIVOT_CONFIG_V5_FIELD_SEGMENT_ANGLE_END",
                                            [endgunArray[index]?.end_angle]
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ))}
                            </div>
                          </div>
                        ) : (
                          <div></div>
                        )}
                      </div>
                    </div>
                  )}

                  <MobileZone>
                    {endgunMode === "2" && (
                      <div style={{ marginBottom: "200px" }}></div>
                    )}
                  </MobileZone>
                </div>
              </TabPanel>

              {/* AUTOREVERSÃO ------------------------------------------------------------------- */}

              <TabPanel value={value} index={8}>
                <h4>{i18n("EDIT_PIVOT_V5_DAYS_OF_THE_WEEK_DESCRIPTION")}</h4>

                <p style={{ marginTop: "-19px" }}>
                  {i18n("PIVOT_CONFIG_V5_AUTORREVERSION_TEXT")}
                </p>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <FormSelect
                    id="autoreversion-mode"
                    label={"EDIT_PIVOT_V5_AUTOREVERSION_MODE"}
                    state={[autoreversionMode, setAutoreversionMode]}
                    values={[
                      ["-1", "EDIT_PIVOT_V5_AUTOREVERSION_DISABLED"],
                      ["0", "EDIT_PIVOT_V5_AUTOREVERSION_BY_SWITCH"],
                      ["1", "EDIT_PIVOT_V5_AUTOREVERSION_BY_ANGLE"],
                    ]}
                  />

                  <div style={{ margin: "10px" }}></div>

                  <FormSelect
                    id="pivot-config-autoreversion-time"
                    label={"EDIT_PIVOT_V5_AUTOREVERSION_TIME"}
                    state={[autoreversionTime, setValidAutoreversionTime]}
                    labelTooltip={"EDIT_PIVOT_V5_AUTOREVERSION_TIME_TOOLTIP"}
                    values={[
                      ["30", "EDIT_PIVOT_V5_AUTOREVERSION_30S"],
                      ["60", "EDIT_PIVOT_V5_AUTOREVERSION_1MIN"],
                      ["120", "EDIT_PIVOT_V5_AUTOREVERSION_2MIN"],
                    ]}
                    disabled={disabledAutoreversionTime}
                  />
                </div>
              </TabPanel>

              {/* ENCODER ------------------------------------------------------------------- */}

              <TabPanel value={value} index={9}>
                <div style={{ padding: isMobile() ? 12 : 0 }}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <h3 style={{ marginTop: "10px", marginBottom: "10px" }}>
                      {i18n("ENCODER")}
                    </h3>
                  </div>

                  <Box>
                    <span style={{ marginTop: "-19px" }}>
                      {i18n("ENCODER_DESCRIPTION_TEXT")}
                    </span>
                  </Box>
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: isMobile() ? "auto" : "auto auto",
                      gap: "10px",
                      alignItems: "baseline",
                    }}
                  >
                    {isMobile() ? (
                      <>
                        <ResetManualContent />
                        <Button
                          variant="contained"
                          color="primary"
                          style={{
                            marginTop: "12px",
                            marginRight: "10px",
                          }}
                          onClick={() => {
                            dispatch(
                              showModal({
                                content: <ResetConfirmationContent />,
                                title: "ENCODER",
                              })
                            );
                          }}
                        >
                          {i18n("ENCODER_BTN")}
                        </Button>
                      </>
                    ) : (
                      <>
                        <Button
                          variant="contained"
                          color="primary"
                          style={{
                            marginTop: "12px",
                            marginRight: "10px",
                          }}
                          onClick={() => {
                            dispatch(
                              showModal({
                                content: <ResetConfirmationContent />,
                                title: "ENCODER",
                              })
                            );
                          }}
                        >
                          {i18n("ENCODER_BTN")}
                        </Button>
                        <ResetManualContent />
                      </>
                    )}
                  </div>
                </div>
              </TabPanel>
            </div>
            <ProtocolFooter protocol={pivot.protocol} />
          </div>
        </div>
      </DashboardBox>

      {isMobile() && (
        <div
          onClick={() => {
            showHistoricConfigModal(pivot.id, props.farmID, ctx, pivot);
          }}
          style={{ display: modal.active ? "none" : "initial" }}
        >
          <ShowHistoricFloatingLabel className={classes.tabBar} />
        </div>
      )}
    </div>
  );
}

export default NewEditPivotV5;

import React, { useState, useEffect, useRef } from "react";
import DashboardBox from "../../../../components/DashboardBox/DashboardBox";
import PivotItem from "./components/PivotItem/PivotItem";
import "./DeviceBox.scss";
import { Pivot } from "../../../../../../redux/pivots/types";
import { TextField, IconButton, Tooltip } from "@material-ui/core";
import { ReactJSX } from "../../../../../../utils/types";
import { Irpd } from "../../../../../../redux/irpds/types";
import IrpdItem from "./components/IrpdItem/IrpdItem";
import {
  baseURL,
  getAuthorization,
} from "../../../../../../services/webclient";
import { useParams } from "react-router";
import useSocketIO from "../../../../../../hooks/tools/useSocketIO";
import { isMobile } from "../../../../../../mobileConfig";
import { AddCircle } from "@material-ui/icons";
import { showModal } from "../../../../../../redux/modal/actions";
import { useDispatch } from "react-redux";
import CreateDeviceModal from "../../../CreateEquipment/CreateDeviceModal";
import SignalWifi3BarIcon from "@material-ui/icons/SignalWifi3Bar";
import SignalWifiOffIcon from "@material-ui/icons/SignalWifiOff";
import Store from "../../../../../../redux/index";
import {
  PivotLight,
  AtomFamilySetterHelper,
  PivotPainelStreamFamily,
  PivotGPSStreamFamily,
  PivotConfigFamily,
  FallbackPivot,
} from "../../../../../../recoils/PivotRecoil";

import { useSetRecoilState } from "recoil";
import { FarmMapHandle } from "../FarmMap/FarmMap";
import MeterSystemItem from "./components/MeterItem/MeterSystemItem";
import { MeterSystem } from "../../../../../../recoils/MeterSystemRecoil";
import DesktopZone from "../../../../../../components/Zones/DesktopZone";
import useCentralIsOnline from "../../../../../../hooks/tools/useCentralIsonline";
import { i18n } from "../../../../../../i18n/i18nText";
import { Repeater } from "../../../../../../recoils/RepeaterRecoil";
import RepeaterItem from "./components/RepeaterItem/RepeaterItem";
import { FarmBauerPivotObj } from "../../../../../../redux/bauerPivot/types";
import BauerPivotItem from "./components/BauerPivotItem/BauerPivotItem";
import { Farm } from "../../../../../../redux/farms/types";

const { SOCKET_SUFFIX } = process.env;

interface Props {
  loading: boolean;
  rightElement?: ReactJSX;
  pivots: Pivot[] | PivotLight[];
  bauerPivots?: FarmBauerPivotObj[];
  irpds?: Irpd[];
  repeaters?: Repeater[];
  metersystems?: MeterSystem[];
  farmMapRef?: React.MutableRefObject<FarmMapHandle>;
  centerElement?: ReactJSX;
  leftElement?: ReactJSX;
  farm: Farm;
}

function DeviceBox(props: Props) {
  const farmID: number = parseInt(useParams().farm, 10);

  const [searchInput, setSearchInput] = useState<string>("");
  const [sweepStatus, setSweepStatus] = useState<any>([]);

  const isAdministrator = Store().store.getState().farm.data?.is_administrator;

  const dispatch = useDispatch();

  const [isOnline] = useCentralIsOnline(
    farmID,
    "GENERAL_BOX_PIVOT_IS_ONLINE_FAILURE"
  );

  const [filter, setFilter] = useState("none");

  const [isConnected, socket] = useSocketIO();

  const currentPageRef = useRef(1);

  /**
   * Recoil, utilizado para preencher dinamicamente os pivots.
   *
   * O server é síncrono, então caminhamos de um em um ao invés de mandar 6
   * de uma vez. É mais leve para o server depois ficar com uma request pendente
   * em caso de navegação do que 6.
   */

  const atomFamilySetter = useSetRecoilState(AtomFamilySetterHelper);

  function setNewLatestPanelStream(pivotId: number, data: any) {
    atomFamilySetter({
      PivotFamilyAtom: PivotPainelStreamFamily,
      pivotId,
      value: data,
    });
  }
  function setNewLatestGPSStream(pivotId: number, data: any) {
    atomFamilySetter({
      PivotFamilyAtom: PivotGPSStreamFamily,
      pivotId,
      value: data,
    });
  }
  function setNewConfig(pivotId: number, data: any) {
    atomFamilySetter({
      PivotFamilyAtom: PivotConfigFamily,
      pivotId,
      value: data,
    });
  }
  function setFallbackPivot(pivotId: number, data: any) {
    atomFamilySetter({
      PivotFamilyAtom: FallbackPivot,
      pivotId,
      value: data,
    });
  }

  function rotatePoint(point, origin, angle, clockwise = false) {
    if (clockwise) angle = Math.abs(360 - angle);
    const angleRad = (angle * Math.PI) / 180.0;

    return {
      lat:
        Math.cos(angleRad) * (point.lat - origin.lat) -
        Math.sin(angleRad) * (point.lng - origin.lng) +
        origin.lat,
      lng:
        Math.sin(angleRad) * (point.lat - origin.lat) +
        Math.cos(angleRad) * (point.lng - origin.lng) +
        origin.lng,
    };
  }

  function addReferenceToPivotImage(pivot: Pivot): Pivot {
    let marker;
    if (pivot.protocol === 5) {
      if (pivot.automation_type == 0 || pivot.automation_type == 1) {
        const or = {
          lat: parseFloat(
            pivot.controllerconfig.content.pivot_positions.latitude_center
          ),
          lng: parseFloat(
            pivot.controllerconfig.content.pivot_positions.longitude_center
          ),
        };
        const po = {
          lat: parseFloat(
            pivot.controllerconfig.content.pivot_positions.latitude_reference
          ),
          lng: parseFloat(
            pivot.controllerconfig.content.pivot_positions.longitude_reference
          ),
        };
        const angle =
          pivot.controllerstream_gps?.content?.current_angle?.current_angle !==
          undefined
            ? parseInt(
                pivot.controllerstream_gps?.content?.current_angle
                  ?.current_angle
              )
            : -1;
        if (angle < 0) {
          marker = "";
        } else {
          const l = rotatePoint(po, or, angle);
          marker = `&path=color:0xc3fc49ff|weight:5|${or.lat}, ${or.lng}|${l.lat}, ${l.lng}`;
        }
        return { ...pivot, image: pivot.image + marker };
      } else if (pivot.automation_type == 2) {
        const cp = {
          lat: parseFloat(
            pivot.controllerstream_gps.content.latitude_longitude_gps
              .latitude_gps
          ),
          lng: parseFloat(
            pivot.controllerstream_gps.content.latitude_longitude_gps
              .longitude_gps
          ),
        };

        marker = `&center=${cp.lat},${cp.lng}&markers=color:0xc3fc49ff|${cp.lat},${cp.lng}`;

        return {
          ...pivot,
          image:
            pivot.image.substring(0, pivot.image.indexOf("&center=")) + marker,
        };
      }
    }
    const point: string = pivot.config.reference;
    const origin: string = pivot.config.center;
    const po = {
      lat: parseFloat(point.split(",")[0]),
      lng: parseFloat(point.split(",")[1]),
    };
    const or = {
      lat: parseFloat(origin.split(",")[0]),
      lng: parseFloat(origin.split(",")[1]),
    };
    const angle = pivot.latest_gps_stream
      ? parseInt(pivot.latest_gps_stream.angle)
      : -1;
    if (angle < 0) {
      marker = "";
    } else {
      const l = rotatePoint(po, or, pivot.latest_gps_stream.angle);
      marker = `&path=color:0xc3fc49ff|weight:5|${or.lat}, ${or.lng}|${l.lat}, ${l.lng}`;
    }
    return { ...pivot, image: pivot.image + marker };
  }

  useEffect(() => {
    (async () => {
      if (props.farmMapRef?.current?.drawPivotsOnMap) {
        props.farmMapRef?.current?.drawPivotsOnMap();
      }

      const PAGES = Math.ceil(props.pivots.length / 10);
      for (let i = 1; i <= PAGES; i += 1) {
        try {
          // Impedindo que ele repita a requisição da mesma página 2x, evitando que o mapa redesenhe (desenhe 2x)
          if (currentPageRef.current > i) {
            return;
          }
          currentPageRef.current = i + 1;
          const response = await fetch(
            `${baseURL.core}/v3/farms/${farmID}/pivots/paginated/?page=${i}`,
            {
              method: "GET",
              headers: {
                Authorization: `Bearer ${getAuthorization()}`,
              },
            }
          );

          const responseObject: { results: Pivot[] } = await response.json();

          responseObject.results.forEach((pivot) => {
            if (pivot.protocol === 5) {
              setNewLatestPanelStream(pivot.id, pivot.controllerstream_panel);
              setNewLatestGPSStream(pivot.id, pivot.controllerstream_gps);
              setNewConfig(pivot.id, pivot.controllerconfig);
              setFallbackPivot(pivot.id, addReferenceToPivotImage(pivot));
            } else {
              setNewLatestPanelStream(pivot.id, pivot.latest_panel_stream);
              setNewLatestGPSStream(pivot.id, pivot.latest_gps_stream);
              setNewConfig(pivot.id, pivot.config);
              setFallbackPivot(pivot.id, addReferenceToPivotImage(pivot));
            }
          });
        } catch (err) {}
      }

      if (props.farmMapRef?.current?.drawPivotsOnMap) {
        props.farmMapRef?.current?.drawPivotsOnMap();
      }
    })();
  }, [props.pivots]);

  useEffect(() => {
    socket.subscribe(`${SOCKET_SUFFIX}farm@${farmID}`);

    socket.bind("device_sweep", (data) => {
      if (data.type === "pivot") {
        setSweepStatus({ ...sweepStatus, [`pivot-${data.id}`]: 2 });
      }
      if (data.type === "irpd") {
        setSweepStatus({ ...sweepStatus, [`irpd-${data.id}`]: 2 });
      }
    });

    return () => {
      socket.unsubscribe(`d@farm@${farmID}`);
    };
  }, []);

  const handleFilter = () => {
    if (!props.pivots) return <></>;
    if (filter === "none") {
      // Typescript me travando SÓ no map, coloquei um any, perdão
      return (props.pivots as any).map((pivot: Pivot | PivotLight) => (
        <PivotItem
          pivot={pivot}
          sweepStatus={sweepStatus[`pivot-${pivot.id}`]}
        />
      ));
    }
    if (filter === "name") {
      return (props.pivots as any)
        .slice()
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((pivot: Pivot | PivotLight) => (
          <PivotItem
            pivot={pivot}
            sweepStatus={sweepStatus[`pivot-${pivot.id}`]}
          />
        ));
    }
  };

  function createFirstDevice() {
    dispatch(
      showModal({
        content: <CreateDeviceModal farmID={farmID} />,
        title: "CREATE_DEVICE_MODAL_TITLE",
      })
    );
  }

  const searchField = (
    <TextField
      id="pivot-search-input"
      // error={validationErrors["name"]}
      label={<>{i18n("FARM_FIELD_SEARCH_BAR")}</>}
      value={searchInput}
      onChange={(value) => setSearchInput(value.target.value)}
      style={{ marginBottom: 10 }}
    />
  );

  const SearchIcon = (
    // <IconButton color="primary">
    //   <Search />
    // </IconButton>
    <div />
  );

  const isOnlineIndicator = (
    <IconButton color="primary">
      {isOnline ? (
        <Tooltip title={i18n("SELECTED_FARM_CENTRAL_ONLINE")}>
          <SignalWifi3BarIcon style={{ color: "#037ade" }} />
        </Tooltip>
      ) : (
        <Tooltip title={i18n("SELECTED_FARM_CENTRAL_OFFLINE")}>
          <SignalWifiOffIcon style={{ color: "gray", opacity: "0.6" }} />
        </Tooltip>
      )}
    </IconButton>
  );

  useEffect(() => {
    setSearchInput("");
  }, [props.loading]);

  if (!props.loading) {
    return (
      // Esse padding é reponspavel por não deixar o último item da DeviceBox ficar atrás do componente de Tabs
      <div style={isMobile() ? { paddingBottom: "60px" } : null}>
        <DashboardBox
          leftElement={isMobile() ? SearchIcon : props.leftElement}
          majorCenterElement={
            isMobile() ? searchField : <h2>{props.centerElement}</h2>
          }
          rightElement={
            isMobile() && !!props.farm.base
              ? isOnlineIndicator
              : props.rightElement
          }
        >
          <DesktopZone>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "2fr 10fr 2fr",
                borderBottom: "1px solid rgba(0,0,0,0.1)",
              }}
            >
              {SearchIcon}
              {searchField}
              {props.farm.base && isOnlineIndicator}
            </div>
          </DesktopZone>
          <div className="device-box__container">
            {isAdministrator && (
              <div className="create-equipment-box">
                <div className="small-button" onClick={createFirstDevice}>
                  <AddCircle />
                  {i18n("CREATE_DEVICE_MODAL_TITLE")}
                </div>
              </div>
            )}
            <div className="device-box__label">
              <>{i18n("SELECTED_FARM_PIVOTS")}</>:
            </div>
            {!props.pivots ||
              (props.pivots.length === 0 && props.bauerPivots.length == 0 && (
                <div className="device-box__no-pivots">
                  {i18n("SELECTED_FARM_NO_PIVOTS")}
                </div>
              ))}
            {searchInput === ""
              ? handleFilter()
              : (props.pivots as any)
                  .filter((pivot) => {
                    return pivot.name
                      .toLowerCase()
                      .includes(searchInput.toLowerCase());
                  })
                  .map((pivot) => <PivotItem key={pivot.id} pivot={pivot} />)}
            {searchInput === ""
              ? props.bauerPivots.map((bauerPivot) => (
                  <BauerPivotItem key={bauerPivot.id} bauerPivot={bauerPivot} />
                ))
              : props.bauerPivots
                  .filter((pivot) => {
                    return pivot.name
                      .toLowerCase()
                      .includes(searchInput.toLowerCase());
                  })
                  .map((bauerPivot) => (
                    <BauerPivotItem
                      key={bauerPivot.id}
                      bauerPivot={bauerPivot}
                    />
                  ))}
            {props.irpds.length === 0 ? (
              <></>
            ) : (
              <div className="device-box__label">
                <>{i18n("SELECTED_FARM_IRPDS")}</>:
              </div>
            )}
            {searchInput === ""
              ? props.irpds.map((irpd) => (
                  <IrpdItem
                    key={irpd.id}
                    irpd={irpd}
                    sweepStatus={sweepStatus[`irpd-${irpd.id}`]}
                  />
                ))
              : props.irpds
                  .filter((irpd) =>
                    irpd.name.toLowerCase().includes(searchInput.toLowerCase())
                  )
                  .map((irpd) => <IrpdItem key={irpd.id} irpd={irpd} />)}
            {props.metersystems?.length === 0 || !props.metersystems ? (
              <></>
            ) : (
              <>
                <div className="device-box__label">{i18n("METER_SYSTEMS")}</div>
                {searchInput === ""
                  ? props.metersystems.map((meter) => (
                      <MeterSystemItem
                        key={meter.id}
                        meterSystemId={meter.id}
                        meter={meter}
                      />
                    ))
                  : props.metersystems
                      .filter((meter) => {
                        return meter.name
                          .toLowerCase()
                          .includes(searchInput.toLowerCase());
                      })
                      .map((meter) => (
                        <MeterSystemItem
                          key={meter.id}
                          meterSystemId={meter.id}
                          meter={meter}
                        />
                      ))}
              </>
            )}
            {props.repeaters.length === 0 ? (
              <></>
            ) : (
              <div className="device-box__label">
                <>{i18n("EDIT_FARM_REPEATERS")}</>:
              </div>
            )}
            {searchInput === ""
              ? props.repeaters.map((repeater) => (
                  <RepeaterItem key={repeater.id} repeater={repeater} />
                ))
              : props.repeaters
                  .filter((repeater) =>
                    repeater.name
                      .toLowerCase()
                      .includes(searchInput.toLowerCase())
                  )
                  .map((repeater) => (
                    <RepeaterItem key={repeater.id} repeater={repeater} />
                  ))}
          </div>
        </DashboardBox>
      </div>
    );
  }

  return (
    <DashboardBox
      leftElement={SearchIcon}
      twoThirdsElement={
        <TextField
          id="pivot-search-input"
          // error={validationErrors["name"]}
          label={<>{i18n("FARM_FIELD_SEARCH_BAR")}</>}
          value={searchInput}
          onChange={(value) => setSearchInput(value.target.value)}
        />
      }
      rightElement={props.rightElement}
    >
      <></>
    </DashboardBox>
  );
}

export default DeviceBox;

import { i18nTextId } from "./i18nText";

const i18n_en = {
  PAGE_REGISTER_TITLE: "Register",
  PAGE_REGISTER_LABEL_LANGUAGE: "Language",
  PAGE_REGISTER_LABEL_EMAIL: "Email",
  PAGE_REGISTER_LABEL_USERNAME: "User",
  PAGE_REGISTER_LABEL_PASSWORD: "Password",
  PAGE_REGISTER_LABEL_CONFIRM_PASSWORD: "Confirm Password",
  PAGE_REGISTER_LABEL_FIRST_NAME: "Name",
  PAGE_REGISTER_LABEL_LAST_NAME: "Last Name",
  PAGE_REGISTER_SUBMIT_BUTTON_TEXT: "Register",
  PAGE_REGISTER_USERNAME_IN_USE: "Username unavailable",
  PAGE_REGISTER_TOKEN_ERROR: "Token has expired or is invalid",
  PAGE_REGISTER_PASSWORD_UNMATCH: "Password and confirm password do not match",
  VALIDATION_FIELD_REQUIRED: "Required field",
  VALIDATION_FIELD_INVALID: "Invalid field",
  VALIDATION_FIELD_MIN: "Minimum ${} digits",
  VALIDATION_FIELD_MAX: "Maximum ${} digits",
  VALIDATION_STRONGER_PASSWORD: "Not strong enough",
  SELECTED_FARM_MAP_LABEL: "Map",
  SELECTED_FARM_EDIT_BUTTON_TEXT: "Edit",
  SELECTED_FARM_FILTER_BUTTON_TEXT: "Filter",
  SELECTED_FARM_FILTER_NAME: "Name",
  SELECTED_FARM_FILTER_PROBLEM: "Problem",
  SELECTED_FARM_PIVOTS: "Pivots",
  SELECTED_FARM_IRPDS: "Pumps",
  SELECTED_FARM_SWEEP_BUTTON_TEXT: "Sweep",
  SELECTED_FARM_SWEEP_EXECUTING_TEXT: "Executing sweep...",
  SELECTED_FARM_SWEEP_END_TEXT: "Sweep finished!",
  SELECTED_FARM_SWEEP_GENERIC_ERROR: "Device sweep failed",
  DEVICE_DRAWER_TITLE: "Devices",
  DEVICE_BOX_VERY_STRONG: "Very Strong",
  DEVICE_BOX_STRONG: "Strong",
  DEVICE_BOX_MODERATE: "Moderate",
  DEVICE_BOX_WEAK: "Weak",
  DEVICE_BOX_ERROR: "Error",
  DEVICE_NO_LOGS: "Execute sweep to see log entries.",
  EDIT_FARM_LABEL: "Edit Farm",
  EDIT_FARM_GENERAL_INFORMATION: "General Information",
  EDIT_FARM_GENERAL_CONTACT: "Contact",
  EDIT_FARM_ENERGY_RANGES: "Power Ranges",
  EDIT_FARM_ENERGY_RANGES_GENERATED: "Auto generated remaining time",
  EDIT_FARM_ENERGY_RANGES_ADD_BUTTON: "Add",
  EDIT_FARM_ENERGY_RANGES_REMOVE_BUTTON: "Remove",
  EDIT_FARM_USERS: "Users",
  EDIT_FARM_USER: "User",
  EDIT_FARM_VISITORS: "VISITORS",
  EDIT_FARM_OPERATORS: "Operators",
  EDIT_FARM_CODE: "Farm Code: ",
  EDIT_FARM_EMPLOYEES: "Users",
  EDIT_FARM_RESALE: "Resale",
  EDIT_FARM_APPROVE: "Approve",
  EDIT_FARM_PERMISSIONS: "Permissions ",
  EDIT_FARM_VIEW: "View ",
  EDIT_FARM_SEND_INVITE_AGAIN: "Send invite again",
  EDIT_FARM_RESEND_INVITATION_SUCCESS: "Invitation sent.",
  EDIT_FARM_RESEND_INVITATION_FAILED:
    "Failed to resend invitation. Please try again later.",
  EDIT_FARM_REMOVE: "Remove ",
  EDIT_FARM_REMOVE_INFO: "Do you want to remove the user ${}?",
  EDIT_FARM_REMOVE_REPEATER_INFO: "Do you want to remove the repeater ${}?",
  EDIT_FARM_BUTTON_CANCEL: "Cancel",
  EDIT_FARM_BUTTON_DETELE: "Delete",
  EDIT_FARM_TITLE_PERMISSIONS: "Edit permissions",
  EDIT_FARM_TAG_VIEW: "View",
  EDIT_FARM_TAG_OPERATE: "Operate",
  EDIT_FARM_BUTTON_SAVE: "Save",
  EDIT_FARM_GROUPS: "Groups",
  EDIT_FARM_GROUPS_INFO:
    "Set up pivot groups to get statistics and reports by group.",
  EDIT_FARM_BUTTON_CREATE_GROUP: "Create pivot group",
  EDIT_FARM_BUTTON_ADD_POWER_RANGE: "Add Power Ranges",
  SELECTED_FARM_DELETE_BUTTON_TEXT: "Delete",
  EDIT_FARM_ADMINISTRATOR: "Administrator",
  EDIT_FARM_TEMPORARY_ADMINISTRATOR: "Temporary administrator:",
  EDIT_FARM_REGISTER_USER:
    "Enter the name, username or email of the user to be invited.",
  EDIT_FARM_BUTTON_REGISTER_USER: "Register User",
  EDIT_FARM_BUTTON_EDIT: "Edit",
  EDIT_FARM_NAME_GROUP: "Group's name",
  EDIT_FARM_REPEATERS: "Repeaters",
  EDIT_FARM_BUTTON_REGISTER_REPEATERS: "Register repeaters",
  EDIT_FARM_USER_PENDING: "Pending",
  EDIT_FARM_GROUP_NAME: "Group's name",
  EDIT_FARM_EDIT_REPEATER: "Edit Repeater",
  EDIT_FARM_USER_DELETE_SUCCESS: "User removed",
  EDIT_FARM_USER_PERMISSION_SUCCESS: "Permissions saved sucessfully",
  EDIT_FARM_GROUP_SUCCESS: "Group created",
  EDIT_FARM_EDIT_GROUP_SUCCESS: "Group edited",
  EDIT_FARM_EDIT_GROUP_REMOVED: "Group removed",
  EDIT_FARM_GROUP_NAME_ERROR: "Group name not filled",
  EDIT_FARM_GROUP_PIVOT_ERROR: "Pivots not selected",
  EDIT_FARM_REPEATER_SUCCESS: "Registered repeater",
  EDIT_FARM_REPEATER_ERROR: "Error registering repeater",
  EDIT_FARM_REPEATER_DELETE_SUCCESS: "Repeater removed",
  EDIT_FARM_EDIT_REPEATER_SUCCESS: "Repeater edited",
  EDIT_FARM_REPEATER_NAME_ERROR: "Repeater name not filled",
  EDIT_FARM_TIMEZONE: "Timezone",
  EDIT_FARM_NO_REGISTERED_USERS: "You don't have any registered users.",
  EDIT_FARM_NO_REGISTERED_GROUPS: "You don't have any registered groups.",
  EDIT_FARM_NO_REGISTERED_REPEATERS: "You don't have any registered repeaters.",
  HISTORIC_SCHEDULE_IRRIGATION_INDEX: "Schedule ${}:",
  HISTORIC_SCHEDULE_IRRIGATION_MODE: "Mode: ",
  HISTORIC_SCHEDULE_IRRIGATION_PERCENT: "Percentage: ",
  HISTORIC_SCHEDULE_IRRIGATION_DIRECTION: "Direction: ",
  HISTORIC_SCHEDULE_IRRIGATION_START_DATE: "Start Date: ",
  HISTORIC_SCHEDULE_IRRIGATION_END_DATE: "End Date: ",
  HISTORIC_SCHEDULE_IRRIGATION_END_ROUNDS: "End after: ",
  HISTORIC_SCHEDULE_IRRIGATION_END_ANGLE: "End Angle: ",
  HISTORIC_SCHEDULE_IRRIGATION_END_SECTOR: "Will finish at the end of sector",
  HISTORIC_SCHEDULE_IRRIGATION_END_MODE: "End Mode: ",
  HISTORIC_SCHEDULE_IRRIGATION_START_END: "Start - End: ${} - ${}",
  FORGET_PASSWORD_OPTION: "Select an option",
  FORGET_PASSWORD_OPTION_USER: "I forgot my username or email",
  ORGET_PASSWORD_OPTION_PASSWORD: "I forgot my password",
  FORGET_PASSWORD_TYPE_EMAIL: "Type your email",
  FORGET_PASSWORD_INFO_USER:
    "Contact your farm administrator or your local dealer.",
  FORGET_PASSWORD_INFO_EMAIL:
    "An email was sent to the address:\n\n${}\n\nFollow the instructions in the email and recover your password.",
  FORGET_PASSWORD_RECOVEY: "Enter your new password.",
  FORGET_PASSWORD_NEW_PASSWORD: "New password",
  FORGET_PASSWORD_REPEAT_NEW_PASSWORD: "Repeate the new password",
  FORGET_PASSWORD_SAVE: "Save password",
  FORGET_PASSWORD_OK: "OK",
  FORGET_PASSWORD_MODAL_SUCCESS: "Password changed successfully!",
  CHANGE_PASSWORD_ERROR:
    "Error changing password, maybe this is not your current password",
  FORGET_PASSWORD_DIFFERENT:
    "New password and confirmation password are different",
  FORGET_PASSWORD_MODAL_EMAIL_FAILED:
    "There is no registration with this email",
  EDIT_PIVOT_LABEL: "Edit Pivot",
  EDIT_MONITOR_LABEL: "Edit Pivot Monitor",
  EDIT_PIVOT_LATITUDE: "Latitude",
  EDIT_PIVOT_LONGITUDE: "Longitude",
  EDIT_PIVOT_SAVE_BUTTON: "Save",
  EDIT_PIVOT_LOAD_BUTTON: "Load",
  EDIT_PIVOT_LOAD_SUCCESS: "Configuration loaded successfully!",
  EDIT_PIVOT_LOAD_ERROR: "Error loading configuration.",
  EDIT_PIVOT_LABEL_GENERAL: "General",
  EDIT_PIVOT_LABEL_LOCATION: "Location",
  EDIT_PIVOT_IMANAGE_RADIO: "iManage Radio",
  EDIT_PIVOT_SENSOR: "Sensors",
  EDIT_PIVOT_LABEL_CLOCK: "Clock",
  EDIT_PIVOT_LABEL_PUMP: "Pressure",
  EDIT_PIVOT_LABEL_PAUSE_TIME: "Pause Time",
  EDIT_PIVOT_LABEL_PLUVIOMETER: "Rain Gauge",
  EDIT_PIVOT_LABEL_END_GUN: "Endgun",
  EDIT_PIVOT_LABEL_GENERAL_SETTINGS: "General Settings",
  EDIT_PIVOT_LABEL_PAUSE_TIME_SETTINGS: "Pause Time Settings",
  EDIT_PIVOT_LABEL_SETTING_HISTORIC: "Configurations History",
  HISTORIC_SIMPLE_IRRIGATION_MODE: "Mode: ",
  HISTORIC_SIMPLE_IRRIGATION_DIRECTION: "Direction: ",
  HISTORIC_SIMPLE_IRRIGATION_PERCENT: "Precipitation: ",
  HISTORIC_SIMPLE_IRRIGATION_START_MODE: "Start Mode: ${}",
  HISTORIC_SIMPLE_IRRIGATION_STOP_MODE: "Stop Mode: ",
  HISTORIC_SIMPLE_IRRIGATION_STOP_ANGLE: "Stop Angle: ",
  HISTORIC_SIMPLE_IRRIGATION_START_DATE: "Start Date: ",
  HISTORIC_SIMPLE_IRRIGATION_STOP_ROUNDS: "Stop Rounds: ",
  HISTORIC_SIMPLE_IRRIGATION_DONT_STOP: "Don't stop",
  HISTORIC_SIMPLE_IRRIGATION_STOP_BY_ANGLE: "Stop by angle",
  HISTORIC_SIMPLE_IRRIGATION_STOP_BY_SECTOR: "Stop by sector",
  HISTORIC_SIMPLE_IRRIGATION_STOP_BY_ROUNDS: "Stop by rounds",
  HISTORIC_SIMPLE_IRRIGATION_INJECTION_PUMP_ON: "On",
  HISTORIC_SIMPLE_IRRIGATION_INJECTION_PUMP_OFF: "Off",
  GPS_STREAM_TABLE_START: "Created at",
  GPS_STREAM_TABLE_ANGLE: "Angle",
  GPS_STREAM_TABLE_POSITION: "Position",
  GPS_STREAM_TABLE_DIRECTION: "Direction",
  GPS_STREAM_TABLE_SPEED: "Precipitation",
  GPS_STREAM_TABLE_STATUS: "Status",
  GPS_STREAM_TABLE_WATER_PRESSURE: "Pressure - Last Tower",
  GPS_STREAM_TABLE_CENTER_PRESSURE: "Pressure - Central Tower",
  GPS_STREAM_TABLE_BATERY: "Battery",
  GPS_STREAM_TABLE_HOURMETER: "Hourmeter",
  GPS_STREAM_TABLE_TITLE: "GPS Information List",
  USER_HAS_NO_FARM: "You don't have access to any farms.",
  SELECTED_IRPD_START: "Start Pump",
  SELECTED_IRPD_STOP: "Stop Pump",
  LANGUAGE_SELECTOR_ENGLISH: "ENGLISH (US)",
  LANGUAGE_SELECTOR_SPANISH: "ESPAÑOL (ES)",
  LANGUAGE_SELECTOR_PORTUGUESE_BRAZIL: "PORTUGUÊS (BR)",
  LANGUAGE_SELECTOR_RUSSIAN: "РУССКИЙ (RU)",
  LANGUAGE_SELECTOR_GERMAN: "DEUTSCH (AT)",
  PIVOT_CONFIG_V5_FIELD_GP_CENTER: "Center",
  PIVOT_CONFIG_V5_FIELD_GP_REFERENCE: "Start Reference",
  PIVOT_CONFIG_V5_FIELD_GP_END_REFERENCE: "End Reference",
  PIVOT_CONFIG_V5_FIELD_RADIUS: "Radius",
  PIVOT_CONFIG_V5_FIELD_FLOW: "Flow Rate",
  PIVOT_CONFIG_V5_FIELD_SPEED: "End Tower Speed",
  PIVOT_CONFIG_V5_FIELD_VOLTAGE_STABLE_TIME: "Stable Voltage Stable",
  PIVOT_CONFIG_V5_FIELD_VOLTAGE_REF: "Reference Voltage",
  PIVOT_CONFIG_V5_FIELD_CLOCK: "Set device clock manually",
  PIVOT_CONFIG_V5_FIELD_AUTO_SET_CLOCK: "Auto Set device clock",
  PIVOT_CONFIG_V5_FIELD_PUMP_SOFT_START_TIME: "Soft Start Time",
  PIVOT_CONFIG_V5_FIELD_PUMP_READ_PRESSURE_OFF: "Off",
  PIVOT_CONFIG_V5_FIELD_PUMP_READ_PRESSURE_SWITCH: "By pressure switch",
  PIVOT_CONFIG_V5_FIELD_PUMP_READ_PRESSURE_SENSOR: "By pressure sensor",
  PIVOT_CONFIG_V5_FIELD_PUMP_PRESS_SWITCH_MAX_RANGE:
    "Pressure sensor maximum range",
  PIVOT_CONFIG_V5_FIELD_PUMP_PRESS_SWITCH_FACTOR: "Pressure Sensor Factor",
  PIVOT_CONFIG_V5_FIELD_PERIODIC_STREAM_TIMER:
    "Periodic Stream Timer (seconds)",
  PIVOT_CONFIG_V5_FIELD_ANGLE_RANGE: "Angle Range",
  PIVOT_CONFIG_V5_PIVOT_SEGMENTS: "Segments",
  PIVOT_CONFIG_V5_FIELD_SEGMENTS_MODE_FORWARD: "Forward Mode",
  PIVOT_CONFIG_V5_FIELD_SEGMENTS_MODE_REVERSE: "Reverse Mode",
  PIVOT_CONFIG_V5_FIELD_SEGMENTS_PERCENT_FORWARD: "% Forward",
  PIVOT_CONFIG_V5_FIELD_SEGMENTS_PERCENT_REVERSE: "% Reverse",
  PIVOT_CONFIG_V5_FIELD_PILOT_AVERAGE_POWER: "Pivot Average Power",
  PIVOT_CONFIG_V5_FIELD_CANAL_AVERAGE_POWER: "Well Average Power",
  PIVOT_CONFIG_V5_FIELD_SEGMENT: "Segment",
  PIVOT_CONFIG_V5_VALUE_LANGUAGE_ENGLISH: "English",
  PIVOT_CONFIG_V5_VALUE_LANGUAGE_PORTUGUESE: "Portuguese",
  PIVOT_CONFIG_V5_VALUE_LANGUAGE_GERMAN: "German",
  PIVOT_CONFIG_V5_VALUE_LANGUAGE_SPANISH: "Spanish",
  PIVOT_CONFIG_V5_VALUE_LANGUAGE_RUSSIAN: "Russian",
  PIVOT_CONFIG_V5_VALUE_SEGMENTS_MODE_NONE: "None",
  PIVOT_CONFIG_V5_VALUE_SEGMENTS_MODE_WET: "Wet",
  PIVOT_CONFIG_V5_VALUE_SEGMENTS_MODE_DRY: "Dry",
  PIVOT_CONFIG_V5_TITLE_ANGLE: "Angle",
  PIVOT_CONFIG_V5_FORWARD: "Forward",
  PIVOT_CONFIG_V5_REVERSE: "Reverse",
  PIVOT_CONFIG_V5_START: "Start",
  PIVOT_CONFIG_V5_END: "End",
  NOTIFICATIONS: "Notifications",
  NO_NOTIFICATION:
    "You don't have any notifications.\nClick on the button below to create one.",
  ALERT_ANDROID_ONLY_NOTIFY: "This option is only available for Android.",
  ALERT_ITEM_TITLE: "${} Pivot(s)",
  ALERT_ITEM_DO_ENABLE: "Enable",
  ALERT_ITEM_DO_DISABLE: "Disable",
  ALERT_ITEM_ENABLED: "Enabled",
  ALERT_ITEM_DISABLED: "Disabled",
  ALERT_ITEM_EDIT: "Edit",
  ALERT_ITEM_DELETE: "Delete",
  ALERT_BOX_TIP: "**Tip: double tap on the card to edit/delete it",
  SELECTED_ALERT_TIME_RANGE: "Time Range",
  SELECTED_ALERT_TIME_BETWEEN: "Between ${} and ${}",
  SELECTED_ALERT_SELECT_PIVOTS: "Select Pivots",
  SELECTED_ALERT_SELECT_REASONS: "Select Reasons",
  SELECTED_ALERT_REASONS: "Reasons",
  EDIT_ALERT_ERROR_MSG: "Error. Check if all fields are filled.",
  NEW_ALERT_CREATE_NOTIFICATION: "Create Notification",
  NEW_ALERT_EDIT_SELECTED: "${} selected",
  NEW_ALERT_EDIT_NOTIFICATION: "Edit Notification",
  NOTIFICATION: "Notification",
  ALARM: "Alarm",
  BASIC_INFO_BOX_COMMING_SOON: "Comming soon",
  BASIC_INFO_BOX_UNEXPECTED_STOPS: "Unexpected Stops",
  BASIC_INFO_BOX_LACK_OF_PRESSURE: "Low pressure",
  BASIC_INFO_BOX_POWER_OUTAGE: "Power fault",
  BASIC_INFO_BOX_MISALIGNMENT: "Safety",
  BASIC_INFO_BOX_POWER_SURGE: "Power surge",
  BASIC_INFO_BOX_ACTIONS_TITLE: "Actions",
  BASIC_INFO_BOX_ACTIONS_BY_WEB: "By Web",
  BASIC_INFO_BOX_ACTIONS_BY_PANEL: "By Panel",
  BASIC_INFO_BOX_NON_EXECUTED_ACTIONS: "Non-executed",
  BASIC_INFO_BOX_HOURS: "Hours",
  BASIC_INFO_BOX_WET: "Wet",
  BASIC_INFO_BOX_DRY: "Dry",
  BASIC_INFO_BOX_TOTAL: "Total",
  BASIC_INFO_BOX_WET_ENERGY_CONSUMPTION: "Wet Mode Energy Consumption",
  BASIC_INFO_BOX_TYPE: "Type",
  BASIC_INFO_BOX_KWH_PRICE: "kW/h Price",
  BASIC_INFO_BOX_WATER_CONSUMPTION: "Consumption",
  BASIC_INFO_BOX_PEAK_HOUR: "Pause Time",
  BASIC_INFO_BOX_OUT_OF_PEAK_HOUR: "Out of Pause Time",
  BASIC_INFO_BOX_AT_NIGHTTIME: "At Nighttime",
  BASIC_INFO_BOX_AVERAGE_WATER_BLADE: "Average Water Precipitation",
  BASIC_INFO_BOX_REPORT_GENERATED_BETWEEN:
    "Report generated between ${} and ${}",
  BASIC_INFO_BOX_WATER_BLADE_RADAR: "Precipitation Chart",
  BASIC_INFO_BOX_WORK_HOURS: "Wet Work Hours",
  BASIC_INFO_BOX_TOTAL_COST: "Total Cost",
  BASIC_INFO_BOX_PONTA: "Peak",
  BASIC_INFO_BOX_FORA_DE_PONTA: "Out of Peak",
  BASIC_INFO_BOX_REDUZIDO: "Nighttime",
  BASIC_INFO_BOX_FLOW: "Flow:",
  BASIC_INFO_BOX_TOTAL_VALUE: "Total: $",
  BASIC_INFO_BOX_TIME_RANGES: "Time Ranges",
  BASIC_INFO_BOX_WATER_BLADE_MM: "Water Blade (mm)",
  BASIC_INFO_BOX_TOTAL_FEES: "Total:",
  BASIC_INFO_BOX_TOTAL_VOLUME: "Total volume:",
  BASIC_INFO_BOX_TENSION_TITLE: "Pivot Voltages (V)",
  BASIC_INFO_BOX_LATEST_PRESSURE_BY_ANGLE_TITLE: "Pressure Comparative (bar)",
  BASIC_INFO_BOX_TENSION_INFO:
    "Voltages greater than 700V and lower than 300V were discarded.",
  BASIC_INFO_BOX_TENSION_INFO_MIN: "Min",
  BASIC_INFO_BOX_TENSION_INFO_AVERAGE: "Avg",
  BASIC_INFO_BOX_TENSION_INFO_MAX: "Max",
  BASIC_INFO_BOX_TENSION: "Voltages (V)",
  BASIC_INFO_BOX_TENSION_DATES: "Date",
  BASIC_INFO_BOX_PRESSURE: "Pressure (bar)",
  BASIC_INFO_BOX_LATEST_PRESSURE: "Pressure",
  BASIC_INFO_BOX_CURRENT_PRESSURE: "Comparison Pressure",
  BASIC_INFO_BOX_CHART: "Charts",
  BASIC_INFO_BOX_TABLE: "Tables",
  BASIC_INFO_BOX_NOT_AVAILABLE:
    "Not available for pivots with protocol before 4.1.",
  BASIC_INFO_BOX_ENABLE_OPERATIONS_SUMMARY:
    "To see this data, go to the Edit Farm page and then click on the “enable“ button on the “Pivot Reports“ tab.",
  BASIC_INFO_BOX_ENABLE_IRPD_INFOS:
    "To see this data, go to the Edit Farm page and click on the “enable“ button on the “Pump Reports“ tab.",
  BASIC_INFO_BOX_OPERATION_SUMMARY_CALCULATED:
    "The Operations Summary is being calculated for the data that already exists in the system. This can take a long time.",
  BASIC_INFO_BOX_GO_TO_EDIT_FARM: "Go to Edit Farm",
  BASIC_INFO_BOX_NOTIFY_DESKTOP_ONLY:
    "Functionality available only in the desktop version.",
  SCHEDULE_IRRIGATION_FIELD_DIRECTION: "Direction",
  SCHEDULE_IRRIGATION_FIELD_MODE: "Operation Mode",
  SCHEDULE_IRRIGATION_FIELD_PERCENT: "Percentimeter",
  SCHEDULE_IRRIGATION_FIELD_PRECIP: "Precipitation",
  SCHEDULE_IRRIGATION_VALUE_FORWARD: "Forward",
  SCHEDULE_IRRIGATION_VALUE_REVERSE: "Reverse",
  SCHEDULE_IRRIGATION_VALUE_DRY: "Dry",
  SCHEDULE_IRRIGATION_VALUE_WET: "Wet",
  SCHEDULE_IRRIGATION_BUTTON_TURN_ON: "Turn ON",
  SCHEDULE_IRRIGATION_BUTTON_ADD: "Add",
  SCHEDULE_IRRIGATION_BUTTON_REMOVE: "Remove",
  SCHEDULE_IRRIGATION_DATE_START_LABEL: "Stard Date",
  SCHEDULE_IRRIGATION_START_DATE_INVALID:
    "You can't create a start date between dates already set",
  SCHEDULE_IRRIGATION_END_DATE_INVALID:
    "You can't create an end date between dates already set",
  GEOLOCATION_GETTER_GET_POSITION: "Get the position you want:",
  GEOLOCATION_GETTER_LATITUDE: "Latitude:",
  GEOLOCATION_GETTER_LONGITUDE: "Longitude:",
  GEOLOCATION_GETTER_GET: "GET POSITION",
  GEOLOCATION_GETTER_WAIT: "Wait a little bit to get a better GPS accuracy.",
  GEOLOCATION_GETTER_ACCURACY_LABEL: "Accuracy:",
  GEOLOCATION_GETTER_ACCURACY_VALUE: "${} meters",
  COUNTRY_SELECTOR_DDI: "Choose a country ",
  CREATE_USER_MODAL_TITLE: "Register User",
  CREATE_USER_MODAL_ADMIN: "Administrator",
  CREATE_USER_MODAL_EMAIL: "Email",
  CREATE_USER_MODAL_BUTTON_SAVE: "Save",
  CREATE_USER_MODAL_SUCCESS: "The invitation was sent.",
  CREATE_USER_MODAL_ERROR:
    "An error occurred while sending the invitation. Please try again.",
  CREATE_USER_MODAL_ALREADY_REGISTERED:
    "User is already registered on the farm.",
  CREATE_USER_MODAL_INVALID_EMAIL: "Invalid email.",
  PIVOT_CONFIG_FIELD_CENTER: "Center",
  PIVOT_CONFIG_FIELD_REFERENCE_START: "Start Reference",
  PIVOT_CONFIG_FIELD_REFERENCE_END: "End Reference",
  PIVOT_CONFIG_FIELD_RADIUS: "Radius to the last tower",
  PIVOT_CONFIG_FIELD_TOTAL_RADIUS: "Total Irrigated Radius",
  PIVOT_CONFIG_FIELD_FLOW: "Flow Rate",
  PIVOT_CONFIG_FIELD_PLUVIOMETER_STOP_MODE: "Stop mode",
  PIVOT_CONFIG_FIELD_PLUVIOMETER_MM_TO_STOP: "Stop at (mm)",
  PIVOT_CONFIG_VALUE_ENDGUN_WITHOUT: "Off",
  PIVOT_CONFIG_VALUE_ENDGUN_ON: "On",
  PIVOT_CONFIG_FIELD_DATE_TIME: "Date and time",
  PIVOT_CONFIG_VALUE_PLUVIOMETER_STOP: "Stop by mm",
  PIVOT_CONFIG_VALUE_PLUVIOMETER_DECREASE: "Precipitation decrement",
  SELECTED_PIVOT_SEGMENT_MODAL_LEFT_TITLE: "Selected segment settings",
  SELECTED_PIVOT_SEGMENT_TITLE: "Selected segment",
  CLICK_ON_SEGMENT: "Click on the segment to edit the information",
  SELECTED_PIVOT_SEGMENT_MODAL_RIGHT_TITLE: "Segment irrigation parameters",
  SELECTED_PIVOT_SEGMENT_MODAL_TITLE: "Send Segment Irrigation",
  SELECTED_PIVOT_SEGMENT_MODAL_SUCCESS: "Segment Irrigation sent successfully",
  SELECTED_PIVOT_SEGMENT_MODAL_FAILURE: "Failed to send Segment Irrigation",
  SEGMENT_IRRIGATION_FIELD_START_MODE: "Start Mode",
  SEGMENT_IRRIGATION_SHOW_CONFIGURED_SEGMENTS: "Show Configured Segments",
  SEGMENT_IRRIGATION_FIELD_END_MODE: "End Mode",
  SEGMENT_IRRIGATION_FIELD_START_DATE: "Start Date",
  SEGMENT_IRRIGATION_FIELD_END_DATE: "End Date",
  SEGMENT_IRRIGATION_FIELD_END_ANGLE: "End Angle",
  SEGMENT_IRRIGATION_FIELD_END_ROUNDS: "Rounds",
  SEGMENT_IRRIGATION_MODE_NOW: "Now",
  SEGMENT_IRRIGATION_ON_DATE: "On a Date",
  SEGMENT_IRRIGATION_NEVER_ENDS: "Don't Stop",
  SEGMENT_IRRIGATION_BY_ANGLE: "By Angle",
  SEGMENT_IRRIGATION_BY_DATE: "By Date",
  SEGMENT_IRRIGATION_END_SECTOR: "End Sector",
  SEGMENT_IRRIGATION_BY_ROUNDS: "By Rounds",
  SEGMENT_IRRIGATION_ROUND_FIELD_ERROR:
    "Error. The number of round must be higher than zero",
  SEGMENT_IRRIGATION_ERROR_END_ANGLE_OUT_OF_RANGE:
    "Stop angle out of range for this pivot",
  SEGMENT_IRRIGATION_ERROR_LOADING_LATEST:
    "Unable to load the last Segment Irrigation",
  SEGMENT_IRRIGATION_INCOMPATIBLE_IRRIGATIONS:
    "Couldn't load the last irrigation. The segments differ.",
  SELECTED_PIVOT_GENERAL: "General",
  SELECTED_PIVOT_HISTORIC_FILTER_PREFERENCES: "Edit History",
  SELECTED_PIVOT_HARVEST: "Harvest",
  SELECTED_PIVOT_BASIC_INFO: "Operations Summary",
  SELECTED_PIVOT_PUMP_BOX: "Pumps",
  SELECTED_PIVOT_HISTORIC: "History",
  SELECTED_PIVOT_RECOMMENDATION: "Recommendation",
  SELECTED_PIVOT_SETUP_IRRIGATION: "Setup Irrigation",
  SELECTED_PIVOT_CHOOSE_IRRIGATION_MODE: "Choose Irrigation Mode:",
  SELECTED_PIVOT_STANDARD_IRRIGATION: "Simple",
  SELECTED_PIVOT_BY_ANGLE_IRRIGATION: "By Angle",
  SELECTED_PIVOT_VRI_IRRIGATION: "Segments",
  SELECTED_PIVOT_SCHEDULE_IRRIGATION: "Schedule",
  SELECTED_PIVOT_STOP_ACTION: "Stop",
  SELECTED_PIVOT_EDIT_BUTTON_TEXT: "Edit",
  SELECTED_PIVOT_BACK_BUTTON_TEXT: "Back",
  SELECTED_PIVOT_SEGMENT_IRRIGATION_BUTTON: "Segments",
  SELECTED_PIVOT_HISTORIC_SWITCH: "Advanced",
  PIVOT_CONFIG_DASHBOARD_NAME: "${}",
  PIVOT_CONFIG_REPORT_PREFERENCES: "Edit Summary",
  PIVOT_REPORT_EXCEL: "Excel",
  PIVOT_REPORT_EXCEL_ERROR:
    "There are no data to generate the excel spreadsheet.",
  SELECTED_PIVOT_DOWNLOADING_EXCEL: "Downloading Excel file. Please wait.",
  SELECTED_PIVOT_DOWNLOADING_EXCEL_ERROR:
    "Something went wrong. Please, tr again later.",
  SELECTED_PIVOT_DOWNLOADING_EXCEL_SUCCESS:
    "Download finished. You can close this window.",
  REPORT_TABLE_START_DATE: "Start",
  REPORT_TABLE_END_DATE: "End",
  REPORT_TABLE_START_TOTAL: "Total",
  REPORT_TABLE_START_WATER_BLADE: "Water Blade",
  REPORT_TABLE_START_DETAILS: "Details",
  DEVICE_BOX_ANALYZE: "Analyze:",
  DEVICE_BOX_CONTROL: "Controller",
  DEVICE_BOX_GPS: "GPS",
  DEVICE_BOX_PIVOT: "Pivot",
  DEVICE_BOX_SHOW_GPS: "Show GPS",
  DEVICE_BOX_SEARCH_RADIO: "Search Radio",
  DEVICE_BOX_KEEP_LINES: "Keep Lines",
  SELECTED_PIVOT_SCHEDULE_MODAL_SUCCESS:
    "Schedule Irrigation sent successfully",
  SELECTED_PIVOT_SCHEDULE_MODAL_FAILURE:
    "FailFailed to send Schedule Irrigation",
  SELECTED_PIVOT_SCHEDULE_MODAL_ROW_LIMIT: "You can only add 7 rows.",
  BY_ANGLE_IRRIGATION_BUTTON_FORWARD: "FORWARD",
  BY_ANGLE_IRRIGATION_BUTTON_BACKWARD: "REVERSE",
  BY_ANGLE_IRRIGATION_FIELD_PERCENTAGE: "Percentage",
  BY_ANGLE_IRRIGATION_FIELD_PERCENT: "Percent",
  BY_ANGLE_IRRIGATION_FIELD_MILILITER: "Milimeter",
  BY_ANGLE_IRRIGATION_ERROR:
    "Error. The informed value is less than the minimun expected",
  BY_ANGLE_IRRIGATION_FIELD_ENDGUN_MODE: "Endgun Mode",
  BY_ANGLE_IRRIGATION_FIELD_OPERATION_MODE: "Operation Mode",
  BY_ANGLE_IRRIGATION_FIELD_DIRECTION: "Direction",
  BY_ANGLE_IRRIGATION_FIELD_DEFAULT_ERROR:
    "Error. Check if all fields are filled.",
  BY_ANGLE_IRRIGATION_FIELD_DIRECTION_FORWARD: "Forward",
  BY_ANGLE_IRRIGATION_FIELD_DIRECTION_REVERSE: "Reverse",
  BY_ANGLE_IRRIGATION_FIELD_ENDGUN_ON: "On",
  BY_ANGLE_IRRIGATION_FIELD_ENDGUN_OFF: "Off",
  BY_ANGLE_IRRIGATION_FIELD_OPERATION_MODE_DRY: "Dry",
  BY_ANGLE_IRRIGATION_FIELD_OPERATION_MODE_WET: "Wet",
  BY_ANGLE_IRRIGATION_BUTTON_SEND: "Send Command",
  IRRIGATION_END_DURATION: "Operation duration forecast: ",
  IRRIGATION_END_DATE_PREDICTION: "End date forecast: ",
  BY_ANGLE_IRRIGATION_BUTTON_CANCEL: "Cancel",
  ALERT_DIALOG_YES: "Yes",
  ALERT_DIALOG_NO: "No",
  ALERT_DIALOG_CONFIRMATION: "Confirmation",
  FIRST_STEPS: "First Steps",
  FIRST_STEPS_SELECT_OPTION: "Select an option",
  FIRST_STEPS_OPTION_ADM:
    "I am the farm's administrator and I need to register my equipment.",
  FIRST_STEPS_OPTION_EMPLOYEE: "I am a farm worker and I need to register.",
  FIRST_STEPS_OPTION_ADM_INFO:
    "Use your camera to scan the QR Code inside the Central, or enter below the 16 digit sequence that corresponds to the Central's ID. ",
  FIRST_STEPS_OPTION_EMPLOYEE_INFO:
    "Ask your administrator for the farm code to continue the registration. ",
  FIRST_STEPS_FARM_CODE: "Farm Code",
  FIRST_STEPS_CENTRAL_ID: "Central ID",
  FIRST_STEPS_CREATE_PROFILE_EMPLOYEE: "Create profile",
  FIRST_STEPS_CREATE_PROFILE_ADM: "Create administrator",
  FIRST_STEPS_CREATE_PROFILE_EMPLOYEE_INFO:
    "Enter your profile data. Your registration will be completed after confirming the verification email.",
  FIRST_STEPS_CREATE_PROFILE_ADM_INFO:
    "Enter the farm administrator's user data. Your registration will be completed after confirming the verification email.",
  GENERAL_BOX_NO_PANEL_STREAM: "No updates yet",
  GENERAL_BOX_LAST_UPDATE: "Last communication: ",
  GENERAL_BOX_STOP_PIVOT_SUCCESS: "Stop command sent.",
  GENERAL_BOX_STOP_PIVOT_FAILURE: "Failed to stop the pivot",
  GENERAL_BOX_PIVOT_IS_ONLINE_FAILURE: "Failed to check if the pivot is online",
  EDIT_IRPD_SEND_SUCCESS: "Send",
  EDIT_IRPD_SEND_CANCEL: "Cancel",
  GENERAL_BOX_IRPD_IS_ONLINE_FAILURE: "Failed to check if the irpd is online",
  IRPD_BOX_STOP_COMMAND_SENT: "Stop command sent successfully.",
  IRPD_BOX_COMMAND_ERROR: "Error sending the command.",
  IRPD_BOX_COMMAND_SENT: "Command sent successfully.",
  IRPD_BOX_CONFIGURATION_SAVED: "Configuration sent successfully.",
  IRPD_BOX_CONFIGURATION_ERROR: "Error sending configuration.",
  EDIT_FARM_CENTER_MAP_BUTTON: "Center on map",
  EDIT_FARM_SAVE_SUCCESS: "Farm saved successfully",
  EDIT_FARM_ERROR_NAME: "Invalid name size",
  EDIT_FARM_ERROR_BILLING_DATE: "Invalid billing date",
  EDIT_FARM_ERROR_INITIAL_LAT: "Invalid initial latitude",
  EDIT_FARM_ERROR_INITIAL_LNG: "Invalid initial longitude",
  EDIT_FARM_ERROR_LAT: "Invalid latitude",
  EDIT_FARM_ERROR_LNG: "Invalid longitude",
  EDIT_FARM_ERROR_ADDRESS: "Invalid address",
  EDIT_FARM_ERROR_POSTAL_CODE: "Invalid postal code",
  EDIT_FARM_ERROR_CITY: "Invalid City",
  EDIT_FARM_ERROR_STATE: "Invalid State",
  EDIT_FARM_ERROR_COUNTRY: "Invalid Country",
  EDIT_FARM_ERROR_PHONE: "Invalid phone number",
  EDIT_FARM_ERROR_INCOMPLETE_POWER_RANGE:
    "There are missing days on your power ranges. Fill all days of the week.",
  EDIT_FARM_REPEATED_DAYS: "This day has already been added to the list.",
  EDIT_FARM_POWER_RANGES: "Power Ranges",
  EDIT_FARM__POWER_RANGES_DESCRIPTION:
    "The Power Ranges are used to calculate energy costs for the reports.",
  EDIT_FARM_ADD_POWER_RANGES: "Add power ranges",
  EDIT_FARM_HOLLIDAYS: "HOLIDAYS",
  EDIT_FARM_ADD_HOLLIDAYS: "ADD HOLIDAYS",
  DATE_PICKER_HOLIDAYS: "Pick a date",
  EDIT_FARM_DAY: "Day",
  EDIT_FARM_MONTH: "Month",
  EDIT_FARM_START_TIME: "Start Time",
  EDIT_FARM_END_TIME: "End Time",
  EDIT_FARM_INTERVAL: "Interval",
  EDIT_FARM_DURATION: "Duration",
  EDIT_FARM_TYPE: "Type",
  EDIT_FARM_OPERATION_SUMMARY_POWER_RANGES_CHANGED_WARNING:
    "The power ranges has changed, save previous changes to enable the buttons below.",
  EDIT_FARM_OPERATION_SUMMARY_ENABLE_LABEL: "Enable Reports",
  EDIT_FARM_OPERATION_SUMMARY_ENABLE_TEXT:
    "Press the button to enable reports generation for the farm pivots.",
  EDIT_FARM_OPERATION_SUMMARY_INVALID_POWER_RANGES:
    "Register valid Power Ranges to enable the reports.",
  EDIT_FARM_OPERATION_SUMMARY_ALREADY_CALCULATING:
    "Your reports are being generated.",
  EDIT_FARM_OPERATION_SUMMARY_ALREADY_ENABLED:
    "Your reports are already enabled.",
  EDIT_FARM_OPERATION_SUMMARY_ALREADY_CALCULATED: "Your reports is activated.",
  EDIT_FARM_OPERATION_SUMMARY_RECALCULATE_LABEL: "Recalculate Reports",
  EDIT_FARM_OPERATION_SUMMARY_RECALCULATE_CONFIRMATION:
    "Are you sure you want to recalculate your\nreports?",
  EDIT_FARM_OPERATION_SUMMARY_RECALCULATE_TEXT:
    "Press the button to recalculate the reports for the farm pivots.",
  EDIT_FARM_OPERATION_SUMMARY_RECALCULATE: "Recalculate",
  EDIT_FARM_OPERATION_SUMMARY_ENABLE_SUCESS: "Reports enabled.",
  EDIT_FARM_OPERATION_SUMMARY_ENABLE_ERROR: "Error enabling reports.",
  EDIT_FARM_OPERATION_SUMMARY_RECALCULATE_SUCCESS:
    "Reports are being recalculated.",
  EDIT_FARM_OPERATION_SUMMARY_RECALCULATE_ERROR:
    "Error while trying to recalculate the reports.",
  EDIT_FARM_PIVOT_REPORTS: "Pivot Reports",
  EDIT_FARM_IRPD_REPORTS: "Pump Reports",
  PIVOT: "Pivot",
  PIVOT_PLURAL: "Pivots",
  PIVOT_STATUS_STOPPED: "Stopped",
  PIVOT_STATUS_IN_MOVING: "Moving in Dry Mode",
  PIVOT_STATUS_IRRIGATING: "Irrigating",
  PIVOT_STATUS_PROBLEM: "Problem",
  PIVOT_STATUS_PROGRAMMED: "Programmed",
  PIVOT_STATUS_PANEL_ON: "Panel on",
  PIVOT_STATUS_PANEL_OFF: "Panel off",
  PIVOT_STATUS_PRESSURIZING: "Waiting for pressure",
  PIVOT_FIELD_NAME: "Name",
  PAINEL_STREAM_REASON_0: "Panel on",
  PAINEL_STREAM_REASON_1: "Moving in dry mode",
  PAINEL_STREAM_REASON_2: "Wet mode after pressurized",
  PAINEL_STREAM_REASON_4: "Wet mode after power fault",
  PAINEL_STREAM_REASON_5: "Dry mode after power fault",
  PAINEL_STREAM_REASON_6: "Powered on after power fault and pressurized",
  PAINEL_STREAM_REASON_8: "Wet mode after pause time",
  PAINEL_STREAM_REASON_9: "Dry mode after pause time",
  PAINEL_STREAM_REASON_10: "Wet mode after pause time and pressurized",
  PAINEL_STREAM_REASON_11: "Dry mode after start of schedule irrigations",
  PAINEL_STREAM_REASON_16: "On after schedule irrigation",
  PAINEL_STREAM_REASON_18: "Wet mode after pressurized",
  PAINEL_STREAM_REASON_21: "Pivot scheduled in dry mode",
  PAINEL_STREAM_REASON_22: "Pivot on and scheduled after pressurized",
  PAINEL_STREAM_REASON_32: "Pivot scheduled on wet mode",
  PAINEL_STREAM_REASON_33: "Dry mode after scheduled",
  PAINEL_STREAM_REASON_34: "Wet mode after scheduled irrigation",
  PAINEL_STREAM_REASON_38: "Pivot wet after power fault",
  PAINEL_STREAM_REASON_39: "Fertigation started",
  PAINEL_STREAM_REASON_65: "Continuing dry schedule",
  PAINEL_STREAM_REASON_66: "Continuing wet schedule",
  PAINEL_STREAM_REASON_98: "Irrigating",
  PAINEL_STREAM_REASON_100: "Panel power on",
  PAINEL_STREAM_REASON_101: "Panel off",
  PAINEL_STREAM_REASON_102: "Waiting for pressurization",
  PAINEL_STREAM_REASON_103: "Pivot stopped with pressure on",
  PAINEL_STREAM_REASON_104: "Fertigation stopped",
  PAINEL_STREAM_REASON_240: "Stopped by rain gauge",
  PAINEL_STREAM_REASON_241: "Stopped before next schedule start",
  PAINEL_STREAM_REASON_242: "Stopped by unknown factor",
  PAINEL_STREAM_REASON_243: "Stopped at end-sector",
  PAINEL_STREAM_REASON_244: "Schedule irrigations finished",
  PAINEL_STREAM_REASON_245: "Performing autoreversion",
  PAINEL_STREAM_REASON_246: "Stopped after power surge",
  PAINEL_STREAM_REASON_247: "Stopped due to Pause Time",
  PAINEL_STREAM_REASON_248: "Stopped for low pressure",
  PAINEL_STREAM_REASON_249: "Stopped over the Web",
  PAINEL_STREAM_REASON_250: "Stopped by pressure timeout",
  PAINEL_STREAM_REASON_251: "Stopped after thermal relay tripped",
  PAINEL_STREAM_REASON_252: "Stopped after irrigation finished",
  PAINEL_STREAM_REASON_253: "Stopped after power fault",
  PAINEL_STREAM_REASON_254: "STOP button pressed",
  PAINEL_STREAM_REASON_255: "Stopped after misaligning",
  PAINEL_STREAM_REASON_256: "No communication",
  PANEL_STREAM_STATUS_0: "Stopped by web",
  PANEL_STREAM_STATUS_1: "Moving forward",
  PANEL_STREAM_STATUS_2: "Moving reverse",
  PANEL_STREAM_STATUS_3: "Waiting pressure",
  PANEL_STREAM_STATUS_4: "Irrigating",
  PANEL_STREAM_STATUS_5: "Waiting pressure delay",
  PANEL_STREAM_STATUS_6: "Moving dry",
  PANEL_STREAM_STATUS_7: "Programmed",
  PANEL_STREAM_STATUS_8: "Preparing to turn on",
  PANEL_STREAM_STATUS_9: "Stopped by pause time",
  PANEL_STREAM_STATUS_10: "Irrigation finished",
  PANEL_STREAM_STATUS_11: "Stopped on auto-reverse",
  PANEL_STREAM_STATUS_12: "Stopped by internet",
  PANEL_STREAM_STATUS_13: "Manual stop",
  PANEL_STREAM_STATUS_14: "Panel energized",
  PANEL_STREAM_STATUS_14_2: "Pivot on",
  PANEL_STREAM_STATUS_15: "Misaligned",
  PANEL_STREAM_STATUS_16: "Pressure failure",
  PANEL_STREAM_STATUS_17: "Invalid irrigation direction",
  PANEL_STREAM_STATUS_18: "Power outage",
  PANEL_STREAM_STATUS_19: "Voltage out of range",
  PANEL_STREAM_STATUS_20: "Parameter error",
  PANEL_STREAM_STATUS_21: "Unknown error",
  PANEL_STREAM_STATUS_22: "Panel off",
  PANEL_STREAM_STATUS_23: "Pressure timeout",
  PANEL_STREAM_STATUS_24: "Irrigation started",
  PANEL_STREAM_STATUS_25: "Stop button pressed (pump on)",
  PANEL_STREAM_STATUS_32: "Pump error",
  PANEL_STREAM_STATUS_33: "Moving dry by pluviometer",
  PANEL_STREAM_STATUS_34: "Stopped by pluviometer",
  PANEL_STREAM_STATUS_35: "Stopped by clock error",
  PANEL_STREAM_STATUS_36: "Fertigation started",
  PANEL_STREAM_STATUS_37: "Fertigation stopped",
  PANEL_STREAM_STATUS_38: "No communication",
  PANEL_STREAM_STATUS_POWER_OFF: "Powered off",
  PANEL_STREAM_STATUS_NONE: "Unknown status",
  IRPD_STATUS_STOPPED: "Stopped",
  IRPD_STATUS_PUMPING: "Pumping",
  IRPD_STATUS_PROBLEM: "Problem",
  IRPD_STATUS_PROGRAMMED: "Programmed",
  IRPD_STATUS_ENERGIZED: "Energized",
  IRPD_STATUS_WITHOUT_ENERGY: "Without energy",
  SELECTED_IRPD_BACK_BUTTON_TEXT: "Back",
  SELECTED_IRPD_SAVE_BUTTON_TEXT: "Save",
  IRPD_STATUS_1: "Turned on over the internet",
  IRPD_STATUS_2: "Turned on manually",
  IRPD_STATUS_3: "On after pause time",
  IRPD_STATUS_4: "Turned on after power fault",
  IRPD_STATUS_251: "Off",
  IRPD_STATUS_252: "Powered off after power fault",
  IRPD_STATUS_253: "Off after pause time",
  IRPD_STATUS_254: "Turned off manually",
  IRPD_STATUS_255: "Stopped over the internet",
  IRPD_CONFIG_FIELD_START_NOW: "Start now",
  IRPD_CONFIG_FIELD_STOP_NOW: "Stop now",
  IRPD_FIELD_START_INTERVAL: "Start in",
  IRPD_CONFIG_FIELD_DONT_STOP: "Don't stop",
  IRPD_FIELD_END_INTERVAL: "Stop in",
  IRPD_FIELD_OFF: "Off",
  EDIT_IRPD_CANCEL: "Are you sure?",
  EDIT_IRPD_ERROR_RTCDATE: "Invalid RTC",
  EDIT_IRPD_ERROR_HOUR_RANGE_MAX: "Invalid maximum hour",
  EDIT_IRPD_ERROR_HOUR_RANGE_MIN: "Invalid minimum hour",
  EDIT_IRPD_ERROR_ENERGY_TIME: "Invalid energy time",
  COUNTRY_AFGHANISTAN: "Afghanistan",
  COUNTRY_SOUTH_AFRICA: "South Africa",
  COUNTRY_ALBANIA: "Albania",
  COUNTRY_GERMANY: "Germany",
  COUNTRY_ANDORRA: "Andorra",
  COUNTRY_ANGOLA: "Angola",
  COUNTRY_ANGUILLA: "Anguilla",
  COUNTRY_ANTARCTICA: "Antarctica",
  COUNTRY_ANTIGUA_AND_BARBUDA: "Antigua & Barbuda",
  COUNTRY_SAUDI_ARABIA: "Saudi Arabia",
  COUNTRY_ALGERIA: "Algeria",
  COUNTRY_ARGENTINA: "Argentina",
  COUNTRY_ARMENIA: "Armenia",
  COUNTRY_ARUBA: "Aruba",
  COUNTRY_AUSTRALIA: "Australia",
  COUNTRY_AUSTRIA: "Austria",
  COUNTRY_AZERBAIJAN: "Azerbaijan",
  COUNTRY_BAHAMAS: "Bahamas",
  COUNTRY_BAHRAIN: "Bahrain",
  COUNTRY_BANGLADESH: "Bangladesh",
  COUNTRY_BARBADOS: "Barbados",
  COUNTRY_BELARUS: "Belarus",
  COUNTRY_BELGIUM: "Belgium",
  COUNTRY_BELIZE: "Belize",
  COUNTRY_BENIN: "Benin",
  COUNTRY_BERMUDA: "Bermuda",
  COUNTRY_BOLIVIA: "Bolivia",
  COUNTRY_BOSNIA_AND_HERZEGOVINA: "Bosnia & Herzegovina",
  COUNTRY_BOTSWANA: "Botswana",
  COUNTRY_BRAZIL: "Brazil",
  COUNTRY_BRUNEI: "Brunei",
  COUNTRY_BULGARIA: "Bulgaria",
  COUNTRY_BURKINA_FASO: "Burkina Faso",
  COUNTRY_BURUNDI: "Burundi",
  COUNTRY_BHUTAN: "Bhutan",
  COUNTRY_CAPE_VERDE: "Cape Verde",
  COUNTRY_CAMEROON: "Cameroon",
  COUNTRY_CAMBODIA: "Cambodia",
  COUNTRY_CANADA: "Canada",
  COUNTRY_CANARY_ISLANDS: "Canary Islands",
  COUNTRY_KAZAKHSTAN: "Kazakhstan",
  COUNTRY_CEUTA_AND_MELILLA: "Ceuta & Melilla",
  COUNTRY_CHAD: "Chad",
  COUNTRY_CHILE: "Chile",
  COUNTRY_CHINA: "China",
  COUNTRY_CYPRUS: "Cyprus",
  COUNTRY_SINGAPORE: "Singapore",
  COUNTRY_COLOMBIA: "Colombia",
  COUNTRY_COMOROS: "Comoros",
  COUNTRY_CONGO_REPUBLIC: "Congo (Republic)",
  COUNTRY_NORTH_KOREA: "North Korea",
  COUNTRY_SOUTH_KOREA: "South Korea",
  COUNTRY_CTE_DIVOIRE: "Côte d¿Ivoire",
  COUNTRY_COSTA_RICA: "Costa Rica",
  COUNTRY_CROATIA: "Croatia",
  COUNTRY_CUBA: "Cuba",
  COUNTRY_CURAAO: "Curaçao",
  COUNTRY_DIEGO_GARCIA: "Diego Garcia",
  COUNTRY_DENMARK: "Denmark",
  COUNTRY_DJIBOUTI: "Djibouti",
  COUNTRY_DOMINICA: "Dominica",
  COUNTRY_EGYPT: "Egypt",
  COUNTRY_EL_SALVADOR: "El Salvador",
  COUNTRY_UNITED_ARAB_EMIRATES: "United Arab Emirates",
  COUNTRY_ECUADOR: "Ecuador",
  COUNTRY_ERITREA: "Eritrea",
  COUNTRY_SLOVAKIA: "Slovakia",
  COUNTRY_SLOVENIA: "Slovenia",
  COUNTRY_SPAIN: "Spain",
  COUNTRY_UNITED_STATES: "United States",
  COUNTRY_ESTONIA: "Estonia",
  COUNTRY_ETHIOPIA: "Ethiopia",
  COUNTRY_FIJI: "Fiji",
  COUNTRY_PHILIPPINES: "Philippines",
  COUNTRY_FINLAND: "Finland",
  COUNTRY_FRANCE: "France",
  COUNTRY_GABON: "Gabon",
  COUNTRY_GAMBIA: "Gambia",
  COUNTRY_GHANA: "Ghana",
  COUNTRY_GEORGIA: "Georgia",
  COUNTRY_GIBRALTAR: "Gibraltar",
  COUNTRY_UNITED_KINGDOM: "United Kingdom",
  COUNTRY_GRENADA: "Grenada",
  COUNTRY_GREECE: "Greece",
  COUNTRY_GREENLAND: "Greenland",
  COUNTRY_GUADELOUPE: "Guadeloupe",
  COUNTRY_GUAM: "Guam",
  COUNTRY_GUATEMALA: "Guatemala",
  COUNTRY_GUERNSEY: "Guernsey",
  COUNTRY_GUYANA: "Guyana",
  COUNTRY_FRENCH_GUIANA: "French Guiana",
  COUNTRY_GUINEA: "Guinea",
  COUNTRY_EQUATORIAL_GUINEA: "Equatorial Guinea",
  COUNTRY_GUINEA_BISSAU: "Guinea-Bissau",
  COUNTRY_HAITI: "Haiti",
  COUNTRY_NETHERLANDS: "Netherlands",
  COUNTRY_HONDURAS: "Honduras",
  COUNTRY_HONG_KONG: "Hong Kong",
  COUNTRY_HUNGARY: "Hungary",
  COUNTRY_YEMEN: "Yemen",
  COUNTRY_BOUVET_ISLAND: "Bouvet Island",
  COUNTRY_ASCENSION_ISLAND: "Ascension Island",
  COUNTRY_CLIPPERTON_ISLAND: "Clipperton Island",
  COUNTRY_ISLE_OF_MAN: "Isle of Man",
  COUNTRY_CHRISTMAS_ISLAND: "Christmas Island",
  COUNTRY_PITCAIRN_ISLANDS: "Pitcairn Islands",
  COUNTRY_RUNION: "Réunion",
  COUNTRY_LAND_ISLANDS: "Åland Islands",
  COUNTRY_CAYMAN_ISLANDS: "Cayman Islands",
  COUNTRY_COCOS_KEELING_ISLANDS: "Cocos (Keeling) Islands",
  COUNTRY_COOK_ISLANDS: "Cook Islands",
  COUNTRY_FAROE_ISLANDS: "Faroe Islands",
  COUNTRY_SOUTH_GEORGIA_AND_SOUTH_SANDWICH_ISLANDS:
    "South Georgia & South Sandwich Islands",
  COUNTRY_HEARD_AND_MCDONALD_ISLANDS: "Heard & McDonald Islands",
  COUNTRY_FALKLAND_ISLANDS_ISLAS_MALVINAS: "Falkland Islands (Islas Malvinas)",
  COUNTRY_NORTHERN_MARIANA_ISLANDS: "Northern Mariana Islands",
  COUNTRY_MARSHALL_ISLANDS: "Marshall Islands",
  COUNTRY_US_OUTLYING_ISLANDS: "U.S. Outlying Islands",
  COUNTRY_NORFOLK_ISLAND: "Norfolk Island",
  COUNTRY_SOLOMON_ISLANDS: "Solomon Islands",
  COUNTRY_SEYCHELLES: "Seychelles",
  COUNTRY_TOKELAU: "Tokelau",
  COUNTRY_TURKS_AND_CAICOS_ISLANDS: "Turks & Caicos Islands",
  COUNTRY_US_VIRGIN_ISLANDS: "U.S. Virgin Islands",
  COUNTRY_BRITISH_VIRGIN_ISLANDS: "British Virgin Islands",
  COUNTRY_INDIA: "India",
  COUNTRY_INDONESIA: "Indonesia",
  COUNTRY_IRAN: "Iran",
  COUNTRY_IRAQ: "Iraq",
  COUNTRY_IRELAND: "Ireland",
  COUNTRY_ICELAND: "Iceland",
  COUNTRY_ISRAEL: "Israel",
  COUNTRY_ITALY: "Italy",
  COUNTRY_JAMAICA: "Jamaica",
  COUNTRY_JAPAN: "Japan",
  COUNTRY_JERSEY: "Jersey",
  COUNTRY_JORDAN: "Jordan",
  COUNTRY_KIRIBATI: "Kiribati",
  COUNTRY_KOSOVO: "Kosovo",
  COUNTRY_KUWAIT: "Kuwait",
  COUNTRY_LAOS: "Laos",
  COUNTRY_LESOTHO: "Lesotho",
  COUNTRY_LATVIA: "Latvia",
  COUNTRY_LEBANON: "Lebanon",
  COUNTRY_LIBERIA: "Liberia",
  COUNTRY_LIBYA: "Libya",
  COUNTRY_LIECHTENSTEIN: "Liechtenstein",
  COUNTRY_LITHUANIA: "Lithuania",
  COUNTRY_LUXEMBOURG: "Luxembourg",
  COUNTRY_MACAU: "Macau",
  COUNTRY_MACEDONIA_FYROM: "Macedonia (FYROM)",
  COUNTRY_MADAGASCAR: "Madagascar",
  COUNTRY_MALAYSIA: "Malaysia",
  COUNTRY_MALAWI: "Malawi",
  COUNTRY_MALDIVES: "Maldives",
  COUNTRY_MALI: "Mali",
  COUNTRY_MALTA: "Malta",
  COUNTRY_MOROCCO: "Morocco",
  COUNTRY_MARTINIQUE: "Martinique",
  COUNTRY_MAURITIUS: "Mauritius",
  COUNTRY_MAURITANIA: "Mauritania",
  COUNTRY_MAYOTTE: "Mayotte",
  COUNTRY_MEXICO: "Mexico",
  COUNTRY_MICRONESIA: "Micronesia",
  COUNTRY_MOZAMBIQUE: "Mozambique",
  COUNTRY_MOLDOVA: "Moldova",
  COUNTRY_MONACO: "Monaco",
  COUNTRY_MONGOLIA: "Mongolia",
  COUNTRY_MONTENEGRO: "Montenegro",
  COUNTRY_MONTSERRAT: "Montserrat",
  COUNTRY_MYANMAR_BURMA: "Myanmar (Burma)",
  COUNTRY_NAMIBIA: "Namibia",
  COUNTRY_NAURU: "Nauru",
  COUNTRY_NEPAL: "Nepal",
  COUNTRY_NICARAGUA: "Nicaragua",
  COUNTRY_NIGER: "Niger",
  COUNTRY_NIGERIA: "Nigeria",
  COUNTRY_NIUE: "Niue",
  COUNTRY_NORWAY: "Norway",
  COUNTRY_NEW_CALEDONIA: "New Caledonia",
  COUNTRY_NEW_ZEALAND: "New Zealand",
  COUNTRY_OMAN: "Oman",
  COUNTRY_CARIBBEAN_NETHERLANDS: "Caribbean Netherlands",
  COUNTRY_PALAU: "Palau",
  COUNTRY_PALESTINE: "Palestine",
  COUNTRY_PANAMA: "Panama",
  COUNTRY_PAPUA_NEW_GUINEA: "Papua New Guinea",
  COUNTRY_PAKISTAN: "Pakistan",
  COUNTRY_PARAGUAY: "Paraguay",
  COUNTRY_PERU: "Peru",
  COUNTRY_FRENCH_POLYNESIA: "French Polynesia",
  COUNTRY_POLAND: "Poland",
  COUNTRY_PUERTO_RICO: "Puerto Rico",
  COUNTRY_PORTUGAL: "Portugal",
  COUNTRY_QATAR: "Qatar",
  COUNTRY_KENYA: "Kenya",
  COUNTRY_KYRGYZSTAN: "Kyrgyzstan",
  COUNTRY_CENTRAL_AFRICAN_REPUBLIC: "Central African Republic",
  COUNTRY_CONGO_DRC: "Congo (DRC)",
  COUNTRY_DOMINICAN_REPUBLIC: "Dominican Republic",
  COUNTRY_CZECH_REPUBLIC: "Czech Republic",
  COUNTRY_ROMANIA: "Romania",
  COUNTRY_RWANDA: "Rwanda",
  COUNTRY_RUSSIA: "Russia",
  COUNTRY_WESTERN_SAHARA: "Western Sahara",
  COUNTRY_ST_PIERRE_AND_MIQUELON: "St. Pierre & Miquelon",
  COUNTRY_AMERICAN_SAMOA: "American Samoa",
  COUNTRY_SAMOA: "Samoa",
  COUNTRY_SAN_MARINO: "San Marino",
  COUNTRY_ST_HELENA: "St. Helena",
  COUNTRY_ST_LUCIA: "St. Lucia",
  COUNTRY_ST_BARTHLEMY: "St. Barthélemy",
  COUNTRY_ST_KITTS_AND_NEVIS: "St. Kitts & Nevis",
  COUNTRY_ST_MARTIN: "St. Martin",
  COUNTRY_SINT_MAARTEN: "Sint Maarten",
  COUNTRY_SO_TOM_AND_PRNCIPE: "São Tomé & Príncipe",
  COUNTRY_ST_VINCENT_AND_GRENADINES: "St. Vincent & Grenadines",
  COUNTRY_SENEGAL: "Senegal",
  COUNTRY_SIERRA_LEONE: "Sierra Leone",
  COUNTRY_SERBIA: "Serbia",
  COUNTRY_SYRIA: "Syria",
  COUNTRY_SOMALIA: "Somalia",
  COUNTRY_SRI_LANKA: "Sri Lanka",
  COUNTRY_SWAZILAND: "Swaziland",
  COUNTRY_SUDAN: "Sudan",
  COUNTRY_SOUTH_SUDAN: "South Sudan",
  COUNTRY_SWEDEN: "Sweden",
  COUNTRY_SWITZERLAND: "Switzerland",
  COUNTRY_SURINAME: "Suriname",
  COUNTRY_SVALBARD_AND_JAN_MAYEN: "Svalbard & Jan Mayen",
  COUNTRY_TAJIKISTAN: "Tajikistan",
  COUNTRY_THAILAND: "Thailand",
  COUNTRY_TAIWAN: "Taiwan",
  COUNTRY_TANZANIA: "Tanzania",
  COUNTRY_BRITISH_INDIAN_OCEAN_TERRITORY: "British Indian Ocean Territory",
  COUNTRY_FRENCH_SOUTHERN_TERRITORIES: "French Southern Territories",
  COUNTRY_TIMOR_LESTE: "Timor-Leste",
  COUNTRY_TOGO: "Togo",
  COUNTRY_TONGA: "Tonga",
  COUNTRY_TRINIDAD_AND_TOBAGO: "Trinidad & Tobago",
  COUNTRY_TRISTAN_DA_CUNHA: "Tristan da Cunha",
  COUNTRY_TUNISIA: "Tunisia",
  COUNTRY_TURKMENISTAN: "Turkmenistan",
  COUNTRY_TURKEY: "Turkey",
  COUNTRY_TUVALU: "Tuvalu",
  COUNTRY_UKRAINE: "Ukraine",
  COUNTRY_UGANDA: "Uganda",
  COUNTRY_URUGUAY: "Uruguay",
  COUNTRY_UZBEKISTAN: "Uzbekistan",
  COUNTRY_VANUATU: "Vanuatu",
  COUNTRY_VATICAN_CITY: "Vatican City",
  COUNTRY_VENEZUELA: "Venezuela",
  COUNTRY_VIETNAM: "Vietnam",
  COUNTRY_WALLIS_AND_FUTUNA: "Wallis & Futuna",
  COUNTRY_ZAMBIA: "Zambia",
  COUNTRY_ZIMBABWE: "Zimbabwe",
  IRRIGATION_BOX_CONVENTIONAL_BUTTON: "Send Command",
  IRRIGATION_BOX_CONVENTIONAL_SUCCESS_MSG: "Simple Irrigation command sent.",
  IRRIGATION_BOX_BY_ANGLE_SUCCESS_MSG: "Command sent successfully.",
  IRRIGATION_BOX_BY_ANGLE_ERROR_MSG: "Error sending command.",
  IRRIGATION_BOX_GENERIC_ERROR_MSG: "Error sending command.",
  IRRIGATION_BOX_CONVENTIONAL_ERROR_MSG:
    "Error sending command. Check if all fields are filled.",
  IRRIGATION_BOX_STOP_PIVOT_DIALOG_TITLE: "Confirm your action",
  IRRIGATION_BOX_STOP_PIVOT_DIALOG_DESCRIPTION:
    "Please confirm the stop action for ${}",
  IRRIGATION_BOX_FORWARD_PIVOT_DIALOG_DESCRIPTION:
    "Please confirm forward irrigation action for ${}",
  IRRIGATION_BOX_REVERSE_PIVOT_DIALOG_DESCRIPTION:
    "Please confirm reverse irrigation action for ${}",
  IRRIGATION_BOX_STOP_PIVOT_DIALOG_YES: "Send",
  IRRIGATION_BOX_STOP_PIVOT_DIALOG_NO: "Cancel",
  MOBILE_MODAL_TITLE_SIMPLE_IRRIGATION: "Simple Irrigation",
  MOBILE_MODAL_TITLE_SCHEDULE_IRRIGATION: "Schedule Irrigation",
  MOBILE_MODAL_TITLE_CONVENTIONAL_IRRIGATION: "Simple Irrigation",
  MOBILE_MODAL_TITLE_LANGUAGES: "Choose a Language",
  MOBILE_MODAL_TITLE_BY_ANGLE_IRRIGATION: "Irrigation By Angle",
  MOBILE_MODAL_TITLE_SEGMENTED_IRRIGATION: "Segment Irrigation",
  PIVOT_ACTION_FIELD_DIRECTION: "Direction",
  PIVOT_ACTION_FIELD_MODE: "Operation Mode",
  PIVOT_ACTION_FIELD_ENDGUN: "Endgun",
  PIVOT_ACTION_FIELD_PRECIPITATION: "Precipitation",
  PIVOT_ACTION_FIELD_START_DATE: "Start at",
  PIVOT_ACTION_FIELD_START_TIME: "Start Time",
  PIVOT_ACTION_FIELD_END_DATE: "End at",
  PIVOT_ACTION_FIELD_END_TIME: "End Time",
  PIVOT_ACTION_VALUE_DIRECTION_UNKNOWN: "Unknown",
  PIVOT_ACTION_VALUE_DIRECTION_STOPPED: "Stopped",
  PIVOT_ACTION_VALUE_DIRECTION_PROGRESS: "Forward",
  PIVOT_ACTION_VALUE_DIRECTION_REVERSE: "Reverse",
  PIVOT_START_ACTION_VALUE_DIRECTION_PROGRESS: "Start Forward",
  PIVOT_START_ACTION_VALUE_DIRECTION_REVERSE: "Start Reverse",
  PIVOT_ACTION_VALUE_DRY: "Dry",
  PIVOT_ACTION_VALUE_MODE_WET: "Wet",
  PIVOT_ACTION_FIELD_PERCENT: "Percent",
  PIVOT_ACTION_FIELD_START_NOW: "Start Now",
  PIVOT_ACTION_FIELD_START_AFTER_PAUSE_TIME: "After Pause Time",
  PIVOT_ACTION_FIELD_START_AFTER_PAUSE_TIME_1: "After Pause Time 1",
  PIVOT_ACTION_FIELD_START_AFTER_PAUSE_TIME_2: "After Pause Time 2",
  PIVOT_ACTION_FIELD_WITHOUT_STOP: "Without stop",
  PIVOT_ACTION_INVALID_DATE: "Invalid date",
  NAV_ITEM_LOGOUT: "Logout",
  NAV_ITEM_LANGUAGE: "Language",
  NAV_ITEM_PROFILE: "Edit Profile",
  NAV_ITEM_FIRST_STEPS: "First Steps",
  NAV_ITEMS_NEW_FARM: "New Farm",
  SELECTED_FARM_NO_PIVOTS: "This farm doesn't have any pivots.",
  SELECTED_FARM_CENTRAL_ONLINE: "Central online",
  SELECTED_FARM_PIVOT_ONLINE: "Pivot online",
  SELECTED_FARM_CENTRAL_FILTER_BY: "Filter equipment by",
  SELECTED_FARM_CENTRAL_OFFLINE: "Central offline",
  SELECTED_FARM_PIVOT_OFFLINE: "Pivot offline",
  SELECTED_FARM_WATER_LIMIT_EXCEEDED: "Water consumption limit exceeded",
  LOADING: "Loading",
  NOT_FOUND_TITLE: "Ops!",
  NOT_FOUND_ERROR_TEXT: "Page not found.",
  NOT_FOUND_BACK_BUTTON_TEXT: "HOME PAGE",
  NOT_FOUND_TOKEN: "Session expired. Log in again.",
  EDIT_PIVOT_V5_RELEVANT_INFO: "Relevant Information",
  EDIT_PIVOT_V5_PIVOT_TITLE_SEGMENTS: "Segments",
  EDIT_PIVOT_V5_PUMP_PRESSURE_CONFIG: "Pressure Settings",
  EDIT_PIVOT_V5_AUTOREVERSION: "Autoreversion",
  EDIT_PIVOT_V5_AUTOREVERSION_30S: "30 seconds",
  EDIT_PIVOT_V5_AUTOREVERSION_1MIN: "1 minute",
  EDIT_PIVOT_V5_AUTOREVERSION_2MIN: "2 minutes",
  EDIT_PIVOT_V5_REFERENCE_NORTH: "Use North as Reference",
  EDIT_PIVOT_V5_REFERENCE_NORTH_SELECT: "Set Pivot Reference",
  EDIT_PIVOT_V5_AUTOREVERSION_TIME_END_ADORNMENT: "s",
  EDIT_PIVOT_V5_AUTOREVERSION_DISABLED: "Disabled",
  EDIT_PIVOT_ERROR_NAME: "Invalid name size",
  EDIT_PIVOT_V5_ERROR_POTENCY: "Invalid power value",
  EDIT_PIVOT_V5_ERROR_GP_CENTER_LAT: "Invalid center latitude",
  EDIT_PIVOT_V5_ERROR_GP_CENTER_LNG: "Invalid center longitude",
  EDIT_PIVOT_V5_ERROR_GP_START_REF_LAT: "Invalid start reference latitude",
  EDIT_PIVOT_V5_ERROR_GP_START_REF_LNG: "Invalid start reference longitude",
  EDIT_PIVOT_V5_ERROR_GP_END_REF_LAT: "Invalid end referencial latitude value",
  EDIT_PIVOT_V5_ERROR_GP_END_REF_LNG: "Invalid end referencial longitude value",
  EDIT_PIVOT_V5_ERROR_SENDING_CONFIGURATION: "Error sending configuration:",
  EDIT_PIVOT_V5_ERROR_RADIUS: "Invalid radius value",
  EDIT_PIVOT_V5_ERROR_FLOW: "Invalid flow value",
  EDIT_PIVOT_V5_ERROR_SPEED: "Invalid radius value",
  EDIT_PIVOT_V5_ERROR_AREA: "Invalid area value",

  EDIT_PIVOT_V5_ERROR_VOLTAGE_STABLE_TIME: "Invalid stable voltage time value",
  EDIT_PIVOT_V5_ERROR_VOLTAGE_REF: "Invalid voltage reference value",
  EDIT_PIVOT_V5_ERROR_PUMP_TIMEOUT: "Invalid pump timeout value",
  EDIT_PIVOT_V5_ERROR_PUMP_PRESS_DELAY: "Invalid pump pressure delay value",
  EDIT_PIVOT_V5_ERROR_PUMP_PRESS_SWITCH: "Invalid pump pressure switch value",
  EDIT_PIVOT_V5_ERROR_PUMP_SOFT_START_TIME:
    "Invalid pump soft start time value",
  EDIT_PIVOT_V5_ERROR_ANGLE_START: "Invalid start angle value",
  EDIT_PIVOT_V5_ERROR_ANGLE_END: "Invalid end angle value",
  EDIT_PIVOT_V5_ERROR_PAUSE_TIME_START: "Invalid start time value",
  EDIT_PIVOT_V5_ERROR_PIVOT_AVERAGE_POWER: "Invalid pivot average power value",
  EDIT_PIVOT_V5_ERROR_CANAL_AVERAGE_POWER: "Invalid canal average power value",
  EDIT_PIVOT_V5_ERROR_PAUSE_TIME_END: "Invalid time end value",
  EDIT_PIVOT_V5_ERROR_POWER_DELAY: "Invalid power delay value",
  EDIT_PIVOT_V5_ERROR_SENSOR_SCALE_END: "Invalid sensor scale end value",
  EDIT_PIVOT_V5_ERROR_PIVOT_SEGMENT: "Invalid pivot segments",
  EDIT_PIVOT_V5_PIVOT_SEGMENTS: "Segments",
  EDIT_PIVOT_V5_ENDGUN: "Endgun",
  EDIT_PIVOT_V5_SAVE_SUCCESS: "Pivot configuration sent",
  EDIT_PIVOT_V5_SEGMENT_WARNING:
    "Fill all the segments and do not exceed the working angle",
  EDIT_PIVOT_V5_SAVE_ERROR:
    "Error while saving pivot. All fields must be filled.",
  PIVOT_CONFIG_V5_FIELD_VOLTAGE_CHECK: "Voltage Control",
  PIVOT_CONFIG_V5_FIELD_SEGMENT_DELETE_ALL: "Delete all",
  PIVOT_CONFIG_V5_FIELD_SEGMENT_ANGLE_START: "Start: ${}º",
  PIVOT_CONFIG_V5_FIELD_SEGMENT_ANGLE_END: "End: ${}º",
  PIVOT_CONFIG_V5_FIELD_SEGMENT_FORWARD: "Forward: ",
  PIVOT_CONFIG_V5_FIELD_SEGMENT_REVERSE: "Reverse: ",
  PIVOT_CONFIG_V5_FIELD_SEGMENT_MODE: "${}, ${}%",
  PIVOT_CONFIG_V5_CONFIG_HISTORY: "Configuration History",
  PIVOT_CONFIG_V5_CONFIG_REMOVE_BUTTOM: "Clear Table",
  PIVOT_CONFIG_V5_SEGMENT_ERROR: "You can create up top ${} segments",
  EDIT_PIVOT_V5_WEEKDAY_MONDAY: "MON",
  EDIT_PIVOT_V5_WEEKDAY_TUESDAY: "TUE",
  EDIT_PIVOT_V5_WEEKDAY_WEDNESDAY: "WED",
  EDIT_PIVOT_V5_WEEKDAY_THURSDAY: "THU",
  EDIT_PIVOT_V5_WEEKDAY_FRIDAY: "FRI",
  EDIT_PIVOT_V5_WEEKDAY_SATURDAY: "SAT",
  EDIT_PIVOT_V5_WEEKDAY_SUNDAY: "SUN",
  EDIT_PIVOT_V5_DAYS_OF_THE_WEEK: "Days of the Week",
  EDIT_PIVOT_V5_CONFIGURATIONS: "Configurations",
  COMMAND_SENT_WAITING_CONFIRMATION:
    "Command sent. Wait for the device confirmation.",
  COMMAND_CONFIRMED: "Command received by the device.",
  COMMAND_ERROR: "Error executing command",
  NAV_NO_NOTIFICATIONS: "You don't have any notifications.",
  CURRENT_ANGLE: "Current angle: ",
  POWER_RANGE_POINT: "Peak",
  POWER_RANGE_OUT_OF_POINT: "Off-Peak",
  POWER_RANGE_REDUCED: "Nighttime",
  POWER_RANGE_TIME_START: "Start",
  POWER_RANGE_TIME_END: "End",
  POWER_RANGE_TYPE: "Type",
  POWER_RANGE_DELETE_CONFIRMATION:
    "Are you sure you want to delete this power range?",
  HISTORIC_SEGMENT_IRRIGATION_START_MODE: "Start Mode: ",
  HISTORIC_SEGMENT_IRRIGATION_START_AT: "Start at: ",
  HISTORIC_SEGMENT_IRRIGATION_END_MODE: "End Mode: ",
  HISTORIC_SEGMENT_IRRIGATION_SEGMENT: "Segment ${}:",
  HISTORIC_SEGMENT_IRRIGATION_START_ANGLE: "Start Angle ${}",
  HISTORIC_SEGMENT_IRRIGATION_END_ANGLE: "End Angle: ${}",
  HISTORIC_SEGMENT_IRRIGATION_FORWARD_INFO: "Forward - ${} - ${}",
  HISTORIC_SEGMENT_IRRIGATION_REVERSE_INFO: "Reverse - ${} - ${}",
  HISTORIC_SEGMENT_IRRIGATION_END_AT: "End at: ",
  HISTORIC_SEGMENT_IRRIGATION_ROUNDS: "Rounds: ${}",
  HISTORIC_SEGMENT_IRRIGATION_START_DATE: "Start: ${}",
  HISTORIC_SEGMENT_IRRIGATION_END_DATE: "End: ${}",
  HISTORIC_SEGMENT_IRRIGATION_AUTOREVERSION_MODE: "Autoreversion Mode: ${}",
  HISTORIC_SEGMENT_IRRIGATION_AUTOREVERSION_QUANTITY: "Rounds: ${}",
  HISTORIC_SEGMENT_IRRIGATION_NEVER_ENDS: "Don't Stop",
  HISTORIC_SEGMENT_IRRIGATION_TOP_BY_ANGLE: "Stop by Angle",
  HISTORIC_SEGMENT_IRRIGATION_STOP_BY_DATE: "Stop by Date",
  HISTORIC_SEGMENT_IRRIGATION_STOP_END_SECTOR: "Stop by Sector End",
  HISTORIC_SEGMENT_IRRIGATION_STOP_BY_ROUNDS: "Stop By Rounds",
  HISTORIC_SEGMENT_IRRIGATION_AUTO_REVERSIONS: "Auto Reversions: ${}",
  HISTORIC_SEGMENT_IRRIGATION_NOW: "Immediate",
  HISTORIC_SEGMENT_IRRIGATION_ON_A_DATE: "On a Date",
  SEGMENT_IRRIGATION_FIELD_START_NOW: "Start now?",
  SEGMENT_IRRIGATION_FIELD_END_BY_TIME: "End by time?",
  SEGMENT_IRRIGATION_FIELD_END_BY_LAPS: "End by rounds?",
  SEGMENT_IRRIGATION_FIELD_END_BY_SEGMENT: "End by segments?",
  SEGMENT_IRRIGATION_FIELD_NEVER_ENDS: "Never ends.",
  SEGMENT_IRRIGATION_FIELD_NUMBER_OF_LAPS: "Number of rounds",
  SEGMENT_IRRIGATION_FIELD_LAPS: "Lap(s)",
  SEGMENT_IRRIGATION_BUTTON_FORWARD: "START FORWARD",
  SEGMENT_IRRIGATION_BUTTON_REVERSE: "START REVERSE",
  DRAWER_HOME: "Home",
  DRAWER_FARMS: "Farms",
  DRAWER_REPORTS: "Reports",
  NOTIFICATIONS_DRAWER_TITLE: "Notifications",
  GENERAL_BOX_PRESSURE_LABEL: "${} bar",
  LOGIN_WELCOME: "Welcome",
  LOGIN_EMAIL: "Email",
  LOGIN_NEW_USER: "New customer?",
  LOGIN_SIGN_IN: "Sign in",
  LOGIN_SIGN_UP: "Sign up",
  LOGIN_USERNAME_OR_EMAIL: "Username or email",
  LOGIN_PASSWORD: "Password",
  LOGIN_OR: "or",
  LOGIN_FORGET_PASSWORD: "Forgot the user or password?",
  LOGIN_GOOGLE_SIGN_IN: "Sign in with Google",
  LOGIN_RECOVER_PASSWORD: "Recover your password",
  LOGIN_GO_BACK: "Go back",
  LOGIN_SUCCESSFUL: "User logged in successfully.",
  SIGN_UP_FOOTER: "Fill in all fields in the form to proceed.",
  SIGN_UP_WELCOME: "Welcome to Irricontrol's platform.",
  SIGN_UP_WELCOME_AUX: "Fill in the form to complete your registration:",
  SIGN_UP_EMAIL: "Email",
  SIGN_UP_REPEAT_EMAIL: "Repeat your email",
  SIGN_UP_NAME: "Name",
  SIGN_UP_LAST_NAME: "Last name",
  SIGN_UP_USERNAME: "Username",
  SIGN_UP_REPEAT_PASSWORD: "Repeat your password",
  SIGN_UP_BACK: "Back",
  SIGN_UP_NEXT: "Next",
  SIGN_UP_WARNING_SUCCESS: "Registration completed successfully",
  LOGIN_ERROR_CREDENTIALS_NOT_PROVIDED: "Username and/or password not provided",
  LOGIN_ERROR_GOOGLE_USER_NOT_REGISTERED: "Google user is not registered",
  LOGIN_ERROR_DEFAULT: "Authentication failed",
  LOGIN_ERROR_SERVER_OFFLINE: "Server offline",
  LOGIN_ERROR_INVALID_CREDENTIALS: "Username or password is wrong",
  SIGN_UP_CONFIRMATION_RETURN:
    "Are you sure you want to leave the page without completing the registration?",
  SIGN_UP_CONFIRMATION_SIGN_UP: "Do you confirm all your data?",
  SIGN_UP_BUTTON_CONFIRM: "Confirm",
  CREATE_DEVICE_MODAL_TITLE: "Register Equipment",
  CREATE_DEVICE_MODAL_CENTRAL: "Central",
  CREATE_DEVICE_MODAL_EQUIPMENT: "Equipment Type",
  CREATE_DEVICE_MODAL_EQUIPMENT_PLACEHOLDER: "Equipment Name",

  CREATE_DEVICE_MODAL_HARDWARE_ID_PLACEHOLDER: "Hardware Id",

  CREATE_DEVICE_MODAL_CREATE_DEVICE: "Register Devices",
  CREATE_DEVICE_MODAL_CONTROLLER_CODE: "Controller Radio",
  CREATE_DEVICE_MODAL_GPS_CODE: "GPS Radio",
  CREATE_DEVICE_MODAL_PIVOT: "Pivot",
  CREATE_DEVICE_MODAL_GPS: "GPS",
  CREATE_DEVICE_MODAL_REPEATER: "Repeater",
  CREATE_DEVICE_MODAL_REPEATER_CODE: "Repeater Radio",
  CREATE_DEVICE_MODAL_CODE_READ_SUCCESS: "Code read successfully",
  CREATE_DEVICE_MODAL_CODE_READ_FAIL: "Code read error",
  CREATE_DEVICE_MODAL_CREATE: "Create",
  CREATE_DEVICE_MODAL_ERROR_NOT_VALID: "Invalid Radio",
  CREATE_DEVICE_MODAL_ERROR_TAKEN: "This device is already taken",
  CREATE_DEVICE_MODAL_ERROR_NOT_MONITOR: "This device is not a GPS",
  CREATE_DEVICE_MODAL_ERROR_NOT_CONTROL: "This device is not a Pivot",
  CREATE_DEVICE_MODAL_ERROR_NOT_REPEATER: "This device is not a Repeater",
  CREATE_DEVICE_MODAL_ERROR_SERVER: "Server error",
  CREATE_DEVICE_MODAL_SUCCESS: "Device was created successfully",
  EDIT_PROFILE_TITLE: "User profile ",
  EDIT_PROFILE_ERROR_NAME: "Invalid name size",
  EDIT_PROFILE_ERROR_LAST_NAME: "Invalid name size",
  EDIT_PROFILE_ERROR_USERNAME: "Invalid username size",
  EDIT_PROFILE_ERROR_EMAIL: "Invalid email address",
  EDIT_PROFILE_ERROR_CONFIRM_EMAIL: "Invalid email address",
  EDIT_PROFILE_ERROR_BIRTHDAY: "Invalid date",
  EDIT_PROFILE_ERROR_COUNTRY_CODE: "Invalid country code",
  EDIT_PROFILE_ERROR_CELLPHONE: "Invalid cellphone number",
  EDIT_PROFILE_ERROR_LANDLINE: "Invalid landline number",
  EDIT_PROFILE_ERROR_ADDRESS: "Invalid address",
  EDIT_PROFILE_ERROR_NUMBER_HOUSE: "Invalid house number",
  EDIT_PROFILE_ERROR_NEIGHBORHOOD: "Invalid neighborhood",
  EDIT_PROFILE_ERROR_CITY: "Invalid City",
  EDIT_PROFILE_ERROR_STATE: "Invalid State",
  EDIT_PROFILE_ERROR_COUNTRY: "Invalid Country",
  EDIT_PROFILE_ERROR_ZIPCODE: "Invalid ZIP code",
  INVALID_CPF: "Invalid document code",
  EDIT_PROFILE_ERROR_PASSWORD: "Invalid password",
  EDIT_PROFILE_ERROR_PASSWORD2: "Invalid password",
  EDIT_PROFILE_NAME: "Name",
  EDIT_PROFILE_LAST_NAME: "Last Name",
  EDIT_PROFILE_EMAIL: "Email",
  EDIT_PROFILE_BIRTHDAY: "Birthday",
  EDIT_PROFILE_CELLPHONE: "Cellphone number",
  EDIT_PROFILE_LANDLINE: "Landline number",
  EDIT_PROFILE_COUNTRY_CODE: "Country code",
  EDIT_PROFILE_ADDRESS: "Address",
  EDIT_PROFILE_NUMBER_HOUSE: "Number",
  EDIT_PROFILE_NEIGHBORHOOD: "Neighborhood",
  EDIT_PROFILE_CITY: "City",
  EDIT_PROFILE_STATE: "State",
  EDIT_PROFILE_COUNTRY: "Country",
  EDIT_PROFILE_ZIPCODE: "ZIP Code",
  EDIT_PROFILE_OFFICIAL_DOCUMENT_CODE: "Official document code",
  PROFILE_BOX_CONVENTIONAL_ERROR_MSG: "Error editing profile.",
  PROFILE_BOX_CONVENTIONAL_SUCCESS_MSG: "User profile sent.",
  EDIT_PROFILE_CONFIRM_PASSWORD: "Password",
  EDIT_PROFILE_PASSWORD: "Confirm password",
  EDIT_PROFILE_CONFIRM_EMAIL: "Confirm Email",
  EDIT_PROFILE_CURRENT_PASSWORD: "Current Password",
  EDIT_PROFILE_NEW_PASSWORD: "New Password",
  EDIT_PROFILE_CONFIRM_NEW_PASSWORD: "Confirm New Password",
  EDIT_PROFILE_PERSONAL_INFO: "Personal Info:",
  EDIT_PROFILE_CONTACT_ADDRESS: "Contact Address:",
  EDIT_PROFILE_PASSWORD_CHANGE: "Password Change:",
  SIMPLE_IRRIGATION_FIELD_DIRECTION: "Direction",
  SIMPLE_IRRIGATION_FIELD_MODE: "Operation Mode",
  SIMPLE_IRRIGATION_FIELD_PERCENT: "Percent",
  SIMPLE_IRRIGATION_FIELD_MM: "Precipitation",
  SIMPLE_IRRIGATION_FIELD_BY_ANGLE: "Stop by angle?",
  SIMPLE_IRRIGATION_FIELD_ANGLE_TO_STOP: "Final Angle",
  SIMPLE_IRRIGATION_VALUE_INJECTION_ON: "On",
  SIMPLE_IRRIGATION_VALUE_INJECTION_OFF: "Off",
  SIMPLE_IRRIGATION_VALUE_FORWARD: "Forward",
  SIMPLE_IRRIGATION_VALUE_REVERSE: "Reverse",
  SIMPLE_IRRIGATION_VALUE_DRY: "Dry",
  SIMPLE_IRRIGATION_VALUE_WET: "Wet",
  SIMPLE_IRRIGATION_FIELD_STOP_MODE: "Stop Mode",
  SIMPLE_IRRIGATION_NEVER_ENDS: "Don't Stop",
  SIMPLE_IRRIGATION_END_SECTOR: "End Sector",
  SIMPLE_IRRIGATION_BY_ROUNDS: "By Rounds",
  SIMPLE_IRRIGATION_FIELD_ROUNDS_TO_STOP: "Rounds",
  FARM: "Farm",
  FARMS: "Farms",
  FARM_FIELD_SEARCH_BAR: "Search",
  FARM_FIELD_NAME: "Farm Name",
  FARM_FIELD_BILLING_DATE: "Power Invoice Day",
  FARM_FIELD_LAT: "Latitude",
  FARM_FIELD_LNG: "Longitude",
  FARM_FIELD_PHONE: "Phone",
  FARM_FIELD_ADDRESS: "Street Address",
  FARM_FIELD_POSTAL_CODE: "Postal Code",
  FARM_FIELD_CITY: "City",
  FARM_FIELD_STATE: "State",
  FARM_FIELD_COUNTRY: "Country",
  FARM_FIELD_START_INTERVAL: "Start Time",
  FARM_FIELD_END_INTERVAL: "End Hour",
  FARM_FIELD_TYPE_INTERVAL: "Type",
  FARM_VALUE_RUSH_HOUR: "Peak Hours",
  FARM_VALUE_OFF_PEAK_TIME: "Off Peak Hours",
  FARM_VALUE_REDUCED_TIME: "Nighttime",
  HISTORIC_SIMPLE_IRRIGATION_CREATED_AT: "Created at: ${}",
  HISTORIC_CREATED_BY: "Sent by: ",
  HISTORIC_CREATED_ON_HARDWARE: "Created on device",
  HISTORIC_PAINEL_STREAM_REASON: "Reason:",
  HISTORIC_PAINEL_STREAM_REASON_VALUE: "${}",
  HISTORIC_PAINEL_STREAM_WITH_ENERGY: "Energized",
  HISTORIC_PAINEL_STREAM_WITHOUT_ENERGY: "Not energized",
  HISTORIC_PAINEL_STREAM_ALIGNED: "Aligned",
  HISTORIC_PAINEL_STREAM_NOT_ALIGNED: "Not aligned",
  HISTORIC_PAINEL_STREAM_HOURMETER: "Hourmeter: ",
  HISTORIC_PAINEL_STREAM_POWER: "Voltage: ",
  HISTORIC_PAINEL_STREAM_WITH_PRESSURE: "With Pressure",
  HISTORIC_PAINEL_STREAM_WITHOUT_PRESSURE: "Pump pressure timeout",
  HISTORIC_PAINEL_STREAM_REVERSIONS: "Executed reversions: ${} times",
  HISTORIC_GPS_STREAM_CREATED_HUMANIZED: "${} ago",
  HISTORIC_GPS_STREAM_CREATED: "${}",
  HISTORIC_GPS_STREAM_POSITION: "Position: ${},${}.",
  HISTORIC_GPS_STREAM_REASON: "Reason:",
  HISTORIC_GPS_STREAM_REASON_VALUE: "${}",
  HISTORIC_GPS_STREAM_SPEED: "Percentage: ",
  HISTORIC_GPS_STREAM_HOURMETER: "Hourmeter: ",
  HISTORIC_GPS_STREAM_WATER_PRESSURE: "Last tower pressure: ",
  HISTORIC_GPS_STREAM_CENTER_PRESSURE: "Center pressure: ${} bar",
  HISTORIC_GPS_STREAM_DIRECTION: "Direction: ",
  HISTORIC_PIVOT_ACTION_STOP: "Stop pivot",
  HISTORIC_PIVOT_ACTION_GENERAL_OVERVIEW: "${} | ${} | ${}",
  HISTORIC_PIVOT_ACTION_GENERAL_OVERVIEW_OLD: "${} | ${}",
  HISTORIC_PIVOT_ACTION_CREATED_HUMANIZED: "${} ago",
  HISTORIC_PIVOT_ACTION_CREATED: "${}",
  HISTORIC_PIVOT_ACTION_START: "Start: ",
  HISTORIC_PIVOT_ACTION_START_NOW: "Immediately",
  HISTORIC_PIVOT_ACTION_END: "Programmed stop: ",
  HISTORIC_PIVOT_ACTION_NEVER_END: "Don't stop",
  HISTORIC_PIVOT_ACTION_MANUAL: "Created manually",
  HISTORIC_PIVOT_ACTION_USER: "By user: ",
  HISTORIC_PIVOT_ACTION_DIRECTION: "Direction: ",
  HISTORIC_PIVOT_ACTION_ENDGUN: "Percentage: ${} %, ${} mm",
  HISTORIC_PIVOT_ACTION_MODE: "Mode: ",
  HISTORIC_PIVOT_ACTION_VRI_DELIVERER_OVERVIEW: "By Angle",
  HISTORIC_PIVOT_ACTION_VRI_START: "Start: ",
  HISTORIC_PIVOT_ACTION_VRI_START_NOW: "Now",
  HISTORIC_PIVOT_ACTION_VRI_DELIVERER_CREATED_HUMANIZED: "${} ago",
  HISTORIC_PIVOT_ACTION_VRI_DELIVERER_CREATED: "${}",
  HISTORIC_PIVOT_ACTION_VRI_DELIVERER_MANUAL: "Created manually",
  HISTORIC_PIVOT_ACTION_VRI_DELIVERER_USER: "User: ",
  HISTORIC_PIVOT_ACTION_VRI_DELIVERER_MODE: "Mode: ",
  HISTORIC_PIVOT_ACTION_VRI_DELIVERER_ACTION: "${} | ${} , ${} | ${} | ${}",
  HISTORIC_PIVOT_ACTION_VRI_DELIVERER_ENDGUN_ON: "Endgun on",
  HISTORIC_PIVOT_ACTION_VRI_DELIVERER_ENDGUN_OFF: "Endgun off",
  HISTORIC_PANEL_STREAM_V5_SIMPLE_IRRIGATION: "Simple Irrigation",
  HISTORIC_PANEL_STREAM_V5_: "Stop Pivot",
  HISTORIC_PANEL_STREAM_V5_SCHEDULE_IRRIGATION: "Schedule Irrigation",
  HISTORIC_PANEL_STREAM_V5_SEGMENT_IRRIGATION: "Segment Irrigation",
  HISTORIC_PANEL_STREAM_V5_STOPPED: "Stopped",
  HISTORIC_PANEL_STREAM_V5_FORWARD: "Forward",
  HISTORIC_PANEL_STREAM_V5_REVERSE: "Reverse",
  HISTORIC_PANEL_STREAM_V5_OVERVIEW: "${} | ${} | ${}",
  HISTORIC_PANEL_STREAM_V5_DIRECTION: "Direction: ",
  HISTORIC_PANEL_STREAM_V5_STATUS: "Status: ${}",
  HISTORIC_PANEL_STREAM_V5_ACTIVE_IRRIGATION: "Active irrigation: ",
  HISTORIC_PANEL_STREAM_V5_OPERATION_TIME_TOTAL: "Total hourmeter: ",
  HISTORIC_PANEL_STREAM_V5_OPERATION_TIME_WET: "Wet hourmeter: ",
  HISTORIC_PANEL_STREAM_V5_MODE: "Mode: ",
  HISTORIC_PANEL_STREAM_V5_PERCENTAGE: "Precipitation: ",
  HISTORIC_PANEL_STREAM_V5_POSITION: "Position",
  HISTORIC_PANEL_STREAM_V5_ANGLE: "Angle: ",
  HISTORIC_PANEL_STREAM_V5_VOLTAGE: "Voltage: ${} V",
  HISTORIC_PANEL_STREAM_V5_PRESSURE_CENTER: "Central tower pressure: ",
  HISTORIC_PANEL_STREAM_V5_PRESSURE_END_TOWER: "End tower pressure: ",
  HISTORIC_GPS_STREAM_V5_NO_LABEL: "${}",
  HISTORIC_GPS_STREAM_V5_REMAINING_TIME: "Estimated remaining time: ",
  HISTORIC_PANEL_STREAM_V5_CURRENT_SCHEDULE: "Current schedule: ",
  HISTORIC_PANEL_STREAM_V5_CURRENT_SEGMENT: "Current segment: ",
  HISTORIC_PANEL_LABEL_DATE: "Date",
  HISTORIC_PANEL_LABEL_TYPE: "Type",
  HISTORIC_PANEL_LABEL_SUMMARY: "Summary",
  HISTORIC_MAINTENANCE_ON: "Maintenance mode enabled.",
  HISTORIC_MAINTENANCE_OFF: "Maintenance mode disabled.",
  IRRIGATION_TYPE_SIMPLE: "Simple Irrigation",
  IRRIGATION_TYPE_SCHEDULE: "Schedule Irrigation",
  IRRIGATION_TYPE_SEGMENT: "Segment Irrigation",
  IRRIGATION_MODE_NONE: "None",
  IRRIGATION_MODE_WET: "Wet",
  IRRIGATION_MODE_DRY: "Dry",
  IRRIGATION_DIRECTION_STOPPED: "Stopped",
  IRRIGATION_DIRECTION_FORWARD: "Forward",
  IRRIGATION_DIRECTION_REVERSE: "Reverse",
  EDIT_PIVOT_LAST_SETTINGS: "Latest configuration: ${}",
  EDIT_PIVOT_MANUAL: "- Manual",
  EDIT_PIVOT_BY_WEB: "- Internet",
  EDIT_PIVOT_ERROR: "Form has invalid values.",
  EDIT_PIVOT_ERROR_CENTER_LAT: "Invalid center latitude",
  EDIT_PIVOT_ERROR_CENTER_LNG: "Invalid center longitude",
  EDIT_PIVOT_ERROR_START_REF_LAT: "Invalid Start Reference latitude",
  EDIT_PIVOT_ERROR_START_REF_LNG: "Invalid Start Reference longitude",
  EDIT_PIVOT_ERROR_END_REF_LAT: "Invalid End Reference latitude",
  EDIT_PIVOT_ERROR_END_REF_LNG: "Invalid End Reference longitude",
  EDIT_PIVOT_ERROR_RADIUS: "Invalid radius value",
  EDIT_PIVOT_ERROR_TOTAL_RADIUS: "Invalid total radius value",
  EDIT_PIVOT_ERROR_AREA: "Invalid Area value",
  EDIT_PIVOT_ERROR_SPEED: "Invalid Last Tower Speed value",
  EDIT_PIVOT_ERROR_FLOW: "Invalid Flow Rate value",
  EDIT_PIVOT_ERROR_POTENCY: "Invalid Pump Power value",
  EDIT_PIVOT_ERROR_PUMP_TIME: "Invalid Pump Time value",
  EDIT_PIVOT_ERROR_POWER_TIME: "Invalid Power Time value",
  EDIT_PIVOT_ERROR_POWER_RANGE_MIN: "Invalid name size",
  EDIT_PIVOT_ERROR_POWER_RANGE_MAX: "Invalid name size",
  EDIT_PIVOT_ERROR_HOUR_RANGE_MIN: "Invalid name size",
  EDIT_PIVOT_ERROR_HOUR_RANGE_MAX: "Invalid name size",
  EDIT_PIVOT_ERROR_CLOCK_DATE: "Invalid date",
  EDIT_PIVOT_ERROR_CLOCK_TIME: "Invalide time",
  EDIT_PIVOT_CLOCK_LABEL: "Set Device Clock",
  EDIT_PIVOT_SAVE_AWAIT: "Configuration sent. Wait for device confirmation",
  EDIT_PIVOT_SAVE_AWAIT_ERROR: "An error occurred",
  EDIT_PIVOT_SAVE_CONTROLLER_SUCCESS: "Controller saved successfully",
  EDIT_PIVOT_SAVE_GPS_SUCCESS: "GPS saved successfully",
  EDIT_PIVOT_SAVE_PUMP_SUCCESS: "Pump saved successfully",
  EDIT_PIVOT_GPS_LABEL: "GPS Radio",
  EDIT_PIVOT_PUMP_LABEL: "Pump Radio",
  EDIT_PIVOT_DELIVERED_LABEL: "Controller Radio",
  EDIT_PIVOT_CENTRAL_LABEL: "Central Radio",
  HISTORIC_GPS_STREAM_COMUNICATION_ERROR: "Pivot without communication",
  HISTORIC_STREAM_LOST_ERROR:
    "Possible loss of information from GPS or controller",
  TERRAIN_MAP: "Terrain",
  GENERIC_REGISTER_DATA_ERROR: "Error while registering data",
  GENERIC_REGISTER_DATA_SUCCESS: "Data successfully registered!",

  CREATE_DEVICE_MODAL_ERROR_FARM_BASE_EMPTY: "Could not create pivot",
  METERSYSTEM_WATER_LEVEL_BY_TIME: "Water Level by Time",
  METERSYSTEM_CURRENT_FLOW: "Flow: ",
  METERSYSTEM_NO_READINGS: "No readings",
  METERSYSTEM_TOTAL_FLOW: "Total Flow",
  METERSYSTEM_FLOW_BY_TIME: "Flow By Time",
  METER_SYSTEM_EDIT: "Edit Meter",
  METER_SYSTEM_EDIT_GENERAL: "General Configurations",
  METERSYSTEM_STATUS_2: "Powered On after power fault",
  METERSYSTEM_STATUS_3: "Monitoring a sensor",
  METERSYSTEM_STATUS_176: "Pump off",
  METERSYSTEM_STATUS_177: "Pump on",
  METERSYSTEM_STATUS_208: "Pump fail to lock contactor",
  METERSYSTEM_STATUS_209: "On pause time",
  METERSYSTEM_STATUS_211: "Off due to power failure",
  METERSYSTEM_STATUS_212: "Battery level below 10V",
  METER_SYSTEMS: "Meter Systems:",
  DEVICES_CONTROLLER_NOT_FOUND: "Controller not found",
  DEVICES_ANALYSIS: "Analysis:",
  DEVICES_STATUS_COLOR_NOT_FOUND: "Status color not found",
  DEVICE: "Device:",
  DEVICE_FOUND: "Found",
  DEVICE_NOT_FOUND: "Not found",
  DEVICE_CENTRAL: "Central",
  DEVICE_LINE_VERY_STRONG: "Very strong",
  DEVICE_LINE_STRONG: "Strong",
  DEVICE_LINE_MODERATE: "Moderate",
  DEVICE_LINE_WEAK: "Weak",
  DEVICE_LINE_ERROR: "Error",
  DEVICE_LINE: "Line:",

  ICROP_FORM_NO_ACCESS:
    "I looks like you don't have access to this functionality yet.",
  ICROP_FORM_SELECT_A_FARM: "Select a farm:",
  EDIT_FARM_IMPORT_NATIONAL_HOLIDAYS: "Import National Holidays",
  PAGE_TRY_AGAIN: "Try Again",
  EDIT_PIVOT_LAT_AND_LONG: "Latitude and Longitude",
  PAUSE_TIME_WITHOUT_CONFIG: "Pause Time not enabled",
  WEEK_OF_THE_DAY: "Week of the day",
  BASIC_INFO_BOX_FILTERED_CHARTS_DATES_INFO:
    "This table has no data for the date range.",

  BASIC_INFO_BOX_INFORMATION_CALCULATED:
    "Information is being calculated for the data that already exists in the system. This can take a long time.",
  CROP_COTTOM: "Cottom",
  CROP_RICE: "Rice",
  CROP_OAT: "Oat",
  CROP_POTATO: "Potato",
  CROP_SWEET_POTATO: "Sweet Potato",
  CROP_SUGAR_CANE: "Sugar Cane",
  CROP_BARLEY: "Barley",
  CROP_PEA: "Pea",
  CROP_BEAN: "Bean",
  CROP_MILLET: "Millet",
  CROP_CORN: "Millet",
  CROP_SWEET_CORN: "Sweet Corn",
  CROP_POPCORN: "Popcorn",
  CROP_CORN_SEEDS: "Corn Seeds",
  CROP_SILAGE_CORN: "Silage Corn",
  CROP_PASTURE: "Pasture",
  CROP_SOY: "Soy",
  CROP_SOY_SEEDS: "Soy Seeds",
  CROP_SORGHUM: "Sorghum",
  CROP_SORGHUM_SEEDS: "Sorghum Seeds",
  CROP_TOMATO: "Tomato",
  CROP_WHEAT: "Wheat",

  ORBITAL_SENSOR: "Orbital Sensor",
  ANNOUNCEMENT_SYSTEM_TITLE: "Announcement",

  PRESSURE_DELAY_INPUT:
    "The Delay Time refers to the amount of time after the pivot has pressurized. There is a waiting time for the pressure to reach the last tower, so the pivot can start moving.",

  PRESSURE_SENSOR_RANGE_MIN:
    "The minimum value indicates the pressure range that the device must maintain. Below the minimum the pivot is stopped.",

  PRESSURE_SENSOR_RANGE_MAX:
    "The maximum value indicates the pressure range that the device must maintain. Above the maximum the pivotis stopped.",

  SENSOR_SCALE_END:
    "The Sensor's Scale refers to the maximum pressure it can measure.",

  GET_NEW_STREAM: "Searching last update",

  ADD_SENSOR: "Add Sensor",

  WATER_LEVEL_SENSOR: "Level Sensor",

  TRIGGER_RULE: "Trigger Rule",

  CHART: "Chart",

  PIVOT_UNDER_MAINTENANCE: "Pivot under maintenance",

  GET_NEW_STREAM_ERROR: "Error while searching last update",
  PIVOT_MANAGEMENT: "Pivot Management",

  CHOOSE_ACCUMULATED_VOL_PERIOD: "Choose the information period:",

  LATEST_INSTANT_FLOW: "Latest instant flow rate",

  APPROXIMATE_USAGE_TIME: "Estimated usage time",

  APPROXIMATE_USAGE_TIME_ABBREVIATED: "Estimated usage time",

  ACCUMULATED_VOLUME: "Total accumulated volume",

  HOURS_24: "24 HOURS",

  HOURS_48: "48 HOURS",

  HOURS_72: "72 HOURS",

  WEEK_1: "1 WEEK",

  MONTH_1: "1 MONTH",

  FLOW_BY_TIME_CHART: "Flow by time chart",

  MONTHLY_WATER_ACTION_CONFIRMATION:
    "The Monthly Water Limit has been exceeded, are you sure you want to continue?",

  ATENTION: "Attention",
  CONTINUE: "Continue",
  SEGMENT_FINAL_ANGLE: "Segment End Angle",
  WORKING_ANGLE: "Pivot Angle Range",
  HARDWARE: "Hardware",
  DAILY_RAINING: "of rain today",
  PLUVIOMETER_HISTOGRAM: "Histogram",
  IRRIGATION_INCREMENTED_DUE_TO_RAIN:
    "Irrigation speed incremented to ${}% due to raining.",
  RAINING_NOW: "Raining now",
  ICROP_DOC_URL:
    "https://irricontroldev.s3.us-east-2.amazonaws.com/icrop_en.pdf",
  ICROP_WELCOME_PANEL_TITLE: "Welcome to the Pivot Management Panel!",
  ICROP_WELCOME_PANEL_P1:
    "Here you have access to pivot irrigation recommendations, and information about the crop and the soil.",
  ICROP_WELCOME_PANEL_P2: "Download the document below or access the panel.",
  ICROP_DOC_DOWNLOAD: "Download document",
  ICROP_ACCESS_PANEL: "Access the panel",
  WEEK_ACCUMULATED_RAIN: "Week's accumulated rain",
  ACCUMULATED_MILLIMETERS: "Accumulated millimeters",
  BASE_CHANGE_CLOCK_ALERT:
    "The clock of the devices below will be changed to the current time",
  EQUIPMENT_ID_MISSING:
    "Equipment not found in the request. Please, contact support team",
  RADIO_ID_NOT_FOUND_IN_DATABASE: "Radio not found",
  RADIO_ID_ALREADY_USED: "Radio is already in use",
  SEND_RADIO_CHANGE_TO: "Send radio update to",
  NO_EQUIPMENT: "None",
  ALL_EQUIPMENTS: "All Equipments",
  SEND: "Send",
  CHANGE_BASE_RADIO_MODAL_TITLE: "Change Central Radio",
  USER_PERMISSION: "No permission",
  USER_PERMISSION_READ: "Viewer",
  USER_PERMISSION_OPERATOR: "Operator",
  USER_PERMISSION_CONFIGURATOR: "Configurator",
  SETORIAL_ENDGUN_ERROR:
    "This pivot is sectorial. Configurations over the red area won't have any effects",
  EDIT_PIVOT_V5_DAYS_OF_THE_WEEK_DESCRIPTION: "Description",
  EDIT_PIVOT_V5_DAYS_OF_THE_WEEK_DESCRIPTION_TEXT:
    "Pause Time are time ranges in which the pivot should not operate on wet mode to avoid use at critical times, for example, when the cost of the electrical energy is higher.",
  EDIT_PIVOT_V5_PIVOT_SEGMENTS_TEXT:
    "It is common to segment some pivots in two or more parts due to the planting variety, crop cicle or even to facilitate the operation management. With this feature, you can segment the pivots and bring more control over its operation.",
  PIVOT_CONFIG_V5_FIELD_ENDGUN_TEXT:
    "For pivots that has an end gun installed on the last tower, it is possible to segment the activation preventing it from turning on at areas where it shouldn't irrigate, such as roads, houses, etc.",
  PIVOT_CONFIG_V5_AUTORREVERSION_TEXT:
    "Autoreversion is an equipment's feature that makes it possible for the pivot to reach the end of its course and automatically come back, operating. The pivot comeback happens when it reaches a physical obstacle, called end of course - available for SmartConnect panels - or when it reaches its final angle of work.",
  WORKING_ANGLE_LABEL: "What is the maximum angle that this pivot reaches?",
  PASSWORD_RECOVERY_INSERT_EMAIL:
    "Insert your email address so we can send you a accout recovery email.",
  VALID_UNTILL: "Valid until",
  NO_EQUIPMENTS: "There are no equipments",
  UPDATE_USER_TO_ADMINISTRATOR_SUCCESS:
    "Administrator user added successfully.",
  CENTRAL_FOUND: "Central found",
  CONTROLLER_FOUND: "Controller found",
  PUMP_FOUND: "Pump found",
  REPEATER_FOUND: "Repeater found",
  FIELD_CAPACITY: "Field Capacity",
  FULL_MOISTURE: "Full Moisture",
  MODERATE_MOISTURE: "Moderate Moisture",
  LIMIT_POINT: "Limit Point",
  MODERATE_WATER_STRESS: "Moderate Water Stress",
  WATER_STRESS: "Water Stress",
  APPROPRIATE: "Appropriate",
  INNAPPROPRIATE: "Innappropriate",
  SCALE: "Scale",
  BASIC_INFO_BOX_RAINFALL: "Rain",
  SECTOR_PIVOT_REASON_FALSE: "No",
  HISTORIC_CONFIG_PIVOT_OLD_DATE_TIME: "Date and Time: ${}",
  HISTORIC_CONFIG_PIVOT_OLD_HOUR_RANGE_MAX: "Final Hour Range: ${}",
  HISTORIC_CONFIG_PIVOT_OLD_HOUR_RANGE_MIN: "Start Hour Range: ${}",
  HISTORIC_CONFIG_PIVOT_OLD_POWER_RANGE_MAX: "Maximum Voltage Range: ${}",
  HISTORIC_CONFIG_PIVOT_OLD_POWER_RANGE_MIN: "Minimum Voltage Range: ${}",
  HISTORIC_CONFIG_PIVOT_OLD_FLOW_RATE: "Flow rate: ${}",
  HISTORIC_CONFIG_PIVOT_OLD_STABILIZATION_TIME:
    "Return time after power fault: ${}",
  HISTORIC_CONFIG_PIVOT_OLD_PRESSURIZATION_TIME: "Pump time: ${}",
  HISTORIC_CONFIG_PIVOT_OLD_VELOCITY_TOWER: "Last tower speed: ${}",
  HISTORIC_CONFIG_PIVOT_OLD_IRRIGATED_AREA: "Irrigated area: ${}",
  HISTORIC_CONFIG_PIVOT_OLD_IRRIGATED_RADIUS: "Irrigated radius: ${}",
  HISTORIC_CONFIG_PIVOT_OLD_RADIUS_TOWER: "Radius to the last tower: ${}",
  HISTORIC_CONFIG_PIVOT_OLD_FINAL_REFERENCE: "Final Reference:\n ${}, ${}",
  HISTORIC_CONFIG_PIVOT_OLD_INITIAL_REFERENCE: "Initial Reference:\n ${}, ${}",
  HISTORIC_CONFIG_PIVOT_OLD_CENTER: "Center:\n ${}, ${}",
  HISTORIC_CONFIG_PIVOT_OLD_SECTOR_PIVOT: "Sectorial Pivot: ${}",
  SECTOR_PIVOT_REASON_TRUE: "Yes",
  HISTORIC_CONFIG_PIVOT_OLD_END_GUN: "Endgun: ${}",
  HISTORIC_CONFIG_PIVOT_OLD_NAME: "Name: ${}",
  HISTORIC_CONFIG_PIVOT_DRIVE: "${}",
  HISTORIC_CONFIG_PIVOT_PINNED: "Favorite Configurations",
  HISTORIC_IRPD_ACTION_USER: "User: ",
  MEASURE_SCALE_UNIT_METER: "m³",
  MEASURE_SCALE_UNIT_LITER: "L",
  MEASURE_SCALE_100: "100",
  MEASURE_SCALE_10: "10",
  MEASURE_SCALE_1: "1",
  HISTORIC_IRPD_STREAM_HOURMETER: "Hourmeter: ",
  HISTORIC_IRPD_ACTION_CREATED: "${} ago | ${}",
  NUMBER_OF_BARS_12: "12",
  NUMBER_OF_BARS_24: "24",
  NUMBER_OF_BARS_30: "30",
  EDIT_FARM_WEEK: "Week",
  CREATE_DEVICE_MODAL_PROTOCOL_5: "G2",
  CREATE_DEVICE_MODAL_PROTOCOL_4_1: "G1",
  EDIT_FARM_IRPD_INFOS_RECALCULATE_TEXT:
    "Press the button to recalculate the reports to the farm's pumps.",
  EDIT_FARM_IRPD_INFOS_ENABLE_TEXT:
    "Press the button to enable the reports to the farm's pumps.",
  SELECTED_FARM_LIST: "List",
  SWAP_RADIO: "Switch Pivot Radios",
  EDIT_RADIO: "Edit radio",
  HISTORIC_PIVOT_ACTION_BLADE: "Percentage: ",
  HISTORIC_PAINEL_TYPE_CONTROLLER_UPDATE: "Update",
  HISTORIC_PAINEL_TYPE_COMMAND: "Command",
  PEAK_TIME_DISABLED: "Be aware: Pause Time disabled",
  TABLE_OF: "of",
  FORGET_PASSWORD_OPTION_PASSWORD: "I forgot my password",
  SIGN_UP_FIELD_USERNAME_CHECK:
    "Use only only letters and numbers. 8 to 20 characters.",
  SIGN_UP_FIELD_USERNAME_ALREADY_EXIST: "Nome de usuário indisponível",
  SIGN_UP_FIELD_REQUIRED: "This field is required.",
  CREATE_FARM_LABEL: "Create Farm",
  SELECTED_FARM_MAP: "Map",
  RADIO: "Radio",
  SWAP: "Swap",
  SIGN_UP_FIELD_PASSWORD_CHECK:
    "Make sure your password has 8+ characters and includes numbers.",
  NO_COMPATIBLE_RADIO_FOUND: "No compatible radio found.",
  SWAP_RADIO_OF: "Swap radio of ",
  SELECT_TO_SWAP_RADIO_OF: "Select the pivot to swap radios of ",
  HISTORIC_CONFIG_PIVOT_DRIVE_MANUAL: "Manual",
  NO_NOTIFICATIONS: "You don't have any notifications.",
  SAVE_RADIO: "Save radio",
  CANCEL: "Cancel",
  CONTROL: "Controller",
  PUMP_CABLE: "Pump Cable",
  HISTORIC_IRPD_ACTION_NOW: "Immediately",
  HISTORIC_PAINEL_TYPE_GPS_STREAM: "GPS Update",
  HISTORIC_PAINEL_NO_GPS: "No GPS Info",
  HISTORIC_PAINEL_TYPE_UPDATE: "Update",
  HISTORIC_PANEL_STREAM_V5_STOP_PIVOT: "Stop Pivot",
  HISTORIC_PAINEL_TYPE_MAINTENANCE: "Maintenance",
  HISTORIC_IRPD_ACTION_START_AT: "Start: ",
  HISTORIC_IRPD_ACTION_END_AT: "End: ",
  CREATE_DEVICE_MODAL_IRPD_SOON: "Coming soon",
  REDO_CONVENTIONAL_ACTION: "Load last irrigation",
  PIVOT_ACTION_VALUE_MODE_DRY: "Dry",
  UPDATING: "Updating...",
  SIGN_UP_FIELD_PASSWORD_REPEAT_CHECK: "The passwords differ.",
  EDIT_FARM_CONTACT: "Contact",
  CREATE_DEVICE_MODAL_PIVO_CHOOSER: "Pivot automation (SmartConnect and Nexus)",
  CREATE_DEVICE_MODAL_BAUER_PIVO_CHOOSER:
    "Bauer Pivot (SmartTouch and Universal Pro)",
  CREATE_DEVICE_MODAL_PIVO_MONITOR_CHOOSER: "Pivot Monitor",
  CREATE_DEVICE_MODAL_IRPD_CHOOSER: "Remote Pump Control",
  CREATE_DEVICE_MODAL_PUMP_PIVOT_CODE: "Pump Radio (Spoti)",
  CREATE_DEVICE_MODAL_PUMP_IRPD_CODE: "Pump Radio (Irripump)",
  HISTORIC_PANEL_LABEL_BY: "By",
  HISTORIC_PAINEL_TYPE_PUMP_UPDATE: "Pump Update",
  HISTORIC_CONFIG_PIVOT_V5_LANGUAGE: "Device Language: ${}",
  HISTORIC_CONFIG_PIVOT_V5_VOLTAGE_MIN: "Minimum Voltage: ${}",
  HISTORIC_CONFIG_PIVOT_V5_VOLTAGE_MAX: "Maximum Voltage: ${}",
  HISTORIC_CONFIG_PIVOT_V5_VOLTAGE_STABLE: "Stable Voltage: ${}",
  HISTORIC_CONFIG_PIVOT_V5_WORKING_ANGLE: "Sector: ${}",
  HISTORIC_CONFIG_PIVOT_V5_PUMP_TIMEOUT: "Pump Timeout: ${}",
  HISTORIC_CONFIG_PIVOT_V5_PUMP_PRESS_DELAY: "Pump Pressure Delay: ${}",
  HISTORIC_CONFIG_PIVOT_V5_PUMP_READ_PRESSURE: "Pressure Read: ${}",
  HISTORIC_CONFIG_PIVOT_V5_PUMP_PRESS_SWITCH: "Pressure Switch: ${}",
  HISTORIC_CONFIG_PIVOT_V5_PUMP_SOFT_START_TIME: "Soft Start Time: ${}",
  HISTORIC_CONFIG_PIVOT_V5_PUMP_PRESS_SWITCH_MAX_RANGE:
    "Pressure Sensor Maximum Value: ${}",
  HISTORIC_CONFIG_PIVOT_V5_PUMP_PRESS_SWITCH_MIN_RANGE:
    "Pressure Sensor Minimum Value: ${}",
  HISTORIC_CONFIG_PIVOT_V5_PAUSE_TIME_START: "Pause Time Start: ${}",
  HISTORIC_CONFIG_PIVOT_V5_PAUSE_TIME_END: "Pause Time End: ${}",
  HISTORIC_CONFIG_PIVOT_V5_POTENCY: "Pump Power: ${}",
  HISTORIC_CONFIG_PIVOT_V5_POWER_DELAY: "Power Delay: ${}",
  HISTORIC_CONFIG_PIVOT_V5_CANAL_AVERAGE_POWER: "Canal Average Power: ${}",
  HISTORIC_CONFIG_PIVOT_V5_PILOT_AVERAGE_POWER: "Pivot Average Power: ${}",
  HISTORIC_CONFIG_PIVOT_V5_ENDGUN_ANGLE_START: "Start Angle: ${}",
  HISTORIC_CONFIG_PIVOT_V5_ENDGUN_ANGLE_END: "End Angle: ${}",
  HISTORIC_CONFIG_PIVOT_V5_FIELD_ENDGUN_ANGLE_MODE: "Mode: ",
  HISTORIC_CREATED_AT: "Sent on: ",
  HISTORIC_IRPD_ACTION_NEVER: "Never",
  HISTORIC_IRPD_ACTION_START: "Turn on",
  HISTORIC_IRPD_ACTION_STOP: "Turn off",
  PAINEL_STREAM_RESUMED_REASON_242: "Unexpected stop",
  PAINEL_STREAM_RESUMED_REASON_246: "Stopped - unstable power",
  PAINEL_STREAM_RESUMED_REASON_248: "Stopped - low pressure",
  PAINEL_STREAM_RESUMED_REASON_250: "Stopped - pressure timout",
  PAINEL_STREAM_RESUMED_REASON_251: "Stopped - thermal relay",
  PAINEL_STREAM_RESUMED_REASON_253: "Stopped - power fault",
  PAINEL_STREAM_RESUMED_REASON_255: "Safety",
  IRPD_ACTION_VALUE_MODE_WET: "Wet",
  IRPD_ACTION_VALUE_MODE_DRY: "Dry",
  IRPD_STATUS_ON_BY_SENSOR: "Pump turned on by sensor",
  IRPD_STATUS_WAITING_AFTER_POWER_FAILURE:
    "Waiting some time after power failure",
  IRPD_STATUS_HOURMETER_CORRUPTED: "Hourmeter not accounted",
  IRPD_STATUS_INCORRECT_SCHEDULE: "Incorrect schedule",
  IRPD_STATUS_END_SCHEDULE_REACHED: "Pump off after schedule finished",
  CREATE_DEVICE_MODAL_POTENCY_PLACEHOLDER: "Pump Power",
  CREATE_DEVICE_MODAL_PROTOCOL: "Version",
  FARM_FIELD_WATER_BILLING_DATE: "Water Invoice Day",
  CREATE_USER_MODAL_USERNAME_OR_EMAIL: "Name, Username or Email",
  SEGMENT_IRRIGATION_CONFIRMATION_BUTTON_FORWARD:
    "Do you confirm that you want to turn on the pivot in FORWARD with these settings?",
  SEGMENT_IRRIGATION_CONFIRMATION_BUTTON_REVERSE:
    "Do you confirm that you want to turn on the pivot in REVERSE with these settings?",
  MEASURE_SCALE: "Measure Scale",
  MEASURE_SCALE_UNIT: "Measure Scale Unit",
  VOLTAGE: "Voltage",
  HISTORIC_CONFIG_PIVOT_UNPINNED_SUCCESSFULLY:
    "Configuration removed from favorites",
  EDIT_FARM_ERROR_RADIO_ID_NOT_FOUND: "Central radio not valid",
  EDIT_FARM_ERROR_RADIO_ID_ALREADY_USED: "Central radio already been used",
  CREATE_DEVICE_MODAL_ERROR_CONTROL_NOT_VALID: "Invalid controller radio",
  CREATE_DEVICE_MODAL_ERROR_CONTROL_ALREADY_USED:
    "Controller radio already used",
  CREATE_DEVICE_MODAL_ERROR_GPS_NOT_VALID: "Invalid GPS radio",
  CREATE_DEVICE_MODAL_ERROR_GPS_ALREADY_USED: "GPS radio already used",
  CREATE_DEVICE_MODAL_ERROR_PUMP_NOT_VALID: "Invalid pump radio",
  CREATE_DEVICE_MODAL_ERROR_PUMP_ALREADY_USED: "Pump radio already used",
  CREATE_USER_MODAL_404: "User does not exist",
  CREATE_USER_MODAL_INVALID: "Invalid username or email",
  EDIT_PERMISSIONS_ADD_ADM_ERROR: "Error adding as administrator",
  RADIO_CHANGED_SUCCESS: "Radio changed successfully",
  RADIO_SWAP_SUCCESS: "Radios swapped sucessfully",
  HISTORIC_CONFIG_PIVOT_PINNED_SUCCESSFULLY: "Configuration added to favorites",
  HISTORIC_CONFIG_PIVOT_PINNED_ERROR: "Error try again later",
  IRRIGATION_BOX_CONVENTIONAL_REDO_ERROR_MSG:
    "There is no previous irrigation configuration",
  FARM_DISABLED:
    "Farm disabled. Access is granted only after payment identification of annual fees.",
  FARM_DISABLED_CONTACT: "Get in touch with ",
  IRPD_STATUS_OPERATING: "Operating",
  IRPD_STATUS_SCHEDULE_EXPIRED: "Schedule Expired",
  CENTRAL_UPDATE: "Central Update",
  SHOW_CENTRAL_STATUS: "Show Central Updates",
  CREATE_USER_MODAL_EMAIL_NOT_FOUND: "Email not found",
  CREATE_USER_MODAL_SEND_INVITE: "Send Invite",
  CREATE_USER_MODAL_KEEP_TYPING: "Keep typing...",
  CREATE_USER_MODAL_FIND_USER_ERROR:
    "An error occurred while searching for user",
  SIGNAL_STRENGTH: "Signal Strength (dbm)",
  ID_RADIO: "ID (Radio)",
  LOGS: "Logs",
  POWER_RANGE_PEAK_ACRONYM: "PH",
  POWER_RANGE_OFF_PEAK_ACRONYM: "OPH",
  POWER_RANGE_REDUCED_ACRONYM: "NT",
  EDIT_FARM_EDIT_USER: "Edit User",
  EDIT_FARM_NO_ADMIN: "No admin registered",
  USER_PERMISSION_CUSTOMIZED: "Custom",
  PIVOT_SEGMENT_CONFIG_REVERSE: "Reverse Config",
  PIVOT_SEGMENT_CONFIG_FORWARD: "Forward Config",
  PIVOT_SEGMENT_BEGIN_REVERSE: "Start Reverse",
  PIVOT_SEGMENT_BEGIN_FORWARD: "Start Forward",
  SELECTED_PIVOT_ANGLE_MODAL_FAILURE: "Failed to send Irrigation by angle",
  EDIT_FARM_ANGLE_RANGES: "Ranges",
  FARM_PIVOT_IRRIGATION_ANGLE_ENDGUN_MESSAGE:
    "* Endgun activation is done automatically according to pivot configuration.",
  CREATE_DEVICE_MODAL_TYPE: "Type",
  CREATE_DEVICE_MODAL_TYPE_MONITORED_CENTRAL: "monitored central pivot",
  CREATE_DEVICE_MODAL_TYPE_MONITORED_LINEAR: "monitored linear pivot",
  CREATE_DEVICE_MODAL_PANEL_TYPE: "Panel Type",
  CREATE_DEVICE_MODAL_NEXUS: "Nexus",
  CREATE_DEVICE_MODAL_SMARTCONNECT: "SmartConnect",
  CREATE_DEVICE_MODAL_BRAND_MODEL: "Brand model",
  CREATE_BRAND_MODEL_BAUER: "Bauer",
  CREATE_BRAND_MODEL_IRRIGABRAS: "Irrigabras",
  CREATE_BRAND_MODEL_VALLEY: "Valley",
  CREATE_BRAND_MODEL_LINDSAY: "Lindsay",
  CREATE_BRAND_MODEL_CARBORUNDUM: "Carborundum",
  CREATE_BRAND_MODEL_KREBS: "Krebs",
  CREATE_BRAND_MODEL_FOCKINK: "Fockink",
  CREATE_BRAND_MODEL_REINKE: "Reinke",
  CREATE_BRAND_MODEL_OTHER: "Other",
  CREATE_DEVICE_MODAL_UNIT: "Potency Unit",
  CREATE_DEVICE_MODAL_KW: "kW",
  CREATE_DEVICE_MODAL_HP: "hp",
  CREATE_DEVICE_POTENCY_PLACEHOLDER: "Potency",
  CREATE_DEVICE_MODAL_PERFORMANCE_PERCENT: "Performance",
  CREATE_DEVICE_MODAL_CONVERTED_POTENCY: "Converted Potency",
  ENABLE_PLUVIOMETER: "Enable pluviometer",
  DISABLE_PLUVIOMETER: "Disable pluviometer",
  PLUVIOMETER_BOX_ENABLE_DIALOG: "Are you sure you want ${}",
  CREATE_BRAND_MODEL_INSERT: "Input Brand Model",
  CREATE_DEVICE_MODAL_ERROR_REPEATER_NOT_VALID: "Invalid Repeater radio",
  CREATE_DEVICE_MODAL_ERROR_REPEATER_ALREADY_USED:
    "Repeater radio already used",
  CREATE_DEVICE_MODAL_METER: "Meter System",
  CREATE_DEVICE_MODAL_SENSOR: "Sensor",
  CREATE_DEVICE_MODAL_SENSOR_5: "Sensor 5mWC",
  CREATE_DEVICE_MODAL_SENSOR_3: "Sensor 10mWC",
  CREATE_DEVICE_MODAL_SENSOR_2: "Sensor 20mWC",
  CREATE_DEVICE_MODAL_SENSOR_7: "Sensor 100mWC",
  CREATE_DEVICE_MODAL_IMETER: "IMeter Radio",
  CREATE_DEVICE_MODAL_ERROR_IMETER_NOT_FOUND: "Invalid Meter System radio",
  CREATE_DEVICE_MODAL_ERROR_IMETER_ALREADY_USED: "IMeter radio already used",
  CREATE_DEVICE_MODAL_ERROR_IRPD_NOT_FOUND: "Invalid Pump radio",
  CREATE_DEVICE_MODAL_ERROR_IRPD_ALREADY_USED: "Pump radio already used",
  CREATE_DEVICE_MODAL_ERROR_INVALID_HARDWARE_ID: "Harware ID is not available",
  SEND_COMMAND_ERROR: "Error sending command.",
  CREATE_DEVICE_MODAL_ENERGY_TYPE: "Type",
  CREATE_DEVICE_MODAL_ENERGY_TYPE_SOLAR: "Solar",
  CREATE_DEVICE_MODAL_ENERGY_TYPE_BIVOLT: "Dual voltage",
  PIVOT_STATUS_NO_COMMUNICATION_INFO_POPOVER:
    "Equipment without communication with the platform.",
  REPORT_TABLE_DISTANCE: "Distance",
  PRIVACY_POLICY: "Privacy Policy",
  TERMS_AND_CONDITIONS: "Terms and Conditions",
  ANNOUNCEMENT_SYSTEM_TITLE_TERMS_AND_CONDITIONS:
    "Irricontrol's Terms and Conditions",
  TERMS_AND_CONDITIONS_CHECKBOX:
    "I declare that I have read and agree to the Irricontrol Terms and Conditions of Use.",
  TERMS_AND_CONDITIONS_LOGIN_1:
    "By using the Irricontrol platform, you declare that you have read and accepted the",
  TERMS_AND_CONDITIONS_LOGIN_2: "and the",
  PAGE_NO_ACCESS_TO_FARMS: "You do not have permission to access this page.",
  PAGE_NO_INTERNET:
    "Could not connect to the internet, please check your network access.",
  PAGE_PRIVACY_POLICY_NOT_ACCEPTED:
    "You have not yet accepted the Irricontrol Terms and Conditions of Use.",
  PAGE_CODE_404: "We couldn't find the page you requested. Please try again.",
  PAGE_DEFAULT: "An unexpected problem has occurred, we're sorry.",
  BACK_TO_INICIAL_PAGE: "Back to home page",
  BILLING_TAB_REDIRECT: "Technical Support",
  PIVOT_CONFIG_FIELD_RADIUS_TOOLTIP:
    "Distance from the center to the last turret.",
  PIVOT_CONFIG_V5_FIELD_SPEED_TOOLTIP: "Speed from last tower to 100%.",
  PIVOT_CONFIG_V5_FIELD_VOLTAGE_MIN: "Minimum operating voltage",
  PIVOT_CONFIG_V5_FIELD_VOLTAGE_MAX: "Maximum operating voltage",
  PIVOT_CONFIG_V5_FIELD_LIMIT_TIME_TOOLTIP:
    "If the pivot is operating outside the voltage limits for a period longer than the set time, the pivot action is stopped.",
  SELECTED_ALERT_SELECT_PIVOT_VERSION_TOOLTIP: "Choose the pivot version:",
  IRRIGATION_END_DATE_PREDICTION_TOOLTIP:
    "The end prediction considers downtime due to peak hours.",
  BASIC_BAR_TOOLTIP_HP: "Peak hours",
  BASIC_BAR_TOOLTIP_HFP: "Off-Peak Hours",
  BASIC_BAR_TOOLTIP_HR: "Off-Peak Hours",
  PIVOT_CONFIG_V5_LANGUAGE_TOOLTIP: "Language for use in the panel controller.",
  EDIT_IRPD_FIELD_ENERGY_TIME: "Power time",
  EDIT_IRPD_FIELD_ENERGY_TIME_TOOLTIP:
    "Return time to continue executing the command after a power outage.",
  PIVOT_CONFIG_V5_FIELD_POWER_DELAY: "Power time",
  PIVOT_CONFIG_V5_FIELD_POWER_DELAY_TOOLTIP:
    "Return time to continue executing the command after a power failure.",
  MEASUREMENT_MAX_VALUE: "Sensor scale",
  MEASUREMENT_MAX_VALUE_TOOLTIP:
    "Refers to the time limit for the pivot to pressurize. If this time is exceeded without reaching pressure, the pump is shut down for safety.",
  PIVOT_CONFIG_V5_FIELD_PUMP_READ_PRESSURE_TOOLTIP:
    "Equipment for gauging the pressure at the center of the pivot.",
  PIVOT_CONFIG_V5_FIELD_PUMP_PRESS_SWITCH_TOOLTIP:
    "If the pivot is operating outside the pressure limits for a period longer than the set period, the pivot action is stopped.",
  PIVOT_CONFIG_V5_FIELD_ENDGUN_ANGLE_MODE: "Stop condition",
  PIVOT_CONFIG_V5_FIELD_ENDGUN_ANGLE_MODE_TOOLTIP:
    '"By value" the pivot command is stopped after reaching the threshold set in the "Value" field. "By decrement" the pivot increases speed to compensate for the applied blade.',
  EDIT_PLUVIOMETER_MM_TO_STOP: "Value",
  EDIT_PLUVIOMETER_MM_TO_STOP_TOOLTIP:
    "Rainfall threshold, in millimeters, set in a given area.",
  PIVOT_CONFIG_V5_FIELD_SENSOR_SCALE_END: "Sensor scale",
  PIVOT_CONFIG_V5_FIELD_SENSOR_SCALE_END_TOOLTIP:
    "Value referring to the scale of the rain gauge used.",
  PIVOT_CONFIG_V5_FIELD_PUMP_TIMEOUT: "Pump timeout",
  PRESSURE_TIMEOUT_INPUT:
    "Refers to the time limit for the pivot to pressurize. If this time is exceeded without reaching pressure, the pump is shut down for safety.",
  PIVOT_CONFIG_V5_FIELD_PUMP_PRESS_SWITCH_MIN_RANGE: "Minimum sensor value",
  PIVOT_CONFIG_FIELD_SETORIAL: "Sector pivot",
  PIVOT_CONFIG_FIELD_SETORIAL_TOOLTIP:
    "Pivot that has a working angle other than 360°.",
  PIVOT_CONFIG_FIELD_SPEED: "Speed of the last tower",
  PIVOT_CONFIG_FIELD_SPEED_TOOLTIP: "Speed of the last turret at 100%.",
  PIVOT_CONFIG_FIELD_POWER_TIME: "Return time after power failure",
  PIVOT_CONFIG_FIELD_POWER_TIME_TOOLTIP:
    "Time that a command remains valid after a power failure.",
  PIVOT_CONFIG_FIELD_PUMP_TIME: "Pressurization time",
  PIVOT_CONFIG_FIELD_PUMP_TIME_TOOLTIP:
    "Refers to the time limit for the pivot to pressurize. If this time is exceeded without reaching pressure, the pump is shut down for safety.",
  PIVOT_CONFIG_FIELD_POWER_RANGE: "Working voltage",
  PIVOT_CONFIG_FIELD_POWER_RANGE_TOOLTIP:
    "Feature that prevents the equipment from operating outside the minimum and maximum voltages.",
  PIVOT_CONFIG_FIELD_POWER_RANGE_MIN: "Minimum operating voltage",
  PIVOT_CONFIG_FIELD_POWER_RANGE_MAX: "Maximum operating voltage",
  ENERGY_TIME_DESCRIPTION:
    "In this function you can set the time in minutes that the pump must wait to restart after a power failure.",
  PIVOT_CONFIG_V5_FIELD_GP_REFERENCE_TOOLTIP:
    "Location of the last tower at the 0° point.",
  PIVOT_CONFIG_V5_FIELD_GP_CENTER_TOOLTIP:
    "Location of the center of the pivot.",
  PIVOT_CONFIG_DEVICE_FIELD_GP_CENTER: "Device Location",
  PIVOT_CONFIG_V5_FIELD_NAME: "Pivot name",
  PIVOT_CONFIG_V5_FIELD_LANGUAGE: "Device Language",
  PIVOT_CONFIG_V5_FIELD_AREA: "Irrigated area",
  PIVOT_CONFIG_FIELD_AREA: "Watered Area",
  PIVOT_CONFIG_V5_FIELD_LIMIT_TIME: "Timeout",
  PIVOT_CONFIG_V5_FIELD_VOLTAGE_LIMIT_TIME: "Timeout",
  PIVOT_CONFIG_V5_TENSION_LIMIT_TOOLTIP:
    "Feature that prevents the equipment from operating outside the minimum and maximum voltages for longer than the threshold time.",
  PIVOT_CONFIG_V5_FIELD_CLOCK_LABEL: "Equipment clock",
  PIVOT_CONFIG_FIELD_POTENCY: "Pump power",
  PIVOT_CONFIG_FIELD_RTC: "Set Equipment Clock",
  PIVOT_CONFIG_FIELD_HOUR_RANGE: "Peak time",
  PIVOT_CONFIG_FIELD_HOUR_RANGE_MIN: "Initial peak time",
  PIVOT_CONFIG_FIELD_HOUR_RANGE_MAX: "Peak end time",
  PIVOT_CONFIG_FIELD_PLUVIOMETER_ENABLE: "Enable rain gauge",
  PIVOT_CONFIG_V5_FIELD_CLOCK_DATE: "Equipment Date",
  PIVOT_CONFIG_V5_FIELD_CLOCK_TIME: "Equipment date",
  EDIT_PIVOT_LABEL_POWER: "Pump power",
  PIVOT_CONFIG_V5_FIELD_PUMP_READ_PRESSURE: "Pressure reading",
  PIVOT_CONFIG_V5_FIELD_PUMP_PRESS_DELAY: "Delay time",
  PIVOT_CONFIG_V5_FIELD_PUMP_PRESS_SWITCH: "Unstable pressure switch time",
  PIVOT_CONFIG_V5_FIELD_PAUSE_TIME_STATUS_1: "Enable peak time 1",
  PIVOT_CONFIG_V5_FIELD_PAUSE_TIME_STATUS_2: "Enable Peak Time 2",
  PIVOT_CONFIG_V5_FIELD_ANGLE_START: "Start angle",
  PIVOT_CONFIG_V5_FIELD_ANGLE_END: "End angle",
  PIVOT_CONFIG_V5_FIELD_ENDGUN_ANGLE_START: "Start Angle",
  PIVOT_CONFIG_V5_FIELD_ENDGUN_ANGLE_END: "End angle",
  PIVOT_CONFIG_V5_FIELD_SEGMENTS_ANGLE_START: "Start angle",
  PIVOT_CONFIG_V5_FIELD_SEGMENTS_ANGLE_END: "End angle",
  BY_ANGLE_IRRIGATION_FIELD_ANGLE_START: "Start Angle",
  BY_ANGLE_IRRIGATION_FIELD_ANGLE_END: "End angle",
  EDIT_PIVOT_V5_AUTOREVERSION_TIME: "Autoreversion timeout",
  EDIT_PIVOT_V5_AUTOREVERSION_TIME_TOOLTIP:
    "Time it takes for the pivot to stop its motion and change its direction.",
  EDIT_PLUVIOMETER_MODE_BY_VALUE: "By value",
  EDIT_PLUVIOMETER_MODE_DECREMENT: "By decrement",
  PIVOT_CONFIG_V5_VALUE_ENDGUN_MODE_ALWAYS_OFF: "Always off",
  PIVOT_CONFIG_V5_VALUE_ENDGUN_MODE_ALWAYS_ON: "Always on",
  PIVOT_CONFIG_V5_VALUE_ENDGUN_MODE_BY_SEGMENTS: "By switch",
  EDIT_PIVOT_V5_AUTOREVERSION_BY_SWITCH: "By end of stroke",
  SIMPLE_IRRIGATION_BY_ANGLE: "By angle",
  PIVOT_CONFIG_VALUE_ENDGUN_BOOSTER: "Booster pump",
  PIVOT_CONFIG_FIELD_ENDGUN: "Endgun",
  IRPD_FIELD_NAME: "Pump name",
  PIVOT_CONFIG_V5_FIELD_POTENCY: "Pump power",
  IRPD_MONTHLY_WATER_LIMIT: "Monthly water consumption limit",
  EDIT_IRPD_FIELD_RTCDATE: "Equipment clock",
  EDIT_IRPD_FIELD_HOUR_RANGE_MAX: "End of peak hour",
  EDIT_IRPD_FIELD_HOUR_RANGE_MIN: "Start of peak Time",
  EDIT_PIVOT_LABEL_POWER_SETTINGS: "Power Settings",
  MEASUREMENT_OFFSET: "Measurement offset",
  MEASUREMENT_OFFSET_TOOLTIP:
    "Setting value for probe measurements, approximating them to the real ones.",
  METERSYSTEM_FLOW_CURVE: "Residual Flow",
  METERSYSTEM_FLOW_CURVE_TOOLTIP:
    "When the level meter is installed in a river, the Irricontrol Platform provides the option to add the constants of the River Key Curve obtained through measurements in it. By means of this equation and the value measured at the level probe, the Irricontrol Platform calculates the residual flow rate shown in the Report on the home page of the analyzed measurement system.",
  PERMISSIONS_REGISTER_USER_INFO: "Permissions",
  PIVOT_CONFIG_DEVICE_FIELD_GP_CENTRAL: "Central Location",
  PIVOT_CONFIG_FIELD_REFERENCE_START_TOOLTIP:
    "Location of the last tower at point 0°.",
  PIVOT_CONFIG_FIELD_REFERENCE_END_TOOLTIP:
    "Location of the end angle of the pivot.",
  PIVOT_CONFIG_FIELD_CENTER_TOOLTIP: "Location of the center of the pivot.",
  NO_PERMISSION: "No Permission",
  NO_PERMISSION_DESCRIPTION:
    "In this permission, the user will not have access to the selected equipment. Thus, it will not appear on the Farm Home Screen.",
  VISUALIZER: "Visualizer",
  VIZUALIZER_DESCRIPTION:
    "In this permission, the user has access to the equipment and can view anything from reports to sent commands. However, the user will not be able to send commands to the equipment, make configuration edits to the equipment, and will not be able to access the Farm edits page.",
  OPERATOR: "Operator",
  OPERATOR_DESCRIPTION:
    "In this permission, the user has the same permissions as the Viewer, plus he can send commands to the equipment. However, the user cannot make edits to the equipment settings and cannot access the Farm edits page.",
  CONFIGURATOR: "Configurator",
  CONFIGURATOR_DESCRIPTION:
    "In this permission, the user has the same permissions as the Operator, in addition, he will be able to make edits to the equipment settings. However, he will not be able to access the Farm edits page.",
  ADMINISTRATOR: "Administrator",
  ADMINISTRATOR_DESCRIPTION:
    "Finally, as Administrator, the user will have all the permissions of the Configurator. He will have access to reports, command history, sending new commands, edits to all equipment information, Farm edits, and will also be able to add and manage users.",
  PERMISSION_INFO: "Permissions Information",
  HISTORIC_PIVOT_GPS_V5_SENSOR: "${} | ${} | ${} - ${} | ${}",
  HISTORIC_PIVOT_GPS_V5: "${} | ${} | ${} | ${}",
  VALUE: "${}",
  VALUE_HOUR_AND_MINUTE: "${}h ${}min",
  VALUE_AND_UNIT_HOUR: "${}h",
  VALUE_AND_UNIT_BAR: "${} bar",
  VALUE_AND_UNIT_MIN: "${} min",
  VALUE_AND_UNIT_DEGREES: "${}°",
  VALUE_AND_UNIT_PERCENT_MILIMETERS: "${}% - ${} mm",
  VALUE_AND_UNIT_MILIMETERS: "${} mm",
  VALUE_OF_TURNS: "${} rounds",
  VALUE_AND_UNIT_POWER: "${} volts",
  EDIT_PIVOT_MONITOR_LABEL: "Monitor Radio",
  CREATE_DEVICE_MODAL_MONITOR_CODE: "Monitor Radio",
  GPS_FOUND: "GPS/Monitor found",
  EDIT_FARM_USER_PERMISSION_ERROR: "Error saving permissions",
  MOBILE_ONLY_INFO: "This feature only works on the mobile version.",
  I18N_KEYS: "TAGS I18N",

  ERROR_MAX_16_CHARACTERS: "The field must have up to 16 characters",
  ERROR_MAX_32_CHARACTERS: "The field must have up to 32 characters",
  ERROR_16_CARACTERS_RADIO_CONTROLER:
    "The Controller Radio field must have up to 16 characters",
  ERROR_16_CARACTERS_RADIO_GPS: "The GPS Radio field must have 16 characters",
  ERROR_16_CARACTERS_RADIO_PUMP:
    "The Pump Cable Radio field must have 16 characters",
  ERROR_16_CARACTERS_RADIO_MONITOR:
    "The Radio field of the Monitor must have 16 characters",
  ERROR_16_CARACTERS_RADIO_REPEATER:
    "The Repeater's Radio field must have 16 characters",
  ERROR_16_CARACTERS_RADIO_IMETER:
    "The Radio field of the IMeter must have 16 characters",

  ERROR_INVALID_CHARACTERS: "The field has invalid characters",
  ERROR_INVALID_AMOUNT_MIN: "Below the minimum amount of characters",
  ERROR_INVALID_AMOUNT_MAX: "Above the maximum amount of characters",
  ERROR_EMPTY_FIELD: "This field cannot be empty",
  ERROR_NEGATIVE_VALUES: "Fill in positive values only",
  ERROR_ZIP_CODE: "ZIP code not found",
  ERROR_MIN_MAX_PRECIPITATION: "Invalid blade value",
  EDIT_PIVOT_V5_ERROR_VOLTAGE_MIN:
    "Minimum voltage value, greater than maximum",
  EDIT_PIVOT_V5_ERROR_VOLTAGE_MAX:
    "Minimum voltage value, greater than maximum",
  ERROR_GENERAL: "Field has invalid values",
  ERROR_ZERO: "Field value cannot be 0",
  ERROR_ADD_NAME: "Add a name for the segment",
  ERROR_PIVOT_LAT_AND_LONG: "Invalid Latitude and Longitude",
  ERROR_PIVOT_LAT: "Invalid Latitude",
  ERROR_PIVOT_LONG: "Invalid Longitude",
  FORGET_PASSWORD_FAILED:
    "Passwords must have:\nAt least 8 characters;\nAt least one number;\nAt least one special character;",
  SIGN_UP_PASSWORD:
    "Password (8+ characters including numbers and special characters)",
  EDIT_PROFILE_ADDRESS_2: "Address details",
  CPF_ALREADY_USED: "CPF already used on the platform",
  SIGN_UP_WARNING_ERROR: "This email or CPF is already registered",
  IRPD_STATUS_NO_COMMUNICATION: "No Communication",
  CREATE_DEVICE_MODAL_IRPD_PROTOCOL_5: "5.0",
  CREATE_DEVICE_MODAL_IRPD_PROTOCOL_5_1: "5.1",
  METERSYSTEM_WATER_LEVEL: "Measurement",
  METERSYSTEM_OFFSET_WATER_LEVEL: "Offset",
  METERSYSTEM_AJUSTED_WATER_LEVEL: "Reservoir Level",
  PIVOT_REPORT_TOTAL_COST_HINT:
    "To change the prices of the power ranges, go to pivot settings.",
  EDIT_PIVOT_PAUSE_TIME_PRICES_TITLE: "Prices of Power Ranges",
  ERROR_VALUE_UNDER: "This value is less than the minimum accepted",
  ERROR_VALUE_OVER: "This value is greater than the maximum accepted",
  ERROR_MIN_MAX: "Must have values greater than zero",
  CPF_ALREADY_REGISTERED: "This email or CPF is already registered",
  CURRENCY: "$",
  PIVOT_CONFIG_V5_BRAND_MODEL: "Brand Model",
  CREATE_DEVICE_MODAL_OTHER: "-",
  IRPD_PRESSURE_CHART_DAY: "Day",
  IRPD_PRESSURE_CHART_NUMBER_OF_POINTS: "Number of Points",
  IMETER_LEVEL_CHART_NUMBER_OF_POINTS: "Number of Points",
  NUMBER_OF_POINTS_12: "12",
  NUMBER_OF_POINTS_24: "24",
  NUMBER_OF_POINTS_30: "30",
  BASIC_INFO_BOX_CHART_PLUVIOMETER: "Rain Gauge",
  BASIC_INFO_BOX_CHART_WATER_CONSUMPTION: "Consumption Chart",
  BASIC_INFO_BOX_CHART_WATER_PRESSURE: "Pressure Chart",
  IRPD_CONFIG_GENERAL_HAS_PRESSURE_SENSOR: "Pressure sensor",
  TABLE_HP: "Peak Hour",
  BASIC_INFO_BOX_CHART_GENERATED_FOR: "Chart for ${}",
  MAINTENANCE_REASON_01: "Supply - Generator",
  MAINTENANCE_SPEC_NONE_01: "---",
  MAINTENANCE_SPEC_01A: "injector nozzle",
  MAINTENANCE_SPEC_01B: "auxiliary fuel pump",
  MAINTENANCE_SPEC_01C: "injection pump",
  MAINTENANCE_SPEC_01D: "injector barrel",
  MAINTENANCE_SPEC_01E: "fuel shortage",
  MAINTENANCE_SPEC_01F: "fuel filter",
  MAINTENANCE_SPEC_01G: "water separator filter",
  MAINTENANCE_SPEC_01H: "fuel hose",
  MAINTENANCE_SPEC_01I: "fuel",
  MAINTENANCE_SPEC_01J: "fuel tank leakage",
  MAINTENANCE_REASON_02: "Supply - Water Pump",
  MAINTENANCE_SPEC_NONE_02: "---",
  MAINTENANCE_SPEC_02A: "injector nozzle",
  MAINTENANCE_SPEC_02B: "auxiliary fuel pump",
  MAINTENANCE_SPEC_02C: "injection pump",
  MAINTENANCE_SPEC_02D: "injector barrel",
  MAINTENANCE_SPEC_02E: "fuel shortage",
  MAINTENANCE_SPEC_02F: "fuel filter",
  MAINTENANCE_SPEC_02G: "water separator filter",
  MAINTENANCE_SPEC_02H: "fuel hose",
  MAINTENANCE_SPEC_02I: "fuel",
  MAINTENANCE_SPEC_02J: "fuel tank leakage",
  MAINTENANCE_REASON_03: "Cooling - Water Pump",
  MAINTENANCE_SPEC_NONE_03: "---",
  MAINTENANCE_SPEC_03A: "water pump",
  MAINTENANCE_SPEC_03B: "alternator belt",
  MAINTENANCE_SPEC_03C: "belt tightener",
  MAINTENANCE_SPEC_03D: "primary air filter",
  MAINTENANCE_SPEC_03E: "secondary air filter",
  MAINTENANCE_SPEC_03F: "propeller",
  MAINTENANCE_SPEC_03G: "cracked radiator",
  MAINTENANCE_SPEC_03H: "radiator cap",
  MAINTENANCE_SPEC_03I: "belt tensioner",
  MAINTENANCE_REASON_04: "Cooling - Pivot",
  MAINTENANCE_SPEC_NONE_04: "---",
  MAINTENANCE_SPEC_04A: "water pump",
  MAINTENANCE_SPEC_04B: "alternator belt",
  MAINTENANCE_SPEC_04C: "belt tightener",
  MAINTENANCE_SPEC_04D: "primary air filter",
  MAINTENANCE_SPEC_04E: "secondary air filter",
  MAINTENANCE_SPEC_04F: "propeller",
  MAINTENANCE_SPEC_04G: "cracked radiator",
  MAINTENANCE_SPEC_04H: "radiator cap",
  MAINTENANCE_SPEC_04I: "belt tensioner",
  MAINTENANCE_REASON_05: "Centrifugal Pump",
  MAINTENANCE_SPEC_NONE_05: "---",
  MAINTENANCE_SPEC_05A: "gasket - tighten",
  MAINTENANCE_SPEC_05B: "gasket - change",
  MAINTENANCE_SPEC_05C: "lubricant - low level",
  MAINTENANCE_SPEC_05D: "damaged retainer",
  MAINTENANCE_SPEC_05E: "broken bearing",
  MAINTENANCE_REASON_06: "Vinasse Canal",
  MAINTENANCE_SPEC_NONE_06: "---",
  MAINTENANCE_SPEC_06A: "lack of vinasse",
  MAINTENANCE_SPEC_06B: "gate failure",
  MAINTENANCE_SPEC_06C: "low flow",
  MAINTENANCE_REASON_07: "Electricity - Water Pump",
  MAINTENANCE_SPEC_NONE_07: "---",
  MAINTENANCE_SPEC_07A: "alternator",
  MAINTENANCE_SPEC_07B: "automatic",
  MAINTENANCE_SPEC_07C: "battery",
  MAINTENANCE_SPEC_07D: "battery - cable",
  MAINTENANCE_SPEC_07E: "battery - support",
  MAINTENANCE_SPEC_07F: "wiring",
  MAINTENANCE_SPEC_07G: "fuse",
  MAINTENANCE_SPEC_07H: "starter motor",
  MAINTENANCE_SPEC_07I: "auxiliary start relay",
  MAINTENANCE_SPEC_07J: "solenoid",
  MAINTENANCE_REASON_08: "Electricity - Pivot",
  MAINTENANCE_SPEC_NONE_08: "---",
  MAINTENANCE_SPEC_08A: "alternator",
  MAINTENANCE_SPEC_08B: "battery",
  MAINTENANCE_SPEC_08C: "battery - cabble",
  MAINTENANCE_SPEC_08D: "battery - support",
  MAINTENANCE_SPEC_08E: "drive contactor",
  MAINTENANCE_SPEC_08F: "wiring",
  MAINTENANCE_SPEC_08G: "fuse",
  MAINTENANCE_SPEC_08H: "generator - brush",
  MAINTENANCE_SPEC_08I: "generator - brush holder",
  MAINTENANCE_SPEC_08J: "drive micro",
  MAINTENANCE_SPEC_08K: "security micro",
  MAINTENANCE_SPEC_08L: "starter motor",
  MAINTENANCE_SPEC_08M: "gearmotor",
  MAINTENANCE_SPEC_08N: "percent meter",
  MAINTENANCE_SPEC_08O: "plate",
  MAINTENANCE_REASON_09: "Structure - Water Pump",
  MAINTENANCE_SPEC_NONE_09: "---",
  MAINTENANCE_SPEC_09A: "header",
  MAINTENANCE_SPEC_09B: "support cable ratchet",
  MAINTENANCE_SPEC_09C: "suction hose",
  MAINTENANCE_SPEC_09D: "suction hose - clamp",
  MAINTENANCE_SPEC_09E: "suction hose - support cable",
  MAINTENANCE_SPEC_09F: "flat tire",
  MAINTENANCE_SPEC_09G: "wheel bearing",
  MAINTENANCE_SPEC_09H: "expansion tank",
  MAINTENANCE_SPEC_09I: "expansion tank - handle",
  MAINTENANCE_SPEC_09J: "expansion tank - register",
  MAINTENANCE_REASON_10: "Structure - Pivot",
  MAINTENANCE_SPEC_NONE_10: "---",
  MAINTENANCE_SPEC_10A: "cardan",
  MAINTENANCE_SPEC_10B: "cardan - flexible joint",
  MAINTENANCE_SPEC_10C: "cardan - universal joint",
  MAINTENANCE_SPEC_10D: "misalignment",
  MAINTENANCE_SPEC_10E: "sprinkler cleaning",
  MAINTENANCE_SPEC_10F: "pivot cleaning",
  MAINTENANCE_SPEC_10G: "span junction hose",
  MAINTENANCE_SPEC_10H: "broken hose",
  MAINTENANCE_SPEC_10I: "wheel stud",
  MAINTENANCE_SPEC_10J: "retaining plate",
  MAINTENANCE_SPEC_10K: "flat tire",
  MAINTENANCE_SPEC_10L: "sprinkler replacement",
  MAINTENANCE_SPEC_10M: "hose replacement",
  MAINTENANCE_SPEC_10N: "loose wheel",
  MAINTENANCE_SPEC_10O: "security system inoperative",
  MAINTENANCE_SPEC_10P: "front reducer support",
  MAINTENANCE_SPEC_10Q: "rear reducer support",
  MAINTENANCE_SPEC_10R: "trusses",
  MAINTENANCE_SPEC_10S: "reinforcement tube",
  MAINTENANCE_SPEC_10T: "base beam",
  MAINTENANCE_REASON_11: "Lubrication - Generator",
  MAINTENANCE_SPEC_NONE_11: "---",
  MAINTENANCE_SPEC_11A: "lubricant - filter",
  MAINTENANCE_SPEC_11B: "lubricant - low level",
  MAINTENANCE_SPEC_11C: "leakage - flexible",
  MAINTENANCE_SPEC_11D: "leakage - front flywheel retainer",
  MAINTENANCE_SPEC_11E: "leakage - rear flywheel retainer",
  MAINTENANCE_SPEC_11F: "leakage - crankcase cover",
  MAINTENANCE_SPEC_11G: "leakage - mirror cover",
  MAINTENANCE_SPEC_11H: "leakage - turbine",
  MAINTENANCE_REASON_12: "Lubrication - Water Pump",
  MAINTENANCE_SPEC_NONE_12: "---",
  MAINTENANCE_SPEC_12A: "lubricant - filter",
  MAINTENANCE_SPEC_12B: "lubricant - low level",
  MAINTENANCE_SPEC_12C: "leakage - flexible",
  MAINTENANCE_SPEC_12D: "leakage - front flywheel retainer",
  MAINTENANCE_SPEC_12E: "leakage - rear flywheel retainer",
  MAINTENANCE_SPEC_12F: "leakage - crankcase cover",
  MAINTENANCE_SPEC_12G: "leakage - mirror cover",
  MAINTENANCE_SPEC_12H: "leakage - turbine",
  MAINTENANCE_REASON_13: "Scheduled Downtime",
  MAINTENANCE_SPEC_NONE_13: "---",
  MAINTENANCE_SPEC_13A: "supply",
  MAINTENANCE_SPEC_13B: "change of location",
  MAINTENANCE_SPEC_13C: "pivoting",
  MAINTENANCE_SPEC_13D: "towing",
  MAINTENANCE_SPEC_13E: "hose change",
  MAINTENANCE_SPEC_13F: "wheels turning",
  MAINTENANCE_REASON_NONE: "---",
  MAINTENANCE_SPEC_NONE: "---",
  MAINTENANCE_NOTE_NONE: "-> ",
  MAINTENANCE_BUTTON_TOOLTIP_ENABLE: "Enable Maintenance Mode",
  MAINTENANCE_BUTTON_TOOLTIP_DISABLE: "Disable Maintenance Mode",
  MAINTENANCE_MODAL_REASON_QUESTION: "Inform reason for downtime?",
  MAINTENANCE_MODAL_REASON_LABEL: "Select a reason:",
  MAINTENANCE_MODAL_SPEC_QUESTION: "Specify the reason for downtime?",
  MAINTENANCE_MODAL_SPEC_LABEL: "Select a specification:",
  MAINTENANCE_MODAL_NOTE_QUESTION: "Any notes?",
  MAINTENANCE_MODAL_NOTE_LABEL: "Note:",
  MAINTENANCE_MODAL_CONFIRMATION_QUESTION_ENABLE: "Enable Maintenance Mode?",
  MAINTENANCE_MODAL_CONFIRMATION_QUESTION_DISABLE: "Disable Maintenance Mode?",
  MAINTENANCE_MODALS_BUTTON_CANCEL: "Cancel",
  MAINTENANCE_MODALS_BUTTON_NEXT: "Next",
  MAINTENANCE_MODALS_BUTTON_CONFIRM: "Confirm",
  CREATE_DEVICE_MODAL_PIVO_LINEAR_MONITOR_CHOOSER: "Linear Pivot Monitor",
  CREATE_DEVICE_MODAL_ERROR_BASE_NOT_VALID: "Invalid base radio",
  CREATE_DEVICE_MODAL_FLOW_RATE: "Flow Rate",
  CREATE_DEVICE_MODAL_PIVOT_LENGTH: "Length",
  CREATE_DEVICE_MODAL_PIVOT_SPEED: "Speed",
  EDIT_MONITOR_LINEAR_LABEL: "Edit Pivot Linear Monitor",
  PIVOT_CONFIG_FIELD_SIZE: "Pivot Size",
  PIVOT_CONFIG_FIELD_SIZE_TOOLTIP: "Total Length of the Linear Pivot",
  PIVOT_CONFIG_V5_LINEAR_SPEED: "Linear Pivot Speed",
  PIVOT_CONFIG_V5_LINEAR_SPEED_TOOLTIP: "Linear pivot speed at 100%",
  BASIC_INFO_BOX_TOTAL_WET_HOURS: "Total hours worked.",
  EDIT_LINEAR_PIVOT_MONITOR_LABEL: "Edit Linear Pivot Monitor",
  LPM_CONFIG_FIELD_LENGTH: "Length",
  LPM_CONFIG_FIELD_LENGTH_TOOLTIP: "Linear pivot total length",
  LPM_CONFIG_V5_FIELD_SPEED: "Speed",
  LPM_CONFIG_V5_FIELD_SPEED_TOOLTIP: "Speed of the linear pivot to 100%",
  BASIC_INFO_BOX_CHART_GENERATED_BETWEEN:
    "Chart for the period from ${} to ${}",
  BASIC_INFO_BOX_WATER_PRESSURE: "Pressure",
  GRAPHIC_SCALE: "Graphic scale",
  GRAPHIC_SCALE_TOOLTIP:
    "The Graphic Scale refers to the maximum meters of water column (MCA) it can measure.",
  LPM_EFFICIENCY: "Efficiency: ",
  IMETER_FIELD_NAME: "Meter name",
  METERSYSTEM_FIELD_NAME: "System Name",
  MCA_10: "10 MCA",
  MCA_20: "20 MCA",
  MCA_100: "100 MCA",
  IMETER_CONFIG_V5_FIELD_SENSOR: "Sensor",
  HISTORIC_SIMPLE_IRRIGATION_INJECTION_PUMP_STATUS: "Fertigation: ",
  REPORT_TABLE_OPERATION_NOTE: "Observation",
  REPORT_TABLE_IRRIGATION_MODE: "Mode",
  PANEL_STREAM_STATUS_26: "Fertirrigating",
  EDIT_PIVOT_V5_INJETCTION_PUMP_CONFIG: "Fertirrigation Pump",
  SIMPLE_IRRIGATION_FIELD_INJECTION_PUMP: "Fertigation",
  IRRIGATION_FIELD_INJECTION_PUMP_NOTE: "Comments",
  IRRIGATION_FIELD_INJECTION_PUMP_NOTE_EXPANDED: "Comments: ",
  IRRIGATION_MODE_FERTIRRIGATION: "Fertirrigation",
  PIVOT_CONFIG_V5_FIELD_INJECTION_PUMP_TOOL_TIP:
    'Select "Enable Fertirrigation" if you want to use the fertigation functionality in the irrigation commands of this pivot.',
  PIVOT_CONFIG_V5_FIELD_INJECTION_PUMP_CHECK: "Activate Fertirrigation",
  EDIT_IMETER_LEVEL_TAB: "Level",
  EDIT_IMETER_LEVEL_TAB_SENSOR_INFO_TITLE: "Sensor's information",
  EDIT_IMETER_LEVEL_TAB_CHART_CONFIG_TITLE: "Chart configuration",
  EDIT_IMETER_LEVEL_TAB_CHART_CONFIG_TEXT:
    "These values are for inserting reference lines into the measurement chart.",
  EDIT_IMETER_LEVEL_TAB_MAX_LIMIT_INPUT: "Maximum value",
  EDIT_IMETER_LEVEL_TAB_MIN_LIMIT_INPUT: "Minimum value",
  BASIC_INFO_BOX_CHART_LAKE_LEVEL: "Level Chart",
  BASIC_INFO_BOX_LEVEL: "Level",
  GENERAL_BOX_MAX_LIMIT: "Maximum value:",
  GENERAL_BOX_MIN_LIMIT: "Minimum value:",
  PERIOD_TOTAL_WATER_CONSUMPTION: "Consumption total for the period",
  PERIOD_TOTAL_RAINFALL: "Rain total for the period",
  PLUVIOMETER_CHART_NUMBER_OF_BARS: "Number of Bars",
  IRPD_CONSUMPTION_CHART_NUMBER_OF_BARS: "Number of Bars",
  SEGMENTS_PLANT_DATE: "Planting date",
  SEGMENTS_HARVEST_DATE: "Estimated harvest date",
  EDIT_PIVOT_LABEL_PIVOT_SEGMENTS: "Segments and Planting",
  SEGMENT_NAME: "Segment/planting name",
  MOBILE_MODAL_TITLE_SEGMENTS_DISPLAY: "Crop",
  PIVOT_CONFIG_V5_FIELD_SEGMENT_CROP_PLANT_DATE: "Planting: ${}",
  PIVOT_CONFIG_V5_FIELD_SEGMENT_CROP_HARVEST_DATE: "Harvest: ${}",
  NEW_SEGMENT: "Add/Edit Segments",
  HOVER_ON_SEGMENT:
    "Hover the cursor under the drawing for segment information",
  ANGLE_RANGE: "Angle range",
  ACCUMULATED_BLADE: "Accumulated Blade",
  SEGMENT_TYPE: "Cropping/management type",
  CROP_WARNING_CONFIG:
    "For more information, configure planting/management dates in edit pivot.",
  CROP_WARNING_INFO: "There is no information in this time range.",
  CROP_WARNING_SECTORIAL:
    "This pivot is sectoral. The pivot segments only consist of the colored areas of the map.",
  PLUVIOMETER_WITHOUT_CONFIG: "Pluviometer not enabled",
  ACTIVATED: "Activated",
  DEACTIVATED: "Deactivated",
  EDIT_PIVOT_V5_AUTOREVERSION_MODE: "Stop condition",
  EDIT_PIVOT_V5_AUTOREVERSION_BY_ANGLE: "By angle",
  ENDGUN_START_ANGLE: "Start",
  ENDGUN_FINAL_ANGLE: "End",
  EDIT_IRPD_LOAD_SUCCESS: "Configuration loaded successfully!",
  EDIT_IRPD_LOAD_ERROR: "Error loading configuration.",
  EDIT_IRPD_LOAD_BUTTON: "Load",
  HISTORIC_CONFIG_INF_MONITOR: "Monitor",
  HISTORIC_CONFIG_INF_CONTROLLER: "Controller",
  HISTORIC_CONFIG_INF_HARDWARE: "Hardware",
  HISTORIC_CONFIG_INF_GPS: "GPS",
  HISTORIC_CONFIG_INF_PUMP: "Pump",
  HISTORIC_CONFIG_INF_IMETER: "Meter",
  HISTORIC_PANEL_LABEL_MONITOR: "MO",
  HISTORIC_PANEL_LABEL_CONTROLLER: "C",
  HISTORIC_PANEL_LABEL_HARDWARE: "H",
  HISTORIC_PANEL_LABEL_GPS: "G",
  HISTORIC_PANEL_LABEL_PUMP: "P",
  HISTORIC_PANEL_LABEL_IMETER: "ME",
  LEGEND: "Legend",
  ALL_CONFIGURATIONS: "All Configurations",
  CONFIG_NOT_SENT: "configuration not sent",
  CONFIG_SENT: "configuration sent, but not received",
  CONFIG_RECEIVED: "configuration received",
  TEXT_CONFIG_NOT_SENT: "Configuration was not sent to the device.",
  TEXT_CONFIG_SENT:
    "Configuration was sent to the device, but it was not received.",
  TEXT_CONFIG_RECEIVED: "Configuration was received by the device.",
  IRPD_EDIT_LABEL: "Edit Water Pump",
  PIVOT_CONFIG_V5_FIELD_PAUSE_TIME_START: "Peak time start",
  PIVOT_CONFIG_V5_FIELD_PAUSE_TIME_END: "Peak time end",

  BAUER_PIVOT_EDIT_ZERO_POSITION: "Zero Position",
  BAUER_PIVOT_EDIT_END_GUN_SELECT_ALWAYS_ON: "Always On",
  BAUER_PIVOT_EDIT_END_GUN_SELECT_AUTOMATIC_MODE: "Automatic Mode",
  BAUER_PIVOT_EDIT_SECTOR_BEGIN: "Start Sector",
  BAUER_PIVOT_EDIT_SECTOR_END: "End Sector",
  BAUER_PIVOT_EDIT_AUTOREVERSE: "Normal",
  BAUER_PIVOT_EDIT_PARKING_FUNCTION: "Parking Position",
  BAUER_PIVOT_EDIT_INTERMEDIATE_FUNCTION: "Intermediate Position",

  BAUER_PIVOT_GENERAL_TOOLTIP_PRESSURE: "Current pressure",
  BAUER_PIVOT_GENERAL_TOOLTIP_ANGLE: "Current angle",
  BAUER_PIVOT_GENERAL_TOOLTIP_IRRIGATION_RATE: "Irrigation rate",

  BAUER_PIVOT_HISTORY_TOOLTIP_USER: "Sent by user",
  BAUER_PIVOT_HISTORY_TOOLTIP_DEVICE: "Sent by device",

  BAUER_PIVOT_HISTORY_USER: "Overview",
  BAUER_PIVOT_HISTORY_PROTOCOL: "Protocol",
  BAUER_PIVOT_HISTORY_PROTOCOL_TABLE: "Protocol Table",

  BAUER_PIVOT_STATUS_0: "Machine not in operation",
  BAUER_PIVOT_STATUS_1: "Machine in Forward operation",
  BAUER_PIVOT_STATUS_2: "Waiting until start time to start irrigation",
  BAUER_PIVOT_STATUS_3: "Irrigation paused",
  BAUER_PIVOT_STATUS_4: "Safety circuit interrupted",
  BAUER_PIVOT_STATUS_5: "Machine operating in emergency mode",
  BAUER_PIVOT_STATUS_6: "Machine stopped via remote control",
  BAUER_PIVOT_STATUS_7: "Safety switch turned off",
  BAUER_PIVOT_STATUS_8: "Water pressure too low",
  BAUER_PIVOT_STATUS_9: "Stopped because of strong wind",
  BAUER_PIVOT_STATUS_10: "Voltage too low",
  BAUER_PIVOT_STATUS_11: "CAN BUS error",
  BAUER_PIVOT_STATUS_12: "I2C error",
  BAUER_PIVOT_STATUS_13: "Start delay time active",
  BAUER_PIVOT_STATUS_14: "Parking position reached",
  BAUER_PIVOT_STATUS_15: "Machine in Reverse operation",
  BAUER_PIVOT_STATUS_16: "GPS error",
  BAUER_PIVOT_STATUS_17: "Stopped because of rainfall",
  BAUER_PIVOT_STATUS_18: "Corner error",
  BAUER_PIVOT_STATUS_19: "Span sensor error",
  BAUER_PIVOT_STATUS_20: "Tilt sensor error",
  BAUER_PIVOT_STATUS_21: "Last tower error",
  BAUER_PIVOT_STATUS_22: "Distance to buried guidance cable too large",
  BAUER_PIVOT_STATUS_23: "Buried guidance signal too low",
  BAUER_PIVOT_STATUS_24: "Buried guidance error",
  BAUER_PIVOT_STATUS_25: "Corner frequency converter error",
  BAUER_PIVOT_STATUS_26: "Compressor error",
  BAUER_PIVOT_STATUS_27: "No movement detected",
  BAUER_PIVOT_STATUS_28: "Intermediate stop position reached",
  BAUER_PIVOT_STATUS_40: "Water pressure too high",
  BAUER_PIVOT_STATUS_41: "Emergency-Off switch pressed",

  BAUER_PIVOT_STATUS_NEW_CON: "New communication",
  BAUER_PIVOT_STATUS_MAN_START: "Manual start",
  BAUER_PIVOT_STATUS_MAN_STOP: "Manual stop",
  BAUER_PIVOT_STATUS_ACK_FWD: "Forward received",
  BAUER_PIVOT_STATUS_ACK_REV: "Reverse received",
  BAUER_PIVOT_STATUS_ACK_STOP: "Stop received",
  BAUER_PIVOT_STATUS_START_FWD: "Forward command sent",
  BAUER_PIVOT_STATUS_START_REV: "Reverse command sent",
  BAUER_PIVOT_STATUS_STOP_CMD: "Stop command sent",
  BAUER_PIVOT_STATUS_GET_CONFIG: "Get configuration",
  BAUER_PIVOT_STATUS_SET_CONFIG: "Set configuration",
  BAUER_PIVOT_STATUS_ACK_CONFIG: "Configuration received",
  BAUER_PIVOT_STATUS_ACK_VRI: "VRI received",
  BAUER_PIVOT_STATUS_RFTP_ERROR_1: "File: No data",
  BAUER_PIVOT_STATUS_RFTP_MAX_RETRY: "File: Limit exceeded",
  BAUER_PIVOT_STATUS_RFTP_ERR_FILE: "File: checksum error",
  BAUER_PIVOT_STATUS_RFTP_OK: "File: successful",
  BAUER_PIVOT_STATUS_ACK_START: "Start received",
  BAUER_PIVOT_STATUS_DEFAULT: "Update",

  PUMP: "Pump",
  IMETER: "iManage",
  EDIT_REPEATER_LABEL_GENERAL: "General",
  EDIT_REPEATER_CENTRAL_LABEL: "Central Radio",
  EDIT_REPEATER_RADIO_LABEL: "Repeater Radio",
  REPEATER_FIELD_NAME: "Name",
  REPEATER_CONFIG_FIELD_ENERGY_TYPE: "Type",
  REPEATER_CONFIG_FIELD_SOLAR: "Solar",
  REPEATER_CONFIG_FIELD_BIVOLT: "Bivolt",
  REPEATER_CONFIG_DEVICE_FIELD_GP_CENTER: "Device Location",
  REPEATER_EDIT_LABEL: "Repeater",
  RADIO_SWAP_CONTROLLER_TITLE: "Change the Controller's radio",
  RADIO_SWAP_GPS_TITLE: "Change the GPS radio",
  RADIO_SWAP_PUMP_TITLE: "Change the Pump radio",
  RADIO_SWAP_IMETER_TITLE: "Change the iManage radio",
  RADIO_SWAP_REPEATER_TITLE: "Exchange Repeater radio",
  RADIO_SWAP_CONTROLLER_SUBTITLE:
    "Select the pivot to change the Controller's radio.",
  RADIO_SWAP_GPS_SUBTITLE: "Select the pivot to change the GPS radio.",
  RADIO_SWAP_PIVOT_PUMP_SUBTITLE: "Select the pivot to change the Pump radio.",
  RADIO_SWAP_IMETER_SUBTITLE: "Select an iManage to switch radios.",
  RADIO_SWAP_PUMP_SUBTITLE: "Select a Pump to change radio.",
  RADIO_SWAP_REPEATER_SUBTITLE: "Select a Repeater to change radios.",
  REPEATER: "Repeater",
  BASIC_INFO_BOX_PRESSURE_FILTERED_CHARTS_INFO:
    "Pressure data generated between ${} and ${}",
  BASIC_INFO_BOX_COMPARISON_FILTERED_CHARTS_INFO:
    "Comparison data generated between ${} and ${}",
  BASIC_INFO_BOX_COMPARE_DATE_START: "Start at:",
  BASIC_INFO_BOX_COMPARE_DATE_END: "Stop at:",
  BASIC_INFO_BOX_PRESSURE_INFO:
    '"Pressure" values are relative to the time interval defined by the report. The "Comparison Pressure" values are relative to the time interval selected in this graph. Use the "Comparison Pressure" data to compare the pressure readings for each irrigated angle between different time periods.',
  HISTORIC_IRPD_STREAM_CONSUMPTION: "Estimated consumption",
  HISTORIC_IRPD_STREAM_PRESSURE: "Pressure",
  DEVICE_BOX_SHOW_PERIODIC_STREAMS: "Show periodic streams",
  LOGIN_ERROR_ACCEPT_PRIVACY:
    "To continue accessing, accept the privacy policy",
  EDIT_FARM_BILLING: "Billing",
  FARM_BILLING_FIELD_PHONE: "Telephone",
  FARM_BILLING_FIELD_ADDRESS: "Address",
  FARM_BILLING_FIELD_POSTAL_CODE: "CEP (Only numbers)",
  FARM_BILLING_FIELD_CITY: "City",
  FARM_BILLING_FIELD_STATE: "State",
  FARM_BILLING_FIELD_COUNTRY: "Country",
  ALERT_DIALOG_ALERT_DELETE:
    "Are you sure you want to delete this notification?",
  NOTIFICATIONS_TABPANEL_LABEL_PIVOTS: "Pivots",
  NOTIFICATIONS_TABPANEL_LABEL_MONITOR: "Pivot monitors",
  NOTIFICATIONS_TABPANEL_LABEL_PUMP: "Pumps",
  NOTIFICATIONS_TABPANEL_LABEL_METER: "Level meters",
  SHOW_REASONS: "Show reasons",
  HIDE_REASONS: "Hide reasons",
  EDIT_NOTIFICATIONS: "Edit notifications",
  EDIT_NOTIFICATIONS_QUESTION: "Do you wish to save your changes?",
  EDIT_NOTIFICATIONS_SAVE: "Save",
  CREATE_NOTIFICATIONS: "Create notifications",
  FARM_HAS_NO_NOTIFICATIONS: "You have no notifications for ${}",
  CREATE_NOTIFICATIONS_QUESTION:
    "Do you wish to create this group of notifications?",
  CREATE_NOTIFICATIONS_CREATE: "Create",
  DELETE_NOTIFICATIONS_QUESTION:
    "Do you wish to delete this group of notifications?",
  DELETE_NOTIFICATIONS: "Delete notifications",
  DELETE_NOTIFICATIONS_CANCEL: "Cancel",
  DELETE_NOTIFICATIONS_DELETE: "Delete",
  CREATE_NOTIFICATIONS_ALL_DAY: "All day",
  CREATE_NOTIFICATIONS_BACK: "Back",
  CREATE_NOTIFICATIONS_NEXT: "Next",
  CREATE_NOTIFICATIONS_FINISH: "Finish",
  CREATE_NOTIFICATIONS_BASIC_INFO: "Basic information",
  CREATE_NOTIFICATIONS_TIME_PERIOD: "Time",
  CREATE_NOTIFICATIONS_DEVICES: "Devices",
  CREATE_NOTIFICATIONS_REASONS: "Reasons",
  CREATE_NOTIFICATIONS_CHOOSE_NAME:
    "Choose a name for this group of notifications",
  CREATE_NOTIFICATIONS_CHOOSE_NAME_PLACEHOLDER:
    "Type a name for this group of notifications",
  CREATE_NOTIFICATIONS_PICK_TIME: "Pick a time for this group of notifications",
  CREATE_NOTIFICATIONS_STARTS_ON: "Starts at",
  CREATE_NOTIFICATIONS_ENDS_ON: "Ends at",
  CREATE_NOTIFICATIONS_SELECT_THE_DEVICES:
    "Select the devices for this group of notifications",
  CREATE_NOTIFICATIONS_SELECT_ALL_THE_DEVICES: "Select all the devices",
  CREATE_NOTIFICATIONS_SELECT_REASONS:
    "Select the reasons for this group of notifications",
  CREATE_NOTIFICATIONS_CHOOSE_GENERATION:
    "Choose the pivot generation for this group of notifications",
  HISTORY_OF_MEASUREMENTS: "History of Measurements",
  FARM_BILLING_FIELD_COMPANY_NAME: "Company name",
  FARM_BILLING_FIELD_DOCUMENT: "Document",
  FARM_BILLING_FIELD_DOCUMENT_TYPE: "Document type",
  FARM_BILLING_FIELD_EMAIL: "Email",
  EDIT_BILLING_SAVE_SUCCESS: "Billing information saved",
  EDIT_BILLING_ERROR: "Error while saving billing information",
  FARM_GENERAL_INFORMATION_TITLE: "General Information",
  FARM_BILLING_DESCRIPTION:
    "This information will be used to issue invoices for the use of the Irricontrol Platform.",
  FARM_BILLING_INFORMATION_TITLE: "Billing Information",
  FARM_BILLING_ADDRESS_TITLE: "Billing Address",
  FARM_CONTACT_INFORMATION_TITLE: "Contact Information",
  FARM_CONTACT_ADDRESS_TITLE: "Contact Address",
  FARM_CONTACT_ADDRESS_SWITCH: "Same as billing address",
  FARM_LOCATION_INFORMATION_TITLE: "Location Information",
  CREATE_FARM_STEP_LABEL_GENERAL: "General",
  CREATE_FARM_STEP_LABEL_CONTACT: "Contact",
  CREATE_FARM_STEP_LABEL_BILLING: "Billing",
  CREATE_FARM_STEP_LABEL_LOCATION: "Location",
  CENTRAL_RADIO: "Central Radio",
  CREATE_FARM_QUESTION: "Do you wish to create this farm?",
  CREATE_FARM_CREATE: "Create",
  FARM_CONTACT_FIELD_POSTAL_CODE: "CEP (Only numbers)",
  COMMUNICATION_TYPE: "Communication Type",
  CREATE_DEVICE_MODAL_XBEE: "XBee",
  CREATE_DEVICE_MODAL_4G: "4G",
  CREATE_DEVICE_MODAL_GATEWAY_CODE: "Gateway Number",
  ERROR_16_CARACTERS_GATEWAY_CODE:
    "The Gateway Number field must have up to 16 characters",
  ERROR_16_CARACTERS_RADIO_GATEWAY:
    "The Gateway Radio field must have up to 16 characters",
  COMMUNICATION_TYPE_OPTION_XBEE: "XBee",
  COMMUNICATION_TYPE_OPTION_4G_LTE: "4G/LTE",
  SELECTED_PIVOT_ONLINE: "Pivot with internet",
  SELECTED_PIVOT_OFFLINE: "Pivot without internet",
  CENTRAL_LINKED: "Does the farm have a Central?",
  CENTRAL_LINKED_TOOLTIP:
    "Farms that have a Central are those that use radio communication. Farms that do not have a Central are those that use 4G communication, available only for pivots.",
  FARM_LOCATION: "Farm Location",
  EDIT_PIVOT_GATEWAY_NUMBER_LABEL: "Gateway Number",
  EDIT_GATEWAY: "Edit gateway",
  SELECTED_IRPD_ONLINE: "Pump with internet",
  SELECTED_IRPD_OFFLINE: "Pump without internet",
  GATEWAY_CHANGED_SUCCESS: "Gateway changed successfully",
  GATEWAY_ID_ALREADY_USED: "Gateway already used on another device",
  SAVE_GATEWAY: "Save gateway",
  HISTORIC_DATA_UNDEFINDED: "Undefined",
  HOVER_OVER_SEGMENT:
    "Hover the cursor over the drawing for segment information",
  CREATE_NOTIFICATIONS_CRITICAL: "Critical",
  CREATE_NOTIFICATIONS_CRITICAL_ALERTS:
    "Do you want this notification to be a critical alert?",
  CREATE_NOTIFICATIONS_CRITICAL_ALERTS_INFO:
    "Critical alerts play different and louder sounds than conventional notifications. These notifications are disruptive and should be used when immediate action is required, for example in the event of unexpected downtime.",
  CRITICAL_ALERTS_QUESTION_ENABLE: "Do you want to enable critical alerts?",
  CRITICAL_ALERTS_QUESTION_DISABLE: "Do you want to disable critical alerts?",
  CRITICAL_ALERTS_CANCEL: "Cancel",
  CRITICAL_ALERTS_ENABLE: "Enable",
  CRITICAL_ALERTS_DISABLE: "Disable",
  CRITICAL_ALERTS: "Critical alerts",
  FARM_PAYMENT_METHOD_TITLE: "Payment Method",
  FARM_BILLING_HISTORY_TITLE: "Billing History",
  BILLING_HISTORY_NO_INVOICES_FOUND: "No invoices found",
  BILLING_HISTORY_NO_BILLING_DATA_FOUND: "Billing data not found",
  BILLING_INVOICE_DUE_DATE: "Due Date",
  BILLING_INVOICE_STATUS: "Status",
  BILLING_INVOICE_STATUS_OPEN: "Open",
  BILLING_INVOICE_STATUS_PAID: "Paid",
  BILLING_INVOICE_STATUS_PAST_DUE: "Past due",
  BILLING_INVOICE_STATUS_VOID: "Void",
  BILLING_INVOICE_AMOUNT: "Amount",
  BILLING_INVOICE_LINK: "Link",
  BILLING_INVOICE_LINK_PAY: "Pay",
  BILLING_INVOICE_LINK_DOCS: "Documents",
  HISTORIC_PAINEL_TYPE_POSITION_STREAM: "Angle update",
  DEVICE_BOX_SHOW_POSITION: "Show position",
  POLICY_SYSTEM_TITLE_TERMS_AND_CONDITIONS: "Terms of Use and Data Protection",
  POLICY_SYSTEM_TITLE_TERMS_AND_CONDITIONS_UPDATE:
    "Terms of Use and Data Protection Update",
  POLICY_SYSTEM_TERMS_AND_CONDITIONS_TEXT: "Ours",
  POLICY_SYSTEM_TERMS_AND_CONDITIONS_TEXT2:
    "aim to add conditions to the use of our services to maintain compliance with current laws that guarantee your data privacy.",
  POLICY_SYSTEM_TERMS_AND_CONDITIONS_UPDATE_TEXT: "We have updated our",
  POLICY_SYSTEM_TERMS_AND_CONDITIONS_UPDATE_TEXT2:
    "to add new conditions to the use of our services and to maintain compliance with current laws that guarantee your data privacy.",
  POLICY_SYSTEM_TERMS_AND_CONDITIONS_SPAN:
    "By continuing, you agree to our updated Terms and all policies described therein. Access and review at",
  POLICY_SYSTEM_TERMS_AND_CONDITIONS_LINK: "Terms of Use and Data Protection",
  POLICY_TERMS_AND_CONDITIONS_CHECKBOX:
    "I declare that I have read and agree to the Terms of Use and Data Protection of the Irricontrol Platform.",
  ACCEPT_TEXT: "I ACCEPT",
  PAYMENT_METHOD_WARNNING:
    "Invoices in manual payment. To trigger automatic payments, add a credit card to the payment options.",
  PAYMENT_METHOD_ADD: "Add payment method",
  PAYMENT_METHOD_UPDATE: "Update payment method",
  PAYMENT_METHOD_CREDIT_CARD_NUMBER: "Credit card number",
  PAYMENT_METHOD_CREDIT_CARD_EXPIRY: "Validity",
  PAYMENT_METHOD_CREDIT_NAME: "Name on card",
  DISPLAY_DATE_FORMAT: "MM/DD/YYYY",
  DEBTOR_NOTIFICANTION_USER:
    "Your farm has overdue payments. Ask your administrator to make the payments.",
  DEBTOR_NOTIFICANTION_ADM: "Your farm has overdue payments. Make the payments",
  DEBTOR_NOTIFICANTION_LINK: "<in settings>",
  DEBTOR_NOTIFICANTION_COUNT: "The farm is automatically blocked in ${} days.",
  BILLING_DESCRIPTION_TEXT:
    "The billings tab concentrates essential financial information related to commercial transactions on the platform.",
  PAYMENT_METHOD_WARNING: "To enable automatic payments, add a credit card.",
  BILLING_WARNING: "There are overdue payments!",
  SUCCESS: "Success",
  SUCCESS_PAGE_TEXT: "Payment method updated successfully.",
  CANCEL_PAGE_TEXT: "Payment method could not be updated.",
  BILLING: "Billing",
  UNDER_CONSTRUCTION: "Under construction",
  PRESSURE_CHART_COMPARISON_END_TOWER: "Comparison pressure - Last tower",
  PRESSURE_CHART_END_TOWER: "Pressure - Last tower",
  PRESSURE_CHART_COMPARISON_CENTER: "Comparison pressure - Center tower",
  PRESSURE_CHART_CENTER: "Pressure - Central tower",
  EDIT_PROFILE_TERMS_AND_CONDITIONS:
    "For more information on the terms governing the Irricontrol platform go to ",
  EDIT_FARM_ERROR_FIELDS: "Registration failed, check the fields again.",
  EDIT_FARM_ERROR: "The farm could not be registered.",
  NO_INFO: "No Information",
  HARDWARE_ID_ALREADY_EXISTS: "Hardware id already used",
  ENCODER: "Encoder",
  ENCODER_DESCRIPTION_TEXT:
    "LTE/4G pivot systems employ precision equipment installed in the central tower, which is responsible for recording the pivot`s angular position in real time. The encoder tab is dedicated to configuring and calibrating the encoder, allowing you to set the pivot`s 0° angle reference according to its current position.",
  ENCODER_BTN: "Reset 0° angle",
  ENCODER_CONFIRMATION: "Do you want to reset the current pivot angle to 0º?",
  EDIT_ENCODER_LABEL_SETTING_HISTORIC: "Encoder Reset History",
  REDEFINE: "Yes",
  REDEFINE_ENCONDER_LABEL: "Encoder Resets",
  EDIT_ENCODER_SAVE_AWAIT_ERROR: "Error resetting the encoder to angle 0º",
  EDIT_ENCODER_SAVE_AWAIT_SUCCESS: "Encoder reset to angle 0º",
  ENCODER_RESET_INSTRUCTION_TITLE: "How to use the Encoder reset",
  ENCODER_RESET_INSTRUCTION_STEP1: "Step 1:",
  ENCODER_RESET_INSTRUCTION_STEP1_TEXT:
    "Position the pivot at the desired initial reference point. Make sure that the Encoder installed in the center of the pivot is in the correct position.",
  ENCODER_RESET_INSTRUCTION_STEP2: "Step 2:",
  ENCODER_RESET_INSTRUCTION_STEP2_TEXT: 'Click on the "RESET ANGLE 0º" button',
  ENCODER_RESET_INSTRUCTION_STEP3: "Step 3:",
  ENCODER_RESET_INSTRUCTION_STEP3_TEXT:
    'A confirmation screen will open. Confirm the reset by clicking "Yes".',
  ENCODER_RESET_INSTRUCTION_DESCRIPTION:
    "It is important to note that this equipment preserves the current angle, even when the pivot panel is switched off. Therefore, only use the reset during the first installation, in cases of equipment replacement, or when the initial reference configuration is updated.",
  PAYMENT_METHOD_CREDIT_CARD: "Credit card",
  PAYMENT_METHOD_CREDIT_BILL: "Bank slip",
  EDIT_BILLING_NOT_FOUND_USER:
    "No billing information registered. Ask your administrator to register it.",
  EDIT_BILLING_NOT_FOUND_ADM:
    "No billing information registered. Please register",
  PAYMENT_METHOD_DELETE_TEXT:
    "Are you sure you want to remove automatic billing?",
  PAYMENT_METHOD_DELETE_TITLE: "Remove automatic billing",
  PAYMENT_METHOD_DELETE_BTN: "Remove",
  PAYMENT_METHOD_UPDATE_BTN: "Update",
  BILLING_PREFERRED_LANGUAGE: "Preferred language for email",
  BILLING_PREFERRED_LANGUAGE_TOOLTIP:
    'The "Preferred language for emails" determines in which language you will receive email communications about payments and invoice details on the Irricontrol Platform. Selecting this language does not change the language of the interface on the Platform.',
  FARM_PAYMENT_METHOD_DESCRIPTION:
    'Add a "Credit Card" to enable automatic payment of invoices. This option ensures that your invoices are paid on the correct date, without the need for manual intervention each billing cycle, avoiding any type of blockages on the platform.',
};

export default i18n_en;

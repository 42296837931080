import usePersistentNotify from "../tools/usePersistentNotify";
import checkExists from "../../utils/checks/checkExists";
import React, { useCallback, useState, useEffect } from "react";
import { i18n } from "../../i18n/i18nText";
import useFarm from "./useFarm";
import { getAuthorization } from "../../services/webclient";
import axios from "axios";
import routes from "../../routes/routes";

const useNotifyIfDebtor = (farmID): [boolean, (farmID) => void] => {
  const notify = usePersistentNotify();
  const [lastFarmID, setLastFarmID] = useState(null);
  const [asyncStatus, farm, farmActions] = useFarm(-1, true);
  const [isAdministrator, setIsAdministrator] = useState(null);

  const fetchFromApi = useCallback(async (url, params = {}) => {
    try {
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${getAuthorization()}`,
        },
        baseURL: process.env.REACTWEB_COREAPI_URL,
        ...params,
      });

      if (checkExists(["data", response, "__cascade"])) {
        return [response.data, true];
      }
      return [{}, true];
    } catch (err) {
      console.log(err);
      if (err.response && err.response.status === 403) {
        return [{}, false];
      }
      return [{}, true];
    }
  }, []);

  const getDebtor = async (farmID, daily = false) => {
    const url = `/v4/farms/${farmID}/billing/customer/is_debtor/?daily=${daily}`;
    return await fetchFromApi(url);
  };

  const notifyDebtor = async (farmID) => {
    try {
      if (farmID !== lastFarmID) {
        const response = await farmActions.get(farmID, true);
        setLastFarmID(farmID);
        if (checkExists(["is_administrator", response, "__cascade"])) {
          const [debtor, groupPermission] = await getDebtor(farmID);

          if (
            !window.location.href.includes("disabled") &&
            !window.location.href.includes("edit") &&
            farmID &&
            debtor.is_debtor
          )
            window.location.href = routes.DASHBOARD.FARM_DISABLED.replace(
              ":farm",
              `${farmID}`
            );

          if (Object.keys(debtor).length) {
            setIsAdministrator(response.is_administrator);
            renderNotification(
              response.is_administrator,
              groupPermission,
              debtor
            );
          }
        }
      } else {
        const [debtor, groupPermission] = await getDebtor(farmID);
        if (Object.keys(debtor).length) {
          renderNotification(isAdministrator, groupPermission, debtor);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const renderNotification = (
    isAdministrator,
    groupPermission,
    debtor = null
  ) => {
    console.log(groupPermission, debtor);
    if (!groupPermission) return;

    let message;
    if (debtor.has_invoice_past_due && !debtor.is_debtor) {
      message = isAdministrator ? (
        <>
          <p>
            {i18n("DEBTOR_NOTIFICANTION_ADM")}{" "}
            <a
              title={i18n("DEBTOR_NOTIFICANTION_LINK")}
              href={`${process.env.WEB_URL}/farms/${farmID}/edit/?tab=1`}
            >
              <b>{i18n("DEBTOR_NOTIFICANTION_LINK")}</b>
            </a>
            .{" "}
            {!!debtor.total_days &&
              i18n("DEBTOR_NOTIFICANTION_COUNT", [debtor.total_days])}
          </p>
        </>
      ) : (
        <p>
          {i18n("DEBTOR_NOTIFICANTION_USER")}{" "}
          {!!debtor.total_days &&
            i18n("DEBTOR_NOTIFICANTION_COUNT", [debtor.total_days])}
        </p>
      );
    }
    if (message) notify(message, "error");
  };

  return [asyncStatus.loading, notifyDebtor];
};

export default useNotifyIfDebtor;

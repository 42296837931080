import React, { useState, useCallback, useEffect, useMemo } from "react";
import FormInput from "../../../../../../../../components/Form/FormInput/FormInput";
import {
  estimatedIrrigationDurationOnServer,
  getWeekdays,
  parseHHMM,
  PTPToMillimeter,
} from "../../../../../../../../utils/models/pivots";

import {
  Pivot,
  ControllerConfig,
  ControllerStream,
} from "../../../../../../../../redux/pivots/types";
import "./ConventionalIrrigationV5.scss";
import { Button, Icon, CircularProgress, TextField } from "@material-ui/core";
import useCreateSimpleIrrigation from "../../../../../../../../hooks/models/useCreateSimpleIrrigation";
import LinearProgress from "@material-ui/core/LinearProgress";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../../../../../../redux";
import Store from "../../../../../../../../redux/index";
import FormSelect from "../../../../../../../../components/Form/FormSelect/FormSelect";
import SpeedInputs from "../../../../../../../../components/SpeedInputs/SpeedInputs";
import { RotateRight, RotateLeft, Opacity } from "@material-ui/icons";
import FormatColorResetIcon from "@material-ui/icons/FormatColorReset";
import moment, { now } from "moment";
import FormDateTimePicker from "../../../../../../../../components/Form/FormDateTimePicker/FormDateTimePicker";
import { useRecoilValue } from "recoil";
import {
  PivotConfigFamily,
  PivotGPSStreamFamily,
} from "../../../../../../../../recoils/PivotRecoil";
import {
  validadeFloatInput,
  handleNumberMask,
  hasValidationErrors,
} from "../../../HistoricBox/utils/utils";
import { isMobile } from "../../../../../../../../mobileConfig";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import ToggleButton from "@material-ui/lab/ToggleButton";
import RedoIcon from "@material-ui/icons/Redo";
import { coreHTTPClient } from "../../../../../../../../services/webclient";
import useNotify from "../../../../../../../../hooks/tools/useNotify";
import { i18n } from "../../../../../../../../i18n/i18nText";
import InputTooltip from "../../../../../../../../components/InputTooltip/InputTooltip";
import { Skeleton } from "@material-ui/lab";
import FormCheckBox from "../../../../../../../../components/Form/FormCheckBox/FormCheckBox";
import FormOutlinedInput from "../../../../../../../../components/Form/FormOutlinedInput/FormOutlinedInput";

interface Props {
  pivot: Pivot;
  farmID: number;
}

function ConventionalIrrigationV5(props: Props) {
  const { pivot, farmID } = props;
  const notify = useNotify();

  const currentGPSStream = useRecoilValue(PivotGPSStreamFamily(pivot.id));

  const [createSimpleIrrigation] = useCreateSimpleIrrigation(
    farmID,
    pivot.id,
    pivot.control
  );
  const [isLoadingLastAction, setIsLoadingLastAction] = useState(false);

  const [direction, setDirection] = useState("");
  const [operation, setOperation] = useState("");
  const [injectionPumpStatus, setInjectionPumpStatus] = useState(false);
  const [injectionPumpNote, setInjectionPumpNote] = useState("");

  const [startMode, setStartMode] = useState("now");

  const [startTime, setStartTime] = useState(moment());

  const [disableButton, setDisableButton] = useState(true);

  const [blade, setBlade] = useState("100");

  const [millimeter, setMillimeter] = useState(
    PTPToMillimeter(props.pivot, 100).toFixed(4)
  );

  const [stopMode, setStopMode] = useState("0");

  const [angle, setAngle] = useState("0");

  const [duration, setDuration] = useState<any>({
    loading: true,
    duration: 0,
    formattedDuration: " - ",
    dateDuration: moment(),
  });

  const setValidAngle = useCallback((value: string) => {
    if (value === "") {
      setAngle(value);
    } else if (value === "0") {
      setAngle(value);
    } else if (
      validadeFloatInput(value) &&
      !hasValidationErrors(
        value,
        0,
        props.pivot.controllerconfig.content?.sector?.end_angle
      )
    ) {
      setAngle(handleNumberMask(value));
    }
    // eslint-disable-next-line
  }, []);

  const [rounds, setRounds] = useState("1");

  const setValidRounds = useCallback((value: string) => {
    if (value === "") {
      setRounds(value);
    } else if (
      validadeFloatInput(value) &&
      !hasValidationErrors(value, 1, 99)
    ) {
      setRounds(handleNumberMask(value));
    }
    // eslint-disable-next-line
  }, []);

  const farmTimezone = Store().store.getState().farm.data?.timezone;

  const gpsCurrentAngle = parseFloat(
    pivot.controllerstream_gps?.content?.current_angle?.current_angle
  );
  const irrigationDirection = direction === "1" ? true : false;
  const pauseTime1 = pivot.controllerconfig?.content?.pause_time_command
    ?.pause_time_command
    ? [
        `${pivot.controllerconfig?.content?.pause_time?.start_pause_time_hour_1}:${pivot.controllerconfig?.content?.pause_time?.start_pause_time_minute_1}`,
        `${pivot.controllerconfig?.content?.pause_time?.end_pause_time_hour_1}:${pivot.controllerconfig?.content?.pause_time?.end_pause_time_minute_1}`,
      ]
    : ["00:00", "00:00"];
  const pauseTime2 = [
    `${pivot.controllerconfig?.content?.pause_time?.start_pause_time_hour_2}:${pivot.controllerconfig?.content?.pause_time?.start_pause_time_minute_2}`,
    `${pivot.controllerconfig?.content?.pause_time?.end_pause_time_hour_2}:${pivot.controllerconfig?.content?.pause_time?.end_pause_time_minute_2}`,
  ];
  const timezone = farmTimezone ? farmTimezone : "America/Sao_Paulo";
  const startDate = startMode === "now" ? null : startTime.toISOString();
  const numberOfRounds = stopMode === "4" ? parseInt(rounds) : null;
  const isWetIrrigation = operation == "1" ? true : false;
  const sectorAngle = pivot.controllerconfig?.content?.sector?.end_angle
    ? pivot.controllerconfig?.content?.sector?.end_angle
    : 360;

  useEffect(() => {
    if (stopMode === "1") {
      setRounds("1");
    } else if (stopMode === "4") {
      setAngle("0");
    } else {
      setRounds("1");
      setAngle("0");
    }
  }, [stopMode]);

  const pivotConfig = useRecoilValue(PivotConfigFamily(props.pivot.id));

  const handleEndAngle = useCallback(
    (mode) => {
      switch (mode) {
        case "1":
          return parseFloat(angle);
        case "3":
          return pivot.controllerconfig?.content?.sector?.end_angle;
        case "4":
          return (
            Math.abs(
              pivot.controllerconfig?.content?.sector?.end_angle -
                pivot.controllerconfig?.content?.sector?.start_angle
            ) * parseInt(rounds)
          );
        default:
          return 0;
      }
    },
    [angle, pivot?.controllerconfig, rounds]
  );

  useEffect(() => {
    if (
      pivotConfig !== undefined &&
      (startMode === "after_pause_time_1" || startMode === "after_pause_time_2")
    ) {
      var config = pivotConfig as ControllerConfig;
      var hour;
      var minute;
      var second = 59;
      if (startMode === "after_pause_time_1") {
        hour = config.content.pause_time.end_pause_time_hour_1;
        minute = config.content.pause_time.end_pause_time_minute_1;
      } else if (startMode === "after_pause_time_2") {
        hour = config.content.pause_time.end_pause_time_hour_2;
        minute = config.content.pause_time.end_pause_time_minute_2;
      }
      setStartTime(
        moment()
          .hour(hour)
          .minute(minute + 5)
          .second(second)
      );
    }
    if (startMode === "now") {
      setStartTime(moment());
    }
  }, [pivotConfig, startMode]);

  // END BACK BUTTON STUFF ****

  var isLoading: boolean = useSelector(
    (state: ApplicationState) => state.loading.data.isLoading
  );

  const disableSaveButton = useMemo(() => {
    return (
      disableButton ||
      isLoading ||
      angle === "" ||
      rounds === "" ||
      direction == "" ||
      operation == "" ||
      direction == null ||
      operation == null ||
      injectionPumpNote.length > 200
    );
  }, [
    disableButton,
    isLoading,
    angle,
    rounds,
    operation,
    direction,
    injectionPumpNote,
  ]);

  useEffect(() => {
    if (operation === "2") {
      setBlade("100");
      setMillimeter(PTPToMillimeter(props.pivot, 100).toFixed(4));
    }
  }, [operation]);

  const InjectionPump = (
    <FormCheckBox
      label={"SIMPLE_IRRIGATION_FIELD_INJECTION_PUMP"}
      state={[injectionPumpStatus, setInjectionPumpStatus]}
    />
  );

  const setValidOperationMode = useCallback(
    (value: string) => {
      setOperation(value);
      if (value === "2") {
        setInjectionPumpStatus(false);
        setInjectionPumpNote("");
      }
    },
    [operation]
  );

  const InjectionPumpBox = (
    <FormOutlinedInput
      id="input"
      type="text"
      label={"IRRIGATION_FIELD_INJECTION_PUMP_NOTE"}
      max="200"
      helperText={
        injectionPumpNote.length > 200 ? "ERROR_INVALID_AMOUNT_MAX" : ""
      }
      state={[injectionPumpNote, setInjectionPumpNote]}
      multiline={true}
    />
  );
  const validRadioButtons = () => {
    if (
      (direction && operation && millimeter && blade) !== "" ||
      injectionPumpNote.length > 200
    ) {
      setDisableButton(false);
    } else {
      setDisableButton(true);
    }
  };

  async function getEstimatedIrrigationDuration() {
    var totalDuration = 0;
    var rawDuration = 0;

    setDuration({
      loading: true,
      duration: 0,
      formattedDuration: " - ",
      dateDuration: moment(),
    });

    var estimatedIrrigation: any = await estimatedIrrigationDurationOnServer(
      pivot.controllerconfig.content?.pivot_parameters?.radius_last,
      pivot.controllerconfig.content?.pivot_parameters?.speed,
      parseFloat(blade),
      irrigationDirection,
      gpsCurrentAngle,
      handleEndAngle(stopMode),
      sectorAngle,
      getWeekdays(pivot.controllerconfig),
      pauseTime1,
      pauseTime2,
      timezone,
      startDate,
      numberOfRounds,
      isWetIrrigation
    );

    totalDuration = Math.round(estimatedIrrigation.total_duration * 100) / 100;
    rawDuration = Math.round(estimatedIrrigation.raw_duration * 100) / 100;

    setDuration({
      loading: false,
      duration: rawDuration,
      formattedDuration: parseHHMM(rawDuration * 60),
      dateDuration: moment().add(totalDuration, "hour"),
    });
  }

  useEffect(() => {
    validRadioButtons();
  }, [direction, operation, millimeter, blade]);

  useEffect(() => {
    if (disableSaveButton) return;
    getEstimatedIrrigationDuration();
  }, [
    direction,
    startMode,
    operation,
    blade,
    angle,
    stopMode,
    startTime,
    pivot.config,
    rounds,
    disableSaveButton,
  ]);

  const handleButton = () => {
    var config = pivotConfig as ControllerConfig;
    const autoReversionCommand =
      config.content.autoreversion_command?.command || 0;

    createSimpleIrrigation({
      message_subtype: "simple",
      equipment: pivot.id,
      end_date_forecast: duration?.dateDuration?.toISOString(),
      operation_duration_forecast: duration?.duration,
      content: {
        injection_pump_command: {
          command: +injectionPumpStatus,
          note: injectionPumpNote,
        },
        autoreversion_command: {
          command: autoReversionCommand,
        },
        simple_irrigation_parameters: {
          mode: parseInt(operation),
          percent: parseFloat(blade),
          stop_mode: parseInt(stopMode),
          stop_angle: parseInt(angle),
          rounds: parseInt(rounds),
          start_mode: handleStartModeValues(startMode),
          start_year: startMode === "now" ? 0 : startTime.year() - 2000,
          start_month: startMode === "now" ? 0 : startTime.month() + 1,
          start_day: startMode === "now" ? 0 : startTime.date(),
          start_hour: startMode === "now" ? 0 : startTime.hour(),
          start_minute: startMode === "now" ? 0 : startTime.minute(),
        },
        irrigation_status: {
          irrigation_status: parseInt(direction),
          irrigation_type: 1,
        },
      },
    });
  };

  const handleStartModeValues = (startMode) => {
    if (startMode === "now") {
      return 0;
    } else {
      return 1;
    }
  };

  const handleStartModeOptions = (): [string, string][] => {
    var config = pivotConfig as ControllerConfig;
    if (
      config?.content.pause_time_command.pause_time_command === 1 &&
      config?.content.pause_time.start_pause_time_hour_2 ===
        config?.content.pause_time.end_pause_time_hour_2
    ) {
      return [
        ["now", "SEGMENT_IRRIGATION_MODE_NOW"], //now
        ["after_pause_time_1", "PIVOT_ACTION_FIELD_START_AFTER_PAUSE_TIME_1"], //after pause time hour 1
        ["on_a_date", "SEGMENT_IRRIGATION_ON_DATE"], //on a date
      ];
    } else if (
      config?.content.pause_time_command.pause_time_command === 1 &&
      config?.content.pause_time.start_pause_time_hour_2 !==
        config?.content.pause_time.end_pause_time_hour_2
    ) {
      return [
        ["now", "SEGMENT_IRRIGATION_MODE_NOW"], //now
        ["after_pause_time_1", "PIVOT_ACTION_FIELD_START_AFTER_PAUSE_TIME_1"], //after pause time hour 1
        ["after_pause_time_2", "PIVOT_ACTION_FIELD_START_AFTER_PAUSE_TIME_2"], //after pause time hour 3
        ["on_a_date", "SEGMENT_IRRIGATION_ON_DATE"], //on a date
      ];
    }
    return [
      ["now", "SEGMENT_IRRIGATION_MODE_NOW"], //now
      ["on_a_date", "SEGMENT_IRRIGATION_ON_DATE"], //on a date
    ];
  };

  const lastIrrigationUsed = async () => {
    setIsLoadingLastAction(true);
    const response = await coreHTTPClient.get(
      `v3/farms/${farmID}/pivots/${pivot.id}/controlleraction/simple/latest/`
    );
    if (response.data?.content?.simple_irrigation_parameters) {
      let lastIrrigationObj =
        response.data?.content.simple_irrigation_parameters;
      let lastIrrigationPump =
        response.data?.content.injection_pump_command.command;
      let lastIrrigationDirection =
        response.data?.content.irrigation_status.irrigation_status;
      setDirection(lastIrrigationDirection.toString());
      setOperation(lastIrrigationObj.mode.toString());
      setInjectionPumpStatus(lastIrrigationPump.toString());
      setBlade(lastIrrigationObj.percent);
      setMillimeter(
        PTPToMillimeter(pivot, parseInt(lastIrrigationObj.percent)).toString()
      );
      // start mode:
      // 0x00 - START NOW        0x01 - START ON A DATE
      if (lastIrrigationObj.start_mode == 1) {
        setStartMode("on_a_date");
        let startLastHour = moment(
          `20${lastIrrigationObj.start_year}-${lastIrrigationObj.start_month}-${lastIrrigationObj.start_day} ${lastIrrigationObj.start_hour}:${lastIrrigationObj.start_minute}`
        );
        if (startLastHour.isAfter(now())) setStartTime(startLastHour);
        //Só carrega a data se for depois de agora.
      } else setStartMode("now");
      //Stop mode:
      // 0x00 - Never ends
      // 0x01 - Stop by angle
      // 0x03 Stop end sector
      // 0x04 Stop by rounds
      setStopMode(lastIrrigationObj.stop_mode.toString());
      if (lastIrrigationObj.stop_mode == 1)
        setValidAngle(lastIrrigationObj.stop_angle.toString());
      if (lastIrrigationObj.stop_mode == 4)
        setValidRounds(lastIrrigationObj.rounds.toString());
    } else notify("IRRIGATION_BOX_CONVENTIONAL_REDO_ERROR_MSG", "error");
    setIsLoadingLastAction(false);
  };

  return (
    <div className="conventional-irrigation-v5">
      <div>
        {isLoading && <LinearProgress />}
        <div className="conventional-irrigation__center-row-without-margin">
          <Button
            style={isMobile() ? { width: "100%" } : {}}
            variant="contained"
            color="primary"
            disabled={isLoadingLastAction}
            endIcon={
              isLoading ? (
                <CircularProgress size={20} style={{ marginLeft: "8px" }} />
              ) : (
                <RedoIcon />
              )
            }
            onClick={lastIrrigationUsed}
          >
            <>{i18n("REDO_CONVENTIONAL_ACTION")}</>
          </Button>
        </div>
        <div
          style={{
            textAlign: "center",
            width: "100%",
            marginBottom: "20px",
            marginTop: "10px",
            fontSize: "14px",
          }}
        >
          {i18n("CURRENT_ANGLE")}{" "}
          {
            (currentGPSStream as ControllerStream).content?.current_angle
              ?.current_angle
          }
          º
        </div>
        <div className="conventional-irrigation-v5__grid-1-1">
          <div className="conventional-irrigation-v5__label">
            {i18n("SIMPLE_IRRIGATION_FIELD_DIRECTION")}
          </div>
          <ToggleButtonGroup
            value={direction}
            exclusive
            onChange={(event, newDirection) => setDirection(newDirection)}
            aria-label="direction"
            style={{ width: 280, marginTop: 5, marginBottom: 5 }}
          >
            <ToggleButton
              value="1"
              aria-label="Progress"
              style={{ width: 140 }}
            >
              <RotateRight />
              {i18n("PIVOT_ACTION_VALUE_DIRECTION_PROGRESS")}
            </ToggleButton>
            <ToggleButton value="2" aria-label="Reverse" style={{ width: 140 }}>
              <RotateLeft />
              {i18n("PIVOT_ACTION_VALUE_DIRECTION_REVERSE")}
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
        <div className="conventional-irrigation-v5__grid-1-1">
          <div className="conventional-irrigation-v5__label">
            <>{i18n("SIMPLE_IRRIGATION_FIELD_MODE")}</>
          </div>
          <ToggleButtonGroup
            value={operation}
            exclusive
            onChange={(event, newOperation) =>
              setValidOperationMode(newOperation)
            }
            aria-label="text alignment"
            style={{ width: 280, marginTop: 5, marginBottom: 5 }}
          >
            <ToggleButton
              value="1"
              aria-label="Progress"
              style={{ width: 140 }}
            >
              <Opacity style={{ height: "25px" }} />
              <>{i18n("PIVOT_ACTION_VALUE_MODE_WET")}</>
            </ToggleButton>
            <ToggleButton value="2" aria-label="Reverse" style={{ width: 140 }}>
              <FormatColorResetIcon />
              <>{i18n("PIVOT_ACTION_VALUE_MODE_DRY")}</>
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
        {operation === "1" && pivot?.controllerconfig?.injection_pump
          ? InjectionPump
          : null}
        {injectionPumpStatus ? InjectionPumpBox : null}
        <SpeedInputs
          pivot={pivot}
          millimeter={millimeter}
          percentage={blade}
          setMillimeter={setMillimeter}
          setPercentage={setBlade}
        />

        <div className={`conventional-irrigation-v5__row-50`}>
          <FormSelect
            id="segment-start-mode"
            label={"SEGMENT_IRRIGATION_FIELD_START_MODE"}
            state={[startMode, setStartMode]}
            // @ts-ignore
            values={handleStartModeOptions()}
          />
          <FormDateTimePicker
            id="segment-start-date"
            minDate={moment()}
            disabled={startMode === "now"}
            label={"SEGMENT_IRRIGATION_FIELD_START_DATE"}
            state={[startTime, setStartTime]}
          />
        </div>

        <div className={`conventional-irrigation-v5__row-50`}>
          <FormSelect
            id="stop-mode"
            label={"SIMPLE_IRRIGATION_FIELD_STOP_MODE"}
            state={[stopMode, setStopMode]}
            values={[
              ["0", "SIMPLE_IRRIGATION_NEVER_ENDS"], // Never Ends
              ["1", "SIMPLE_IRRIGATION_BY_ANGLE"], // Stop by Angle
              ["3", "SIMPLE_IRRIGATION_END_SECTOR"], // Stop End Sector
              ["4", "SIMPLE_IRRIGATION_BY_ROUNDS"], // Stop by Rounds
            ]}
          />
          {stopMode === "1" ? (
            <FormInput
              state={[angle, setValidAngle]}
              id="angle"
              label={"SIMPLE_IRRIGATION_FIELD_ANGLE_TO_STOP"}
              inputMode="numeric"
              endAdornment="º"
            />
          ) : (
            <></>
          )}
          {stopMode === "4" ? (
            <FormInput
              state={[rounds, setValidRounds]}
              id="rounds"
              label={"SIMPLE_IRRIGATION_FIELD_ROUNDS_TO_STOP"}
              inputMode="numeric"
              endAdornment=""
            />
          ) : (
            <></>
          )}
        </div>
      </div>
      {stopMode !== "0" && direction && (
        <>
          <div
            style={{
              fontSize: "15px",
              display: "flex",
              marginBottom: "-8px",
              marginTop: "8px",
            }}
          >
            <strong style={{ marginBottom: "10px", marginRight: "5px" }}>
              {i18n("IRRIGATION_END_DURATION")}
            </strong>
            {duration?.loading ? (
              <Skeleton width={100} />
            ) : (
              duration.formattedDuration
            )}
          </div>
          <div
            style={{
              fontSize: "15px",
              display: "flex",
              alignItems: "center",
              marginBottom: "-8px",
            }}
          >
            <strong style={{ marginRight: "5px" }}>
              {i18n("IRRIGATION_END_DATE_PREDICTION")}
            </strong>
            {duration?.loading ? (
              <Skeleton width={60} />
            ) : (
              duration.dateDuration?.format("DD MMM HH:mm")
            )}
            <div style={{ margin: "0 0 2px -5px" }}>
              <InputTooltip
                size={14}
                description={i18n("IRRIGATION_END_DATE_PREDICTION_TOOLTIP")}
              />
            </div>
          </div>
        </>
      )}
      <div className="conventional-irrigation-v5__center-row">
        <Button
          style={isMobile() ? { width: "100%" } : null}
          variant="contained"
          color="primary"
          disabled={disableSaveButton}
          endIcon={
            isLoading ? (
              <CircularProgress size={20} style={{ marginLeft: "8px" }} />
            ) : (
              <Icon>opacity</Icon>
            )
          }
          onClick={handleButton}
        >
          {i18n("IRRIGATION_BOX_CONVENTIONAL_BUTTON")}
        </Button>
      </div>
    </div>
  );
}

export default ConventionalIrrigationV5;

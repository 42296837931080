import React, { useMemo, useEffect, useState } from "react";
import moment from "moment";
import { SegmentIrrigation } from "../../../../../../../../../../redux/pivots/types";
import {
  irrigationEndMode,
  irrigationStartMode,
  formatDateTime2,
  irrigationMode,
  irrigationInjectionPump,
} from "../../../../utils/utils";

import { DATETIME_FORMAT } from "../../../../../../../../../../utils/consts";
import { useParams } from "react-router";
import usePivot from "../../../../../../../../../../hooks/models/usePivot";
import { i18n } from "../../../../../../../../../../i18n/i18nText";
import { PTPToMillimeter } from "../../../../../../../../../../utils/models/pivots";
import { formatFloatValue } from "../../../../../../../../../../utils/models/format";

interface Props {
  segmentIrrigation: SegmentIrrigation;
}

function ExpandedSegmentIrrigation(props: Props): any {
  const { segmentIrrigation } = props;

  const pivotID = useParams().pivot;

  const farmID = useParams().farm;

  const [asyncStatus, pivot, _] = usePivot(parseInt(farmID), parseInt(pivotID));

  const [segments, setSegments] = useState<any>(undefined);

  const createdOnHardware = <span>{i18n("HISTORIC_CREATED_ON_HARDWARE")}</span>;

  const createdAt = useMemo(
    () => moment(segmentIrrigation.created).format(DATETIME_FORMAT),
    [segmentIrrigation.created]
  );
  const startTime = useMemo(
    () =>
      formatDateTime2(
        segmentIrrigation.content.segment_irrigation_parameters.start_time_year,
        segmentIrrigation.content.segment_irrigation_parameters
          .start_time_month,
        segmentIrrigation.content.segment_irrigation_parameters.start_time_day,
        segmentIrrigation.content.segment_irrigation_parameters.start_time_hour,
        segmentIrrigation.content.segment_irrigation_parameters
          .start_time_minute
      ),
    [segmentIrrigation.content]
  );
  const endTime = useMemo(
    () =>
      formatDateTime2(
        segmentIrrigation.content.segment_irrigation_parameters.end_time_year,
        segmentIrrigation.content.segment_irrigation_parameters.end_time_month,
        segmentIrrigation.content.segment_irrigation_parameters.end_time_day,
        segmentIrrigation.content.segment_irrigation_parameters.end_time_hour,
        segmentIrrigation.content.segment_irrigation_parameters.end_time_minute
      ),
    [segmentIrrigation.content]
  );

  const createdByUser = (
    <span>
      {i18n("HISTORIC_CREATED_BY")}
      <b>{i18n("VALUE", [`${segmentIrrigation.username}`])}</b>
    </span>
  );

  const startDateTime = (
    <span>
      {i18n("HISTORIC_SEGMENT_IRRIGATION_START_AT")}
      <b>{i18n("VALUE", [`${startTime}`])}</b>
    </span>
  );

  const segmentIrrigationContent = (
    <>
      <span>
        {i18n("HISTORIC_SEGMENT_IRRIGATION_START_MODE")}
        <b>
          {i18n("VALUE", [
            irrigationStartMode(
              segmentIrrigation.content.segment_irrigation_parameters.start_mode
            ),
          ])}
        </b>
      </span>
      {segmentIrrigation.content.segment_irrigation_parameters.start_mode ===
      1 ? (
        startDateTime
      ) : (
        <></>
      )}
      <span>
        {i18n("HISTORIC_SEGMENT_IRRIGATION_END_MODE")}
        <b>
          {i18n("VALUE", [
            irrigationEndMode(
              segmentIrrigation.content.segment_irrigation_parameters.end_mode
            ),
          ])}
        </b>
      </span>
      {segmentIrrigation.content.segment_irrigation_parameters.end_mode ===
      1 ? (
        <span>
          {i18n("HISTORIC_SEGMENT_IRRIGATION_END_AT")}
          <b>
            {i18n("VALUE_AND_UNIT_DEGREES", [
              segmentIrrigation.content.segment_irrigation_parameters?.stop_angle?.toString(),
            ])}
          </b>
        </span>
      ) : (
        <></>
      )}
      {segmentIrrigation.content.segment_irrigation_parameters.end_mode ===
      2 ? (
        <span>
          {i18n("HISTORIC_SEGMENT_IRRIGATION_END_AT")}
          <b>{i18n("VALUE", [`${endTime}`])}</b>
        </span>
      ) : (
        <></>
      )}
      {segmentIrrigation.content.segment_irrigation_parameters.end_mode ===
      4 ? (
        <span>
          {i18n("HISTORIC_SEGMENT_IRRIGATION_END_AT")}
          <b>
            {i18n("VALUE", [
              segmentIrrigation.content.segment_irrigation_parameters.rounds.toString(),
            ])}
          </b>
        </span>
      ) : (
        <></>
      )}
    </>
  );

  const stopPivotContent = <>{i18n("HISTORIC_PANEL_STREAM_V5_STOPPED")}</>;

  useEffect(() => {
    if (asyncStatus && pivot) {
      setSegments(pivot.controllerconfig?.content?.segments);
    }
  }, [asyncStatus, pivot]);

  if (segments)
    return (
      <div className="expanded-content__background">
        <div className="expanded-content__group">
          <span>
            {i18n("HISTORIC_CREATED_AT")}
            <b>{i18n("VALUE", [`${createdAt}`])}</b>
          </span>
          {segmentIrrigation.created_on_hardware
            ? createdOnHardware
            : createdByUser}
          <br></br>
          {segmentIrrigation.content.irrigation_status.irrigation_status === 0
            ? stopPivotContent
            : segmentIrrigationContent}
          <span>
            {i18n("CURRENT_ANGLE")}
            <b>
              {i18n("VALUE_AND_UNIT_DEGREES", [
                ` ${formatFloatValue(segmentIrrigation.current_angle, 1)}`,
              ])}
            </b>
          </span>
        </div>
        <div className="expanded-content__group">
          {segmentIrrigation.content.segment_modes.map((segment, index) => {
            return (
              <>
                <div>
                  {pivot?.controllerconfig?.segments_crop?.[index]?.name
                    ? pivot?.controllerconfig?.segments_crop?.[index]?.name
                    : `${i18n("PIVOT_CONFIG_V5_FIELD_SEGMENT")} ${index + 1}:`}
                </div>
                {segmentIrrigation.content.irrigation_status
                  .irrigation_status === 1 && segment ? (
                  <div>
                    {`${i18n("PIVOT_CONFIG_V5_FORWARD")} - ${irrigationMode(
                      segment.mode_forward
                    )} - ${segment.percent_forward}% - ${formatFloatValue(
                      PTPToMillimeter(pivot, segment.percent_forward)
                    )} mm`}
                  </div>
                ) : null}
                {segmentIrrigation.content.irrigation_status
                  .irrigation_status === 2 && segment ? (
                  <div>
                    {i18n("HISTORIC_SEGMENT_IRRIGATION_REVERSE_INFO", [
                      `${irrigationMode(segment.mode_reverse)}`,
                      `${segment.percent_reverse}%`,
                    ])}
                  </div>
                ) : null}
                <br></br>
                {!!segmentIrrigation.content?.injection_pump_command
                  ?.command && (
                  <>
                    <div>
                      <span>
                        {i18n(
                          "HISTORIC_SIMPLE_IRRIGATION_INJECTION_PUMP_STATUS"
                        )}
                        <b>
                          {i18n("VALUE", [
                            irrigationInjectionPump(
                              segmentIrrigation.content?.injection_pump_command
                                ?.command
                            ),
                          ])}
                        </b>
                      </span>
                    </div>
                    <div>
                      <span>
                        {i18n("IRRIGATION_FIELD_INJECTION_PUMP_NOTE_EXPANDED")}
                        <b>
                          {
                            segmentIrrigation.content?.injection_pump_command
                              ?.note
                          }
                        </b>
                      </span>
                    </div>
                  </>
                )}
              </>
            );
          })}
        </div>
      </div>
    );

  return <></>;
}

export default ExpandedSegmentIrrigation;

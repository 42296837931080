import { Collapse, TableCell, TableRow } from "@material-ui/core";
import React, { useState } from "react";
import { isMobile } from "../../mobileConfig";
import { Column } from "./GeneralTable";
import "./GeneralTable.scss";

export default function GeneralTableItem({ row, columns, index, type }: any) {
  const array: number[] = Array(row.length).fill(-1);
  const [expanded, setExpanded] = useState<number[]>(array);
  const [flag, setFlag] = useState<boolean>(false);

  const ValuedColumn = React.memo(
    (props: { column: Column } & { value: any }) => (
      <TableCell
        style={isMobile() ? { padding: "10px" } : {}}
        key={props.column.id}
        align={props.column.align}
      >
        {props.column.format && typeof props.value === "number"
          ? props.column.format(props.value)
          : props.value}
      </TableCell>
    ),
    (prevProps, nextProps) => {
      if (prevProps.value !== nextProps.value) return true;
    }
  );

  return (
    <>
      <TableRow
        hover
        role="checkbox"
        style={{
          backgroundColor: row.color,
          height: 64,
          overflow: "hidden",
        }}
        tabIndex={-1}
        key={row.id}
        onClick={() => {
          let aux = expanded;

          if (expanded[index] !== row.id) {
            aux[index] = row.id;
            setFlag(true);
            setExpanded(aux);
          } else if (expanded[index] === row.id) {
            aux[index] = -1;
            setFlag(false);
            setExpanded(aux);
          }
        }}
      >
        {columns.map((column: Column) => {
          return (
            <ValuedColumn
              key={column.id}
              column={column}
              value={row[column.id]}
            />
          );
        })}
      </TableRow>

      <TableCell
        padding={"none"}
        colSpan={12}
        style={{ borderWidth: 0, width: "100%" }}
      >
        <Collapse in={flag}>
          <>{row.datailedComponent()}</>
        </Collapse>
      </TableCell>
    </>
  );
}

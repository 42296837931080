import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useContext,
  useMemo,
} from "react";
import "./PivotItem.scss";
import {
  Pivot,
  ControllerStream,
  GPSStream,
  PIVOT_COMMUNICATION_TYPE,
} from "../../../../../../../../redux/pivots/types";
import {
  convertIrrigationRemainingTimeToHoursMinutes,
  fillMapHistoryBySocket,
  isOldProtocol,
  isValidIrrigationRemainingTime,
} from "../../../../../../../../utils/models/pivots";
import { useNavigate, useParams } from "react-router";
import { IconButton, Slide, Tooltip } from "@material-ui/core";
import MobileZone from "../../../../../../../../components/Zones/MobileZone";
import DesktopZone from "../../../../../../../../components/Zones/DesktopZone";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Done, Autorenew, Opacity } from "@material-ui/icons";
import useSocketIO from "../../../../../../../../hooks/tools/useSocketIO";
import { DrawerContext } from "../../../../../../components/DrawerProvider/DrawerProvider";
import {
  PivotLight,
  PivotPainelStreamFamily,
  PivotGPSStreamFamily,
  FallbackPivot,
  LatestStreamPivot,
  PivotPeriodicStreamFamily,
  AtomFamilySetterHelper,
} from "../../../../../../../../recoils/PivotRecoil";

import { superUserStatus } from "../../../../../../../../recoils/SuperUserStatusRecoil";

import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import moment from "moment";
import { coreHTTPClient } from "../../../../../../../../services/webclient";
import {
  pivotActionDirectionIcon,
  pivotActionDirectionIconV5,
} from "../../../../../../Pivot/SelectedPivot/components/HistoricBox/utils/utils";
import styled from "@emotion/styled";
import { i18n, i18nTextId } from "../../../../../../../../i18n/i18nText";
import StatusHelper from "./StatusHelperPopover";
import { getDailyMesure } from "../../../../../../Pivot/utils/utils";
import routes from "../../../../../../../../routes/routes";
import SignalWifi3BarIcon from "@material-ui/icons/SignalWifi3Bar";
import SignalWifiOffIcon from "@material-ui/icons/SignalWifiOff";

const { SOCKET_SUFFIX } = process.env;

const InformationGrid = styled.div``;

interface Props {
  pivot: Pivot | PivotLight;
  sweepStatus?: number; // 0 - sweep not activated, 1 - sweep started, 2 - sweep done successfully
  shouldNotShowMapPreview?: boolean;
  shouldHideLatestStatus?: boolean;
  shouldHideInfoFromRecoil?: boolean;
}

function PivotItem(props: Props) {
  const { pivot } = props;

  const farmID: string = useParams().farm;

  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);

  const [runningSweep, setRunningSweep] = useState<number>(undefined);

  const [isConnected, socket] = useSocketIO();

  const ctx = useContext(DrawerContext);

  const pivotItemHandler = useCallback(() => {
    ctx.setFarmID(farmID);

    ctx.setPivotID(String(props.pivot.id));
    navigate(
      routes.DASHBOARD.SELECTED_PIVOT.replace(":farm", farmID).replace(
        ":pivot",
        String(props.pivot.id)
      )
    );
  }, [farmID, props.pivot.id]);

  const [newLatestPanelStream, setNewLatestPanelStream] = useRecoilState(
    PivotPainelStreamFamily(props.pivot.id)
  );

  const [newLatestGPSStream, setNewLatestGPSStream] = useRecoilState(
    PivotGPSStreamFamily(props.pivot.id)
  );

  const [latestPeriodicStream, setLatestPeriodicStream] = useRecoilState(
    PivotPeriodicStreamFamily(props.pivot.id)
  );

  // Checagem de super usuário
  const [superUser, setSuperUser] = useRecoilState(superUserStatus);
  // fim checagem

  // Referencia dos estados para não haver problemas quando paralelizar ele no Interval
  const newLatestPanelStreamRef = useRef(newLatestPanelStream);

  const newLatestGPSStreamRef = useRef(newLatestGPSStream);

  useEffect(() => {
    newLatestPanelStreamRef.current = newLatestPanelStream;
    newLatestGPSStreamRef.current = newLatestGPSStream;
  }, [newLatestPanelStream, newLatestGPSStream]);

  const [fallbackPivot, setFallbackPivot] = useRecoilState(
    FallbackPivot(props.pivot.id)
  );

  const [newLatestAngle, setNewLatestAngle] = useState<string | undefined>(
    undefined
  );

  const [isRaining, setIsRaining] = useState(false);

  useEffect(() => {
    if (
      latestPeriodicStream?.content?.pluviometer_daily_measure?.daily_measure >
      0
    ) {
      setIsRaining(
        moment().diff(moment(latestPeriodicStream?.created), "minutes") <= 70
      );
    }
  }, [latestPeriodicStream]);

  const [lastUpdateHour, setLastUpdateHour] = useState<any>();

  const showPluviometerInfos = useMemo(() => {
    if (fallbackPivot?.pluviometer && pivot.protocol === 5) {
      return true;
    }
    return false;
  }, [fallbackPivot?.pluviometer, pivot.protocol]);

  const showPluviometerMeasure = useMemo(() => {
    const periodicDate: moment.Moment = moment(latestPeriodicStream?.created);
    const dateCompare: boolean =
      periodicDate.day() === moment().day() &&
      periodicDate.month() === moment().month() &&
      periodicDate.year() === moment().year();

    if (dateCompare) {
      return true;
    }
    return false;
  }, [latestPeriodicStream]);

  const atomFamilySetter = useSetRecoilState(AtomFamilySetterHelper);

  useEffect(() => {
    if (isConnected) {
      socket.subscribe(`${SOCKET_SUFFIX}pivot@${props.pivot.id}`);

      if (isOldProtocol(props.pivot as any)) {
        socket.bind("painel_stream", (data) => {
          // setLatestPanelStream(data);
          setNewLatestPanelStream(data);
        });

        socket.bind("gps_stream", (data) => {
          if (
            fallbackPivot &&
            pivot &&
            fallbackPivot.map_history &&
            pivot.id == fallbackPivot.id
          ) {
            const newMapHistory = fillMapHistoryBySocket(
              fallbackPivot.map_history,
              data,
              fallbackPivot.protocol
            );

            if (newMapHistory) {
              atomFamilySetter({
                PivotFamilyAtom: FallbackPivot,
                pivotId: pivot.id,
                value: {
                  ...fallbackPivot,
                  map_history: [...newMapHistory],
                },
              });
            }
          }
          // setLatestGPSStream(data);
          setNewLatestGPSStream(data);
        });
      } else {
        socket.bind("ControllerStream_panel", (data) => {
          if (data.content.irrigation_status?.irrigation_status === 24) return;
          setNewLatestPanelStream(data);
        });

        socket.bind("ControllerStream_gps", (data) => {
          if (
            data.content?.latitude_longitude_gps?.latitude_gps === 0 ||
            data.content?.latitude_longitude_gps?.longitude_gps === 0
          ) {
            return;
          }

          if (
            fallbackPivot &&
            pivot &&
            fallbackPivot.map_history &&
            fallbackPivot.id == pivot.id
          ) {
            const newMapHistory = fillMapHistoryBySocket(
              fallbackPivot.map_history,
              data,
              fallbackPivot.protocol
            );

            if (newMapHistory) {
              atomFamilySetter({
                PivotFamilyAtom: FallbackPivot,
                pivotId: pivot.id,
                value: {
                  ...fallbackPivot,
                  map_history: [...newMapHistory],
                },
              });
            }
          }
          setNewLatestGPSStream(data);
        });

        socket.bind("ControllerStream_periodic", (data) => {
          setLatestPeriodicStream(data);
        });
      }
    }

    return () => {
      socket.unbind("painel_stream");
      socket.unbind("gps_stream");
      socket.unbind("ControllerStream_panel");
      socket.unbind("ControllerStream_gps");
      socket.unbind("ControllerStream_periodic");
      socket.unsubscribe(`d@pivot@${props.pivot.id}`);
    };
  }, [fallbackPivot]);

  useEffect(() => {
    if (props.sweepStatus !== undefined) {
      setRunningSweep(props.sweepStatus);
    }
  }, [props.sweepStatus]);

  // Função para a comparação de valores de cada objeto feito para apenas atualizar o Pivo se ele realmente for diferente
  function isObject(object) {
    return object != null && typeof object === "object";
  }

  function deepEqual(object1, object2) {
    if (!object1 || !object2) return false;
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);

    if (keys1.length !== keys2.length) {
      return false;
    }

    for (const key of keys1) {
      const val1 = object1[key];
      const val2 = object2[key];
      const areObjects = isObject(val1) && isObject(val2);
      if (
        (areObjects && !deepEqual(val1, val2)) ||
        (!areObjects && val1 !== val2)
      ) {
        return false;
      }
    }

    return true;
  }

  // Este Use Effect é uma segunda segurança para manter o stream que vem pelo
  // subscibe atualizado caso ele falhe.
  // logo a cada um minuto ele realiza um "Check"
  useEffect(() => {
    const id = setInterval(async () => {
      const response = await coreHTTPClient.get(
        `v3/farms/${farmID}/pivots/${pivot.id}/stream/latest/`
      );
      if (response.data) {
        if (
          response.data.latest_panel_stream &&
          !deepEqual(
            response.data.latest_panel_stream,
            newLatestPanelStreamRef.current
          )
        ) {
          setNewLatestPanelStream(response.data.latest_panel_stream);
        }
        if (
          response.data.latest_gps_stream &&
          !deepEqual(
            response.data.latest_gps_stream,
            newLatestGPSStreamRef.current
          )
        ) {
          setNewLatestGPSStream(response.data.latest_gps_stream);
        }
      }
    }, 60 * 1000);

    return () => {
      clearInterval(id);
    };
  }, []);

  const latestStreamPivot = useRecoilValue(LatestStreamPivot(pivot.id));

  useEffect(() => {
    const { pivot } = props;
    if (pivot && pivot.protocol !== 5 && newLatestGPSStream) {
      setNewLatestAngle((newLatestGPSStream as GPSStream)?.angle);
    } else if (pivot && pivot.protocol === 5 && newLatestGPSStream) {
      setNewLatestAngle(
        (newLatestGPSStream as ControllerStream)?.content?.current_angle
          ?.current_angle
      );
    }
  }, [newLatestGPSStream]);

  useEffect(() => {
    setLatestPeriodicStream((prevState) => {
      if (!prevState) {
        return fallbackPivot?.controllerstream_periodic;
      }
      if (
        prevState?.content?.pluviometer_total?.total_measure <
        fallbackPivot?.controllerstream_periodic?.content?.pluviometer_total
          ?.total_measure
      ) {
        setIsRaining(true);
      } else setIsRaining(false);
    });
  }, [fallbackPivot?.controllerstream_periodic]);

  const irrigationData = useMemo(() => {
    if (newLatestPanelStream && newLatestGPSStream) {
      if (
        moment(newLatestPanelStream.created).isSameOrAfter(
          moment(newLatestGPSStream.created).second(0)
        )
      ) {
        return null;
      }

      if (pivot.protocol === 5) {
        if (
          (newLatestGPSStream as ControllerStream).content
            ?.current_irrigation_information?.direction === 0 ||
          !(newLatestGPSStream as ControllerStream).content
            ?.current_irrigation_information?.irrigation_percent
        ) {
          return null;
        }

        return {
          percentage: (newLatestGPSStream as ControllerStream).content
            .current_irrigation_information.irrigation_percent,
          direction: pivotActionDirectionIconV5(
            (newLatestGPSStream as ControllerStream).content
              .current_irrigation_information.direction
          ),
        };
      }

      if (
        (newLatestGPSStream as GPSStream).clockwise === 0 ||
        !(newLatestGPSStream as GPSStream).speed
      ) {
        return null;
      }

      return {
        percentage: (newLatestGPSStream as GPSStream).speed,
        direction: pivotActionDirectionIcon(
          (newLatestGPSStream as GPSStream).clockwise
        ),
      };
    }
    return null;
  }, [newLatestGPSStream, newLatestPanelStream, pivot]);

  useEffect(() => {
    setLastUpdateHour(
      newLatestPanelStream && newLatestGPSStream ? ( // Verifica se existe alguns dos streams vindos do RECOIL
        moment(newLatestPanelStream?.created) >
        moment(newLatestGPSStream?.created) ? ( // Verifica se a hora do panelStream é maior que o GPSStream
          moment(newLatestPanelStream?.created).format("DD MMM HH:mm") // Caso SIM retorna o moment do Panel
        ) : (
          moment(newLatestGPSStream?.created).format("DD MMM HH:mm") // Caso Nao retorna o moment do GPS
        )
      ) : (pivot as Pivot).latest_gps_stream && // Caso de falha com o RECOIL ele pega pelo ultimo dado que vem da reponse do usePivots V5 ***DEPRECADO***
        (pivot as Pivot).latest_panel_stream ? (
        moment((pivot as Pivot).latest_panel_stream.arrived) >
        moment((pivot as Pivot).latest_gps_stream.arrived) ? (
          moment((pivot as Pivot).latest_panel_stream.arrived).format(
            "DD MMM HH:mm"
          )
        ) : (
          moment((pivot as Pivot).latest_gps_stream.arrived).format(
            "DD MMM HH:mm"
          )
        )
      ) : (pivot as Pivot).controllerstream_panel && // Caso de falha com o RECOIL ele pega pelo ultimo dado que vem da reponse do usePivots v4 ***DEPRECADO***
        (pivot as Pivot).controllerstream_gps ? (
        moment((pivot as Pivot).controllerstream_panel.arrived) >
        moment((pivot as Pivot).controllerstream_gps.arrived) ? (
          moment((pivot as Pivot).controllerstream_panel.arrived).format(
            "DD MMM HH:mm"
          )
        ) : (
          moment((pivot as Pivot).controllerstream_gps.arrived).format(
            "DD MMM HH:mm"
          )
        )
      ) : (
        <>{i18n("UPDATING")}</> // Enquanto esta sem valor ele fica com o sinal de atualizando...
      )
    );
    setLoading(false);
  }, [newLatestGPSStream, newLatestPanelStream]);

  const COMMUNICATION_TYPE = ["XBee", "4G/LTE"];

  const isOnlineIndicator = (
    <IconButton color="primary">
      {pivot.communication_type === PIVOT_COMMUNICATION_TYPE.TYPE_4G &&
      pivot.is_online !== null ? (
        pivot.is_online ? (
          <Tooltip title={i18n("SELECTED_PIVOT_ONLINE")}>
            <SignalWifi3BarIcon style={{ color: "#037ade" }} />
          </Tooltip>
        ) : (
          <Tooltip title={i18n("SELECTED_PIVOT_OFFLINE")}>
            <SignalWifiOffIcon style={{ color: "gray", opacity: "0.6" }} />
          </Tooltip>
        )
      ) : null}
    </IconButton>
  );

  return (
    <div className="pivot-item__content">
      {loading ? (
        <></>
      ) : (
        <>
          <DesktopZone>
            <div className="pivot-item" onClick={pivotItemHandler}>
              <div className="pivot-item__row-left">
                <div className="pivot-item__text-container">
                  <div className="pivot-item__text-content">
                    <div className="pivot-item__title">
                      {props.pivot.name}

                      {!props.shouldHideLatestStatus && latestStreamPivot && (
                        <>
                          {superUser && (
                            <>
                              <div
                                className="protocol"
                                style={{
                                  backgroundColor: "808080",
                                }}
                              >
                                {`v${props.pivot.protocol}`}
                              </div>
                              <div
                                className="protocol"
                                style={{
                                  backgroundColor: "808080",
                                }}
                              >
                                {`${
                                  COMMUNICATION_TYPE[
                                    props.pivot.communication_type
                                  ]
                                }`}
                              </div>
                            </>
                          )}

                          <div
                            className="pivot-item__status"
                            style={{
                              backgroundColor:
                                latestStreamPivot?.streamStatus?.color,
                            }}
                          >
                            {latestStreamPivot?.streamStatus?.text.toUpperCase()}

                            {irrigationData && (
                              <div
                                style={{
                                  // fontWeight: "normal",
                                  marginLeft: "5px",
                                  flexWrap: "wrap",
                                }}
                              >
                                {` ${irrigationData.percentage}% `}

                                {irrigationData.direction}
                              </div>
                            )}

                            {latestStreamPivot?.streamStatus?.number === 4 &&
                            isValidIrrigationRemainingTime(
                              latestStreamPivot?.irrigation_remaining_time
                            )
                              ? convertIrrigationRemainingTimeToHoursMinutes(
                                  latestStreamPivot?.irrigation_remaining_time
                                )
                              : ""}
                          </div>
                          {latestStreamPivot?.streamStatus?.text ===
                            i18n("PAINEL_STREAM_REASON_256") ||
                          latestStreamPivot?.streamStatus?.number === 38 ? (
                            <StatusHelper />
                          ) : null}
                        </>
                      )}
                    </div>

                    {showPluviometerInfos && (
                      <div
                        title={isRaining ? i18n("RAINING_NOW") : ""}
                        className="pivot-item__angle"
                        style={{ marginBottom: "8px" }}
                      >
                        <Opacity
                          style={{
                            color: isRaining ? "#0066ff" : "black",
                            marginLeft: "-5px",
                          }}
                        />

                        <div style={{ marginBottom: "-3px" }}>
                          {getDailyMesure(
                            showPluviometerMeasure,
                            latestPeriodicStream?.content
                              ?.pluviometer_daily_measure?.daily_measure
                          )}
                        </div>
                      </div>
                    )}

                    {newLatestAngle !== undefined &&
                      !props.shouldHideInfoFromRecoil &&
                      pivot.automation_type !== 2 && (
                        <div className="pivot-item__angle">
                          <Autorenew style={{ marginLeft: "-5px" }} />
                          {isOldProtocol(props.pivot)
                            ? `${
                                parseFloat(newLatestAngle) >= 0
                                  ? `${String(
                                      Math.round(parseFloat(newLatestAngle))
                                    )}º`
                                  : "-"
                              }`
                            : `${
                                parseFloat(newLatestAngle) >= 0
                                  ? `${String(
                                      Math.round(newLatestAngle as any)
                                    )}º`
                                  : "-"
                              }`}
                        </div>
                      )}

                    <div
                      className="pivot-item__last-update"
                      style={{
                        display: "grid",
                        gridTemplateColumns: "5fr 1fr",
                        gridGap: "5px",
                        alignItems: "center",
                      }}
                    >
                      {lastUpdateHour}
                      {isOnlineIndicator}
                    </div>
                  </div>
                </div>
              </div>

              <div className="pivot-item__row-right">
                <div className="pivot-item__sweep-status">
                  {runningSweep === 1 ? <CircularProgress /> : <></>}

                  {runningSweep === 2 ? <Done /> : <></>}
                </div>
              </div>
            </div>
          </DesktopZone>

          <MobileZone>
            <Slide direction="down" in>
              <div className="pivot-item" onClick={pivotItemHandler}>
                <div className="pivot-item__row-left">
                  {!props.shouldNotShowMapPreview &&
                  (fallbackPivot?.image || (props.pivot as Pivot).image) ? (
                    <img
                      src={
                        (fallbackPivot?.image || (props.pivot as Pivot).image) +
                        process.env.MAPS_KEY
                      }
                      alt="pivot"
                    />
                  ) : props.shouldNotShowMapPreview ? (
                    <></>
                  ) : (
                    <div
                      style={{
                        marginLeft: 10,
                        alignSelf: "center",
                        width: 90,
                        height: 90,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <CircularProgress
                        style={{ color: "#00000033" }}
                        size={50}
                      />
                    </div>
                  )}

                  {/* <img src={createPivotMapUrl(props.pivot)} alt="" /> */}
                  <div className="pivot-item__text-container">
                    <div className="pivot-item__text-content">
                      <div className="pivot-item__title">
                        {props.pivot.name}

                        {superUser && (
                          <div
                            className="protocol"
                            style={{
                              backgroundColor: "808080",
                            }}
                          >
                            {`v${props.pivot.protocol}`}
                          </div>
                        )}

                        {!props.shouldHideLatestStatus && latestStreamPivot && (
                          <div
                            className="pivot-item__status"
                            style={{
                              backgroundColor:
                                latestStreamPivot?.streamStatus?.color,
                            }}
                          >
                            {latestStreamPivot?.streamStatus?.text.toUpperCase()}

                            {irrigationData && (
                              <div
                                style={{
                                  // fontWeight: "normal",
                                  marginLeft: "5px",
                                  flexWrap: "wrap",
                                }}
                              >
                                {` ${irrigationData.percentage}% `}

                                {irrigationData.direction}
                              </div>
                            )}

                            {latestStreamPivot?.streamStatus?.number === 4 &&
                            isValidIrrigationRemainingTime(
                              latestStreamPivot?.irrigation_remaining_time
                            )
                              ? convertIrrigationRemainingTimeToHoursMinutes(
                                  latestStreamPivot?.irrigation_remaining_time
                                )
                              : ""}

                            {latestStreamPivot?.streamStatus?.text ===
                              i18n("PAINEL_STREAM_REASON_256") ||
                            latestStreamPivot?.streamStatus?.number === 38 ? (
                              <StatusHelper />
                            ) : null}
                          </div>
                        )}
                      </div>

                      <InformationGrid id="InformationGrid">
                        {newLatestAngle !== undefined &&
                          !props.shouldHideInfoFromRecoil &&
                          pivot.automation_type !== 2 && (
                            <div className="pivot-item__angle">
                              <Autorenew style={{ marginLeft: "-5px" }} />
                              {isOldProtocol(props.pivot)
                                ? `${
                                    parseFloat(newLatestAngle) >= 0
                                      ? `${String(
                                          Math.round(parseFloat(newLatestAngle))
                                        )}º`
                                      : "-"
                                  }`
                                : `${
                                    parseFloat(newLatestAngle) >= 0
                                      ? `${String(
                                          Math.round(newLatestAngle as any)
                                        )}º`
                                      : "-"
                                  }`}
                            </div>
                          )}

                        {showPluviometerInfos && (
                          <div
                            title={isRaining ? i18n("RAINING_NOW") : ""}
                            className="pivot-item__angle"
                            style={{ marginBottom: "3px" }}
                          >
                            <Opacity
                              style={{ color: isRaining ? "#0066ff" : "black" }}
                            />

                            <div style={{ marginBottom: "-5px" }}>
                              {getDailyMesure(
                                showPluviometerMeasure,
                                latestPeriodicStream?.content
                                  ?.pluviometer_daily_measure?.daily_measure
                              )}
                            </div>
                          </div>
                        )}
                      </InformationGrid>

                      <div
                        className="pivot-item__last-update"
                        style={{
                          display: "grid",
                          gridTemplateColumns: "5fr 1fr",
                          gridGap: "5px",
                          alignItems: "center",
                        }}
                      >
                        {lastUpdateHour}
                        {isOnlineIndicator}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="pivot-item__row-right">
                  <div className="pivot-item__sweep-status">
                    {runningSweep === 1 ? <CircularProgress /> : <></>}

                    {runningSweep === 2 ? <Done /> : <></>}
                  </div>
                </div>
              </div>
            </Slide>
          </MobileZone>
        </>
      )}
    </div>
  );
}

export default React.memo(PivotItem);

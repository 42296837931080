import React, { useEffect, useState } from "react";
import "./PivotGeneralBox.scss";
import {
  AutorenewTwoTone,
  PowerSettingsNew,
  InvertColors,
  RotateRight,
  RotateLeft,
} from "@material-ui/icons";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";

import moment from "moment-timezone";
import { Button, Tooltip } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { showModal } from "../../../../../../redux/modal/actions";
import { useParams } from "react-router";
import DialogModal from "../../../../../../components/DialogModal/DialogModal";
import { useStyles } from "../IrrigationBox/IrrigationBox.style";
import FarmMap from "../../../../Farm/SelectedFarm/components/FarmMap/FarmMap";
import useSocketIO from "../../../../../../hooks/tools/useSocketIO";
import { isMobile } from "../../../../../../mobileConfig";
import { coreHTTPClient } from "../../../../../../services/webclient";
import styled from "@emotion/styled";
import { i18n } from "../../../../../../i18n/i18nText";
import {
  bauerPivotStatus,
  colorStatus,
} from "../../../../../../utils/models/bauerpivots";
import {
  BauerPivotObj,
  BauerPivot,
} from "../../../../../../redux/bauerPivot/types";
import QuickSelect from "../../../../../../components/QuickSelect/QuickSelect";
import useNotifyIfDebtor from "../../../../../../hooks/models/useNotifyIfDebtor";
import useDebtor from "../../../../../../hooks/models/useDebtor";

const EndTowerIcon = require("../../../../../../assets/images/end_tower_pressure.png");

const Status = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 200px;
  background-color: gray;
  font-weight: 600;
  padding: 4px 8px;
  font-size: 0.7rem;
  border-radius: 8px;
  color: white;

  @media (max-width: 1024px) {
    margin-top: 12px;
  }
`;

// TODO: Isolate this type
export interface PivotStatusAPIResponse {
  irrigation_status: number;
  power: number;
  voltage: number;
  angle: number;
}

function PivotGeneralBox(props: any) {
  // --------------------------------------------------------------------

  // const { pivot } = useContext(PivotContext);
  const dispatch = useDispatch();

  const farmID: number = parseInt(useParams().farm, 10);
  const pivotID: number = parseInt(useParams().pivot, 10);

  const classes = useStyles(props);

  const [bauerPivot, setBauerPivot] = useState<
    BauerPivotObj & { history: { [index: number]: number } }
  >();
  const [loading, setLoading] = useState(true);

  const [isConnected, socket] = useSocketIO();

  const [, notifyIfDebtor] = useNotifyIfDebtor(farmID);
  const [, debtor, action] = useDebtor(farmID);

  useEffect(() => {
    action.get(farmID, false);
  }, []);

  useEffect(() => {
    let machine_address = "";
    (async () => {
      const bauerPivot = await coreHTTPClient.get(
        `v4/smartrain/pivot/${pivotID}`
      );

      machine_address = bauerPivot.data.hardware_id;
      if (isConnected) {
        socket.subscribe(`${machine_address}@smartrain_pivot`);
        socket.bind("message_up_received", (data: BauerPivot) => {
          if (data.status != undefined)
            setBauerPivot((prev) => {
              return { ...prev, latest: data };
            });
        });
      }
    })();
    return () => {
      if (isConnected) {
        socket.unbind("message_up_received");
        socket.unsubscribe(`${machine_address}@smartrain_pivot`);
      }
    };
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const bauerPivot = await coreHTTPClient.get(
          `v4/smartrain/pivot/${pivotID}`
        );
        const bauerPivotHistory = await coreHTTPClient.get(
          `v4/smartrain/pivot/${pivotID}/irrigationHistory`
        );
        if (bauerPivot.data) {
          let bauerPivotObj = {
            ...bauerPivot.data,
            history: bauerPivotHistory.data,
          };
          bauerPivotObj = setBauerPivot(bauerPivotObj);
        }
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    })();
  }, [pivotID]);

  // Função para a comparação de valores de cada objeto feito para apenas atualizar o Pivo se ele realmente for diferente
  function isObject(object) {
    return object != null && typeof object === "object";
  }
  function deepEqual(object1, object2) {
    if (!object1 || !object2) return false;
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);

    if (keys1.length !== keys2.length) {
      return false;
    }

    for (const key of keys1) {
      const val1 = object1[key];
      const val2 = object2[key];
      const areObjects = isObject(val1) && isObject(val2);
      if (
        (areObjects && !deepEqual(val1, val2)) ||
        (!areObjects && val1 !== val2)
      ) {
        return false;
      }
    }

    return true;
  }

  function EnhancedTableHead(props) {
    const headCells = isMobile()
      ? [
          {
            id: "startAngle",
            label: "Angle Start",
          },
          {
            id: "endAngle",
            label: "End Angle",
          },
          {
            id: "precipitation",
            label: "mm",
          },
        ]
      : [
          {
            id: "segment",
            label: "Segment",
          },
          {
            id: "startAngle",
            label: "Angle Start",
          },
          {
            id: "endAngle",
            label: "End Angle",
          },
          {
            id: "precipitation",
            label: "mm",
          },
        ];
    return (
      <TableHead>
        <TableRow>
          {headCells.map((headCell, index) => (
            <TableCell
              key={headCell.id}
              align={"center"}
              padding={"none"}
              // sortDirection={orderBy === headCell.id ? order : false}
            >
              {headCell.label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  function tableRows(op: "fwd" | "rev") {
    let tableRows = [];
    if (isMobile()) {
      for (let index = 6; index > 0; index--) {
        if (bauerPivot.latest[`segment_${index}_end`] == 0) continue;
        else
          tableRows.push(
            <TableRow>
              <TableCell padding="none" align="center">
                {bauerPivot.latest[`segment_${index}_begin`]}
              </TableCell>
              <TableCell padding="none" align="center">
                {bauerPivot.latest[`segment_${index}_end`]}
              </TableCell>
              <TableCell
                padding="none"
                align="center"
                style={{
                  wordBreak: "break-word",
                }}
              >
                {bauerPivot.latest[`segment_${index}_${op}`]}
              </TableCell>
            </TableRow>
          );
      }
    } else {
      for (let index = 6; index > 0; index--) {
        if (bauerPivot.latest[`segment_${index}_end`] == 0) continue;
        else
          tableRows.push(
            <TableRow>
              <TableCell padding="none" align="center">
                {`Segment ${index}`}
              </TableCell>
              <TableCell padding="none" align="center">
                {bauerPivot.latest[`segment_${index}_begin`]}
              </TableCell>
              <TableCell padding="none" align="center">
                {bauerPivot.latest[`segment_${index}_end`]}
              </TableCell>
              <TableCell
                padding="none"
                align="center"
                style={{
                  wordBreak: "break-word",
                }}
              >
                {bauerPivot.latest[`segment_${index}_${op}`]}
              </TableCell>
            </TableRow>
          );
      }
    }
    return tableRows.reverse();
  }

  const forwardIrrigationHandler = () => {
    notifyIfDebtor(farmID);

    dispatch(
      showModal({
        content: (
          <DialogModal
            title="IRRIGATION_BOX_STOP_PIVOT_DIALOG_TITLE"
            description="IRRIGATION_BOX_FORWARD_PIVOT_DIALOG_DESCRIPTION"
            descriptionArgs={[bauerPivot.name]}
            yes="IRRIGATION_BOX_STOP_PIVOT_DIALOG_YES"
            yesAction={async () => {
              await coreHTTPClient.post(
                `v4/smartrain/pivot/${pivotID}/forward`
              );
            }}
            no="IRRIGATION_BOX_STOP_PIVOT_DIALOG_NO"
            children={
              <div>
                <span style={{ fontWeight: "bold" }}>
                  Current Angle: {bauerPivot.latest.machine_angle / 100}°
                </span>
                <TableContainer>
                  <Table
                    stickyHeader
                    // className={classes.table}
                    aria-labelledby="tableTitle"
                    aria-label="enhanced table"
                  >
                    <EnhancedTableHead />
                    <TableBody>
                      {tableRows("fwd").length > 0 ? tableRows("fwd") : null}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            }
          />
        ),
      })
    );
  };

  const reverseIrrigationHandler = () => {
    notifyIfDebtor(farmID);

    dispatch(
      showModal({
        content: (
          <DialogModal
            title="IRRIGATION_BOX_STOP_PIVOT_DIALOG_TITLE"
            description="IRRIGATION_BOX_REVERSE_PIVOT_DIALOG_DESCRIPTION"
            descriptionArgs={[bauerPivot.name]}
            yes="IRRIGATION_BOX_STOP_PIVOT_DIALOG_YES"
            yesAction={async () => {
              await coreHTTPClient.post(
                `v4/smartrain/pivot/${pivotID}/reverse`
              );
            }}
            no="IRRIGATION_BOX_STOP_PIVOT_DIALOG_NO"
            children={
              <div>
                <span style={{ fontWeight: "bold" }}>
                  Current Angle: {bauerPivot.latest.machine_angle / 100}°
                </span>
                <TableContainer>
                  <Table
                    stickyHeader
                    // className={classes.table}
                    aria-labelledby="tableTitle"
                    aria-label="enhanced table"
                  >
                    <EnhancedTableHead />
                    <TableBody>
                      {tableRows("rev").length > 0 ? tableRows("rev") : null}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            }
          />
        ),
      })
    );
  };

  const stopIrrigationV5Handler = () => {
    notifyIfDebtor(farmID);

    dispatch(
      showModal({
        content: (
          <DialogModal
            title="IRRIGATION_BOX_STOP_PIVOT_DIALOG_TITLE"
            description="IRRIGATION_BOX_STOP_PIVOT_DIALOG_DESCRIPTION"
            descriptionArgs={[bauerPivot.name]}
            yes="IRRIGATION_BOX_STOP_PIVOT_DIALOG_YES"
            yesAction={async () => {
              await coreHTTPClient.post(`v4/smartrain/pivot/${pivotID}/stop`);
            }}
            no="IRRIGATION_BOX_STOP_PIVOT_DIALOG_NO"
          />
        ),
      })
    );
  };

  /*
    Irrigation Action Buttons
  */

  const irrigationButtonsV5 = (
    <>
      <Button
        className={isMobile() && classes.bottomMobile}
        variant="contained"
        color="primary"
        onClick={forwardIrrigationHandler}
        startIcon={<RotateRight />}
        // disabled={!isOnline || maintenanceStatus !== false}
      >
        {i18n("PIVOT_START_ACTION_VALUE_DIRECTION_PROGRESS")}
      </Button>

      <Button
        className={isMobile() && classes.bottomMobile}
        variant="contained"
        color="primary"
        onClick={reverseIrrigationHandler}
        startIcon={<RotateLeft />}
        // disabled={!isOnline || maintenanceStatus !== false}
      >
        {i18n("PIVOT_START_ACTION_VALUE_DIRECTION_REVERSE")}
      </Button>

      <Button
        variant="contained"
        className={
          isMobile() ? classes.stopPivotButtonMobile : classes.stopPivotButton
        }
        onClick={stopIrrigationV5Handler}
        startIcon={<PowerSettingsNew />}
        // disabled={!isOnline || maintenanceStatus !== false}
      >
        <>{i18n("SELECTED_PIVOT_STOP_ACTION")}</>
      </Button>
    </>
  );

  const mobileIrrigationButtonsV5 = (
    <>
      <Button
        className={isMobile() && classes.bottomMobile}
        variant="contained"
        color="primary"
        onClick={reverseIrrigationHandler}
        startIcon={<RotateLeft />}
        // disabled={!isOnline || maintenanceStatus !== false}
      >
        {i18n("PIVOT_START_ACTION_VALUE_DIRECTION_REVERSE")}
      </Button>

      <Button
        className={isMobile() && classes.bottomMobile}
        variant="contained"
        color="primary"
        onClick={forwardIrrigationHandler}
        startIcon={<RotateRight />}
        // disabled={!isOnline || maintenanceStatus !== false}
      >
        {i18n("PIVOT_START_ACTION_VALUE_DIRECTION_PROGRESS")}
      </Button>

      <Button
        variant="contained"
        className={
          isMobile() ? classes.stopPivotButtonMobile : classes.stopPivotButton
        }
        onClick={stopIrrigationV5Handler}
        startIcon={<PowerSettingsNew />}
        // disabled={!isOnline || maintenanceStatus !== false}
      >
        <>{i18n("SELECTED_PIVOT_STOP_ACTION")}</>
      </Button>
    </>
  );

  function currentIrrigationSegment(currentAngle: number, op: "fwd" | "rev") {
    if (currentAngle <= bauerPivot.latest.segment_1_end) {
      return bauerPivot.latest[`segment_1_${op}`];
    } else if (currentAngle <= bauerPivot.latest.segment_2_end) {
      return bauerPivot.latest[`segment_2_${op}`];
    } else if (currentAngle <= bauerPivot.latest.segment_3_end) {
      return bauerPivot.latest[`segment_3_${op}`];
    } else if (currentAngle <= bauerPivot.latest.segment_4_end) {
      return bauerPivot.latest[`segment_4_${op}`];
    } else if (currentAngle <= bauerPivot.latest.segment_5_end) {
      return bauerPivot.latest[`segment_5_${op}`];
    } else return bauerPivot.latest[`segment_6_${op}`];
  }

  // ///////////////////////////
  // Pivot live information //
  // ///////////////////////////

  //
  // V5
  //

  const mobileV5infos = (
    <>
      <div className="generalpivot__content__group">
        <div style={{ display: "flex", alignItems: "flex-start" }}>
          {bauerPivot?.latest?.status != undefined ? (
            <Tooltip
              title={bauerPivotStatus(bauerPivot?.latest.status).toUpperCase()}
              aria-label="info"
            >
              <Status
                style={{
                  backgroundColor: colorStatus(bauerPivot?.latest.status),
                }}
              >
                <span>
                  {bauerPivot
                    ? bauerPivotStatus(bauerPivot.latest.status).length > 20
                      ? bauerPivotStatus(bauerPivot.latest.status)
                          .slice(0, 20)
                          .toUpperCase() + "..."
                      : bauerPivotStatus(bauerPivot.latest.status).toUpperCase()
                    : i18n("LOADING")}
                </span>
              </Status>
            </Tooltip>
          ) : (
            <Tooltip
              title={bauerPivotStatus(-1).toUpperCase()}
              aria-label="info"
            >
              <Status
                style={{
                  backgroundColor: "#757575",
                }}
              >
                <span>{bauerPivotStatus(-1).toUpperCase()}</span>
              </Status>
            </Tooltip>
          )}
        </div>

        <div className="generalpivot__content__item">
          {!isMobile() ? <>{i18n("GENERAL_BOX_LAST_UPDATE")}</> : <></>}
          {bauerPivot?.metadata?.last_communication ? (
            moment
              .utc(bauerPivot.metadata.last_communication)
              .tz(moment.tz.guess())
              .format("DD MMM HH:mm")
          ) : (
            <>{" -"}</>
          )}
        </div>
      </div>

      <div className="generalpivot__content__group">
        {bauerPivot?.latest?.current_pressure != undefined ? (
          <div className="generalpivot__content__item-mobile">
            <div style={{ marginTop: "4px" }}>{""}</div>
            <Tooltip
              title={i18n("BAUER_PIVOT_GENERAL_TOOLTIP_PRESSURE")}
              aria-label="info"
            >
              <img
                src={EndTowerIcon}
                alt="pressure icon"
                width="20px"
                height="20px"
                style={{ opacity: 0.85 }}
              />
            </Tooltip>
            <div style={{ marginTop: "4px" }}>
              {i18n("GENERAL_BOX_PRESSURE_LABEL", [
                bauerPivot.latest.current_pressure || "-",
              ])}
            </div>
          </div>
        ) : null}

        {bauerPivot?.latest?.machine_angle != undefined ? (
          <div className="generalpivot__content__item-mobile">
            <Tooltip
              title={i18n("BAUER_PIVOT_GENERAL_TOOLTIP_ANGLE")}
              aria-label="info"
            >
              <AutorenewTwoTone />
            </Tooltip>
            {`${bauerPivot?.latest?.machine_angle / 100 || "0"}º`}
          </div>
        ) : null}

        {bauerPivot?.latest?.status == 1 ? (
          <div className="generalpivot__content__item">
            <Tooltip
              title={i18n("BAUER_PIVOT_GENERAL_TOOLTIP_IRRIGATION_RATE")}
              aria-label="info"
            >
              <InvertColors />
            </Tooltip>
            {currentIrrigationSegment(
              bauerPivot.latest.machine_angle / 100,
              "fwd"
            )}
            {" mm"}
          </div>
        ) : bauerPivot?.latest?.status == 15 ? (
          <div className="generalpivot__content__item">
            <Tooltip
              title={i18n("BAUER_PIVOT_GENERAL_TOOLTIP_IRRIGATION_RATE")}
              aria-label="info"
            >
              <InvertColors />
            </Tooltip>
            {currentIrrigationSegment(
              bauerPivot.latest.machine_angle / 100,
              "rev"
            )}
            {" mm"}
          </div>
        ) : null}
      </div>
    </>
  );

  const desktopV5infos = (
    <>
      <div style={{ display: "flex", alignItems: "flex-start" }}>
        {bauerPivot?.latest?.status != undefined ? (
          <Tooltip
            title={bauerPivotStatus(bauerPivot?.latest.status).toUpperCase()}
            aria-label="info"
          >
            <Status
              style={{
                backgroundColor: colorStatus(bauerPivot?.latest.status),
              }}
            >
              <span>
                {bauerPivot
                  ? bauerPivotStatus(bauerPivot.latest.status).length > 20
                    ? bauerPivotStatus(bauerPivot.latest.status)
                        .slice(0, 20)
                        .toUpperCase() + "..."
                    : bauerPivotStatus(bauerPivot.latest.status).toUpperCase()
                  : i18n("LOADING")}
              </span>
            </Status>
          </Tooltip>
        ) : (
          <Tooltip title={bauerPivotStatus(-1).toUpperCase()} aria-label="info">
            <Status
              style={{
                backgroundColor: "#757575",
              }}
            >
              <span>{bauerPivotStatus(-1).toUpperCase()}</span>
            </Status>
          </Tooltip>
        )}
      </div>

      <div className="generalpivot__content__item">
        {!isMobile() ? <>{i18n("GENERAL_BOX_LAST_UPDATE")}</> : <></>}
        {bauerPivot?.metadata?.last_communication ? (
          moment
            .utc(bauerPivot.metadata.last_communication)
            .tz(moment.tz.guess())
            .format("DD MMM HH:mm")
        ) : (
          <>{" -"}</>
        )}
      </div>
      {bauerPivot?.latest?.current_pressure != undefined ? (
        <div
          className="generalpivot__content__item"
          style={{ marginBottom: "6px" }}
        >
          <Tooltip
            title={i18n("BAUER_PIVOT_GENERAL_TOOLTIP_PRESSURE")}
            aria-label="info"
          >
            <img
              src={EndTowerIcon}
              alt="pressure icon"
              width="20px"
              height="20px"
              style={{ marginRight: "8px", opacity: 0.85 }}
            />
          </Tooltip>
          {i18n("GENERAL_BOX_PRESSURE_LABEL", [
            bauerPivot?.latest?.current_pressure || "-",
          ])}
        </div>
      ) : null}

      {/* Conferir se pode tirar daqui tambem ou so da lista la fora */}

      {bauerPivot?.latest?.machine_angle != undefined ? (
        <div className="generalpivot__content__item">
          <Tooltip
            title={i18n("BAUER_PIVOT_GENERAL_TOOLTIP_ANGLE")}
            aria-label="info"
          >
            <AutorenewTwoTone />
          </Tooltip>
          {`${bauerPivot?.latest?.machine_angle / 100} º`}
        </div>
      ) : (
        <div className="generalpivot__content__item">
          <Tooltip
            title={i18n("BAUER_PIVOT_GENERAL_TOOLTIP_ANGLE")}
            aria-label="info"
          >
            <AutorenewTwoTone />
          </Tooltip>
          {`-`}
        </div>
      )}
      {bauerPivot?.latest?.status == 1 ? (
        <div className="generalpivot__content__item">
          <Tooltip
            title={i18n("BAUER_PIVOT_GENERAL_TOOLTIP_IRRIGATION_RATE")}
            aria-label="info"
          >
            <InvertColors />
          </Tooltip>
          {currentIrrigationSegment(
            bauerPivot.latest.machine_angle / 100,
            "fwd"
          )}
          {" mm"}
        </div>
      ) : bauerPivot?.latest?.status == 15 ? (
        <div className="generalpivot__content__item">
          <Tooltip
            title={i18n("BAUER_PIVOT_GENERAL_TOOLTIP_IRRIGATION_RATE")}
            aria-label="info"
          >
            <InvertColors />
          </Tooltip>
          {currentIrrigationSegment(
            bauerPivot.latest.machine_angle / 100,
            "rev"
          )}
          {" mm"}
        </div>
      ) : null}
    </>
  );

  const v5Infos = isMobile() ? mobileV5infos : desktopV5infos;

  const isLatestUndefinedOrEmpty =
    typeof bauerPivot?.latest === "undefined" ||
    Object.keys(bauerPivot?.latest)?.length === 0;

  const modifiedBauerPivot = isLatestUndefinedOrEmpty
    ? {
        ...bauerPivot,
        latest: {
          ...bauerPivot?.latest,
          sector_end: 36000,
          sector_begin: 0,
          zero_position: 0,
          last_tower_radius: 0,
        },
      }
    : bauerPivot;

  const generalBoxContent = (
    <>
      <div className="generalpivot">
        <div className={"generalpivot__inner"}>
          {!isMobile() && (
            <div className="generalpivot__map">
              {/** KKKKKK  */}
              <FarmMap
                bauerPivots={[modifiedBauerPivot]}
                draggable={false}
                disableUI
                disableFullScreen
                infoWindow={false}
              />
            </div>
          )}
          <div className="generalpivot__content">
            {/* {pivotName} */}
            <QuickSelect farmID={farmID} />
            <div className="generalpivot__content__items">{v5Infos}</div>
          </div>

          <div className="generalpivot__actions">
            {isMobile() ? mobileIrrigationButtonsV5 : irrigationButtonsV5}
          </div>
        </div>
      </div>
    </>
  );

  if (loading) return <></>;
  return generalBoxContent;
}

export default React.memo(PivotGeneralBox);

import React, { useState, useEffect, useCallback, useContext } from "react";
import "./BauerPivotItem.scss";
import { useNavigate, useParams } from "react-router";
import { IconButton, Slide, Tooltip } from "@material-ui/core";
import MobileZone from "../../../../../../../../components/Zones/MobileZone";
import DesktopZone from "../../../../../../../../components/Zones/DesktopZone";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Done, Autorenew, Opacity } from "@material-ui/icons";
import { DrawerContext } from "../../../../../../components/DrawerProvider/DrawerProvider";
import SignalWifi3BarIcon from "@material-ui/icons/SignalWifi3Bar";
import SignalWifiOffIcon from "@material-ui/icons/SignalWifiOff";
import moment from "moment-timezone";
import styled from "@emotion/styled";
import { i18n } from "../../../../../../../../i18n/i18nText";
import routes from "../../../../../../../../routes/routes";
import { FarmBauerPivotObj } from "../../../../../../../../redux/bauerPivot/types";
import {
  bauerPivotStatus,
  colorStatus,
} from "../../../../../../../../utils/models/bauerpivots";

const InformationGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 5fr;
  grid-gap: 5px;
`;

const Status = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 160px;
  background-color: gray;
  font-weight: 500;
  padding: 4px 8px;
  font-size: 0.6rem;
  border-radius: 8px;
  color: white;

  @media (max-width: 1024px) {
    margin-top: 12px;
  }
`;

const SpanStatus = styled.span``;

interface Props {
  bauerPivot: FarmBauerPivotObj;
  sweepStatus?: number; // 0 - sweep not activated, 1 - sweep started, 2 - sweep done successfully
  shouldNotShowMapPreview?: boolean;
  shouldHideLatestStatus?: boolean;
  shouldHideInfoFromRecoil?: boolean;
}

function BauerPivotItem(props: Props) {
  const { bauerPivot } = props;

  const isOnlineIndicator = () => {
    return (
      <IconButton color="primary">
        {bauerPivot.connected ? (
          <Tooltip title={i18n("SELECTED_FARM_PIVOT_ONLINE")}>
            <SignalWifi3BarIcon style={{ color: "#037ade" }} />
          </Tooltip>
        ) : (
          <Tooltip title={i18n("SELECTED_FARM_PIVOT_OFFLINE")}>
            <SignalWifiOffIcon style={{ color: "gray", opacity: "0.6" }} />
          </Tooltip>
        )}
      </IconButton>
    );
  };

  const farmID: string = useParams().farm;

  const navigate = useNavigate();

  const [runningSweep, setRunningSweep] = useState<number>(undefined);

  const ctx = useContext(DrawerContext);

  const pivotItemHandler = useCallback(() => {
    ctx.setFarmID(farmID);

    ctx.setPivotID(String(bauerPivot.id));
    navigate(
      routes.DASHBOARD.SELECTED_BAUER_PIVOT.replace(":farm", farmID).replace(
        ":pivot",
        String(bauerPivot.id)
      )
    );
  }, [farmID, bauerPivot.id]);

  useEffect(() => {
    if (props.sweepStatus !== undefined) {
      setRunningSweep(props.sweepStatus);
    }
  }, [props.sweepStatus]);

  return (
    <div className="pivot-item__content">
      <DesktopZone>
        <div className="pivot-item" onClick={pivotItemHandler}>
          <div className="pivot-item__row-left">
            <div className="pivot-item__text-container">
              <div className="pivot-item__text-content">
                <div className="pivot-item__title">
                  {bauerPivot.name}
                  {bauerPivot.latest?.status != undefined ? (
                    <Tooltip
                      title={bauerPivotStatus(
                        bauerPivot.latest.status
                      ).toUpperCase()}
                      aria-label="info"
                    >
                      <Status
                        style={{
                          backgroundColor: colorStatus(
                            bauerPivot?.latest.status
                          ),
                        }}
                        className="pivot-item__status"
                      >
                        <span>
                          {bauerPivotStatus(bauerPivot.latest.status).length >
                          20
                            ? bauerPivotStatus(bauerPivot.latest.status)
                                .slice(0, 20)
                                .toUpperCase() + "..."
                            : bauerPivotStatus(
                                bauerPivot.latest.status
                              ).toUpperCase()}
                        </span>
                      </Status>
                    </Tooltip>
                  ) : (
                    <Tooltip
                      title={bauerPivotStatus(
                        bauerPivot.latest.status
                      ).toUpperCase()}
                      aria-label="info"
                    >
                      <Status
                        style={{
                          backgroundColor: "#757575",
                        }}
                        className="pivot-item__status"
                      >
                        <span>{bauerPivotStatus(-1).toUpperCase()}</span>
                      </Status>
                    </Tooltip>
                  )}
                </div>
                {bauerPivot.latest.machine_angle >= 0 ? (
                  <div className="pivot-item__angle">
                    <Autorenew style={{ marginLeft: "-5px" }} />
                    {`${Math.round(bauerPivot.latest.machine_angle / 100)}º`}
                  </div>
                ) : (
                  <div className="pivot-item__angle">
                    <Autorenew style={{ marginLeft: "-5px" }} />
                    {"-"}
                  </div>
                )}

                <div className="pivot-item__last-update">
                  {bauerPivot.metadata?.last_communication ? (
                    moment
                      .utc(bauerPivot.metadata.last_communication)
                      .tz(moment.tz.guess())
                      .format("DD MMM HH:mm")
                  ) : (
                    <>{"-"}</>
                  )}
                  {isOnlineIndicator()}
                </div>
              </div>
            </div>
          </div>

          <div className="pivot-item__row-right">
            <div className="pivot-item__sweep-status">
              {runningSweep === 1 ? <CircularProgress /> : <></>}

              {runningSweep === 2 ? <Done /> : <></>}
            </div>
          </div>
        </div>
      </DesktopZone>

      <MobileZone>
        <Slide direction="down" in>
          <div className="pivot-item" onClick={pivotItemHandler}>
            <div className="pivot-item__row-left">
              <div className="pivot-item__text-container">
                <div className="pivot-item__text-content">
                  <div className="pivot-item__title">
                    {bauerPivot.name}
                    {bauerPivot.latest?.status != undefined ? (
                      <Tooltip
                        title={bauerPivotStatus(
                          bauerPivot.latest.status
                        ).toUpperCase()}
                        aria-label="info"
                      >
                        <Status
                          style={{
                            backgroundColor: colorStatus(
                              bauerPivot?.latest.status
                            ),
                          }}
                          className="pivot-item__status"
                        >
                          <span>
                            {bauerPivotStatus(bauerPivot.latest.status).length >
                            20
                              ? bauerPivotStatus(bauerPivot.latest.status)
                                  .slice(0, 20)
                                  .toUpperCase() + "..."
                              : bauerPivotStatus(
                                  bauerPivot.latest.status
                                ).toUpperCase()}
                          </span>
                        </Status>
                      </Tooltip>
                    ) : (
                      <Tooltip
                        title={bauerPivotStatus(-1).toUpperCase()}
                        aria-label="info"
                      >
                        <Status
                          style={{
                            backgroundColor: "#757575",
                          }}
                          className="pivot-item__status"
                        >
                          <span>{bauerPivotStatus(-1)}</span>
                        </Status>
                      </Tooltip>
                    )}
                  </div>
                  <InformationGrid id="InformationGrid">
                    {`${bauerPivot.latest.machine_angle}` != "" ? (
                      <div className="pivot-item__angle">
                        <Autorenew style={{ marginLeft: "-5px" }} />
                        {bauerPivot.latest.machine_angle
                          ? `${Math.round(
                              bauerPivot.latest.machine_angle / 100
                            )}º`
                          : "-"}
                      </div>
                    ) : null}
                  </InformationGrid>
                  <div className="pivot-item__last-update">
                    {bauerPivot.metadata?.last_communication ? (
                      moment
                        .utc(bauerPivot.metadata.last_communication)
                        .tz(moment.tz.guess())
                        .format("DD MMM HH:mm")
                    ) : (
                      <>{"-"}</>
                    )}
                    {isOnlineIndicator()}
                  </div>
                </div>
              </div>
            </div>

            <div className="pivot-item__row-right">
              <div className="pivot-item__sweep-status">
                {runningSweep === 1 ? <CircularProgress /> : <></>}

                {runningSweep === 2 ? <Done /> : <></>}
              </div>
            </div>
          </div>
        </Slide>
      </MobileZone>
    </div>
  );
}

export default React.memo(BauerPivotItem);

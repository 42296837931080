import {
  Button,
  FormControl,
  InputLabel,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Select,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";

import { CircularProgress, Tooltip } from "@material-ui/core";
import { blue, red } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import { Done, DoneAll, Error } from "@material-ui/icons";
import Alert from "@material-ui/lab/Alert";
import { useRecoilState } from "recoil";
import { useCountdown } from "../../../../../../../../../../hooks/models/useCountdown";
import useIrpds from "../../../../../../../../../../hooks/models/useIrpds";
import useNotify from "../../../../../../../../../../hooks/tools/useNotify";
import useSocketIO from "../../../../../../../../../../hooks/tools/useSocketIO";
import { i18n } from "../../../../../../../../../../i18n/i18nText";
import { isMobile } from "../../../../../../../../../../mobileConfig";
import { MeterSystemStateList } from "../../../../../../../../../../recoils/MeterSystemRecoil";
import { PivotLightStateList } from "../../../../../../../../../../recoils/PivotRecoil";
import { coreHTTPClient } from "../../../../../../../../../../services/webclient";
import { MessageStatus } from "../../../../../../../../../Dashboard/Pivot/EditPivot/EditPivot";
import BaseRadioField from "../BaseRadioField";
import { PIVOT_COMMUNICATION_TYPE } from "../../../../../../../../../../redux/pivots/types";

const { SOCKET_SUFFIX } = process.env;

const useStyles = makeStyles({
  radioInputField: {
    margin: "20px 0",
  },
  sendButton: {
    width: "100%",
    backgroundColor: "#0089D6",
    color: "#fff",
    "&: hover": {
      backgroundColor: "#185F95",
    },
  },
  disabledButton: {
    width: "100%",
  },
  deviceList: {
    padding: 0,
    marginTop: 10,
    overflowY: "auto",
    maxHeight: 250,
  },
  desktopContainer: {
    width: 400,
    paddingBottom: 20,
  },
  mobileContainer: {
    padding: "30px 20px 20px 20px",
  },
});

function ModalChangeBase(props) {
  const [loadingIrpds, irpds] = useIrpds(props.farmID);
  const [pivotLight, setPivotLight] = useRecoilState(PivotLightStateList);

  const [meterSystems, setMeterSystems] = useRecoilState(MeterSystemStateList);
  const [imeters, setIMeters] = useState([]);

  const [selectedEquipment, setSelectedEquipment] = useState(-1);
  const [isConnected, socket] = useSocketIO();

  const [radioStatus, setRadioStatuses] = useState({});
  const [loaded, setLoaded] = useState(false);
  const [radioId, setRadioId] = useState(props.radioId);

  const [counter, setCounter] = useCountdown("Base", String(-1));

  const [error, setError] = useState<any>(undefined);
  const [radioIdError, setRadioIdError] = useState<boolean>(false);
  const notify = useNotify();

  const classes = useStyles();

  useEffect(() => {
    if (pivotLight)
      setPivotLight(
        pivotLight.filter(
          (pivot) =>
            pivot.communication_type === PIVOT_COMMUNICATION_TYPE.TYPE_XBEE
        )
      );
  }, []);

  function getRadioStatusIcon(
    status: MessageStatus,
    error: string | undefined
  ): JSX.Element {
    switch (status) {
      case MessageStatus.WAITING:
        return <CircularProgress size={18} />;
      case MessageStatus.SENT:
        return <Done style={{ color: "darkgray" }} />;
      case MessageStatus.DELIVERED:
        return <DoneAll style={{ color: blue[500] }} />;
      case MessageStatus.ERROR:
        return (
          <Tooltip
            title={`${i18n(
              "EDIT_PIVOT_V5_ERROR_SENDING_CONFIGURATION"
            )} ${error}`}
          >
            <Error style={{ color: red[500] }} />
          </Tooltip>
        );
      default:
        return <></>;
    }
  }

  useEffect(() => {
    // bind pivot sockets
    pivotLight &&
      pivotLight.forEach((pivot, index) => {
        if (isConnected && pivot.id) {
          socket.subscribe(`${SOCKET_SUFFIX}pivot@${pivot.id}`);

          socket.bind("ControllerConfig_standard", (data) => {
            if (data?.message_errors) {
              setError(data.message_errors);
              changeRadioStatusById(MessageStatus.ERROR, pivot.id);
            }
            if (radioStatus[pivot.id] < data.message_status) {
              changeRadioStatusById(data.message_status, pivot.id);
            }
          });

          socket.bind("pivot_config", (data) => {
            if (data.delivered) {
              changeRadioStatusById(MessageStatus.DELIVERED, pivot.id);
            } else if (data.sent) {
              changeRadioStatusById(MessageStatus.SENT, pivot.id);
            }
          });
        }
      });

    // bind irpd sockets
    irpds &&
      irpds.forEach((irpd, index) => {
        if (isConnected && irpd.id) {
          socket.subscribe(`${SOCKET_SUFFIX}irpd@${irpd.id}`);

          socket.bind("IrpdConfigV5_standard", (data) => {
            if (data?.message_errors) {
              setError(data.message_errors);
            }
            changeRadioStatusById(data.message_status, irpd.id);
          });

          socket.bind("irpd_config", (data) => {
            if (data.delivered) {
              changeRadioStatusById(MessageStatus.DELIVERED, irpd.id);
            } else if (data.sent) {
              changeRadioStatusById(MessageStatus.SENT, irpd.id);
            }
          });
        }
      });

    // bind imeter sockets
    meterSystems &&
      meterSystems.forEach((meterSystem, index) => {
        if (isConnected && meterSystem.id) {
          const imeter = meterSystem.imeter_set[0];
          socket.subscribe(`${SOCKET_SUFFIX}imeter@${imeter.id}`);

          socket.bind("IMeterConfig_standard", (data) => {
            if (data?.message_error) {
              setError(data.message_error);
            }
            if (radioStatus[imeter.id] <= data.message_status) {
              changeRadioStatusById(data.message_status, imeter.id);
            }
          });
        }
      });

    return () => {
      pivotLight &&
        pivotLight.forEach((pivot, index) => {
          socket.unbind("ControllerConfig_standard");
          socket.unbind("pivot_config");
          socket.unsubscribe(`d@pivot@${pivot.id}`);
        });

      irpds &&
        irpds.forEach((irpd, index) => {
          socket.unbind("IrpdConfigV5_standard");
          socket.unbind("irpd_config");
          socket.unsubscribe(`${SOCKET_SUFFIX}irpd@${irpd.id}`);
        });

      meterSystems &&
        meterSystems.forEach((meterSystem, index) => {
          const imeter = meterSystem.imeter_set[0];
          socket.unbind("IMeterConfig_standard");
          socket.unsubscribe(`${SOCKET_SUFFIX}imeter@${imeter.id}`);
        });
    };
  }, [isConnected, loaded]);

  useEffect(() => {
    (async () => {
      let pivotsLightResponse = await coreHTTPClient.get(
        `v3/farms/${props.farmID}/pivots/light/`
      );
      setPivotLight(
        pivotsLightResponse.data.filter(
          (pivot) =>
            pivot.communication_type !== PIVOT_COMMUNICATION_TYPE.TYPE_4G
        )
      );

      let metersystemsResponse = await coreHTTPClient.get(
        `v3/farms/${props.farmID}/metersystems/`
      );
      setMeterSystems(metersystemsResponse.data);

      // get imeters from meter system
      let newImeters = metersystemsResponse.data.map(
        (meterSystem) => meterSystem.imeter_set[0]
      );
      setIMeters(newImeters);

      // init radio statuses
      let copyRadioStatus = Object.assign({}, radioStatus);
      for (let equipment of [
        ...pivotsLightResponse.data,
        ...newImeters,
        ...irpds,
      ]) {
        copyRadioStatus[equipment.id] = 0;
      }
      setRadioStatuses(copyRadioStatus);
      setLoaded(true);
    })();
  }, [props.farmID]);

  async function sendButtonOnClick() {
    if (!radioIdError) {
      try {
        let changeRadio = await coreHTTPClient
          .post(`v3/farms/${props.farmID}/base/`, {
            radio_id: radioId,
            equipment_id: selectedEquipment,
          })
          .then((response) => {
            // load radio statuses
            if (selectedEquipment != -2) {
              if (selectedEquipment != -1) {
                const meterSystem = meterSystems.find(
                  (ms) => ms.id == selectedEquipment
                );
                changeRadioStatusById(
                  MessageStatus.WAITING,
                  meterSystem ? meterSystem.imeter_set[0].id : selectedEquipment
                );
              } else {
                changeRadioStatus(MessageStatus.WAITING);
              }
            }

            // para todos os equipamentos espera, número de equipamentos x 6 segundos
            if (selectedEquipment == -1) {
              setCounter([...pivotLight, ...imeters, ...irpds].length * 6);
            }
            // para 1 ou nenhum equipamento espera 20 segundos
            else {
              setCounter(20);
            }

            props.setRadioId(response.data.radio_id);

            notify("RADIO_CHANGED_SUCCESS", "success", 4000);
          });
      } catch (error) {
        if (error?.response?.data["error"])
          notify(error.response.data["error"], "error", 4000);
        else notify("EDIT_PIVOT_ERROR", "error", 4000);
      }
    } else {
      notify("EDIT_PIVOT_ERROR", "error", 4000);
    }
  }

  function changeRadioStatus(status) {
    let equipments = [...pivotLight, ...imeters, ...irpds];
    for (let equipment of equipments) {
      setRadioStatuses((prevState) => ({
        ...prevState,
        [equipment.id]: status,
      }));
    }
  }

  function changeRadioStatusById(status, id) {
    setRadioStatuses((prevState) => ({
      ...prevState,
      [id]: status,
    }));
  }

  function selectEquipmentOnChange(e) {
    setSelectedEquipment(Number.parseInt(e.target.value as string));
  }

  return loaded ? (
    <div
      className={
        isMobile() ? classes.mobileContainer : classes.desktopContainer
      }
    >
      <div>
        <BaseRadioField
          radioId={radioId.toUpperCase()}
          setRadioId={setRadioId}
          label={i18n("EDIT_PIVOT_CENTRAL_LABEL")}
          status={MessageStatus.NOT_SENT}
          locked={false}
          editBtn={false}
          setRadioIdError={setRadioIdError}
          showQrCode={true}
        />
        <FormControl className={classes.radioInputField}>
          <InputLabel htmlFor="age-native-simple">
            {i18n("SEND_RADIO_CHANGE_TO")}
          </InputLabel>
          <Select
            native
            value={selectedEquipment}
            onChange={selectEquipmentOnChange}
            inputProps={{
              name: "age",
              id: "age-native-simple",
            }}
          >
            <option value={-2}>{i18n("NO_EQUIPMENT")}</option>
            <option value={-1}>{i18n("ALL_EQUIPMENTS")}</option>
            {pivotLight &&
              irpds &&
              meterSystems &&
              [...pivotLight, ...irpds, ...meterSystems].map((pivot) => {
                return <option value={pivot.id}>{pivot.name}</option>;
              })}
          </Select>
        </FormControl>
        <Alert severity="error" style={{ marginBottom: 15 }}>
          {i18n("BASE_CHANGE_CLOCK_ALERT")}
        </Alert>
        <Button
          onClick={sendButtonOnClick}
          variant="contained"
          color="secondary"
          className={
            counter === 0 ? classes.sendButton : classes.disabledButton
          }
          disabled={counter !== 0}
        >
          {counter !== 0 ? <>{counter}</> : i18n("SEND")}
        </Button>
      </div>
      <List className={isMobile() ? null : classes.deviceList}>
        {pivotLight &&
          irpds &&
          meterSystems &&
          [...pivotLight, ...irpds, ...meterSystems].map((equipment) => {
            const labelId = `checkbox-list-secondary-label-${equipment}`;
            return (
              <ListItem key={equipment.id}>
                <ListItemText id={labelId} primary={equipment.name} />
                <ListItemSecondaryAction>
                  {
                    /* prettier-ignore */

                    // @ts-ignore
                    getRadioStatusIcon(radioStatus[equipment.imeter_set === undefined ? equipment.id : equipment.imeter_set[0].id], error)
                  }
                </ListItemSecondaryAction>
              </ListItem>
            );
          })}
      </List>
    </div>
  ) : (
    <div
      style={{ display: "flex", justifyContent: "center" }}
      className={
        isMobile() ? classes.mobileContainer : classes.desktopContainer
      }
    >
      <CircularProgress size={30} />
    </div>
  );
}

export default ModalChangeBase;

import React from "react";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
} from "@material-ui/core";

import { useStyles } from "../../../../../../../components/GeneralTable/GeneralTable.style";
import {
  Column,
  labelDisplayedRows,
  Row,
  TablePaginationActions,
} from "../../../../../../../components/GeneralTable/GeneralTable";
import GeneralTableItem from "../../../../../../../components/GeneralTable/GeneralTableItem";
import { isMobile } from "../../../../../../../mobileConfig";
import { Skeleton } from "@material-ui/lab";

interface Props {
  columns: Column[];
  rowsPerPage: number;
  total: number;
  rows: Row[];
  loading?: boolean;
  nextPage: () => void;
  previousPage: () => void;
  currentPage: (value: string) => void;
}

function ConfigHistoryTable(props: Props) {
  const classes = useStyles(props);

  const {
    columns,
    rowsPerPage,
    total,
    rows,
    loading,
    nextPage,
    previousPage,
    currentPage,
  } = props;

  const [page, setPage] = React.useState(0);

  const array: number[] = Array(rows?.length).fill(-1);

  const handleChangePage = (event: unknown, newPage: number) => {
    if (newPage === page + 1 || newPage === page - 1) {
      if (newPage > page) {
        nextPage();
      } else if (newPage < page) {
        previousPage();
      }
      setPage(newPage);
    } else {
      currentPage(String(newPage + 1));
      setPage(newPage);
    }
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => setPage(0);

  // COMPONENTES DA TABELA EM VARIÁVEIS
  let tableColumns = columns.map((column) => (
    <TableCell
      key={column.id}
      align={column.align}
      style={{ minWidth: 0, zIndex: 0 }}
    >
      {column.label}
    </TableCell>
  ));

  let emptyTableRows = new Array(rowsPerPage).fill(0).map(() => {
    let myArray = [];

    for (
      let i = 0;
      isMobile() ? i < columns?.length : i <= columns?.length;
      i++
    ) {
      myArray.push(
        <TableCell
          className={
            columns?.length > 3 ? classes.emptyColumnV4 : classes.emptyColumnV5
          }
        >
          <Skeleton />
        </TableCell>
      );
    }

    return <TableRow className={classes.root}>{myArray}</TableRow>;
  });

  let filledTableRows = rows?.map((row, index) => {
    return (
      <GeneralTableItem
        row={row}
        columns={columns}
        index={index}
        type={"config"}
      />
    );
  });

  return (
    <>
      <div className={classes.tableWrapper}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {tableColumns}
              {!isMobile() && <TableCell align={"right"}></TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>{loading ? emptyTableRows : filledTableRows}</TableBody>
        </Table>
      </div>

      <div style={{ marginBottom: "100px" }}>
        <TablePagination
          rowsPerPageOptions={[]}
          component="div"
          count={total}
          rowsPerPage={rowsPerPage}
          page={page}
          labelDisplayedRows={labelDisplayedRows}
          backIconButtonProps={{
            "aria-label": "previous page",
          }}
          nextIconButtonProps={{
            "aria-label": "next page",
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
      </div>
    </>
  );
}

export default ConfigHistoryTable;

import React, { useEffect, useContext, useState, useRef } from "react";
import DashboardBox from "../../components/DashboardBox/DashboardBox";
import "./SelectedPivot.scss";
import GeneralBox from "./components/PivotGeneralBox/PivotGeneralBox";
import HistoricBox from "./components/HistoricBox/HistoricBox";
import EditIcon from "@material-ui/icons/Edit";
import { useParams, useNavigate } from "react-router";
import {
  coreHTTPClient,
  getAuthorization,
} from "../../../../services/webclient";
import {
  EmptyPivot,
  PIVOT_COMMUNICATION_TYPE,
  Pivot,
} from "../../../../redux/pivots/types";
import Routes from "../../../../routes/routes";
import { ReactJSX } from "../../../../utils/types";
import { Button, IconButton, CircularProgress, Icon } from "@material-ui/core";
import {
  EditSharp,
  ArrowBack,
  GetApp,
  LocationOn,
  LocationOff,
  Refresh,
  CachedOutlined,
} from "@material-ui/icons";
import DashboardBoxTitle from "../../components/DashboardBox/DashboardBoxTitle";
import Store from "../../../../redux/index";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import CustomCircularProgress from "../../../../components/CustomCircularProgress/CustomCircularProgress";
import { DrawerContext } from "../../components/DrawerProvider/DrawerProvider";
import BasicInfoBox from "./components/BasicInfoBox/BasicInfoBox";
import { isMobile, isAndroid } from "../../../../mobileConfig";
import { ApplicationState } from "../../../../redux";
import useNotify from "../../../../hooks/tools/useNotify";
import moment from "moment";
import FilterByDate from "../../../../components/FilterByDate/FilterByDate";
import {
  FilterTypesChoices,
  FilterByDateState,
} from "../../../../redux/filterByDate/types";
import FarmMap from "../../Farm/SelectedFarm/components/FarmMap/FarmMap";
import styled from "@emotion/styled";
import axios from "axios";
import {
  FullPivotFamily,
  PivotLightStateFamily,
  PivotPainelStreamFamily,
  PivotGPSStreamFamily,
  FallbackPivot,
  PivotConfigFamily,
  PivotLightStateList,
  PivotPeriodicStreamFamily,
  LatestStreamPivot,
  PivotLight,
} from "../../../../recoils/PivotRecoil";

import {
  useRecoilValue,
  useRecoilState,
  useRecoilCallback,
  useSetRecoilState,
} from "recoil";

import {
  getLastFarmId,
  setLastFarmId,
} from "../../Farm/SelectedFarm/SelectedFarm";
import MaintenanceButton from "./components/MaintenanceButton/MaintenanceButton";
import { useAnimation, motion } from "framer-motion";
import { i18n } from "../../../../i18n/i18nText";
import { FarmRedux } from "../../../../redux/farm/types";
import PluviometerChart from "./components/PluviometerChart/PluviometerChart";
import FeatherIcon from "../../../../assets/images/feather.svg";
import { showModal } from "../../../../redux/modal/actions";
import SegmentsDisplay from "./components/IrrigationBox/components/SegmentsDisplay/SegmentsDisplay";
import ConfirmationModal from "../../../Auth/SignUp/ConfirmationModal/ConfirmationModal";
import QuickSelect from "../../../../components/QuickSelect/QuickSelect";
let filesaver = require("file-saver");

export const PivotContext = React.createContext({ pivot: EmptyPivot() });

export const FloatingFadedNavBar = styled(motion.div)`
  position: fixed;
  top: 0px;
  left: 0px;

  height: 55px;

  //iOs only
  @supports (-webkit-touch-callout: none) {
    height: 115px;
  }

  width: 100%;

  box-shadow: inset 0px 75px 25px -48px rgba(0, 0, 0, 0.75);

  color: white;

  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 15px;
  z-index: 5;
`;

export const MiddleLayoutNavBar = styled.div`
  box-sizing: border-box;
  height: 55px;
  width: 100%;
  background-color: #44b86a;

  padding-right: 20px;
  padding-left: 5px;

  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

  display: grid;
  grid-template-columns: 50px calc(100% - 150px) 50px 50px;

  h2 {
    color: white;
    font-size: 20px;
  }
`;

// Requerido para fazer o padding da navbar no iOS
export const MobileContainer = styled.div`
  height: 260px;
  margin-top: -70px; //nega o espaço global da navbar
  //iOs only
  @supports (-webkit-touch-callout: none) {
    margin-top: -120px;
  }

  margin-right: -10px;
  margin-left: -10px;
  margin-bottom: 50px;
`;

export interface PivotParams {
  farm: string;
  pivot: string;
}

function SelectedPivot() {
  const dispatch = useDispatch();

  const farmID: number = parseInt(useParams().farm, 10);
  const pivotID: number = parseInt(useParams().pivot, 10);

  const notify = useNotify();
  const ctx = useContext(DrawerContext);
  const navigate = useNavigate();
  const floatingFadedNavBarAnimation = useAnimation();

  const [sendingExcel, setSendingExcel] = useState<boolean>(false);

  // const [asyncStatus, pivot] = usePivot(farmID, pivotID);
  const pivot = useRecoilValue<Pivot | undefined>(FullPivotFamily(pivotID));
  const pivotLight = useRecoilValue<PivotLight | undefined>(
    PivotLightStateFamily(pivotID)
  );

  const [advancedHistoric, setAdvancedHistoric] = useState<boolean>(false);

  const [centralHistoric, setCentralHistoric] = useState<boolean>(false);

  // RECOIL
  // Estados recoil
  const [latestPanelStream, setLatestPanelStream] = useRecoilState(
    PivotPainelStreamFamily(pivotID)
  );
  const setLatestPeriodicStream = useSetRecoilState(
    PivotPeriodicStreamFamily(pivotID)
  );
  const [latestGPSStream, setlatestGPSStream] = useRecoilState(
    PivotGPSStreamFamily(pivotID)
  );
  const [fallbackPivot, setFallbackPivot] = useRecoilState(
    FallbackPivot(pivotID)
  );
  const setNewConfig = useSetRecoilState(PivotConfigFamily(pivotID));

  const [pivotLightList, setPivotLightList] = useRecoilState(
    PivotLightStateList
  );

  const latestStreamPivot = useRecoilValue(LatestStreamPivot(pivotID));

  const language = useSelector(
    (state: ApplicationState) => state.setting.language,
    shallowEqual
  );

  const resetPivotRelatedStreams = useRecoilCallback(
    ({ snapshot, reset }) => async () => {
      const pivotLightList = await snapshot.getPromise(PivotLightStateList);

      if (pivotLightList) {
        pivotLightList.forEach((pivot) => {
          reset(PivotPainelStreamFamily(pivot.id));
          reset(PivotGPSStreamFamily(pivot.id));
          reset(PivotConfigFamily(pivot.id));
          reset(PivotPeriodicStreamFamily(pivot.id));
          reset(FallbackPivot(pivot.id));
        });
      }
    }
  );

  const [openConfirmation, setConfirmation] = useState(false);

  const [reportLoaded, setLoadingReport] = useState(true);

  async function recalculateReport() {
    await new Promise(async (resolve) => {
      const response = await coreHTTPClient
        .post(
          `/v3/reports/farms/${farmID}/pivot/${pivotID}/recalculate-reports/`
        )
        .then((res) => {
          setLoadingReport(false);
          notify(
            "EDIT_FARM_OPERATION_SUMMARY_RECALCULATE_SUCCESS",
            "success",
            4000
          );
          setLoadingReport(true);
        })
        .catch((err) => {
          notify("EDIT_FARM_OPERATION_SUMMARY_RECALCULATE_ERROR", "error");
        });
    });
  }

  useEffect(() => {
    if (getLastFarmId() != farmID) {
      resetPivotRelatedStreams();
      setLastFarmId(farmID);
    }

    (async () => {
      const pivotsLightResponse = await coreHTTPClient.get(
        `v3/farms/${farmID}/pivots/light/`
      );

      setPivotLightList(pivotsLightResponse.data);
    })();
  }, [farmID]);

  // useEffect de navegação direta
  useEffect(() => {
    (async () => {
      const response = await coreHTTPClient.get(
        `v3/farms/${farmID}/pivots/${pivotID}/`
      );

      const pivot: Pivot = response.data;
      if (fallbackPivot != null) return;
      if (pivot.protocol === 5) {
        setLatestPanelStream(pivot.controllerstream_panel);
        setlatestGPSStream(pivot.controllerstream_gps);
        setNewConfig(pivot.controllerconfig);
        setFallbackPivot(pivot);
        setLatestPeriodicStream(pivot?.controllerstream_periodic);
      } else {
        setLatestPanelStream(pivot.latest_panel_stream);
        setlatestGPSStream(pivot.latest_gps_stream);
        setLatestPeriodicStream(pivot?.controllerstream_periodic);
        setNewConfig(pivot.config);
        setFallbackPivot(pivot);
      }
    })();
  }, [pivotID, reportLoaded]);

  // Fim recoil

  // Estruturas pra suportar o pivotId inline
  // const [localPivotId, setLocalPivotId] = useState(ctx.pivotID);

  // useEffect(() => {
  //   if (isMobile() && ctx.farmID && localPivotId) {
  //     navigate(
  //       routes.DASHBOARD.SELECTED_PIVOT.replace(
  //         ":farm",
  //         String(ctx.farmID)
  //       ).replace(":pivot", localPivotId)
  //     );
  //   }
  // }, [localPivotId]);

  const toggleAdvancedChecked = (): void => {
    setAdvancedHistoric((prev: boolean) => !prev);
  };

  const toggleCentralChecked = (): void => {
    setCentralHistoric((prev: boolean) => !prev);
  };

  const getPivotStatus = async (): Promise<void> => {
    try {
      await coreHTTPClient
        .get(`v3/farms/${farmID}/pivots/${pivotID}/pivotStatus/`)
        .then((response) => {
          notify("GET_NEW_STREAM", "info");
        });
    } catch (err) {
      notify("GET_NEW_STREAM_ERROR", "error");
      console.log(err);
    }
  };
  // Mostra o nome da fazenda e pivô no título da página
  useEffect(() => {
    const farm: FarmRedux | undefined = Store().store.getState().farm.data;

    pivot && pivot.name && farm && farm.name
      ? (document.title = `${farm.name} - ${pivot.name}`)
      : (document.title = "Irricontrol");
  }, [pivot, pivot?.name]);

  useEffect(() => {
    if (pivot) {
      ctx.openSelect();
      // ctx.setPivots(pivots);
      ctx.setFarmID(farmID);
      ctx.setPivotNavigate(false);
      ctx.setPivotID(pivot.id);
    }
  }, [pivot]);

  useEffect(() => {
    ctx.closeSelect();
    ctx.setPivots(null);
    ctx.setInicitialOffset(false);
    ctx.setInicitialIrpdId(false);
    ctx.setInicitialCheck(false);
    ctx.setInicitialModels(false);

    return () => {
      ctx.closeSelect();
      ctx.setPivots(null);
      ctx.setInicitialOffset(false);
      ctx.setInicitialIrpdId(false);
      ctx.setInicitialCheck(false);
      ctx.setInicitialModels(false);

      // dashboardContainer.removeEventListener("scroll", onScrollChanged);
    };
  }, []);

  const buttonBack: ReactJSX = (
    <Button
      color="primary"
      size="small"
      onClick={() =>
        navigate(
          Routes.DASHBOARD.SELECTED_FARM.replace(":farm", String(farmID))
        )
      }
      startIcon={<ArrowBack />}
    >
      <>{i18n("SELECTED_PIVOT_BACK_BUTTON_TEXT")}</>
    </Button>
  );

  const editButtonHandler = (): void => {
    navigate(
      Routes.DASHBOARD.EDIT_PIVOT.replace(":farm", String(farmID)).replace(
        ":pivot",
        String(pivotID)
      )
    );
  };

  const segmentsDisplayHandler = () => {
    dispatch(
      showModal({
        content: <SegmentsDisplay pivot={pivot} />,
        title: "MOBILE_MODAL_TITLE_SEGMENTS_DISPLAY",
      })
    );
  };

  const rightElement: ReactJSX = (
    <>
      {pivot?.permission_level >= 2 && (
        <MaintenanceButton
          pivot_automation_type={pivot.automation_type}
          pivot_id={pivotID}
          farm_id={farmID}
        />
      )}
      {pivot?.automation_type === 0 && pivot.protocol === 5 && (
        <Button color="primary" size="small" onClick={segmentsDisplayHandler}>
          {<img src={FeatherIcon} />}
        </Button>
      )}
      {pivot?.permission_level === 3 && (
        <Button color="primary" size="small" onClick={editButtonHandler}>
          <EditSharp />
        </Button>
      )}
    </>
  );

  const rightElements: ReactJSX = (
    <div className="flex-right-container">
      <div>
        <FilterByDate
          title={"SELECTED_PIVOT_HISTORIC_FILTER_PREFERENCES"}
          type={FilterTypesChoices.HISTORIC}
        />
      </div>

      <div>
        <IconButton
          title={i18n(
            pivot?.communication_type === PIVOT_COMMUNICATION_TYPE.TYPE_4G
              ? "DEVICE_BOX_SHOW_POSITION"
              : "DEVICE_BOX_SHOW_GPS"
          )}
          aria-label="delete"
          onClick={toggleAdvancedChecked}
          size="small"
        >
          {advancedHistoric ? (
            <LocationOn fontSize="small" />
          ) : (
            <LocationOff fontSize="small" />
          )}
        </IconButton>
      </div>

      {pivot?.communication_type !== PIVOT_COMMUNICATION_TYPE.TYPE_4G && (
        <div>
          <IconButton
            title={i18n("SHOW_CENTRAL_STATUS")}
            aria-label="delete"
            onClick={toggleCentralChecked}
            size="small"
          >
            {centralHistoric ? (
              <Icon fontSize="small">public</Icon>
            ) : (
              <Icon fontSize="small">public_off</Icon>
            )}
          </IconButton>
        </div>
      )}
      {/* {pivot?.protocol === 5 && pivot?.permission_level === 3 ? (
        <div>
          <IconButton aria-label="delete" onClick={getPivotStatus} size="small">
            <SyncIcon></SyncIcon>
          </IconButton>
        </div>
      ) : null} */}
    </div>
  );

  let filterByDate: FilterByDateState = useSelector(
    (state: ApplicationState) => state.filterByDate
  );

  const handleExcelClick = (): void => {
    setSendingExcel(true);
    let date_start = undefined;
    let date_end = undefined;

    if (
      filterByDate.data.type === FilterTypesChoices.REPORT &&
      filterByDate.data.start_date &&
      filterByDate.data.end_date
    ) {
      date_end = filterByDate.data.end_date.toISOString();
      date_start = filterByDate.data.start_date.toISOString();
    } else {
      date_end = moment().toISOString();
      date_start = moment().subtract(1, "month").toISOString();
    }

    // kwh_peak
    let ponta =
      pivot.protocol >= 5
        ? parseFloat(pivot.controllerconfig.kwh_peak)
        : parseFloat(pivot.config.kwh_peak);

    // kwh_out_of_peak
    let foraDePonta =
      pivot.protocol >= 5
        ? parseFloat(pivot.controllerconfig.kwh_out_of_peak)
        : parseFloat(pivot.config.kwh_out_of_peak);

    // kwh_reduced
    let reduzido =
      pivot.protocol >= 5
        ? parseFloat(pivot.controllerconfig.kwh_reduced)
        : parseFloat(pivot.config.kwh_reduced);

    if (isMobile()) {
      setSendingExcel(false);
      window.open(
        `${
          process.env.WEB_URL
        }/pivots/${pivotID}/excel/?date_start=${date_start}&date_end=${date_end}&kwh_value_p=${ponta}&kwh_value_hfp=${foraDePonta}&kwh_value_r=${reduzido}&name=${
          pivot.name
        }&language=${language}&apikey=${getAuthorization()}`
      );
    } else {
      axios
        .get(
          pivot.automation_type != 2
            ? `/v3/reports/pivots/${pivotID}/excel/?date_start=${date_start}&date_end=${date_end}&kwh_value_p=${ponta}&kwh_value_hfp=${foraDePonta}&kwh_value_r=${reduzido}`
            : `/v3/reports/pivots/${pivotID}/excel/linear/?date_start=${date_start}&date_end=${date_end}`,
          {
            headers: {
              Authorization: `Bearer ${getAuthorization()}`,
              "Content-Type":
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
              Connection: "close",
            },
            baseURL: process.env.REACTWEB_COREAPI_URL,
            responseType: "blob",
          }
        )
        .then((response) => {
          let blob: Blob = new Blob([response.data], {
            type:
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          pivot.automation_type != 2
            ? filesaver.saveAs(blob, `Resumo ${pivot.name}.xlsx`)
            : filesaver.saveAs(blob, `Resumo ${pivot.name}.xlsx`);
        })
        .catch((err) => {
          notify("PIVOT_REPORT_EXCEL_ERROR", "error");
        })
        .finally(() => {
          setSendingExcel(false);
        });
    }
  };

  const excelButton: ReactJSX = (
    <>
      <Button
        size="small"
        onClick={handleExcelClick}
        startIcon={sendingExcel ? <CircularProgress size={18} /> : <GetApp />}
        disabled={sendingExcel}
      >
        <>{i18n("PIVOT_REPORT_EXCEL")}</>
      </Button>
    </>
  );

  if (pivot) {
    return (
      <div className={isMobile() ? "selected-pivot-mobile" : "selected-pivot"}>
        {isMobile() && (
          <FloatingFadedNavBar animate={floatingFadedNavBarAnimation}>
            <ArrowBack
              style={{ marginTop: 15 }}
              onClick={() =>
                navigate(
                  Routes.DASHBOARD.SELECTED_FARM.replace(
                    ":farm",
                    String(farmID)
                  )
                )
              }
            />

            {pivot?.permission_level == 3 ? (
              <EditIcon
                style={{ fontSize: "1.25em", marginTop: 15 }}
                onClick={() =>
                  navigate(
                    Routes.DASHBOARD.EDIT_PIVOT.replace(
                      ":farm",
                      String(farmID)
                    ).replace(":pivot", String(pivotID))
                  )
                }
              />
            ) : null}
          </FloatingFadedNavBar>
        )}
        <PivotContext.Provider value={{ pivot }}>
          <div className="selected-pivot__grid">
            <div className="selected-pivot__column">
              {isMobile() && (
                <MobileContainer>
                  <FarmMap
                    pivotsLight={[pivotLight]}
                    draggable={false}
                    latestGPSStream={latestGPSStream}
                    latestPanelStream={latestPanelStream}
                    disableUI
                    disableFullScreen
                    clickOnMapToToggleFullscreen
                    zoomScroll={!!isAndroid()}
                  />

                  <MiddleLayoutNavBar>
                    <div
                      style={{
                        display: "flex",
                        width: "calc(100vw - 45px)",
                        alignItems: "center",
                        gap: "30px",
                        marginLeft: "10px",
                      }}
                    >
                      <QuickSelect farmID={farmID} />

                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          height: "100%",
                          gap: "15px",
                        }}
                      >
                        <MaintenanceButton
                          pivot_automation_type={pivot.automation_type}
                          permissionLevel={pivot.permission_level}
                          pivot_id={pivotID}
                          farm_id={farmID}
                        />

                        <Refresh
                          style={{
                            color: "white",
                          }}
                          onClick={() => window.location.reload()}
                        />
                      </div>
                    </div>
                  </MiddleLayoutNavBar>
                </MobileContainer>
              )}

              {isMobile() ? (
                // general box do mobile
                <DashboardBox singleElement={<GeneralBox />}>
                  <></>
                </DashboardBox>
              ) : (
                // general box do desktop
                <DashboardBox
                  leftElement={buttonBack}
                  centerElement={
                    <DashboardBoxTitle
                      title={"SELECTED_PIVOT_GENERAL"}
                      pivotName={pivot.name}
                    />
                  }
                  rightElement={rightElement}
                >
                  <GeneralBox />
                </DashboardBox>
              )}

              <DashboardBox
                fixedHeight
                centerElement={
                  <DashboardBoxTitle title={"SELECTED_PIVOT_HISTORIC"} />
                }
                rightElement={rightElements}
              >
                <HistoricBox
                  advanced={advancedHistoric}
                  central={centralHistoric}
                />
              </DashboardBox>
            </div>

            <div
              className="selected-pivot__column_single_item"
              style={{ height: "100%" }}
            >
              <div style={{ marginBottom: pivot.pluviometer ? "0px" : "80px" }}>
                <DashboardBox
                  fixedHeight
                  centerElement={
                    <DashboardBoxTitle title={"SELECTED_PIVOT_BASIC_INFO"} />
                  }
                  rightElement={
                    pivot.protocol === 4.1 || pivot.protocol === 5 ? (
                      <>
                        <FilterByDate
                          title={"PIVOT_CONFIG_REPORT_PREFERENCES"}
                          type={FilterTypesChoices.REPORT}
                        />
                        <IconButton
                          title={i18n(
                            "EDIT_FARM_OPERATION_SUMMARY_RECALCULATE"
                          )}
                          aria-label="delete"
                          onClick={() => {
                            setConfirmation(true);
                          }}
                          size="small"
                        >
                          <CachedOutlined />
                        </IconButton>
                        <ConfirmationModal
                          openModal={openConfirmation}
                          setModal={setConfirmation}
                          text={
                            "EDIT_FARM_OPERATION_SUMMARY_RECALCULATE_CONFIRMATION"
                          }
                          confirmationButtonText={"SECTOR_PIVOT_REASON_TRUE"}
                          handleConfirmation={() => {
                            recalculateReport();
                          }}
                        />
                      </>
                    ) : null
                  }
                  leftElement={
                    pivot.protocol === 4.1 || pivot.protocol === 5
                      ? excelButton
                      : null
                  }
                >
                  {reportLoaded && pivot && <BasicInfoBox pivot={pivot} />}
                </DashboardBox>
              </div>

              {pivot.pluviometer && (
                <div style={{ marginBottom: "80px" }}>
                  <PluviometerChart farmID={farmID} pivotID={pivotID} />
                </div>
              )}
            </div>
          </div>
        </PivotContext.Provider>
      </div>
    );
  }
  {
    return <CustomCircularProgress />;
  }
}

export default React.memo(SelectedPivot);

import React, { useCallback, useEffect, useState } from "react";
import styled from "@emotion/styled";
import {
  ArrowBack,
  CheckCircle,
  Done,
  DoneAll,
  Settings,
  Straighten,
} from "@material-ui/icons";
import { Map } from "@material-ui/icons";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  makeStyles,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import DashboardBox from "../../../components/DashboardBox/DashboardBox";
import { useNavigate, useParams } from "react-router";
import routes from "../../../../../routes/routes";
import { isMobile } from "../../../../../mobileConfig";
import green from "@material-ui/core/colors/green";
import { ValidationErrorMessage } from "../../../../../components/Form/types";
import { coreHTTPClient } from "../../../../../services/webclient";
import {
  IMeter,
  MeterSystemItemFamily,
} from "../../../../../recoils/MeterSystemRecoil";
import { useRecoilValue, useSetRecoilState } from "recoil";
import FormInput from "../../../../../components/Form/FormInput/FormInput";
import FormDateTimePicker from "../../../../../components/Form/FormDateTimePicker/FormDateTimePicker";
import moment from "moment";
import CustomCircularProgress from "../../../../../components/CustomCircularProgress/CustomCircularProgress";
import { numPad } from "../../../../../utils/types";
import { blue, red } from "@material-ui/core/colors";
import useSocketIO from "../../../../../hooks/tools/useSocketIO";
import useNotify from "../../../../../hooks/tools/useNotify";
import FormInputPosition from "../../../Pivot/EditPivot/components/EditPivotForm/components/FormInputPosition/FormInputPosition";
import { centerMarkerSelector } from "../../../../../recoils/DraggableMapRecoil";
import DraggableMap from "../../../Pivot/EditPivot/components/EditPivotForm/components/DraggableMap/DraggableMap";
import FormSelect from "../../../../../components/Form/FormSelect/FormSelect";
import { i18n } from "../../../../../i18n/i18nText";
import ProtocolFooter from "../../../../../components/ProtocolFooter/ProtocolFooter";
import { useCountdown } from "../../../../../hooks/models/useCountdown";
import { useDispatch, useSelector } from "react-redux";
import GeolocationGetter from "../../../../../components/GeolocationGetter/GeolocationGetter";
import ChangeRadioField from "../../../Pivot/EditPivot/components/ChangeRadioField/ChangeRadioField";
import InputTooltip from "../../../../../components/InputTooltip/InputTooltip";
import { checkIsOnIntervalString } from "../../../../../utils/checks";
import DrawerRightEditIMETER from "../ConfigHistoryImeter/DrawerConfigImeter";
import HistoricConfigMobileV5Imeter from "../ConfigHistoryImeter/HistoricConfigMobileV5Imeter";
import ShowHistoricFloatingLabel from "../../../Pivot/EditPivot/components/EditPivotForm/ShowHistoricFloatingLabel";
import { hideModal, showModal } from "../../../../../redux/modal/actions";
import { ModalState } from "../../../../../redux/modal/types";
import { ApplicationState } from "../../../../../redux";
import { showLocationModal } from "../../../../../redux/locationModal/actions";

const greenDot = require("../../../../../assets/images/markers/light-green-dot.svg");
const { SOCKET_SUFFIX } = process.env;

const DoneAllContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  animation: shadow-pulse-big 1s 1;
`;

const EditMeterSystem = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 10px 10px 55px;
  width: 100%;
  max-width: 1200px;

  @media (max-width: 1024px) {
    padding: 0;
  }
`;

const DashboardBoxContainer = styled.div`
  background-color: white;
  display: grid;
  grid-template-columns: 12fr;
  height: 100%;
`;

const CheckBoxItem = styled.div`
  font-size: 0.7em;
  opacity: 0.8;
  margin-left: -12px;
  margin-top: 8px;
  display: grid;
  grid-template-columns: 1fr 8fr;
  height: 100%;
`;

const CheckBoxLabel = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ClockContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 10px;

  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    grid-gap: 0px;
  }
`;

const LastSettings = styled.div`
  font-size: 0.8em;
  opacity: 0.5;
`;

const Button3D = styled.button`
  cursor: pointer;
  background-color: rgb(255, 255, 255);
  padding: 5px;
  margin-bottom: -7px;
  margin-right: 15px;
  box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1);
  border: 1px solid gray;
  border-radius: 50%;

  img {
    margin: 0px;
  }
`;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
      style={{ height: "100%" }}
    >
      {value === index && <Box p={isMobile() ? 1 : 3}>{children}</Box>}
    </Typography>
  );
}

const useStyles = makeStyles({
  box: {
    padding: "0px",
  },
  tab: {
    borderBottom: "1px solid #ddd",
    borderRight: "1px solid #ddd",
  },
  tabBar: {
    height: "40px",
    border: "0px",
    boxShadow: "0px 0px 0px 0px ",
  },
});

export function EditImeter() {
  const iMeterID = useParams().meter;
  const farmID = useParams().farm;
  const meterSystemID = useParams().metersystem;
  const navigate = useNavigate();
  const [iMeter, setIMeter] = useState<IMeter>();

  useEffect(() => {
    // Caso o Recoil do MeterSystem NÃO ESTEJA preenchido busca o MeterSystem pela API
    (async () => {
      let imeterResponse = await coreHTTPClient.get(
        `v3/farms/${farmID}/metersystems/${meterSystemID}/meter/${iMeterID}/`
      );
      setIMeter(imeterResponse.data);

      const tempImeter = imeterResponse.data;
      if (tempImeter?.permission_level < 3) {
        navigate(
          routes.DASHBOARD.SELECTED_METER_SYSTEM.replace(
            ":farm",
            String(farmID)
          ).replace(":metersystem", meterSystemID)
        );
      }
    })();
  }, []);

  /*
    O componente EditImeter é um componente intermediário que vem antes do componente de formulário
    de edição do iMeter, com ele passamos o objeto iMeter como props, isso facilita a imlementação.
  */
  if (iMeter) return <EditImeterForm iMeter={iMeter} />;
  else return <CustomCircularProgress />;
}

interface Props {
  iMeter: IMeter;
}

function EditImeterForm(props: Props) {
  var modal: ModalState = useSelector((state: ApplicationState) => state.modal);

  const navigate = useNavigate();
  const notify = useNotify();
  const dispatch = useDispatch();
  const classes = useStyles({});
  const iMeterID = useParams().meter;
  const farmID = useParams().farm;
  const meterSystemID = useParams().metersystem;
  const [counter, setCounter] = useCountdown("IMeter", iMeterID);
  const [validationErrors, setValidationErrors] = useState<
    ValidationErrorMessage
  >({
    imeterName: undefined,
    meterSystemName: undefined,
    sensor: undefined,
    name: undefined,
    a: undefined,
    b: undefined,
    c: undefined,
    sensorMaxValue: undefined,
    sensorOffset: undefined,
    minLimit: undefined,
    maxLimit: undefined,
    pauseTimeStart1: undefined,
    pauseTimeEnd1: undefined,
    pauseTimeOverlap: undefined,
    pauseTimeStart2: undefined,
    pauseTimeEnd2: undefined,
    periodicTimer: undefined,
    iMeterLng: undefined,
    iMeterLat: undefined,
    mesureScale: undefined,
    measureUnit: undefined,
  });

  const [isConnected, socket] = useSocketIO();
  const [iMeterCoordsError, setiMeterCoordsError] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [hideButtonSave, setHideButtonSave] = useState(false);
  const [loading, setLoading] = useState(false);
  const [event, setEvent] = useState(false);
  const [error, setError] = useState<any>(
    props.iMeter.latest_config?.message_error === ""
      ? undefined
      : props.iMeter.latest_config?.message_error
  );

  // Status de confirmação de configuração (-1, 0, 1, 2) --> legenda na função getRadioStatus
  const [radioStatus, setRadioStatus] = useState<number>(
    props.iMeter.latest_config?.message_status
  );

  useEffect(() => {
    socket.subscribe(`${SOCKET_SUFFIX}imeter@${iMeterID}`);

    socket.bind("IMeterConfig_standard", (data) => {
      if (data?.message_error) {
        setError(data.message_error);
      }
      setLastUpdate(moment(data.created).format("DD MMM YYYY HH:mm"));
      setCreatedOnHardware(data.created_on_hardware);
      // Setamos apenas status maiores que o anterior pela situação assíncrona do socket
      if (radioStatus <= data.message_status) {
        setRadioStatus(data.message_status);
      }
    });

    return () => {
      socket.unsubscribe(`${SOCKET_SUFFIX}imeter@${iMeterID}`);
    };
  }, []);

  useEffect(() => {
    checkErrors();
  }, [validationErrors]);

  // ------------------------------------------------------------------ COMEÇO DAS ABAS

  // GERAL -------------------------------------------------------------------

  const [lastUpdate, setLastUpdate] = useState(
    moment(props.iMeter.latest_config.created).format("DD MMM YYYY HH:mm")
  );

  const [createdOnHardware, setCreatedOnHardware] = useState<boolean>(
    props.iMeter.latest_config.created_on_hardware
  );

  const [imeterName, setImeterName] = useState<string>(
    props.iMeter.latest_config.imeter_name
  );

  const setValidImeterName = useCallback(
    (value) => {
      let errorID = "imeterName";
      setImeterName(value);
      setValidationErrors({
        ...validationErrors,
        [errorID]: checkIsOnIntervalString(value, 0, 20),
      });
    },
    [validationErrors]
  );

  const [meterSystemName, setMeterSystemName] = useState<string>(
    props.iMeter.latest_config.metersystem_name
  );

  const setValidMeterSystemName = useCallback(
    (value) => {
      let errorID = "meterSystemName";
      setMeterSystemName(value);
      setValidationErrors({
        ...validationErrors,
        [errorID]: checkIsOnIntervalString(value, 0, 17),
      });
    },
    [validationErrors]
  );

  //Sets value to an unexisting start value so when all the data options is loaded it can set it to current config value

  const [sensor, setSensor] = useState<number>(-1);

  // List of sensors defined in the backend so that you are always up to date with the
  // list of available sensors, thus avoiding errors in registering and editing meters

  const [sensorOptions, setSensorOptions] = useState<any[]>([]);

  useEffect(() => {
    (async () => {
      coreHTTPClient.get(`v3/sensors/`).then((res) => {
        let sensors: any[] = [];
        res.data.forEach(
          (sensor: {
            id: number;
            available: boolean;
            sensor: { name: string };
          }) => {
            if (sensor.available) {
              sensors.push([sensor.id, sensor.sensor.name]);
            } else if (
              sensor.id == props.iMeter.sensor_process_controller_pair.id
            ) {
              sensors.push([sensor.id, sensor.sensor.name]);
            }
          }
        );
        setSensorOptions(sensors);
        setSensor(props.iMeter.sensor_process_controller_pair.sensor);
      });
    })();
  }, []);

  const [measureScale, setMeasureScale] = useState(
    props.iMeter.latest_config?.measure_scale
  );

  const [a, setA] = useState<number | string>(
    props.iMeter.latest_config?.flow_curve_equation[0]
  );

  const setValidA = useCallback(
    (value) => {
      setA(value);
      if (!value) {
        setValidationErrors({
          ...validationErrors,
          a: "ERROR_EMPTY_FIELD",
        });
      } else {
        setValidationErrors({
          ...validationErrors,
          a: undefined,
        });
      }
    },
    [validationErrors]
  );

  const [b, setB] = useState<number | string>(
    props.iMeter.latest_config?.flow_curve_equation[1]
  );

  const setValidB = useCallback(
    (value) => {
      setB(value);
      if (!value) {
        setValidationErrors({
          ...validationErrors,
          b: "ERROR_EMPTY_FIELD",
        });
      } else {
        setValidationErrors({
          ...validationErrors,
          b: undefined,
        });
      }
    },
    [validationErrors]
  );

  const [c, setC] = useState<number | string>(
    props.iMeter.latest_config?.flow_curve_equation[2]
  );

  const setValidC = useCallback(
    (value) => {
      setC(value);
      if (!value) {
        setValidationErrors({
          ...validationErrors,
          c: "ERROR_EMPTY_FIELD",
        });
      } else {
        setValidationErrors({
          ...validationErrors,
          c: undefined,
        });
      }
    },
    [validationErrors]
  );

  // Checkbox que indica se o usuário deseja setar o relógio do equipamento manualmente
  const [rtc, setRtc] = useState<boolean>(false);

  const [clock, setClock] = useState(moment());

  // LOCALIZAÇÃO -------------------------------------------------------------------

  const [iMeterLat, setIMeterLat] = useState(
    props.iMeter.latest_config.position_imeter.split(",")[0]
  );

  const setValidIMeterLat = useCallback(
    (value) => {
      setIMeterLat(value);
    },
    [validationErrors]
  );

  const [iMeterLng, setIMeterLng] = useState(
    props.iMeter.latest_config.position_imeter.split(",")[1]
  );

  const setValidIMeterLng = useCallback(
    (value) => {
      setIMeterLng(value);
    },
    [validationErrors]
  );

  // NÍVEL -------------------------------------------------------------------

  const [sensorMaxValue, setSensorMaxValue] = useState<number | null>(
    props.iMeter.latest_config?.graphic_max_value
  );

  const setValidSensorMaxValue = useCallback(
    (value) => {
      setSensorMaxValue(value);
      if (parseFloat(value) < 1) {
        setSensorMaxValue(1);
        setValidationErrors({
          ...validationErrors,
          sensorMaxValue: undefined,
        });
      } else if (!value) {
        setValidationErrors({
          ...validationErrors,
          sensorMaxValue: "ERROR_EMPTY_FIELD",
        });
      } else {
        setValidationErrors({
          ...validationErrors,
          sensorMaxValue: undefined,
        });
      }
    },
    [validationErrors]
  );

  const [sensorOffset, setSensorOffset] = useState<number | string>(
    props.iMeter.latest_config?.sensor_offset / 100
  );

  const setValidSensorOffset = useCallback(
    (value) => {
      setSensorOffset(value);
      if (!value) {
        setValidationErrors({
          ...validationErrors,
          sensorOffset: "ERROR_EMPTY_FIELD",
        });
      } else {
        setValidationErrors({
          ...validationErrors,
          sensorOffset: undefined,
        });
      }
    },
    [validationErrors]
  );

  const [minLimit, setMinLimit] = useState<number>(
    props.iMeter.latest_config?.min_limit
  );

  const setValidMinLimit = useCallback(
    (value) => {
      setMinLimit(value);
      if (parseFloat(value) < 0) {
        setMinLimit(0);
        setValidationErrors({
          ...validationErrors,
          minLimit: undefined,
        });
      } else if (parseFloat(value) > 100) {
        setMinLimit(100);
        setValidationErrors({
          ...validationErrors,
          minLimit: undefined,
        });
      } else {
        setMinLimit(value);
        setValidationErrors({
          ...validationErrors,
          minLimit: undefined,
        });
      }
    },
    [validationErrors]
  );

  const [maxLimit, setMaxLimit] = useState<number>(
    props.iMeter.latest_config?.max_limit
  );

  const setValidMaxLimit = useCallback(
    (value) => {
      setMaxLimit(value);
      if (parseFloat(value) < 0) {
        setMaxLimit(0);
        setValidationErrors({
          ...validationErrors,
          minLimit: undefined,
        });
      } else if (parseFloat(value) > 100) {
        setMaxLimit(100);
        setValidationErrors({
          ...validationErrors,
          maxLimit: undefined,
        });
      } else {
        setMaxLimit(value);
        setValidationErrors({
          ...validationErrors,
          maxLimit: undefined,
        });
      }
    },
    [validationErrors]
  );

  // HORÁRIO DE PICO ? -------------------------------------------------------------------

  // Setamos os checkboxes dos dias da semana de horário de pico nesta variável
  const [weekday, setWeekday] = useState<any>({
    mon: props.iMeter.latest_config.content.peak_time.monday_enable,
    tue: props.iMeter.latest_config.content.peak_time.tuesday_enable,
    wed: props.iMeter.latest_config.content.peak_time.wednesday_enable,
    thu: props.iMeter.latest_config.content.peak_time.thursday_enable,
    fri: props.iMeter.latest_config.content.peak_time.friday_enable,
    sat: props.iMeter.latest_config.content.peak_time.saturday_enable,
    sun: props.iMeter.latest_config.content.peak_time.sunday_enable,
  });

  const [measureUnit, setMeasureUnit] = useState(
    props.iMeter.latest_config?.measure_unit === "l"
      ? props.iMeter.latest_config?.measure_unit.toUpperCase()
      : props.iMeter.latest_config?.measure_unit
  );

  // Checkbox que indica caso o usuário deseja setar o horário de pico 1
  const [pauseTimeStatus1, setPauseTimeStatus1] = useState<boolean>(
    props.iMeter.latest_config.content.enable_peak_time.enable === 1
      ? true
      : false
  );

  // Checkbox que indica caso o usuário deseja setar o horário de pico 2
  const [pauseTimeStatus2, setPauseTimeStatus2] = useState<boolean>(
    (props.iMeter.latest_config.content?.peak_time.start_hour_1 === 0 &&
      props.iMeter.latest_config.content?.peak_time.start_minute_1 === 0 &&
      props.iMeter.latest_config.content?.peak_time.stop_hour_2 === 0 &&
      props.iMeter.latest_config.content?.peak_time.end_minute_2 === 0) ||
      pauseTimeStatus1 === false
      ? false
      : true
  );

  // Horário de Pico Inicial referente ao horário de pico 1
  const [pauseTimeStart1, setPauseTimeStart1] = useState<any>(
    props.iMeter.latest_config.content.enable_peak_time.enable === 1
      ? moment().set({
          hour: props.iMeter.latest_config.content.peak_time.start_hour_1,
          minute: props.iMeter.latest_config.content.peak_time.start_minute_1,
          second: 0,
          millisecond: 0,
        })
      : moment().set({
          hour: 0,
          minute: 0,
          second: 0,
          millisecond: 0,
        })
  );

  // Horário de Pico Final referente ao horário de pico 1
  const [pauseTimeEnd1, setPauseTimeEnd1] = useState<any>(
    props.iMeter.latest_config.content.enable_peak_time.enable === 1
      ? moment().set({
          hour: props.iMeter.latest_config.content.peak_time.stop_hour_1,
          minute: props.iMeter.latest_config.content.peak_time.end_minute_1,
          second: 0,
          millisecond: 0,
        })
      : moment().set({
          hour: 0,
          minute: 0,
          second: 0,
          millisecond: 0,
        })
  );

  // Horário de Pico Inicial referente ao horário de pico 2
  const [pauseTimeStart2, setPauseTimeStart2] = useState<any>(
    moment().set({
      hour: props.iMeter.latest_config.content.peak_time.start_hour_2,
      minute: props.iMeter.latest_config.content.peak_time.start_minute_2,
      second: 0,
      millisecond: 0,
    })
  );

  // Horário de Pico Final referente ao horário de pico 2
  const [pauseTimeEnd2, setPauseTimeEnd2] = useState<any>(
    props.iMeter.latest_config.content?.peak_time.start_hour_2 === 0 &&
      props.iMeter.latest_config.content?.peak_time.start_minute_2 === 0 &&
      props.iMeter.latest_config.content?.peak_time.stop_hour_2 === 0 &&
      props.iMeter.latest_config.content?.peak_time.end_minute_2 === 0
      ? moment().set({
          hour: 0,
          minute: 1,
          second: 0,
          millisecond: 0,
        })
      : moment().set({
          hour: props.iMeter.latest_config.content.peak_time.stop_hour_2,
          minute: props.iMeter.latest_config.content.peak_time.end_minute_2,
          second: 0,
          millisecond: 0,
        })
  );

  const doneIconInput = (
    <DoneAllContainer>
      <DoneAll style={{ color: blue[500] }} />
    </DoneAllContainer>
  );

  function getRadioStatusIcon(status: number) {
    /* 
      Status (-1): enviando configuração ao equipamenti
      Status ( 0): equipamento não recebeu a configuração
      Status ( 1): configuração enviada ao equipamento
      Status ( 2): configuração salva pelo equipamento
    */
    switch (status) {
      case -1:
        return <CircularProgress size={18} />;
      case 0:
        return null;
      case 1:
        return <Done />;
      case 2:
        return doneIconInput;
      default:
        return null;
    }
  }

  // Caso o usuário ative o horário de pico 1 porém não ative nenhum dia da semana, desativa o botão de salvar
  useEffect(() => {
    if (
      pauseTimeStatus1 &&
      !weekday.mon &&
      !weekday.tue &&
      !weekday.wed &&
      !weekday.thu &&
      !weekday.fri &&
      !weekday.sat &&
      !weekday.sun
    ) {
      setHideButtonSave(true);
    } else setHideButtonSave(false);
  }, [weekday, pauseTimeStatus1, pauseTimeStatus2]);

  // bind d@imeter@id IMeterConfig_standard

  // Função que procura por erros na variável validationErros, para habilitar ou não o botão salvar
  const checkErrors = () => {
    for (const key in validationErrors) {
      if (validationErrors.hasOwnProperty(key)) {
        const element = validationErrors[key];
        if (element !== undefined) {
          setHideButtonSave(true);
          return;
        } else {
          setHideButtonSave(false);
        }
      }
    }
  };

  /*
    Caso   o horário  de pico 2  não  esteja  ativado,
    desativamos a possiblidade de erro na sobreposição
    dos horários de pico 1 e 2
  */
  useEffect(() => {
    if (!pauseTimeStatus2) {
      setValidationErrors({
        ...validationErrors,
        ["pauseTimeOverlap"]: undefined,
      });
    }
  }, [pauseTimeStatus2]);

  // Apontando o input resposável pelo erro de sobreposição nos horários de pico
  useEffect(() => {
    if (pauseTimeStart1.isSameOrAfter(pauseTimeEnd1)) {
      setValidationErrors({
        ...validationErrors,
        ["pauseTimeStart1"]: "EDIT_PIVOT_V5_ERROR_PAUSE_TIME_END",
      });
    } else {
      setValidationErrors({
        ...validationErrors,
        ["pauseTimeStart1"]: undefined,
      });
    }
  }, [pauseTimeStart1, pauseTimeEnd1]);

  // Apontando o input resposável pelo erro de sobreposição nos horários de pico
  useEffect(() => {
    if (pauseTimeStart2.isSameOrAfter(pauseTimeEnd2)) {
      setValidationErrors({
        ...validationErrors,
        ["pauseTimeStart2"]: "EDIT_PIVOT_V5_ERROR_PAUSE_TIME_END",
      });
    } else {
      setValidationErrors({
        ...validationErrors,
        ["pauseTimeStart2"]: undefined,
      });
    }
  }, [pauseTimeStart2, pauseTimeEnd2]);

  useEffect(() => {
    if (!pauseTimeStatus1) {
      setPauseTimeStatus2(false);
    }
  }, [pauseTimeStatus1]);

  // Intervalo de Medição , de quanto em quantos segundos
  // um IMeterStream do tipo 'periodic' será recebido do hardware
  const [periodicTimer, setPeriodicTimer] = useState<any>(60);

  // ------------------------------------------------------------------ FIM DAS ABAS

  // Responsável pelas mudanças de abas do config
  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  function handleSelectedTab(index) {
    return {
      id: `vertical-tab-${index}`,
      "aria-controls": `vertical-tabpanel-${index}`,
    };
  }

  async function handleSaveButton() {
    setError(undefined);
    setLoading(true);
    setRadioStatus(-1);
    setSelectedTab(0);

    if (!rtc) setClock(moment());

    await new Promise(async (resolve) => {
      try {
        // Temos o campo 'name' vindo do IMeter que podemos atualizar com este PATCH
        await coreHTTPClient.patch(
          `v3/farms/${farmID}/metersystems/${meterSystemID}/meter/${iMeterID}/`,
          {
            name: imeterName,
            position: String(iMeterLat) + "," + String(iMeterLng),
            sensor_process_controller_pair: sensor,
          }
        );

        await coreHTTPClient.patch(
          `v3/farms/${farmID}/metersystems/${meterSystemID}/`,
          {
            name: meterSystemName,
          }
        );

        // Salvo novo config
        const saveConfigResponse = await coreHTTPClient.post(
          `v3/farms/${farmID}/metersystems/${meterSystemID}/meter/${iMeterID}/config/standard/`,
          {
            content: {
              clock: {
                second: rtc ? clock.second() : moment().second(),
                minute: rtc ? clock.minute() : moment().minute(),
                hour: rtc ? clock.hour() : moment().hour(),
                day: rtc ? clock.date() : moment().date(),
                month: rtc ? clock.month() + 1 : moment().month() + 1,
                year: rtc ? clock.year() - 2000 : moment().year() - 2000,
              },
              enable_peak_time: {
                enable: pauseTimeStatus1 ? 1 : 0,
              },
              peak_time: {
                start_hour_1: pauseTimeStatus1
                  ? parseInt(`${numPad(pauseTimeStart1.hour(), 2)}`)
                  : 0,
                start_minute_1: pauseTimeStatus1
                  ? parseInt(`${numPad(pauseTimeStart1.minutes(), 2)}`)
                  : 0,
                stop_hour_1: pauseTimeStatus1
                  ? parseInt(`${numPad(pauseTimeEnd1.hour(), 2)}`)
                  : 0,
                stop_minute_1: pauseTimeStatus1
                  ? parseInt(`${numPad(pauseTimeEnd1.minutes(), 2)}`)
                  : 0,
                start_hour_2: pauseTimeStatus2
                  ? parseInt(`${numPad(pauseTimeStart2.hour(), 2)}`)
                  : 0,
                start_minute_2: pauseTimeStatus2
                  ? parseInt(`${numPad(pauseTimeStart2.minutes(), 2)}`)
                  : 0,
                stop_hour_2: pauseTimeStatus2
                  ? parseInt(`${numPad(pauseTimeEnd2.hour(), 2)}`)
                  : 0,
                stop_minute_2: pauseTimeStatus2
                  ? parseInt(`${numPad(pauseTimeEnd2.minutes(), 2)}`)
                  : 0,
                sunday_enable: pauseTimeStatus1 ? weekday.sun : 0,
                monday_enable: pauseTimeStatus1 ? weekday.mon : 0,
                tuesday_enable: pauseTimeStatus1 ? weekday.tue : 0,
                wednesday_enable: pauseTimeStatus1 ? weekday.wed : 0,
                thursday_enable: pauseTimeStatus1 ? weekday.thu : 0,
                friday_enable: pauseTimeStatus1 ? weekday.fri : 0,
                saturday_enable: pauseTimeStatus1 ? weekday.sat : 0,
              },
              periodic_stream_timer: {
                time: parseInt(periodicTimer),
              },
            },
            graphic_max_value: parseFloat(sensorMaxValue.toString()),
            sensor_offset: parseInt(
              String(parseFloat(String(sensorOffset)) * 100)
            ),
            flow_curve_equation: [a, b, c],
            measure_scale: measureScale,
            measure_unit: measureUnit,
            min_limit: minLimit ? minLimit : 0,
            max_limit: maxLimit ? maxLimit : 0,
            metersystem_name: meterSystemName,
            imeter_name: imeterName,
            position_imeter: String(iMeterLat) + "," + String(iMeterLng),
            sensor_process_controller_pair: sensor,
          }
        );

        notify("IRPD_BOX_CONFIGURATION_SAVED", "success");
        setCounter(12);
      } catch {
        notify("IRPD_BOX_CONFIGURATION_ERROR", "error");
        setCounter(0);
      } finally {
        setLoading(false);
      }
    });
  }

  const saveButton = (
    <Button
      color="primary"
      disabled={hideButtonSave || iMeterCoordsError || loading || counter !== 0}
      endIcon={
        loading || counter !== 0 ? (
          <CircularProgress size={20} style={{ marginLeft: "8px" }} />
        ) : (
          <CheckCircle />
        )
      }
      onClick={() => {
        handleSaveButton();
      }}
    >
      {counter !== 0 ? (
        `${counter}`
      ) : (
        <>{i18n("SELECTED_IRPD_SAVE_BUTTON_TEXT")}</>
      )}
    </Button>
  );

  const backButton = (
    <Button
      color="primary"
      size="small"
      onClick={() => {
        navigate(
          routes.DASHBOARD.SELECTED_METER_SYSTEM.replace(":farm", farmID)
            .replace(":metersystem", meterSystemID)
            .replace(":meter", iMeterID)
        );
      }}
      startIcon={<ArrowBack />}
    >
      <>{i18n("SELECTED_IRPD_BACK_BUTTON_TEXT")}</>
    </Button>
  );

  const markers = [
    {
      lat: {
        state: iMeterLat,
        setFunction: setValidIMeterLat,
      },
      lng: {
        state: iMeterLng,
        setFunction: setValidIMeterLng,
      },
      markerUrl: greenDot,
      key: "center",
      event: {
        state: event,
        setFunction: setEvent,
      },
    },
  ];

  const markerButton = () => (
    <Button3D
      onClick={() => {
        dispatch(
          showLocationModal({
            content: (
              <GeolocationGetter
                setLatitude={setIMeterLat}
                setLongitude={setIMeterLng}
                recoilSelector={centerMarkerSelector}
              />
            ),
          })
        );
      }}
    >
      <img src={greenDot} />
    </Button3D>
  );

  useEffect(() => {
    if (props.iMeter.function === "PULSE_FLOW") {
      setValidationErrors({ ...validationErrors, sensorOffset: undefined });
      setValidationErrors({ ...validationErrors, a: undefined });
      setValidationErrors({ ...validationErrors, b: undefined });
      setValidationErrors({ ...validationErrors, c: undefined });
    } else {
      setValidationErrors({ ...validationErrors, mesureScale: undefined });
      setValidationErrors({ ...validationErrors, measureUnit: undefined });
    }
  }, [props.iMeter.function]);

  const showHistoricConfigModal = (
    farmID: number,
    iMeter: any,
    iMeterID: number,
    meterSystemID: number,
    loadHistoricInfo: any
  ) => {
    dispatch(
      showModal({
        content: (
          <HistoricConfigMobileV5Imeter
            farmID={farmID}
            iMeter={iMeter}
            iMeterID={iMeterID}
            meterSystemID={meterSystemID}
            loadHistoricInfo={loadHistoricInfo}
            sensorOptions={sensorOptions}
          />
        ),
        title: "EDIT_PIVOT_LABEL_SETTING_HISTORIC",
      })
    );
  };

  const setImeterLatLng = useSetRecoilState(centerMarkerSelector);

  // função que passa os setters dos estados dos inputs da tela de
  // Editar Medidor para o botão CARREGAR do histórico de configurações
  function loadHistoricInfo(
    metersystemName: string,
    imeterName: string,
    imeterSensor: any,
    imeterA: any,
    imeterB: any,
    imeterC: any,
    imeterLat: string,
    imeterLng: string,
    imeterMarker: any,
    imeterSensorMaxValue: any,
    imeterSensorOffset: any,
    imeterMinLimit: any,
    imeterMaxLimit: any
  ) {
    try {
      setValidMeterSystemName(metersystemName);
      setValidImeterName(imeterName);
      setSensor(imeterSensor);
      setValidA(imeterA);
      setValidB(imeterB);
      setValidC(imeterC);
      setValidIMeterLat(imeterLat);
      setValidIMeterLng(imeterLng);
      setImeterLatLng(imeterMarker);
      setValidSensorMaxValue(imeterSensorMaxValue);
      setValidSensorOffset(imeterSensorOffset);
      setValidMinLimit(imeterMinLimit);
      setValidMaxLimit(imeterMaxLimit);

      notify("EDIT_IRPD_LOAD_SUCCESS", "success", 3000);
      dispatch(hideModal());
    } catch (error) {
      notify("EDIT_IRPD_LOAD_ERROR", "error");
    }
  }

  return (
    <EditMeterSystem>
      <DrawerRightEditIMETER
        iMeter={props.iMeter}
        loadHistoricInfo={loadHistoricInfo}
        sensorOptions={sensorOptions}
      />

      <Container>
        <DashboardBox
          leftElement={backButton}
          centerElement={<h2>{i18n("METER_SYSTEM_EDIT")}</h2>}
          rightElement={saveButton}
        >
          <DashboardBoxContainer>
            <Tabs
              orientation={"horizontal"}
              value={selectedTab}
              onChange={handleTabChange}
              indicatorColor="primary"
              aria-label="Edit Farm"
              centered={isMobile() ? true : false}
            >
              <Tab
                className={classes.tab}
                label={i18n("EDIT_PIVOT_LABEL_GENERAL")}
                {...handleSelectedTab(0)}
                icon={
                  validationErrors["name"] ||
                  validationErrors["imeterName"] ||
                  validationErrors["meterSystemName"] ||
                  validationErrors["a"] ||
                  validationErrors["b"] ||
                  validationErrors["c"] ? (
                    <Settings
                      style={{
                        color: red[500],
                      }}
                    />
                  ) : (
                    <Settings
                      style={{
                        color: green[500],
                      }}
                    />
                  )
                }
              />

              <Tab
                className={classes.tab}
                label={i18n("EDIT_PIVOT_LABEL_LOCATION")}
                {...handleSelectedTab(1)}
                icon={
                  iMeterCoordsError ? (
                    <Map
                      style={{
                        color: red[500],
                      }}
                    />
                  ) : (
                    <Map
                      style={{
                        color: green[500],
                      }}
                    />
                  )
                }
              />

              <Tab
                className={classes.tab}
                label={i18n("EDIT_IMETER_LEVEL_TAB")}
                {...handleSelectedTab(2)}
                icon={
                  validationErrors["sensorMaxValue"] ||
                  validationErrors["sensorOffset"] ||
                  validationErrors["minLimit"] ||
                  validationErrors["maxLimit"] ? (
                    <Straighten
                      style={{
                        color: red[500],
                        transform: "rotate(270deg)",
                      }}
                    />
                  ) : (
                    <Straighten
                      style={{
                        color: green[500],
                        transform: "rotate(270deg)",
                      }}
                    />
                  )
                }
              />
            </Tabs>

            {/* GERAL ----------------------------------------------------- */}

            <TabPanel value={selectedTab} index={0}>
              <LastSettings>
                <span>{i18n("EDIT_PIVOT_LAST_SETTINGS", [lastUpdate])}</span>
                {createdOnHardware ? (
                  <span>{i18n("EDIT_PIVOT_MANUAL")}</span>
                ) : (
                  <span>{i18n("EDIT_PIVOT_BY_WEB")}</span>
                )}
              </LastSettings>

              <div
                className="general-form__row-3-3-3-3"
                style={{ marginBottom: "20px" }}
              >
                <ChangeRadioField
                  radioID={props.iMeter.base.radio_id}
                  farmID={parseInt(farmID)}
                  deviceID={props.iMeter.id}
                  label={i18n("EDIT_PIVOT_CENTRAL_LABEL")}
                  deviceType="IMETER"
                  meterSystemID={meterSystemID}
                  status={0}
                  locked
                />

                <ChangeRadioField
                  radioID={props.iMeter.imeter_device.radio_id}
                  farmID={parseInt(farmID)}
                  deviceID={props.iMeter.id}
                  label={i18n("EDIT_PIVOT_IMANAGE_RADIO")}
                  status={radioStatus}
                  deviceType="IMETER"
                  meterSystemID={meterSystemID}
                  deviceSubType="imeter_device"
                />
              </div>

              <h4 style={{ margin: 0, marginBottom: "5px", marginTop: "0px" }}>
                {i18n("METER_SYSTEM_EDIT_GENERAL")}
              </h4>

              <GridContainer>
                <FormInput
                  id="meterSystem-name"
                  helperText={validationErrors["meterSystemName"]}
                  label={"METERSYSTEM_FIELD_NAME"}
                  state={[meterSystemName, setValidMeterSystemName]}
                />
                <FormInput
                  id="imeter-name"
                  helperText={validationErrors["imeterName"]}
                  label={"IMETER_FIELD_NAME"}
                  state={[imeterName, setValidImeterName]}
                />
                <FormSelect
                  id="pivot-config-language"
                  label={"IMETER_CONFIG_V5_FIELD_SENSOR"}
                  state={[sensor, setSensor]}
                  values={sensorOptions}
                  noI18n
                />
              </GridContainer>

              <div
                style={
                  props.iMeter.function === "PULSE_FLOW"
                    ? { display: "none" }
                    : {}
                }
              >
                <Divider style={{ marginTop: "20px", marginBottom: "20px" }} />

                <h4
                  style={{
                    margin: 0,
                    marginBottom: "5px",
                    marginTop: "0px",
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  {i18n("METERSYSTEM_FLOW_CURVE")}
                  <InputTooltip
                    description={i18n("METERSYSTEM_FLOW_CURVE_TOOLTIP")}
                    size={14}
                  />
                </h4>

                <div style={{ display: "flex" }}>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <FormInput
                      id="state-a"
                      helperText={validationErrors["a"]}
                      type="number"
                      inputMode="numeric"
                      label={null}
                      stringLabel={"A"}
                      state={[String(a), setValidA]}
                      endAdornment="x²"
                    />
                    <div style={{ margin: "35px 7px 7px 7px" }}>+</div>
                  </div>

                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <FormInput
                      id="state-b"
                      helperText={validationErrors["b"]}
                      type="number"
                      inputMode="numeric"
                      label={null}
                      stringLabel={"B"}
                      state={[String(b), setValidB]}
                      endAdornment="x"
                    />
                    <div style={{ margin: "35px 7px 7px 7px" }}>+</div>
                  </div>

                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <FormInput
                      id="state-c"
                      helperText={validationErrors["c"]}
                      type="number"
                      inputMode="numeric"
                      label={null}
                      stringLabel={"C"}
                      state={[String(c), setValidC]}
                      endAdornment=""
                    />
                  </div>
                </div>
              </div>

              <Divider style={{ marginTop: "20px", marginBottom: "20px" }} />

              <h4 style={{ margin: 0, marginBottom: "5px", marginTop: "0px" }}>
                {i18n("EDIT_IRPD_FIELD_RTCDATE")}
              </h4>

              <ClockContainer>
                <CheckBoxItem>
                  <Checkbox
                    color="primary"
                    checked={rtc}
                    onChange={(event) => {
                      setRtc((prevState) => !prevState);
                    }}
                  />

                  <CheckBoxLabel style={{ maxWidth: "180px" }}>
                    {i18n("PIVOT_CONFIG_V5_FIELD_CLOCK")}
                  </CheckBoxLabel>
                </CheckBoxItem>

                <FormDateTimePicker
                  disabled={!rtc}
                  id="iMeter-config-rtc-date"
                  label={"PIVOT_CONFIG_V5_FIELD_CLOCK_LABEL"}
                  state={[clock, setClock]}
                />
              </ClockContainer>
            </TabPanel>

            {/* LOCALIZAÇÃO ----------------------------------------------------- */}

            <TabPanel value={selectedTab} index={1}>
              <div>
                <FormInputPosition
                  setError={setiMeterCoordsError}
                  id="imeter-config-center"
                  markerUrl={markerButton()}
                  label={"PIVOT_CONFIG_DEVICE_FIELD_GP_CENTER"}
                  lat={[iMeterLat, setValidIMeterLat]}
                  lng={[iMeterLng, setValidIMeterLng]}
                  recoilSelector={centerMarkerSelector}
                />
                <div style={{ height: "400px", width: "100%" }}>
                  <DraggableMap
                    _setExperimentalRecoil
                    markers={markers}
                    zoomScroll={false}
                  />
                </div>
              </div>
            </TabPanel>

            {/* NÍVEL ----------------------------------------------------- */}

            <TabPanel value={selectedTab} index={2}>
              <h4 style={{ margin: 0, marginBottom: "5px", marginTop: "0px" }}>
                {i18n("EDIT_IMETER_LEVEL_TAB_SENSOR_INFO_TITLE")}
              </h4>

              <GridContainer>
                <div
                  style={
                    props.iMeter.function === "PULSE_FLOW"
                      ? { display: "none" }
                      : {}
                  }
                >
                  <FormInput
                    id="state-sensor-max-value"
                    helperText={validationErrors["sensorMaxValue"]}
                    type="number"
                    min="1"
                    inputMode="numeric"
                    label={null}
                    stringLabel={i18n("GRAPHIC_SCALE")}
                    tooltip={"GRAPHIC_SCALE_TOOLTIP"}
                    state={[String(sensorMaxValue), setValidSensorMaxValue]}
                    endAdornment="m"
                  />
                </div>

                <div
                  style={
                    props.iMeter.function === "PULSE_FLOW"
                      ? { display: "none" }
                      : {}
                  }
                >
                  <FormInput
                    id="state-sensor-offset"
                    helperText={validationErrors["sensorOffset"]}
                    type="number"
                    inputMode="numeric"
                    label={null}
                    stringLabel={i18n("MEASUREMENT_OFFSET")}
                    tooltip={"MEASUREMENT_OFFSET_TOOLTIP"}
                    state={[String(sensorOffset), setValidSensorOffset]}
                    endAdornment="m"
                  />
                </div>
              </GridContainer>

              <Divider style={{ marginTop: "20px", marginBottom: "20px" }} />

              <h4 style={{ margin: 0, marginBottom: "5px", marginTop: "0px" }}>
                {i18n("EDIT_IMETER_LEVEL_TAB_CHART_CONFIG_TITLE")}
              </h4>

              <p>{i18n("EDIT_IMETER_LEVEL_TAB_CHART_CONFIG_TEXT")}</p>

              <GridContainer>
                <FormInput
                  id="min-limit"
                  helperText={validationErrors["minLimit"]}
                  type="number"
                  min="0"
                  max="100"
                  inputMode="numeric"
                  label={null}
                  stringLabel={i18n("EDIT_IMETER_LEVEL_TAB_MIN_LIMIT_INPUT")}
                  state={[String(minLimit), setValidMinLimit]}
                  endAdornment="%"
                />

                <FormInput
                  id="max-limit"
                  helperText={validationErrors["maxLimit"]}
                  type="number"
                  min="0"
                  max="100"
                  inputMode="numeric"
                  label={null}
                  stringLabel={i18n("EDIT_IMETER_LEVEL_TAB_MAX_LIMIT_INPUT")}
                  state={[String(maxLimit), setValidMaxLimit]}
                  endAdornment="%"
                />
              </GridContainer>
            </TabPanel>
          </DashboardBoxContainer>
          <ProtocolFooter protocol={parseFloat(props?.iMeter?.protocol)} />
        </DashboardBox>

        {isMobile() && (
          <div
            onClick={() => {
              showHistoricConfigModal(
                Number(farmID),
                props.iMeter,
                Number(iMeterID),
                Number(meterSystemID),
                loadHistoricInfo
              );
            }}
            style={{ display: modal.active ? "none" : "initial" }}
          >
            <ShowHistoricFloatingLabel className={classes.tabBar} />
          </div>
        )}
      </Container>
    </EditMeterSystem>
  );
}

import React, { useMemo } from "react";
import { Column } from "../../../../../../../../components/GeneralTable/GeneralTable";
import moment from "moment";
import { ReactJSX } from "../../../../../../../../utils/types";
import { getModeAdd } from "../../../../../../../../utils/models/pivots";
import ExpandedContentConfigV5 from "../ExpandedContent/ExpandedContentConfigV5/ExpandedContentConfigV5";
import ConfigHistoryTable from "../ConfigHistoryTable";
import { Tooltip, Button } from "@material-ui/core";
import RemoveIcon from "@material-ui/icons/Remove";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import DoneIcon from "@material-ui/icons/Done";
import { Irpd } from "../../../../../../../../redux/irpds/types";
import useIrpdConfigHistoricV5 from "../../../../../../../../hooks/models/useIrpdConfigHistoricV5";
import { i18n } from "../../../../../../../../i18n/i18nText";
import { Labels } from "../../../HistoricConfigMobile/HistoricConfigMobileV5";
import DesktopZone from "../../../../../../../../components/Zones/DesktopZone";
import { isMobile } from "../../../../../../../../mobileConfig";

const noPointer1 = {
  cursor: "default",
  marginTop: "-5px",
};

const noPointer2 = {
  cursor: "default",
  marginTop: "-7px",
};

const noPointer3 = {
  cursor: "default",
  marginTop: "-7px",
};

function getMessageStatusIcon(status: number): JSX.Element | null {
  switch (status) {
    case 0:
      return <RemoveIcon style={noPointer1} htmlColor={"rgba(0,0,0,0.6)"} />;
    case 1:
      return <DoneIcon style={noPointer2} htmlColor={"rgba(0,0,0,0.6)"} />;
    case 2:
      return <DoneAllIcon style={noPointer3} htmlColor={"rgb(33, 150, 243)"} />;
    default:
      break;
  }
}

function getMessageStatusTooltipText(status: number): string {
  switch (status) {
    case 0:
      return i18n("CONFIG_NOT_SENT");
    case 1:
      return i18n("CONFIG_SENT");
    case 2:
      return i18n("CONFIG_RECEIVED");
    default:
      break;
  }
}

interface Props {
  irpdID: number;
  farmID: number;
  loadHistoricInfo: (
    irpdName: string,
    irpdEnableMonthlyWaterLimit: boolean,
    irpdMonthlyWaterLimit: string,
    irpdPotency: string,
    irpdEnableSensorScale: boolean,
    irpdSensorScale: string,
    irpdEnergyTime: string,
    irpdFlow: string,
    irpdEnablePressureSensor: boolean,
    irpdPositionLat: string,
    irpdPositionLng: string,
    irpdPositionMarker: any,
    irpdKwhPeak: number,
    irpdKwhOutOfPeak: number,
    irpdKwhReduced: number,
    irpdWeek: any,
    irpdPauseTimeStatus1: any,
    irpdPauseTimeStart1: any,
    irpdPauseTimeEnd1: any,
    irpdPauseTimeStatus2: any,
    irpdPauseTimeStart2: any,
    irpdPauseTimeEnd2: any
  ) => void;
  irpd: Irpd;
  pinned?: boolean;
}

const HistoricConfigV5 = (props: Props) => {
  const { irpd, irpdID, farmID } = props;

  const [
    async,
    historic,
    total,
    previousPage,
    nextPage,
    currentPage,
  ] = useIrpdConfigHistoricV5(farmID, irpdID, props.pinned);

  // MOBILE -----------------------------------------------------------------------------

  const normalColumnsMobile: Column[] = [
    {
      id: "date",
      label: <>{i18n("HISTORIC_PANEL_LABEL_DATE")}</>,
      minWidth: 100,
    },
    {
      id: "by",
      label: <>{i18n("HISTORIC_PANEL_LABEL_BY")}</>,
      minWidth: 100,
    },
  ];

  const pinnedColumnsMobile: Column[] = [
    {
      id: "name",
      label: <>{i18n("SIGN_UP_NAME")}</>,
      minWidth: 200,
    },
    {
      id: "date",
      label: <>{i18n("HISTORIC_PANEL_LABEL_DATE")}</>,
      minWidth: 100,
    },
    {
      id: "by",
      label: <>{i18n("HISTORIC_PANEL_LABEL_BY")}</>,
      minWidth: 100,
    },
  ];

  const rowsMobile = useMemo(() => {
    if (historic === undefined) return; // Historic isn't loaded yet.

    return historic?.map((item) => {
      const by = (
        <span>
          {i18n("HISTORIC_CONFIG_PIVOT_DRIVE", [
            getModeAdd(item.created_on_hardware, item.created_by?.username),
          ])}
        </span>
      );

      const pump = (
        <Tooltip title={getMessageStatusTooltipText(item.message_status)}>
          {getMessageStatusIcon(item.message_status)}
        </Tooltip>
      );

      return {
        id: item.uuid,
        name: item.name ? item.name : " - ",
        date: moment(item.created).format("DD/MM/YYYY HH:mm"),
        by: by,
        datailedComponent: (): ReactJSX => (
          <ExpandedContentConfigV5
            pump={pump}
            irpdID={irpdID}
            farmID={farmID}
            irpd={irpd}
            irpdConfig={item}
            loadHistoricInfo={props.loadHistoricInfo}
          />
        ),
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [historic, irpd]);

  // DESKTOP -----------------------------------------------------------------------------

  const normalColumnsDesktop: Column[] = [
    {
      id: "date",
      label: <>{i18n("HISTORIC_PANEL_LABEL_DATE")}</>,
      minWidth: 100,
    },
    {
      id: "by",
      label: <>{i18n("HISTORIC_PANEL_LABEL_BY")}</>,
      minWidth: 100,
    },
    {
      id: "pump",
      label: (
        <Tooltip title={<>{i18n("HISTORIC_CONFIG_INF_PUMP")}</>}>
          <Button>
            <>{i18n("HISTORIC_PANEL_LABEL_PUMP")}</>
          </Button>
        </Tooltip>
      ),
      minWidth: 100,
      align: "center",
    },
  ];

  const pinnedColumnsDesktop: Column[] = [
    {
      id: "name",
      label: <>{i18n("SIGN_UP_NAME")}</>,
      minWidth: 200,
    },
    {
      id: "date",
      label: <>{i18n("HISTORIC_PANEL_LABEL_DATE")}</>,
      minWidth: 100,
    },
    {
      id: "by",
      label: <>{i18n("HISTORIC_PANEL_LABEL_BY")}</>,
      minWidth: 100,
    },
    {
      id: "pump",
      label: (
        <Tooltip title={<>{i18n("HISTORIC_CONFIG_INF_PUMP")}</>}>
          <Button>
            <>{i18n("HISTORIC_PANEL_LABEL_PUMP")}</>
          </Button>
        </Tooltip>
      ),
      minWidth: 100,
      align: "center",
    },
  ];

  const rowsDesktop = useMemo(() => {
    if (historic === undefined) return; // Historic isn't loaded yet.

    return historic?.map((item) => {
      const by = (
        <span>
          {i18n("HISTORIC_CONFIG_PIVOT_DRIVE", [
            getModeAdd(item.created_on_hardware, item.created_by?.username),
          ])}
        </span>
      );

      const pump = (
        <Tooltip title={getMessageStatusTooltipText(item.message_status)}>
          {getMessageStatusIcon(item.message_status)}
        </Tooltip>
      );

      return {
        id: item.uuid,
        name: item.name ? item.name : " - ",
        date: moment(item.created).format("DD/MM/YYYY HH:mm"),
        by: by,
        pump: pump,
        datailedComponent: (): ReactJSX => (
          <ExpandedContentConfigV5
            irpdID={irpdID}
            farmID={farmID}
            irpd={irpd}
            irpdConfig={item}
            loadHistoricInfo={props.loadHistoricInfo}
          />
        ),
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [historic, irpd]);

  return (
    <div>
      <DesktopZone>
        <Labels />
      </DesktopZone>

      {isMobile() ? (
        <ConfigHistoryTable
          columns={props.pinned ? pinnedColumnsMobile : normalColumnsMobile}
          total={total}
          rows={rowsMobile}
          rowsPerPage={10}
          loading={
            historic === undefined || rowsMobile === undefined || async.loading
          }
          nextPage={nextPage}
          previousPage={previousPage}
          currentPage={currentPage}
        />
      ) : (
        <ConfigHistoryTable
          columns={props.pinned ? pinnedColumnsDesktop : normalColumnsDesktop}
          total={total}
          rows={rowsDesktop}
          rowsPerPage={10}
          loading={
            historic === undefined || rowsDesktop === undefined || async.loading
          }
          nextPage={nextPage}
          previousPage={previousPage}
          currentPage={currentPage}
        />
      )}

      <DesktopZone>
        <div style={{ marginBottom: "20px" }} />
      </DesktopZone>
    </div>
  );
};

export default HistoricConfigV5;

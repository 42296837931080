import React from "react";
import CustomCircularProgress from "../../../../components/CustomCircularProgress/CustomCircularProgress";
import useIrpd from "../../../../hooks/models/useIrpd";
import { useParams } from "react-router";
import EditIrpdForm from "./Components/EditLrpdForm/EditIrpdForm";
import EditIrpdFormV5 from "./Components/EditIrpdFormV5/EditIrpdFormV5";

export default function EditIrpd() {
  const farmID: number = parseInt(useParams().farm, 10);
  const irpdID: number = parseInt(useParams().irpd, 10);
  const [async, irpd, actions] = useIrpd(farmID, irpdID);

  if (async.done && irpd !== undefined) {
    if (irpd.protocol == 4) {
      return <EditIrpdForm irpd={irpd} />;
    } else return <EditIrpdFormV5 irpd={irpd} />;
  }

  return <CustomCircularProgress />;
}
